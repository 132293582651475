import React, { useState, useCallback, useEffect, useMemo } from "react";

import moment from "moment";
import { Popover } from "antd";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import { routeUrls } from "utils/routes";
import EvenListFilter from "./SearchFilter";
import HeadingGroup from "components/HeadingGroup";
import { getEventList } from "services/transaction";
import { TitleColumnButtonStyled } from "styled/ButtonStyled";
import { setEventFilterAction } from "store/reducers/SearchFilter/actions";
import { PopoverContentStyled, PopoverTextStyled } from "styled/PopoverStyled";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EVENT_LIST_PAGING,
  EVENT_TYPE,
} from "utils/constants";
import {
  EventListContainerStyled,
  DetailsButtonStyled,
  DetailsTextStyled,
} from "./Styled";
import { formatRouteEventDetail } from "utils/helpers";

const ManageEvenList = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { evenSearchFilter } = useAppSelector((state) => state.searchFilter);
  const { eventType } = useAppSelector((state) => state.event);

  const dispatch = useDispatch();

  const [data, setData] = useState<EventDetailProps[]>([]);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const [pagingIndex, setPageIndex] = useState(0);
  const [pagingData, setPagingData] = useState(EVENT_LIST_PAGING);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState(
    '{"queryKey":"createdDate","operation":"desc"}'
  );

  const customizeFilterData = (data: any) => {
    return Object.keys(data).reduce((newData: any, key: string) => {
      const value = data[key];
      if (!value || value === "AL") {
        return newData;
      }
      return [
        ...newData,
        {
          operation: "=",
          queryType: "string",
          queryKey: key,
          queryValue: value,
        },
      ];
    }, [] as any[]);
  };

  const getData = useCallback(async () => {
    setLoading(true);
    const customFilter = customizeFilterData(evenSearchFilter);

    try {
      const { data, paging } = await getEventList({
        pageIndex: pagingIndex,
        pageSize,
        filter: JSON.stringify(customFilter),
        sorts: sort,
      });

      setData(
        data.map((item: EventDetailProps) => ({
          ...item,
          key: item.eventId,
          eventType: eventType.find(
            (event: OptionProps) => event.value === item.eventType
          ).label,
        }))
      );
      setPagingData(paging);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [pagingIndex, pageSize, sort, evenSearchFilter, eventType]);

  useEffect(() => {
    getData();
  }, [getData]);

  const goToDetail = (eventId: number, eventType: string) => {
    if (eventType === EVENT_TYPE.DynamicPricing) {
      history(
        formatRouteEventDetail(routeUrls.dynamicPricingEventDetail, eventId)
      );
    } else if (eventType === EVENT_TYPE.MemberBenefit) {
      history(
        formatRouteEventDetail(routeUrls.memberBenefitEventDetail, eventId)
      );
    } else if (eventType === EVENT_TYPE.GuestPass) {
      history(
        formatRouteEventDetail(routeUrls.previewGuestPassEvent, eventId)
      );
    } else {
      history(
        formatRouteEventDetail(routeUrls.previewEventDetail, eventId)
      );
    }
  };

  const hoverContent = (eventName: string) => {
    return <PopoverContentStyled>{eventName}</PopoverContentStyled>;
  };

  const columns = useMemo(
    () => [
      {
        title: t("SS.EVENT_MANAGEMENT.NO."),
        key: "index",
        width: 20,

        render: (value: any, item: any, index: number) => (
          <>{index + 1 + pagingIndex * pageSize}</>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.EVENT_MANAGEMENT.EVENT_NAME")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "eventName",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,

        render: (eventName: string, item: any) => (
          <Popover content={hoverContent(eventName)} placement="topLeft">
            <PopoverTextStyled>
              <DetailsButtonStyled
                onClick={() => goToDetail(item.eventId, item.eventType)}
              >
                <DetailsTextStyled>{eventName}</DetailsTextStyled>
              </DetailsButtonStyled>
            </PopoverTextStyled>
          </Popover>
        ),
      },

      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.EVENT_MANAGEMENT.EVENT_TYPE")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "eventType",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 50,

        render: (eventType: string) => (
          <Popover content={hoverContent(eventType)} placement="topLeft">
            <PopoverTextStyled>{eventType}</PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.EVENT_MANAGEMENT.EVENT_REASON")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "eventReason",
        width: 50,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (eventReason: string) => (
          <Popover content={hoverContent(eventReason)} placement="topLeft">
            <PopoverTextStyled>{eventReason}</PopoverTextStyled>
          </Popover>
        ),
      },

      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.EVENT_MANAGEMENT.CREATOR")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "operatorName",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,

        render: (operatorName: string) => <>{operatorName}</>,
      },
      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.EVENT_MANAGEMENT.CREATED_DATE")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "createdDate",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: true,
        width: 10,

        render: (createdDate: string) => (
          <>{moment(createdDate.slice(0, -1)).format(DATE_TIME_FORMAT)}</>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.CREATE_EVENT.START_DATE")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "startDate",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,

        render: (startDate: string) => (
          <>{moment(startDate).format(DATE_FORMAT)}</>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled>
            {t("SS.CREATE_EVENT.END_DATE")}
          </TitleColumnButtonStyled>
        ),
        dataIndex: "endDate",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,

        render: (endDate: string) => <>{moment(endDate).format(DATE_FORMAT)}</>,
      },
      {
        title: (
          <TitleColumnButtonStyled>{t("SS.STATUS")}</TitleColumnButtonStyled>
        ),
        dataIndex: "status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,

        render: (status: string) => <>{status}</>,
      },
    ],
    [t, pagingIndex]
  );

  const onSearchData = (filter: any) => {
    setPageIndex(0);
    dispatch(setEventFilterAction(filter));
  };

  const onSort = (_: any, __: any, sort: any) => {
    const order = sort?.order;
    if (order === "ascend") {
      const sortValue = { queryKey: sort.field, operation: "asc" };
      setSort(JSON.stringify(sortValue));
    } else {
      const sortValue = { queryKey: sort.field, operation: "desc" };
      setSort(JSON.stringify(sortValue));
    }
  };

  return (
    <>
      <HeadingGroup title={t("SS.EVENT_MANAGEMENT")} />
      <EvenListFilter onSearch={onSearchData} />
      <EventListContainerStyled>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="eventId"
          customStyle={{
            height: 60,
            boldTitle: true,
            noUpperCase: true,
            centerTitle: true,
          }}
          paging={{
            ...pagingData,
            pageSize,
            setPageIndex: setPageIndex,
            setPageSize: setPageSize,
          }}
          onTableChange={onSort}
        />
      </EventListContainerStyled>
    </>
  );
};

export default ManageEvenList;
