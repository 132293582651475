import React from "react";

import ActionGroup from "./RuleAction";
import ConditionGroup from "./RuleCondition";
import { Col, FormInstance, Row } from "antd";
import { useTranslation } from "react-i18next";

import {
  BorderTitleStyled,
  FormContainerStyled,
  SessionTitleStyled,
} from "../../CreateUpdateEvent/Styled";
import Delay from "components/Delay";

interface IProps {
  form: FormInstance;
  startDate: Date;
  endDate: Date;
}

const EventRule: React.FC<IProps> = ({ form,startDate, endDate }) => {
  const { t } = useTranslation();

  return (
    <>
      <SessionTitleStyled>
        {t("SS.CREATE_EVENT.RULE_SETTINGS")}
      </SessionTitleStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 2 }}
            xs={{ span: 2 }}
            style={{ paddingLeft: 10, paddingRight: 0 }}
          >
            <BorderTitleStyled>{t("SS.CREATE_EVENT.IF")}</BorderTitleStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ paddingLeft: 0, paddingRight: 7 }}
          >
            <ConditionGroup form={form} />
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 2 }}
            xs={{ span: 2 }}
            style={{ paddingLeft: 10, paddingRight: 0 }}
          >
            <BorderTitleStyled>{t("SS.CREATE_EVENT.THEN")}</BorderTitleStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ paddingLeft: 0, paddingRight: 7 }}
          >
            <ActionGroup form={form} />
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Delay title={t("TS.GUEST.PASS.SENT")} form={form} startDate={startDate} endDate={endDate}/>
      </FormContainerStyled>
    </>
  );
};

export default EventRule;
