import { sortList } from "utils/helpers";
import {
  GET_DYNAMIC_PRICING_MASTER_DATA,
  GET_EVENT_ID,
  GET_EVENT_TYPE,
  GET_GUEST_PASS_EVENT_MASTER_DATA,
  GET_MEMBER_BENEFIT_MASTER_DATA,
  GET_MEMBER_CREDIT_MASTER_DATA,
  GET_REASON_TYPE,
  SET_DYNAMIC_PRICING_MASTER_DATA,
  SET_EVENT_ID,
  SET_EVENT_TYPE,
  SET_GUEST_PASS_EVENT_MASTER_DATA,
  SET_MEMBER_BENEFIT_MASTER_DATA,
  SET_MEMBER_CREDIT_MASTER_DATA,
  SET_REASON_TYPE,
} from "./types";

interface InitialStateProps {
  loading: boolean;
  criterias: CriteriaOptionProps[];
  actions: OptionProps[];
  reasons: OptionProps[];
  clubs: OptionProps[];
  tiers: OptionProps[];
  plans: OptionProps[];
  addons: OptionProps[];
  ledgerGroups: OptionProps[];
  items: OptionProps[];
  eventId: number | null;
  eventType: OptionProps[];
  reasonType: OptionProps[];
  discountTypes: OptionProps[];
}

interface IActionProps {
  type: string;
  data: any;
}

const initialState: InitialStateProps = {
  loading: true,
  criterias: [],
  actions: [],
  reasons: [],
  clubs: [],
  tiers: [],
  plans: [],
  addons: [],
  eventId: null,
  eventType: [],
  reasonType: [],
  ledgerGroups: [],
  items: [],
  discountTypes: [],
};

export default (state = initialState, action: IActionProps): any => {
  switch (action.type) {
    case GET_MEMBER_CREDIT_MASTER_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_MEMBER_CREDIT_MASTER_DATA:
      return {
        ...state,
        loading: false,
        criterias: sortList(customCriterias(action.data.criterias), `label`),
        actions: sortList(customActions(action.data.actions), `label`),
        reasons: sortList(customReason(action.data.reasons), `label`),
        clubs: sortList(customClubGroup(action.data.clubGroups), `label`),
        plans: sortList(customPlan(action.data.plans), `label`),
        addons: sortList(customAddon(action.data.addons), `label`),
        tiers: sortList(customTier(action.data.tiers), `label`),
      };
    case GET_DYNAMIC_PRICING_MASTER_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_DYNAMIC_PRICING_MASTER_DATA:
      return {
        ...state,
        loading: false,
        criterias: sortList(
          customCriterias(action.data.criterias || []),
          `label`
        ),
        actions: sortList(customActions(action.data.actions || []), `label`),
        reasons: sortList(customReason(action.data.reasons || []), `label`),
        clubs: sortList(customClubGroup(action.data.clubGroups || []), `label`),
        plans: sortList(customPlan(action.data.plans || []), `label`),
        addons: sortList(customAddon(action.data.addons || []), `label`),
        tiers: sortList(customTier(action.data.tiers || []), `label`),
        ledgerGroups: sortList(
          customLedgerGroups(action.data.ledgerGroups || []),
          `label`
        ),
        items: sortList(customItems(action.data.items || []), `label`),
        discountTypes: sortList(
          customDiscountType(action.data.discountTypes || []),
          `label`
        ),
      };
    case GET_EVENT_ID:
      return state;
    case SET_EVENT_ID:
      return {
        ...state,
        eventId: action.data,
      };
    case GET_EVENT_TYPE:
      return {
        ...state,
        loading: true,
      };
    case SET_EVENT_TYPE:
      return {
        ...state,
        loading: false,
        eventType: customEventType(action.data),
      };
    case GET_REASON_TYPE:
      return {
        ...state,
        loading: true,
      };
    case SET_REASON_TYPE:
      return {
        ...state,
        loading: false,
        reasonType: customReasonType(action.data),
      };
    case GET_MEMBER_BENEFIT_MASTER_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_MEMBER_BENEFIT_MASTER_DATA:
      return {
        ...state,
        loading: false,
        criterias: sortList(
          customCriterias(action.data.criterias || []),
          `label`
        ),
        actions: sortList(customActions(action.data.actions || []), `label`),
        reasons: sortList(customReason(action.data.reasons || []), `label`),
        clubs: sortList(customClubGroup(action.data.clubGroups || []), `label`),
        addons: sortList(customAddon(action.data.addons || []), `label`),
        plans: sortList(customPlan(action.data.plans || []), `label`),
        tiers: sortList(customTier(action.data.tiers || []), `label`),
        items: sortList(customItems(action.data.items || []), `label`),
      };
    case GET_GUEST_PASS_EVENT_MASTER_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_GUEST_PASS_EVENT_MASTER_DATA:
      return {
        ...state,
        loading: false,
        criterias: sortList(
          customCriterias(action.data.criterias || []),
          `label`
        ),
        actions: sortList(customActions(action.data.actions || []), `label`),
        reasons: sortList(customReason(action.data.reasons || []), `label`),
        clubs: sortList(customClubGroup(action.data.clubGroups || []), `label`),
        plans: sortList(customPlan(action.data.plans || []), `label`),
        addons: sortList(customAddon(action.data.addons || []), `label`),
        tiers: sortList(customTier(action.data.tiers || []), `label`),
      };
    default:
      return state;
  }
};

const customCriterias = (criteras: CriteriaProps[]): CriteriaOptionProps[] => {
  return criteras.map((criteria) => ({
    ...criteria,
    label: criteria.displayName,
    value: criteria.criteriaName,
  }));
};
const customActions = (actions: ActionProps[]): OptionProps[] => {
  return actions.map((action) => ({
    label: action.actionName,
    value: action.actionCode,
  }));
};
const customClubGroup = (clubs: ClubProps[]): OptionProps[] => {
  return clubs.map((club) => ({
    label: club.clubGroupName,
    value: club.clubGroupId.toString(),
  }));
};
const customAddon = (addons: AddonProps[]): OptionProps[] => {
  return addons.map((addon) => ({
    label: addon.addonName,
    value: addon.addonId.toString(),
  }));
};
const customPlan = (plans: PlanProps[]): OptionProps[] => {
  return plans.map((plan) => ({
    label: plan.planName,
    value: plan.planId.toString(),
  }));
};
const customTier = (tiers: TierProps[]): OptionProps[] => {
  return tiers.map((tier) => ({
    label: tier.tierName,
    value: tier.tierId.toString(),
  }));
};
const customReason = (reasons: ReasonProps[]): OptionProps[] => {
  return reasons.map((reason) => ({
    label: reason.reasonName,
    value: reason.lookupAndReasonCodeID,
    code: reason.reasonCode,
  }));
};
const customEventType = (eventType: EventTypeProps[]): OptionProps[] => {
  let eventTypeList = eventType.map((eventType) => ({
    label: eventType.eventTypeName,
    value: eventType.eventTypeCode,
  }));
  eventTypeList.unshift({ label: "All", value: "AL" });
  return eventTypeList;
};
const customReasonType = (reasons: ReasonProps[]): OptionProps[] => {
  let reasonTypeList = reasons.map((reason) => ({
    label: reason.reasonName,
    value: reason.reasonCode,
  }));
  reasonTypeList.push({ label: "Others", value: "Others" });
  return reasonTypeList;
};
const customLedgerGroups = (
  ledgerGroups: LedgerGroupProps[]
): OptionProps[] => {
  return ledgerGroups.map((ledgerGroup) => ({
    label: ledgerGroup.ledgerGroupName,
    value: ledgerGroup.ledgerGroupId,
  }));
};
const customItems = (items: ItemProps[]): OptionProps[] => {
  return items.map((item) => ({ label: item.itemName, value: item.itemId }));
};
const customDiscountType = (discounts: DiscountProps[]): OptionProps[] => {
  return discounts.map((discount) => ({
    label: discount.discountName,
    value: discount.discountType,
  }));
};
