import Table from "components/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDataSaleItemEvent } from "services/event";
import { getMemberBenefitApplicableSaleItem } from "services/memberBenefitEvent";
import { TextRightStyled, TextWrapStyled } from "styled/EventStyled";
import { EVENT_LIST_PAGING, ITEM_LEDGER_OPTION } from "utils/constants";
import { renderDuration } from "..";
import { SaleItem } from "./types";

const ApplicableSaleItem: React.FC<{ eventId: string }> = ({ eventId }) => {
  const [data, setData] = useState<SaleItem[]>([]);
  const [pagingInfo, setPagingInfo] = useState(EVENT_LIST_PAGING);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [actionItemType, setActionItemType] = useState<string>("");

  const columns = useMemo(() => {
    return [
      {
        title: t("TS.MEMBER_BENEFIT_EVENT.NO"),
        dataIndex: "key",
      },
      {
        title:
          actionItemType === ITEM_LEDGER_OPTION[0].value
            ? t("TS.MEMBER_BENEFIT.ITEMS")
            : t("TS.CREATE_MEMBER_BENEFIT.LEDGER"),
        dataIndex: "name",
        render: (name: string) => <TextWrapStyled>{name}</TextWrapStyled>,
      },
      {
        title: t("TS.MEMBER_BENEFIT_EVENT.QUANTITY"),
        dataIndex: "quantity",
        render: (quantity: any) => (
          <TextRightStyled>{quantity}</TextRightStyled>
        ),
      },
      {
        title: t("TS.MEMBER_BENEFIT_EVENT.VALID_DURATION"),
        dataIndex: "duration",
        render: (duration: any, item: any) => (
          <TextRightStyled>
            {renderDuration(duration, item.durationType)}
          </TextRightStyled>
        ),
      },
    ];
  }, [actionItemType]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getMemberBenefitApplicableSaleItem(eventId, {
        ...pagingInfo,
        pageSize,
      });
      setData(
        res.items.map((item: ApplicableSaleItemProps, index: number) => ({
          ...item,
          order: pagingInfo.pageIndex * pageSize + index + 1,
        }))
      );
      setActionItemType(res.actionItemType);
      setPagingInfo(res.paging);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [pagingInfo.pageIndex, pageSize, eventId]);

  useEffect(() => {
    getData();
  }, [getData, actionItemType === ""]);

  const updatePageIndex = (newPageIndex: number) => {
    setPagingInfo({
      ...pagingInfo,
      pageIndex: newPageIndex,
    });
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={formatDataSaleItemEvent(data)}
        rowKey="id"
        loading={isLoading}
        customStyle={{
          noUpperCase: true,
          boldTitle: true,
        }}
        paging={{
          ...pagingInfo,
          setPageIndex: updatePageIndex,
          setPageSize: setPageSize,
        }}
      />
    </>
  );
};

export default ApplicableSaleItem;
