import { Col, FormInstance, Input, Row, Select } from "antd";
import {
  DashedStyled,
  PresetGroupSelectStyled,
} from "pages/CreateUpdateEvent/Styled";
import { HeaderStyled } from "pages/ManageGate/Styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setChosenGatesAction } from "store/reducers/Gate/action";
import { FormItemStyled } from "styled/FormStyled";
import { FormContainerStyled, InputTitleStyled } from "../Styled";
import { GateProps, GetMasterData } from "../types";

interface IProps {
  form: FormInstance;
  data: GetMasterData;
}

const GateInformation: React.FC<IProps> = ({ form, data }) => {
  const { t } = useTranslation();
  const [clubGroup, setClubGroup] = useState<OptionProps[]>([]);
  const [gateList, setGateList] = useState<OptionProps[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const sortedClub = data.clubGroups.sort((club1, club2) =>
        club1.clubGroupName > club2.clubGroupName ? 1 : -1
      );
      const clubList: OptionProps[] = sortedClub.map((club) => ({
        value: club.clubGroupId.toString(),
        label: club.clubGroupName,
      }));
      setClubGroup(clubList);
      setTimeout(() => {
        const currentClub = form.getFieldValue("ClubGroupId");
        if (currentClub) {
          setUpdate(true);
          getListGate(currentClub);
        }
      }, 100);
    }
  }, [data, form]);

  const getListGate = (value: string) => {
    const currentClub = data.clubGroups.find(
      (item) => item.clubGroupId.toString() == value
    );
    if (currentClub) {
      const listGate = currentClub.gates.map((gate) => ({
        value: gate.gateId.toString() ?? "",
        label: gate.gateName ?? "",
      }));
      setGateList(listGate);
      const currentGates: GateProps[] = form
        .getFieldValue("Gates")
        .map((data: string) => {
          const gate = listGate.find((gate) => gate.value == data);
          return {
            GateId: gate?.value,
            GateName: gate?.label,
          };
        });
      dispatch(setChosenGatesAction(currentGates));
    }
  };

  const _handleOnChange = (value: any) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, Gates: [] });
    getListGate(value);
  };

  const _handleGates = (values: any, chosenGates: any) => {
    const gates: GateProps[] = chosenGates.map((gate: OptionProps) => ({
      GateId: gate.value,
      GateName: gate.label,
    }));
    dispatch(setChosenGatesAction(gates));
  };

  return (
    <>
      <Row gutter={24}>
        <Col className="d-flex" md={9} xs={9}>
          <HeaderStyled $fontSize={14}>
            {update
              ? t("TS.EDIT_GATE_RULE.GATE_CONTROL")
              : t("TS.CONFIG_GATE_RULE.GATE_CONTROL")}
          </HeaderStyled>
        </Col>
      </Row>

      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
            <InputTitleStyled>{t("TS.MANAGE_GATE.RULE_NAME")}</InputTitleStyled>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" md={{ span: 24 }} xs={{ span: 24 }}>
            <FormItemStyled
              name="RuleName"
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("TS.MANAGE_GATE.RULE_NAME"),
                  }),
                },
                {
                  min: 2,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("TS.MANAGE_GATE.RULE_NAME"),
                  }),
                },
                {
                  max: 100,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("TS.MANAGE_GATE.RULE_NAME"),
                  }),
                },
              ]}
            >
              <Input autoFocus autoComplete="off" />
            </FormItemStyled>
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="d-flex" md={{ span: 7 }} xs={{ span: 7 }}>
            <InputTitleStyled>{t("TS.MANAGE_GATE.CLUB")}</InputTitleStyled>
          </Col>
          <Col className="d-flex" md={{ span: 17 }} xs={{ span: 17 }}>
            <InputTitleStyled>{t("TS.MANAGE_GATE.GATE_NAME")}</InputTitleStyled>
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="gutter-row" md={{ span: 7 }} xs={{ span: 7 }}>
            <FormItemStyled
              name="ClubGroupId"
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("TS.MANAGE_GATE.CLUB"),
                  }),
                },
              ]}
            >
              <Select options={clubGroup} onChange={_handleOnChange} />
            </FormItemStyled>
          </Col>
          <Col className="gutter-row" md={{ span: 17 }} xs={{ span: 17 }}>
            <FormItemStyled
              name="Gates"
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("TS.MANAGE_GATE.GATE_NAME"),
                  }),
                },
              ]}
            >
              <PresetGroupSelectStyled
                optionFilterProp="label"
                showArrow
                mode="multiple"
                onChange={_handleGates}
                options={gateList}
              />
            </FormItemStyled>
          </Col>
        </Row>
      </FormContainerStyled>
      <DashedStyled />
    </>
  );
};

export default GateInformation;
