import React, { useEffect, useState } from "react";

import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { DropDownDateTypeInputStyled, FormItemStyled } from "styled/FormStyled";
import { FormListFieldData } from "antd/lib/form/FormList";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { FormInstance, Input, Row, Form, DatePicker } from "antd";
import {
  DATE_FORMAT,
  REGEX_POSITIVE_DECIMAL_NUMBER,
  ValueType,
  MEMBER_ANNIVERSARY_CRITERIA,
  DURATION_TYPE_OPTION,
  MEMBER_DURATION_CRITERIA,
} from "utils/constants";
import {
  ColStyled,
  UploadStyled,
  FileTextStyled,
  DisplayBoxStyled,
  RangePickerStyled,
  PresetGroupSelectStyled,
  BlankBackgroundButtonStyled,
} from "pages/CreateUpdateEvent/Styled";
import { BoundaryInputStyled } from "pages/ViewMemberCreditSpending/Styled";
import { validationInputInt } from "services/event";

interface IProps {
  typeInput: string | undefined;
  form: FormInstance;
  groupField: FormListFieldData;
  parentIdx: number;
}

const NumberRangeInput: React.FC<{ form: FormInstance; parentIdx: number }> = ({
  form,
  parentIdx,
}) => {
  const { rules } = form.getFieldsValue();
  const { t } = useTranslation();

  let formInitValues = {
    value1: rules[parentIdx].values[0],
    value2: rules[parentIdx].values[1],
  };

  const onChange = (
    value: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const { rules } = form.getFieldsValue();
    if (type === "value1") {
      rules[parentIdx].values[0] = value.currentTarget.value;
    } else {
      rules[parentIdx].values[1] = value.currentTarget.value;
    }
  };

  return (
    <Form
      name="basic"
      form={form}
      initialValues={formInitValues}
      layout="vertical"
      hideRequiredMark
    >
      <Row gutter={24}>
        <ColStyled
          md={{ span: 12 }}
          xs={{ span: 12 }}
          style={{ paddingRight: 6 }}
        >
          <FormItemStyled
            name="value1"
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.VALUE"),
                }),
              },
              {
                pattern: REGEX_POSITIVE_DECIMAL_NUMBER,
                message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                  key: t("SS.CREATE_EVENT.VALUE"),
                }),
              },
            ]}
          >
            <Input
              min="0"
              autoComplete="off"
              type="number"
              placeholder={t("SS.CREATE_EVENT.INPUT_VALUE")}
              onChange={(val: any) => onChange(val, "value1")}
            />
          </FormItemStyled>
        </ColStyled>
        <ColStyled
          md={{ span: 12 }}
          xs={{ span: 12 }}
          style={{ paddingLeft: 6 }}
        >
          <FormItemStyled
            name="value2"
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.VALUE"),
                }),
              },
              {
                pattern: REGEX_POSITIVE_DECIMAL_NUMBER,
                message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                  key: t("SS.CREATE_EVENT.VALUE"),
                }),
              },
            ]}
          >
            <Input
              min="0"
              autoComplete="off"
              type="number"
              placeholder={t("SS.CREATE_EVENT.INPUT_VALUE")}
              onChange={(val: any) => onChange(val, "value2")}
            />
          </FormItemStyled>
        </ColStyled>
      </Row>
    </Form>
  );
};

const NumberInput: React.FC<{ groupField: FormListFieldData }> = ({
  groupField,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(groupField.name);

  return (
    <FormItemStyled
      name={[groupField.name, "values"]}
      fieldKey={[groupField.fieldKey as number, "values"]}
      rules={[
        {
          required: true,
          message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
            key: t("SS.CREATE_EVENT.VALUE"),
          }),
        },
        {
          pattern: REGEX_POSITIVE_DECIMAL_NUMBER,
          message: value
            ? t("SS.CREATE_EVENT.INVALID_FORMAT", {
                key: t("SS.CREATE_EVENT.VALUE"),
              })
            : "",
        },
      ]}
    >
      <Input
        min="0"
        autoComplete="off"
        type="number"
        placeholder={t("SS.CREATE_EVENT.INPUT_VALUE")}
        onChange={(event) => setValue(parseInt(event.target.value, 0))}
      />
    </FormItemStyled>
  );
};

const NumberInputInt: React.FC<{
  groupField: FormListFieldData;
  suffix: String;
  isMembershipDuration: boolean;
  rule: any;
}> = ({ groupField, suffix, isMembershipDuration, rule }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number | string>(
    rule.values.length === 0 ? "" : groupField.name
  );

  useEffect(() => {
    setValue("");
  }, [rule]);

  return (
    <BoundaryInputStyled $displayType={isMembershipDuration ? "flex" : ""}>
      <FormItemStyled
        name={[groupField.name, "values"]}
        fieldKey={[groupField.fieldKey as number, "values"]}
        rules={[
          {
            required: true,
            message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
              key: t("SS.CREATE_EVENT.VALUE"),
            }),
          },
          validationInputInt(rule, value, t),
        ]}
      >
        <Input
          min="0"
          autoComplete="off"
          type="number"
          placeholder={t("SS.CREATE_EVENT.INPUT_VALUE")}
          onChange={(event) => setValue(parseInt(event.target.value, 0))}
          suffix={suffix}
        />
      </FormItemStyled>
      {isMembershipDuration && (
        <FormItemStyled
          name={[groupField.name, "durationType"]}
          fieldKey={[groupField.fieldKey as number, "durationType"]}
        >
          <DropDownDateTypeInputStyled
            defaultValue={MEMBER_DURATION_CRITERIA.defaultDropDownDateType}
            options={DURATION_TYPE_OPTION}
          />
        </FormItemStyled>
      )}
    </BoundaryInputStyled>
  );
};

const DateInput: React.FC<{ groupField: FormListFieldData }> = ({
  groupField,
}) => {
  return (
    <FormItemStyled
      name={[groupField.name, "values"]}
      fieldKey={[groupField.fieldKey as number, "values"]}
    >
      <DatePicker size="middle" format={DATE_FORMAT} allowClear={false} />
    </FormItemStyled>
  );
};

const DateRangeInput: React.FC<{ groupField: FormListFieldData }> = ({
  groupField,
}) => {
  return (
    <FormItemStyled
      name={[groupField.name, "values"]}
      fieldKey={[groupField.fieldKey as number, "values"]}
    >
      <RangePickerStyled
        size="middle"
        format={DATE_FORMAT}
        allowClear={false}
      />
    </FormItemStyled>
  );
};

const MultipleInput: React.FC<{
  groupField: FormListFieldData;
  form: FormInstance;
  parentIdx: number;
}> = ({ groupField, form, parentIdx }) => {
  const { t } = useTranslation();
  const { rules } = form.getFieldsValue();
  const event = useAppSelector((state) => state.event);
  const option = rules[parentIdx].criteria.toLowerCase() + "s";

  return (
    <>
      <FormItemStyled
        {...groupField}
        name={[groupField.name, "values"]}
        fieldKey={[groupField.fieldKey as number, "values"]}
        rules={[
          {
            required: true,
            message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
              key: t("SS.CREATE_EVENT.VALUE"),
            }),
          },
        ]}
      >
        <PresetGroupSelectStyled
          mode="multiple"
          showArrow
          style={{ width: "100%" }}
          placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
            key: t("SS.CREATE_EVENT.VALUE"),
          })}
          optionFilterProp="label"
          options={event[`${option}`]}
        />
      </FormItemStyled>
    </>
  );
};

const UploadFile: React.FC<{
  groupField: FormListFieldData;
  form: FormInstance;
  parentIdx: number;
}> = ({ groupField, form, parentIdx }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();

  useEffect(() => {
    const file = form.getFieldValue("rules")[0].values;
    setFile(file && file.name ? file : null);
  }, [form, setFile]);

  const onChangeUpload = (event: any) => {
    const { rules } = form.getFieldsValue();
    const file = event.file.originFileObj;
    const words = file && file.name.split(".");
    if (file && words[words.length - 1] !== "csv") {
      return (rules[parentIdx].values = []);
    }
    setFile(file);
    rules[parentIdx].values = [file];
  };

  const removeFile = () => {
    const { rules } = form.getFieldsValue();
    rules[parentIdx].values = [];
    setFile(null);
  };

  return (
    <>
      {!file ? (
        <FormItemStyled
          {...groupField}
          name={[groupField.name, "values"]}
          fieldKey={[groupField.fieldKey as number, "values"]}
          rules={[
            {
              required: true,
              message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                key: t("SS.CREATE_EVENT.FILE"),
              }),
            },
          ]}
        >
          <UploadStyled
            onChange={onChangeUpload}
            accept=".csv"
            showUploadList={false}
          >
            <BlankBackgroundButtonStyled icon={<UploadOutlined />}>
              {t("SS.CREATE_EVENT.UPLOAD_FILE")}
            </BlankBackgroundButtonStyled>
          </UploadStyled>
        </FormItemStyled>
      ) : (
        <DisplayBoxStyled>
          <FileTextStyled>
            {file.name.length > 50
              ? file.name.substring(0, 50) + "..."
              : file.name}
          </FileTextStyled>
          <CloseOutlined style={{ fontSize: "14px" }} onClick={removeFile} />
        </DisplayBoxStyled>
      )}
    </>
  );
};

const ValueInputType: React.FC<IProps> = ({
  typeInput,
  form,
  groupField,
  parentIdx,
}) => {
  const { rules } = form.getFieldsValue();
  let suffix = "";
  let isMembershipDuration = false;

  if (rules) {
    suffix =
      rules[parentIdx]?.criteria === MEMBER_ANNIVERSARY_CRITERIA.criteria
        ? MEMBER_ANNIVERSARY_CRITERIA.suffix
        : "";
    isMembershipDuration =
      rules[parentIdx]?.criteria === MEMBER_DURATION_CRITERIA.criteria
        ? true
        : false;
  }

  return (
    <>
      {typeInput === ValueType.InputType && (
        <NumberInput groupField={groupField} />
      )}
      {typeInput === ValueType.IntInputType && (
        <NumberInputInt
          groupField={groupField}
          suffix={suffix}
          isMembershipDuration={isMembershipDuration}
          rule={rules[parentIdx]}
        />
      )}
      {typeInput === ValueType.InputRangeType && (
        <NumberRangeInput form={form} parentIdx={parentIdx} />
      )}
      {typeInput === ValueType.DateType && (
        <DateInput groupField={groupField} />
      )}
      {typeInput === ValueType.DateRangeType && (
        <DateRangeInput groupField={groupField} />
      )}
      {typeInput === ValueType.MultiOptionType && (
        <MultipleInput
          groupField={groupField}
          form={form}
          parentIdx={parentIdx}
        />
      )}
      {typeInput === ValueType.UpLoadFile && (
        <UploadFile groupField={groupField} form={form} parentIdx={parentIdx} />
      )}
    </>
  );
};

export default ValueInputType;
