import { all, call, put, takeEvery } from "redux-saga/effects";
import { getGuestPassEventMasterData } from "services/guestPass";
import {
  getDynamicPricingtMasterData,
  getEvenType,
  getMemberBenefitMasterData,
  getMemberCreditMasterData,
  getReasonType,
} from "services/transaction";
import {
  setDynamicPricingMasterDataAction,
  setEventIdAction,
  setEventTypeAction,
  setGuestPassEventMasterDataAction,
  setMemberBenefitMasterDataAction,
  setMemberCreditMasterDatasAction,
  setReasonTypeAction,
} from "store/reducers/Event/actions";
import {
  GetReasonTypeAction,
  GET_DYNAMIC_PRICING_MASTER_DATA,
  GET_EVENT_ID,
  GET_EVENT_TYPE,
  GET_GUEST_PASS_EVENT_MASTER_DATA,
  GET_MEMBER_BENEFIT_MASTER_DATA,
  GET_MEMBER_CREDIT_MASTER_DATA,
  GET_REASON_TYPE,
} from "store/reducers/Event/types";

function* getMemberCreditMaster() {
  const data: EventMasterDataProps = yield call(getMemberCreditMasterData);
  yield put(setMemberCreditMasterDatasAction(data));
}

function* getDynamicPricingMaster() {
  const data: EventMasterDataProps = yield call(getDynamicPricingtMasterData);
  yield put(setDynamicPricingMasterDataAction(data));
}

type ParamsEventId = { data: number; type: string };
function* getEventId({ data }: ParamsEventId) {
  yield put(setEventIdAction(data));
}

function* getEventType() {
  const data: EventTypeProps = yield call(getEvenType);
  yield put(setEventTypeAction(data));
}

function* getReason(action: GetReasonTypeAction) {
  const result: ReasonProps[] = yield call(getReasonType, action.data);
  yield put(setReasonTypeAction(result));
}

function* getMemberBenefitMaster() {
  const data: MemberBenefitMasterData = yield call(getMemberBenefitMasterData);
  yield put(setMemberBenefitMasterDataAction(data));
}

function* getGuestPassEventMaster() {
  const data: GuestPassEventMasterData = yield call(
    getGuestPassEventMasterData
  );
  yield put(setGuestPassEventMasterDataAction(data));
}

function* watchFetchProducts() {
  yield all([
    takeEvery(GET_MEMBER_CREDIT_MASTER_DATA, getMemberCreditMaster),
    takeEvery(GET_DYNAMIC_PRICING_MASTER_DATA, getDynamicPricingMaster),
    takeEvery(GET_EVENT_ID, getEventId),
    takeEvery(GET_EVENT_TYPE, getEventType),
    takeEvery(GET_REASON_TYPE, getReason),
    takeEvery(GET_MEMBER_BENEFIT_MASTER_DATA, getMemberBenefitMaster),
    takeEvery(GET_GUEST_PASS_EVENT_MASTER_DATA, getGuestPassEventMaster),
  ]);
}

export default watchFetchProducts;
