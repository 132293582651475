import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  font-size: 12px;
  .no-link-payment {
    color: red;
    font-size: 12px;
    width: 237px;
    display: flex;
  }
  .ant-modal-title {
    font-size: 12px;
  }
  .ant-modal-header {
    padding: 5px 10px;
  }
  .ant-modal-body {
    padding: 5px 10px;
    font-size: 12px;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 40px;
    font-size: 12px;
    line-height: 35px;
  }
  .ant-modal-footer {
    text-align: center;
    .ant-btn {
      font-size: 12px;
      &:hover {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
    .ant-btn-primary {
      background: var(--primary-color);
      border-color: var(--primary-color);
      &:hover {
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--white-color);
      }
    }
    .ant-col.ant-form-item-control {
      &:hover {
        border-color: var(--secondary-color);
      }
    }
  }
`;

export const ModalTitleStyled = styled.span`
  font-size: 13px;
  color: var(--gray-color);
  font-weight: bold;
`;

export const ModalHeadingStyled = styled.p`
  color: var(--gray-color);
  margin-bottom: 0;
`;
