import React, { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";
import TableParent from "components/Table";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Popover } from "antd";
import { EditOutlined } from "@ant-design/icons";
import TableStyled from "components/Table/Styled";
import ChangeExpiryDate from "./ChangeExpiryDate";
import { getMemberCredit } from "services/transaction";
import { formatDate, onFormatSeparateThousand } from "utils/helpers";
import { TitleColumnButtonStyled } from "styled/ButtonStyled";
import {
  DATE_FORMAT,
  EVENT_LIST_PAGING,
  REACT_APP_STANDARD_HEIGHT,
  PostMessageTableType,
} from "utils/constants";
import { PopoverContentStyled, PopoverTextStyled } from "styled/PopoverStyled";
import {
  AlignTextStyled,
  BlankIconButtonStyled,
  Boundary,
  Container,
  EmptyListStyled,
  HeaderTitleStyled,
  TransactionListStyled,
} from "./Styled";
import "./styles.css";

const ViewMemberCreditSpending: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [pagingIndex, setPageIndex] = useState(0);
  const [pagingData, setPagingData] = useState(EVENT_LIST_PAGING);
  const [data, setData] = useState<MemberCreditSpendintProps[]>([]);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const [sort, setSort] = useState("createdDate=desc");
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [amountAvailable, setAmountAvailable] = useState<string>("0");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [memberCreditId, setMemberCreditId] = useState<string>("");
  const [expiryDate, setExpiryDate] = useState<string>("");

  const { operatorId, memberId } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "",
      memberId: params.get("memberId") || "",
    };
  }, [window.location.search]);

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getMemberCredit(
        {
          pageIndex: pagingIndex,
          pageSize,
          sorts: sort,
        },
        memberId
      );
      setData(
        res.data.map((item: any, key: number) => ({ ...item, key: key }))
      );
      setPagingData(res.paging);
      setLoading(false);
      setAmountAvailable(res.extras[0].toString());
      setExpandedRowKeys([]);
    } catch {
      setLoading(false);
    }
  }, [pagingIndex, pageSize, sort]);

  useEffect(() => {
    getData();
  }, [getData]);

  const hoverContent = (eventName: string) => {
    return <PopoverContentStyled>{eventName}</PopoverContentStyled>;
  };

  const columns = useMemo(
    () => [
      {
        title: (
          <AlignTextStyled>{t("SS.EVENT_MANAGEMENT.NO.")}</AlignTextStyled>
        ),
        key: "index",
        render: (value: any, item: any, index: number) => (
          <AlignTextStyled>{index + 1 + pagingIndex * 10}</AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.EVENT_MANAGEMENT.EVENT_NAME")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "eventName",
        dataIndex: "eventName",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (eventName: string, item: any) => (
          <Popover content={hoverContent(eventName)} placement="topLeft">
            <PopoverTextStyled $color={"var(--navi-color)"}>
              {eventName}
            </PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.EVENT_MANAGEMENT.EVENT_REASON")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "eventReason",
        dataIndex: "eventReason",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (eventReason: string, item: any) => (
          <Popover content={hoverContent(eventReason)} placement="topLeft">
            <PopoverTextStyled $color={"var(--navi-color)"}>
              {eventReason}
            </PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REDEEM_AMOUNT")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "credit",
        dataIndex: "credit",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (credit: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(credit.toString())}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REDEEM_DATE")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "createdDate",
        dataIndex: "createdDate",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (createdDate: string) => (
          <AlignTextStyled $textAlign={"center"}>
            {formatDate(createdDate)}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REMAINING")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "remaining",
        dataIndex: "remaining",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (remaining: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(remaining.toString())}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.EXPIRED_DATE")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "expiredDate",
        dataIndex: "expiredDate",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
        render: (expiredDate: string, item: any) => (
          <AlignTextStyled $textAlign={"center"}>
            {moment(expiredDate).utcOffset(0).format(DATE_FORMAT)}
            <BlankIconButtonStyled
              disabled={item.remaining ? false : true}
              onClick={() => onOpenChangeExpiryDate(item)}
            >
              <EditOutlined />
            </BlankIconButtonStyled>
          </AlignTextStyled>
        ),
      },
    ],
    [t]
  );

  const columnsSubTable = useMemo(
    () => [
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.TRANSACTIONID")}
          </TitleColumnButtonStyled>
        ),
        key: "transactionReferenceNumber",
        dataIndex: "transactionReferenceNumber",
        render: (transactionReferenceNumber: number) => (
          <AlignTextStyled $textAlign={"center"}>
            {transactionReferenceNumber}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.USEDAMOUNT")}
          </TitleColumnButtonStyled>
        ),
        key: "amount",
        dataIndex: "amount",
        render: (amount: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(amount.toString())}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.DATE")}
          </TitleColumnButtonStyled>
        ),
        key: "createdDate",
        dataIndex: "createdDate",
        render: (createdDate: string) => (
          <AlignTextStyled $textAlign={"center"}>
            {formatDate(createdDate)}
          </AlignTextStyled>
        ),
      },
    ],
    [t]
  );

  const onSort = (_: any, __: any, sort: any) => {
    const order = sort?.order;
    if (order === "ascend") {
      const sortValue = sort.field + "=" + "asc";
      setSort(sortValue);
    } else {
      const sortValue = sort.field + "=" + "desc";
      setSort(sortValue);
    }
  };

  const expandedRow = (row: any) => {
    let inTable = data[row.key].memberCreditDetails;
    return (
      <ConfigProvider renderEmpty={() => customizeRenderEmpty(true)}>
        <TableStyled
          key="id"
          columns={columnsSubTable}
          loading={loading}
          dataSource={inTable}
          $customStyle={{
            isSubTable: true,
            noUpperCase: true,
          }}
          pagination={false}
        />
      </ConfigProvider>
    );
  };

  const sendHeightPostMessage = () => {
    let height = document.getElementById("memberCreditTable")?.offsetHeight;
    if (data.length >= 10) {
      height = REACT_APP_STANDARD_HEIGHT;
    }
    let postMessage: MemberCreditPostMessage = {
      height: height,
      tableType: PostMessageTableType.MEMBER_CREDIT_TABLE,
    };
    window.parent.postMessage(postMessage, "*");
  };

  useEffect(() => {
    if (!loading) {
      sendHeightPostMessage();
    }
  }, [loading]);

  const customizeRenderEmpty = (isExpandRow: boolean) => (
    <EmptyListStyled>
      <AlignTextStyled>
        {isExpandRow
          ? t("TS.NO_TRANSACTION_FOUND")
          : t("TS.NO_MEMBER_CREDIT_FOUND")}
      </AlignTextStyled>
    </EmptyListStyled>
  );

  const onTableRowExpand = (expanded: any, record: any) => {
    let keys = expandedRowKeys;
    if (expanded) {
      keys.push(record.key);
    } else {
      keys = expandedRowKeys.filter((x) => x !== record.key);
    }
    setExpandedRowKeys(keys);
  };

  const onOpenChangeExpiryDate = (item: any) => {
    setMemberCreditId(item.id);
    setExpiryDate(item.expiredDate);
    setIsOpenModal(true);
  };

  const onToggleModal = (toggle: boolean) => {
    setIsOpenModal(toggle);
    getData();
  };

  return (
    <Boundary id="memberCreditTable">
      <ConfigProvider renderEmpty={() => customizeRenderEmpty(false)}>
        <Container>
          <HeaderTitleStyled $fontSize={11}>
            {t("SS.MEMBER_CREDIT_SPENDING.TOTAL_AMOUNT_AVAILABLE", {
              key: onFormatSeparateThousand(amountAvailable),
            })}
          </HeaderTitleStyled>
          <TransactionListStyled>
            <TableParent
              columns={columns}
              dataSource={data}
              key="key"
              loading={loading}
              onExpand={onTableRowExpand}
              expandedRowKeys={expandedRowKeys}
              expandedRowRender={expandedRow}
              customStyle={{
                height: 84,
                noUpperCase: true,
                boldTitle: true,
                centerTitle: true,
              }}
              paging={{
                ...pagingData,
                pageSize,
                color: "var(--navi-color)",
                setPageIndex: setPageIndex,
                setPageSize: setPageSize,
              }}
              onTableChange={onSort}
            />
          </TransactionListStyled>
        </Container>
      </ConfigProvider>
      <ChangeExpiryDate
        isOpenModal={isOpenModal}
        setOpenModal={(res) => onToggleModal(res)}
        memberCreditId={memberCreditId}
        operatorId={operatorId}
        expiryDate={expiryDate}
      />
    </Boundary>
  );
};
export default ViewMemberCreditSpending;
