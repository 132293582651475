export const TIER = "Tier";
export const MEMBER_TYPE = "MemberType";
export const COMPANION = "Companion";
export const HOME_CLUB = "HomeClub"

export default {
  TIER,
  MEMBER_TYPE,
  COMPANION,
};
