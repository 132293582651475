import { Action } from "redux";

export const GET_EVENT_ID = "GET_EVENT_ID";
export const SET_EVENT_ID = "SET_EVENT_ID";
export const GET_EVENT_TYPE = "GET_EVENT_TYPE";
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
export const GET_REASON_TYPE = "GET_REASON_TYPE";
export const SET_REASON_TYPE = "SET_REASON_TYPE";
export const GET_MEMBER_CREDIT_MASTER_DATA = "GET_MEMBER_CREDIT_MASTER_DATA";
export const SET_MEMBER_CREDIT_MASTER_DATA = "SET_MEMBER_CREDIT_MASTER_DATA";
export const GET_DYNAMIC_PRICING_MASTER_DATA =
  "GET_DYNAMIC_PRICING_MASTER_DATA";
export const SET_DYNAMIC_PRICING_MASTER_DATA =
  "SET_DYNAMIC_PRICING_MASTER_DATA";

export const GET_MEMBER_BENEFIT_MASTER_DATA = "GET_MEMBER_BENEFIT_MASTER_DATA";
export const SET_MEMBER_BENEFIT_MASTER_DATA = "SET_MEMBER_BENEFIT_MASTER_DATA";
export const GET_GUEST_PASS_EVENT_MASTER_DATA = "GET_GUEST_PASS_EVENT_MASTER_DATA";
export const SET_GUEST_PASS_EVENT_MASTER_DATA = "SET_GUEST_PASS_EVENT_MASTER_DATA";

export type GetMemberCreditMasterDataAction = Action<
  typeof GET_MEMBER_CREDIT_MASTER_DATA
>;

export interface SetMemberCreditMasterDataAction
  extends Action<typeof SET_MEMBER_CREDIT_MASTER_DATA> {
  data: EventMasterDataProps;
}

export type GetDynamicPricingMasterDataAction = Action<
  typeof GET_DYNAMIC_PRICING_MASTER_DATA
>;

export interface SetDynamicPricingMasterDataAction
  extends Action<typeof SET_DYNAMIC_PRICING_MASTER_DATA> {
  data: EventMasterDataProps;
}

export interface GetEventIdAction extends Action<typeof GET_EVENT_ID> {
  data: number;
}

export interface SetEventIdAction extends Action<typeof SET_EVENT_ID> {
  data: number;
}

export type GetEventTypeDataAction = Action<typeof GET_EVENT_TYPE>;

export interface SetEventTypeDataAction extends Action<typeof SET_EVENT_TYPE> {
  data: EventTypeProps;
}

export interface GetReasonTypeAction extends Action<typeof GET_REASON_TYPE> {
  data: string;
}

export interface SetReasonTypeAction extends Action<typeof SET_REASON_TYPE> {
  data: ReasonProps[];
}

export type GetMemberBenfitMasterDataAction = Action<
  typeof GET_MEMBER_BENEFIT_MASTER_DATA
>;

export interface SetMemberBenefitMasterDataAction
  extends Action<typeof SET_MEMBER_BENEFIT_MASTER_DATA> {
  data: MemberBenefitMasterData;
}

export type GetGuestPassEventMasterDataAction = Action<
  typeof GET_GUEST_PASS_EVENT_MASTER_DATA
>;

export interface SetGuestPassEventMasterDataAction
  extends Action<typeof SET_GUEST_PASS_EVENT_MASTER_DATA> {
  data: GuestPassEventMasterData;
}

export type EventMasterDataTypes =
  | GetMemberCreditMasterDataAction
  | SetMemberCreditMasterDataAction
  | GetDynamicPricingMasterDataAction
  | SetDynamicPricingMasterDataAction
  | GetEventIdAction
  | SetEventIdAction
  | GetEventTypeDataAction
  | SetEventTypeDataAction
  | GetReasonTypeAction
  | SetReasonTypeAction
  | GetMemberBenfitMasterDataAction
  | SetMemberBenefitMasterDataAction
  | GetGuestPassEventMasterDataAction
  | SetGuestPassEventMasterDataAction;
