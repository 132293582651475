import { notification } from "antd";
import axios from "axios";
import { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } from "utils/constants";
import i18n from "utils/i18n";

import { getAccessToken, getDefaultUserInfo } from "./accessToken";
import { CONNECT_TOKEN, GET_TOKEN_BY_TOKEN_URL } from "./apis";

export const getTokenByOneTimeToken = (token: string) =>
  axios.get(
    `${_env.REACT_APP_API_LOGIN_BASE_URL}${GET_TOKEN_BY_TOKEN_URL.replace(
      "{token}",
      token
    )}`
  );

export const getToken = (): Promise<{ data: TokenProps }> => {
  const loginInfo = {
    client_id: REACT_APP_CLIENT_ID,
    client_secret: REACT_APP_CLIENT_SECRET,
    grant_type: "client_credentials",
  } as AuthorizeProps;

  return axios.post(
    `${_env.REACT_APP_API_LOGIN_BASE_URL}${CONNECT_TOKEN}`,
    Object.keys(loginInfo)
      .map((key: string) => `${key}=${loginInfo[key]}`)
      .join("&"),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

interface AuthorizeProps {
  [key: string]: string;
}

export const http = axios.create({
  baseURL: _env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  const token = getAccessToken();
  const defaultUserInfo = getDefaultUserInfo();
  return {
    ...config,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...config.headers,
      ...defaultUserInfo,
    },
  };
});

http.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    notification.open({
      key: "token-expired",
      className: "ant-notification-error",
      message: i18n.t("SS_NOTIFICATION_SERVICES.EXPIRED_SESSION") as string,
      description: i18n.t("SS_NOTIFICATION_SERVICES.EXPIRED_SESSION_MESSAGE") as string,
      duration: 3,
      onClose: () =>
      (window.parent.location.href =
        _env.REACT_APP_EMBEDDED_BASE_URL),
    });
  } else {
    notification.open({
      key: "service-unavailable",
      className: "ant-notification-error",
      message: i18n.t("SS_NOTIFICATION_SERVICES") as string,
      description: i18n.t("SS_NOTIFICATION_SERVICES_NOTIFICATION") as string,
      duration: 3,
    });
  }

  return Promise.reject(error);
});

export const httpConman = axios.create({
  baseURL: _env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

httpConman.interceptors.request.use((config) => {
  const token = getAccessToken();
  const defaultUserInfo = getDefaultUserInfo();
  return {
    ...config,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...config.headers,
      ...defaultUserInfo,
    },
  };
});

httpConman.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    notification.open({
      key: "token-expired",
      className: "ant-notification-error",
      message: i18n.t("SS_NOTIFICATION_SERVICES.EXPIRED_SESSION") as string,
      description: i18n.t("SS_NOTIFICATION_SERVICES.EXPIRED_SESSION_MESSAGE") as string,
      duration: 3,
      onClose: () =>
      (window.parent.location.href = _env.REACT_APP_EMBEDDED_BASE_URL.replace(
        "MembershipManagement",
        "Configuration/LogIn?ReturnUrl=%2fConfiguration%2fHomes"
      )),
    });
  } else {
    notification.open({
      key: "service-unavailable",
      className: "ant-notification-error",
      message: i18n.t("SS_NOTIFICATION_SERVICES") as string,
      description: i18n.t("SS_NOTIFICATION_SERVICES_NOTIFICATION") as string,
      duration: 3,
    });
  }

  return Promise.reject(error);
});
