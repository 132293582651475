import { EventType } from "utils/constants";
import { http } from "./common";
import {
  DELETE_EVENT,
  DELETE_GUEST_PASS_EVENT_URL,
  GET_CLUB_LIST_URL,
  GET_DOWNLOAD_FILE,
  GET_DOWNLOAD_FILE_GUEST_PASS_EVENT,
  GET_DYNAMIC_PRICING_MASTER_DATA_URL,
  GET_EVENT,
  GET_EVENT_LIST,
  GET_EVENT_TYPE,
  GET_GUEST_PASS_EVENT_MASTER_DATA_URL,
  GET_MEMBER_BENEFIT,
  GET_MEMBER_BENEFIT_DETAIL,
  GET_MEMBER_BENEFIT_MASTER_DATA_URL,
  GET_MEMBER_CREDIT,
  GET_MEMBER_CREDIT_MASTER_DATA_URL,
  GET_PREVIEW_EVENT,
  GET_REASON_TYPE,
  GET_TRANSATION_DETAIL_URL,
  GET_TRANSATION_LIST_URL,
  POST_DYNAMIC_PRICING_DATA,
  POST_DYNAMIC_PRICING_DATA_FILE,
  POST_MEMBER_CREDIT,
  POST_MEMBER_CREDIT_DATA,
  POST_MEMBER_CREDIT_DATA_FILE,
  POST_MEMEBER_BENEFIT_EVENT_DATA,
  POST_MEMEBER_BENEFIT_EVENT_DATA_WITH_FILE,
  UPDATE_CREDIT_EVENT,
  UPDATE_CREDIT_EVENT_WITH_FILE,
  UPDATE_DYNAMIC_PRICING_EVENT,
  UPDATE_DYNAMIC_PRICING_EVENT_WITH_FILE,
  UPDATE_EXPIRY_DATE,
  UPDATE_GUEST_PASS_EXPIRY_DATE,
  UPDATE_MEMBER_BENEFIT_EXPIRY_DATE,
  UPDATE_STATUS_EVENT,
} from "./common/apis";

export const getTransactionList = async (filterData: any) => {
  const res = await http.post(GET_TRANSATION_LIST_URL, filterData);
  return res.data;
};

export const getTransactionDetails = async (
  transactionId: string
): Promise<TransactionDataProps> => {
  const res = await http.get(
    GET_TRANSATION_DETAIL_URL.replace("{id}", transactionId)
  );
  return res.data;
};

export const getClubList = async (operatorId: string): Promise<ClubProps[]> => {
  const res = await http.get(
    GET_CLUB_LIST_URL.replace("{operatorId}", operatorId)
  );
  return res.data;
};

export const getPreviewEvent = async (
  eventId: string,
  pageIndex: string,
  pageSize: string
) => {
  const res = await http.get(
    GET_PREVIEW_EVENT.replace("{eventId}", eventId)
      .replace("{pageIndex}", pageIndex)
      .replace("{pageSize}", pageSize)
  );
  return res;
};

export const getMemberCreditMasterData = async (): Promise<any> => {
  const res = await http.get(GET_MEMBER_CREDIT_MASTER_DATA_URL);
  return res.data;
};

export const getDynamicPricingtMasterData = async (): Promise<any> => {
  const res = await http.get(GET_DYNAMIC_PRICING_MASTER_DATA_URL);
  return res.data;
};

export const submitEventData = async (
  data: MemberCreditEventProps | DynamicPricingEventProps,
  type: string
): Promise<any> => {
  const res = await http.post(
    type === EventType.MemberCredit
      ? POST_MEMBER_CREDIT_DATA
      : type === EventType.DynamicPricing
      ? POST_DYNAMIC_PRICING_DATA
      : POST_MEMEBER_BENEFIT_EVENT_DATA,
    data
  );
  return res.data;
};

export const submitEventDataWithFile = async (
  data: any,
  evenType: string
): Promise<any> => {
  const header = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await http.post(
    evenType === EventType.MemberCredit
      ? POST_MEMBER_CREDIT_DATA_FILE
      : evenType === EventType.DynamicPricing
      ? POST_DYNAMIC_PRICING_DATA_FILE
      : POST_MEMEBER_BENEFIT_EVENT_DATA_WITH_FILE,
    data,
    header
  );
  return res.data;
};

export const getEvent = async (eventId: string): Promise<any> => {
  const res = await http.get(GET_EVENT.replace("{eventId}", eventId));
  return res.data;
};

export const getEventList = async (filterData: any): Promise<any> => {
  const res = await http.post(GET_EVENT_LIST, filterData);
  return res.data;
};

export const getEvenType = async (): Promise<any> => {
  const res = await http.get(GET_EVENT_TYPE);
  return res.data;
};

export const updateStatusEvent = async (
  status: string,
  eventId: number
): Promise<any> => {
  const body = { status: status };
  const res = await http.put(
    UPDATE_STATUS_EVENT.replace("{eventId}", eventId.toString()),
    body
  );
  return res.data;
};

export const getMemberCredit = async (
  filterData: any,
  memberId: string
): Promise<any> => {
  const res = await http.post(
    GET_MEMBER_CREDIT.replace("{memberId}", memberId),
    filterData
  );
  return res.data;
};

export const updateEventCredit = async (
  eventType: string,
  body: any
): Promise<any> => {
  const res = await http.put(
    eventType === EventType.MemberCredit
      ? UPDATE_CREDIT_EVENT
      : UPDATE_DYNAMIC_PRICING_EVENT,
    body
  );
  return res.data;
};

export const updateEventCreditWithFile = async (
  type: string,
  body: any
): Promise<any> => {
  const header = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await http.put(
    type === EventType.MemberCredit
      ? UPDATE_CREDIT_EVENT_WITH_FILE
      : UPDATE_DYNAMIC_PRICING_EVENT_WITH_FILE,
    body,
    header
  );
  return res.data;
};

export const getReasonType = async (reasonType: string): Promise<any> => {
  const res = await http.get(
    GET_REASON_TYPE.replace("{reasonType}", reasonType)
  );
  return res.data;
};

export const submitMemberCredit = async (body: any): Promise<any> => {
  const res = await http.post(POST_MEMBER_CREDIT, body);
  return res.data;
};

export const updateExpiryDate = async (body: any): Promise<any> => {
  const res = await http.put(UPDATE_EXPIRY_DATE, body);
  return res.data;
};

export const updateEndDate = async (
  eventId: string,
  body: any
): Promise<any> => {
  const res = await http.put(GET_EVENT.replace("{eventId}", eventId), body);
  return res.data;
};

export const getDownloadFile = async (eventId: string): Promise<any> => {
  const res = await http.get(GET_DOWNLOAD_FILE.replace("{eventId}", eventId));
  return res;
};
export const getDownloadFileGuestPassEvent = async (
  eventId: string
): Promise<any> => {
  const res = await http.get(
    GET_DOWNLOAD_FILE_GUEST_PASS_EVENT.replace("{eventId}", eventId)
  );
  return res;
};

export const deleteEvent = async (eventId: string): Promise<any> => {
  const res = await http.delete(DELETE_EVENT.replace("{eventId}", eventId));
  return res;
};

export const getMemberBenefitMasterData = async (): Promise<any> => {
  const res = await http.get(GET_MEMBER_BENEFIT_MASTER_DATA_URL);
  return res.data;
};

export const getMemberBenefit = async (filterData: any): Promise<any> => {
  const res = await http.post(GET_MEMBER_BENEFIT, filterData);
  return res.data;
};

export const getMemberBenefitDetail = async (
  memberBenefitsId: string
): Promise<any> => {
  const res = await http.get(
    GET_MEMBER_BENEFIT_DETAIL.replace("{memberBenefitsId}", memberBenefitsId)
  );
  return res.data;
};

export const updateExpiryDateMemberBenefit = async (
  body: any
): Promise<any> => {
  const res = await http.put(UPDATE_MEMBER_BENEFIT_EXPIRY_DATE, body);
  return res.data;
};

export const getGuestPassEventMasterData = async (): Promise<any> => {
  const res = await http.get(GET_GUEST_PASS_EVENT_MASTER_DATA_URL);
  return res.data;
};

export const deleteGuestPassEvent = async (eventId: string): Promise<any> => {
  const res = await http.delete(
    DELETE_GUEST_PASS_EVENT_URL.replace("{eventId}", eventId)
  );
  return res;
};

export const updateExpiryDateGuestPass = async (body: any): Promise<any> => {
  const res = await http.put(UPDATE_GUEST_PASS_EXPIRY_DATE, body);
  return res.data;
};
