import { DAY_VALUE, GuestPassActionCode, MEET_CRITERIA } from "utils/constants";

export const INIT_GUEST_PASS_EVENT = {
  eventName: "",
  description: "",
  startDate: "",
  endDate: "",
  reasonCode: null,
  lookupAndReasonCodeID: null,
  ruleConditionType: "AND",
  rules: [
    {
      criteria: null,
      operator: null,
      values: [],
      dataType: "",
    },
  ],
  actions: [
    {
      actionCode: GuestPassActionCode.GIVE_GUEST_PASS,
      quantity: "",
      duration: "",
      durationType: DAY_VALUE,
    },
  ],
  when: MEET_CRITERIA,
  delayDurationType: DAY_VALUE,
};
