import { Button, Switch } from "antd";
import styled, { SimpleInterpolation } from "styled-components/macro";

import { font, mixin } from "utils/styles";

const commonCss = `
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  ${font.bold};
  transition: all 0.3s;
  > svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

const buttonCss = `
  min-height: 36px;
  min-width: 114px;
  font-size: 12px;
  ${commonCss};
`;

const buttonSmallCss = `
  min-height: 25px;
  font-size: 11px;
  ${commonCss};
`;

const ButtonStyled = styled(Button)`
  transition: all 0.3s;
  border-radius: 3px !important;

  ${(props): SimpleInterpolation => {
    switch (props.type) {
      case "primary":
        return `
          ${props.size === "small" ? buttonSmallCss : buttonCss}
          &.ant-btn-primary {
            background-color: var(--primary-color);
            color: var(--white-color);
            border: 1px solid var(--primary-color);
            &:hover, &:focus {
              background-color: var(--white-color);
              color: var(--primary-color);
            }
          }
        `;
      case "link":
        return `
          ${props.size === "small" ? "font-size: 11px;" : "font-size: 12px;"}
          &.ant-btn-link {
            padding: 0;
            cursor: pointer;
            color: var(--secondary-color);

            &:hover,
            &:visited,
            &:active {
              color: var(--secondary-color);
            }
            &:hover,
            &:focus,
            &:active {
              span:not([class^="icon-"]) {
                text-decoration: none;
              }
            }
            span:not([class^="icon-"]) {
              text-decoration: underline;
            }
            .icon-download-ico {
              font-weight: 600;
              margin-right: 5px;
            }
          } 
        `;
      case "default":
        return `
          ${props.size === "small" ? buttonSmallCss : buttonCss}

          &.ant-btn {
            background-color: var(--gray1-color);
            color: var(--gray-color);
            border: 1px solid var(--gray3-color);

            &:hover, &:focus {
              background-color: var(--gray-color);
              color: var(--gray3-color);
            }
          }
          `;

      default:
        return `
          ${props.size === "small" ? buttonSmallCss : buttonCss}
          &.ant-btn {
            background-color: var(--gray-color);
            color: var(--white-color);
            border: 1px solid var(--gray-color);

            &:hover, &:focus {
              background-color: var(--white-color);
              color: var(--gray-color);
            }
          }
        `;
    }
  }}
`;

export const ButtonSecondaryStyled = styled(Button)`
  ${(props): SimpleInterpolation => `${props.size === "small" ? buttonSmallCss : buttonCss}
  text-transform: none;
  &.ant-btn {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 1px solid var(--primary-color);
    transition: all 0.3s;

    &:hover, &:focus {
      background-color: var(--secondary-color);
    }
  }
  &.ant-btn[disabled]{
    background: var(--gray5-color) !important;
    border: 1px solid var(--gray5-color) !important;
  }
  `}
`;

export default ButtonStyled;

export const SwitchStyled = styled(Switch)`
  background-color: var(--gray-color);
  margin: 0 5px 6px 5px;
  &.ant-switch-checked {
    background-color: var(--primary-color);
  }
`;

export const BlankButtonStyled = styled(Button) <{ $color?: string, $marginLeft?: number }>`
  font-size: 14px;
  border: none;
  height: 20px;
  color: var(--secondary-color);
  ${(props): SimpleInterpolation => `color: var(--${props.$color ? props.$color : 'secondary'}-color);`}
  ${(props): SimpleInterpolation => `margin-left: ${props.$marginLeft}px;`}
  background: transparent;
  box-shadow: none;
  padding: 0px;
  display: inline;
  text-decoration: none;
  &:focus {
    color: var(--blue-color);
    ${(props): SimpleInterpolation => `color: var(--${props.$color ? props.$color : 'secondary'}-color);`}
  }
  &:hover {
    box-shadow: none;
    background: transparent;
    ${(props): SimpleInterpolation => `color: var(--${props.$color ? props.$color : 'secondary'}-color);`}
  }
`;

export const TitleColumnButtonStyled = styled(Button) <{ $color?: string }>`
  font-size: 11px;
  border: none;
  font-weight: bold;
  background: transparent;
  height: 17px;
  padding: 0px;
  ${(props): SimpleInterpolation => `color: ${props.$color};`}
  box-shadow: none;
  &:focus {
    color: var(--blue-color);
    font-weight: bold;
    background: transparent;
  }
  &:hover {
    background: transparent;
    box-shadow: none;
  }
`;
