import Table from "components/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGuestPassApplicable } from "services/guestPass";
import { TextRightStyled } from "styled/EventStyled";
import { EVENT_LIST_PAGING } from "utils/constants";
import { renderDuration } from "..";

const ApplicableAmount: React.FC<{ eventId: string }> = ({ eventId }) => {
  const [data, setData] = useState<ApplicableAmountProps[]>([]);
  const [pagingInfo, setPagingInfo] = useState(EVENT_LIST_PAGING);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        title: t("TS.MEMBER_BENEFIT_EVENT.NO"),
        dataIndex: "order",
      },
      {
        title: t("TS.MEMBER_BENEFIT_EVENT.QUANTITY"),
        dataIndex: "quantity",
        render: (quantity: any) => (
          <TextRightStyled>{quantity}</TextRightStyled>
        ),
      },
      {
        title: t("TS.MEMBER_BENEFIT_EVENT.VALID_DURATION"),
        dataIndex: "duration",
        render: (duration: any, item: any) => (
          <TextRightStyled>
            {renderDuration(duration, item.durationType)}
          </TextRightStyled>
        ),
      },
    ];
  }, []);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getGuestPassApplicable(eventId, {
        ...pagingInfo,
        pageSize,
      });

      setData(
        res.actions.map((item: ApplicableAmountProps, index: number) => ({
          ...item,
          order: pagingInfo.pageIndex * pageSize + index + 1,
        }))
      );
      setPagingInfo({
        ...res.paging,
        totalItemsCount: res.paging.totalActions,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [pagingInfo.pageIndex, pageSize, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const updatePageIndex = (newPageIndex: number) => {
    setPagingInfo({
      ...pagingInfo,
      pageIndex: newPageIndex,
    });
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="order"
        loading={isLoading}
        customStyle={{
          noUpperCase: true,
          boldTitle: true,
        }}
        paging={{
          ...pagingInfo,
          setPageIndex: updatePageIndex,
          setPageSize: setPageSize,
        }}
      />
    </>
  );
};

export default ApplicableAmount;
