import styled from "styled-components/macro";

import { mixin } from "utils/styles";

const HeadingGroupStyled = styled.div`
  color: var(--gray-color);
  h3 {
    ${mixin.headingH3}
    text-transform: uppercase;
    margin-bottom: 5px;
    color: var(--gray-color);
  }
  p {
    margin-bottom: 15px;
    font-size: 11px;
    line-height: 18px;
    color: var(--gray-color);
  }
`;

export const DescriptionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  p {
    flex-grow: 1;
  }
  .ant-dropdown-button {
    margin: 0;
    .ant-btn {
      box-shadow: none;
    }
  }
`;

export default HeadingGroupStyled;
