import './index.css';

import React from 'react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import 'utils/i18n';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<App />);

serviceWorker.unregister();
