import {
  EventMasterDataTypes,
  GET_DYNAMIC_PRICING_MASTER_DATA,
  GET_EVENT_ID,
  GET_EVENT_TYPE,
  GET_GUEST_PASS_EVENT_MASTER_DATA,
  GET_MEMBER_BENEFIT_MASTER_DATA,
  GET_MEMBER_CREDIT_MASTER_DATA,
  GET_REASON_TYPE,
  SET_DYNAMIC_PRICING_MASTER_DATA,
  SET_EVENT_ID,
  SET_EVENT_TYPE,
  SET_GUEST_PASS_EVENT_MASTER_DATA,
  SET_MEMBER_BENEFIT_MASTER_DATA,
  SET_MEMBER_CREDIT_MASTER_DATA,
  SET_REASON_TYPE
} from "./types";

export const getMemberCreditMasterDataAction = (): EventMasterDataTypes => ({
  type: GET_MEMBER_CREDIT_MASTER_DATA,
});

export const setMemberCreditMasterDatasAction = (
  data: EventMasterDataProps
): EventMasterDataTypes => ({
  type: SET_MEMBER_CREDIT_MASTER_DATA,
  data,
});

export const getDynamicPricingMasterDataAction = (): EventMasterDataTypes => ({
  type: GET_DYNAMIC_PRICING_MASTER_DATA,
});

export const setDynamicPricingMasterDataAction = (
  data: EventMasterDataProps
): EventMasterDataTypes => ({
  type: SET_DYNAMIC_PRICING_MASTER_DATA,
  data,
});

export const getEventIdAction = (data: number): EventMasterDataTypes => ({
  type: GET_EVENT_ID,
  data,
});

export const setEventIdAction = (data: number): EventMasterDataTypes => ({
  type: SET_EVENT_ID,
  data,
});

export const getEventTypeAction = (): EventMasterDataTypes => ({
  type: GET_EVENT_TYPE,
});

export const setEventTypeAction = (
  data: EventTypeProps
): EventMasterDataTypes => ({
  type: SET_EVENT_TYPE,
  data,
});

export const getReasonTypeAction = (data: string): EventMasterDataTypes => ({
  type: GET_REASON_TYPE,
  data,
});

export const setReasonTypeAction = (
  data: ReasonProps[]
): EventMasterDataTypes => ({
  type: SET_REASON_TYPE,
  data,
});

export const getMemberBenfitMasterDataAction = (): EventMasterDataTypes => ({
  type: GET_MEMBER_BENEFIT_MASTER_DATA,
});

export const setMemberBenefitMasterDataAction = (
  data: MemberBenefitMasterData
): EventMasterDataTypes => ({
  type: SET_MEMBER_BENEFIT_MASTER_DATA,
  data,
});

export const getGuestPassEventMasterDataAction = (): EventMasterDataTypes => ({
  type: GET_GUEST_PASS_EVENT_MASTER_DATA,
});

export const setGuestPassEventMasterDataAction = (
  data: GuestPassEventMasterData
): EventMasterDataTypes => ({
  type: SET_GUEST_PASS_EVENT_MASTER_DATA,
  data,
});


