import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { getAllClubs, searchTempMembers, updateArchiveRecord, updateConvertRecord } from 'services/tempMember';
import { Club, Member, Paging, SearchMember } from './type';
import { DATE_FORMAT, NotificationType } from 'utils/constants';
import { Message } from 'utils/message';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { showNotification } from 'utils/helpers';

const useProcessMember = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pagingData, setPagingData] = useState<Paging>({
    pageIndex: 0,
    totalItemsCount: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [clubs, setClubs] = useState<Club[]>([]);
  const [search, setSearch] = useState<SearchMember>({
    searchKey: '',
    sortBy: [{ name: 'LastName', direction: 'desc' }],
    clubId: '',
    sortDirection: 'asc'
  });

  const findClubName = (clubId: number, clubsData: Club[]) => {
    const club = clubsData.find((club) => club.id == clubId);
    return club ? club.displayName : '';
  };

  const formatForApi = (params: {
    pageIndex: number;
    pageSize: number;
    clubId: string | number;
    searchKey: string;
    sortBy: { name: string, direction: string }[];
    sortDirection: string;
  }, paging: { pageIndex: number, pageSize: number }) => {
    return {
      sorts: params.sortBy.map(sort => `${sort.name}=${sort.direction}`).join(','),
      filter: JSON.stringify({
        or: [
          {
            operation: "contains",
            queryType: "text",
            queryKey: "MembershipNumber",
            queryValue: params.searchKey,
          },
          {
            operation: "contains",
            queryType: "text",
            queryKey: "LastName",
            queryValue: params.searchKey,
          }
        ]
      }),
      pageIndex: paging.pageIndex,
      pageSize: paging.pageSize,
      ...(params.clubId ? { clubId: params.clubId } : {}),
    }
  }

  const params = useMemo(() => {
    return {
      ...search,
      pageIndex: pageIndex === 0 ? 1 : pageIndex,
      pageSize,
      clubId: search.clubId ? Number(search.clubId) : '',
    };
  }, [search, pageIndex, pageSize]);

  const fetchDataMember = async (
    clubData?: Club[],
    pagingData?: { pageIndex: number, pageSize: number }
  ) => {
    const response = await searchTempMembers(formatForApi(params, pagingData || { pageIndex: 1, pageSize: 10 }));
    setData(
      response.data.map((member: Member) => ({
        key: member.id,
        FirstName: member.firstName,
        LastName: member.lastName,
        Email: member.email,
        MembershipNumber: member.membershipNumber,
        Club: findClubName(member.clubId, clubData || clubs),
        Plan: member.planName,
        StartDate: moment(member.startDate).format(DATE_FORMAT),
        Status: member.status,
      }))
    );
    setPagingData({
      ...response.paging,
      pageIndex: response.paging.pageIndex - 1,
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const clubs = await getAllClubs();
      setClubs(clubs);
      await fetchDataMember(clubs, { ...pagingData, pageIndex: 1 });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (pageIndex > 0) {
      handleSearch({
        ...pagingData,
        pageIndex: pageIndex,
        pageSize
      })
    }
  }, [pageIndex]);

  useEffect(() => {
    if (clubs.length == 0) {
      return;
    }
    handleSearch();
  }, [search.sortBy, search.sortDirection]);

  const resetSearch = () => {
    setSearch((prevState) => ({
      ...prevState,
      searchKey: '',
      clubId: '',
    }));
  };

  const onSearch = (fieldName: string, data: any) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [fieldName]: data,
    }));
  };

  const handleSearch = async (pagingData?: Paging) => {
    try {
      setIsLoading(true);
      await fetchDataMember(clubs, pagingData);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetPageIndex = (newPageIndex: number) => {
    setPageIndex(newPageIndex + 1);
  };

  const handleSetPageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    const pagingUpdated = {
      ...pagingData,
      pageIndex: 1,
      pageSize: newPageSize,
    };
    handleSearch(pagingUpdated);
  };

  const handleMemberAction = (
    memberId: string,
    titleKey: string,
    confirmKey: string,
    successMessageKey: string,
    action: (memberId: string) => Promise<void>
  ) => {
    Message.confirmMessage(
      t(titleKey),
      t(confirmKey),
      400,
      () => {
        action(memberId)
          .then(() => {
            showNotification(
              t('SS.SUCCESSFULLY'),
              t(successMessageKey),
              NotificationType.success,
              2
            );
            handleSearch();
          })
          .catch((error) => {
            console.error(error);
          });
      },
      () => Swal.close()
    );
  };

  const archiveMember = (memberId: string) => {
    handleMemberAction(
      memberId,
      'TS.ARCHIVE.TITLE',
      'TS.ARCHIVE.CONFIRM',
      'TS.CONVERT.SUCCESS',
      updateArchiveRecord
    );
  };

  const convertMember = (memberId: string) => {
    handleMemberAction(
      memberId,
      'TS.CONVERT.TITLE',
      'TS.CONVERT.CONFIRM',
      'TS.CONVERT.SUCCESS',
      updateConvertRecord
    );
  };

  const onSort = (_: unknown, data: unknown, record: any) => {
    setSearch((prev) => ({
      ...prev,
      sortBy: [
        ...prev.sortBy.filter(item => item.name !== record.field),
        {
          name: record.field,
          direction: record.order === 'ascend' ? 'asc' : 'desc'
        }
      ]
    }))
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return {
    data,
    isLoading,
    pagingData,
    clubs,
    search,
    resetSearch,
    onSearch,
    handleSearch,
    handleSetPageIndex,
    handleSetPageSize,
    convertMember,
    archiveMember,
    onSort,
    handleKeyPress
  };
};

export default useProcessMember;
