export const DEFAULT_PAGING = {
  pageIndex: 0,
  totalPages: 0,
  pageSize: 20,
  totalItems: 0,
  totalItemsCount: 0,
};

export const DEFAULT_PAGING_CUSTOM = {
  pageIndex: 0,
  pageSize: 25,
  totalPages: 0,
  totalItemsCount: 0,
};

export const EVENT_LIST_PAGING = {
  pageIndex: 0,
  pageSize: 10,
  totalPages: 0,
  totalItemsCount: 0,
};

export const MANAGE_GATE_PAGING = {
  pageIndex: 0,
  pageSize: 10,
  totalPages: 0,
  totalItemsCount: 0,
};

export const OPTION_REASON_OTHERS = {
  label: "Others",
  value: "0",
  code: "Others",
};

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_MONTH_DAY_YEAR = "MM/DD/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATE_FILTER_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_FOR_SUBMIT = "YYYY-MM-DDTHH:mm:ss";
export const DATE_TIME_FILTER_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const REGEX_POSITIVE_NUMBER = /^[0-9]+[0-9]*$/g;
export const REGEX_GREATER_THAN_0_NUMBER = /^[1-9]+[0-9]*$/g;
export const REGEX_INTEGER_NUMBER = /^[+]?[0-9]*$/g;
export const REGEX_POSITIVE_DECIMAL_NUMBER = /^([1-9]+\.?[0-9]*|\.[0-9]+)$/g;
export const REGEX_NUMBER_FROM_1_TO_100 =
  /^(?:0\.[0-9]*[1-9]+\d*|[1-9][0-9]?(?:\.\d+)?|(100(\.[0]+)?))$/g;
export const REGEX_POSITIVE_SEPARATE_NUMBER =
  /(^0{1}(?:\.\d+))|(^[1-9]\d{0,2}((?:,\d{3})*)(?:\.\d+[1-9]{0})?)$/g;
export const REGEX_POSITIVE_INTEGER = /(^[1-9]\d{0,9})((?:,\d{3})*)$/g;

export const REGEX_0_99 = /^([0-9][0-9]?){0,1}$/g;
export const REGEX_1_99 = /^([1-9][0-9]?){0,1}$/g;
export const REGEX_INTEGER_1_31 = /^(0?[1-9]|[12][0-9]|3[01])$/g;

export const STATUS_OPTIONS = [
  {
    label: "SS.TRANSACTION.ALL",
    value: "",
  },
  {
    label: "SS.TRANSACTION.SUCCESS",
    value: "success",
  },
  {
    label: "SS.TRANSACTION.FAIL",
    value: "fail",
  },
];

export const REACT_APP_CLIENT_ID = "CF810B69-0B40-4B19-986A-BFE05695A55F";
export const REACT_APP_STANDARD_HEIGHT = 428;

export const REACT_APP_CLIENT_SECRET = "jh1Mfd6sOERPmmTCRPc6";

const CURRENCY_FORMAT_DEFAULT = {
  languageTag: "en-US",
  currency: "USD",
  prefix: "$",
};
export const CURRENCY_FORMAT_BY_COUTRY: Record<
  string,
  {
    languageTag: string;
    currency: string;
    prefix: string;
  }
> = {
  id: {
    languageTag: "id-ID",
    currency: "IDR",
    prefix: "Rp",
  },
  my: CURRENCY_FORMAT_DEFAULT,
  th: CURRENCY_FORMAT_DEFAULT,
  sg: CURRENCY_FORMAT_DEFAULT,
  hk: CURRENCY_FORMAT_DEFAULT,
  ph: CURRENCY_FORMAT_DEFAULT,
};

export const DAY_VALUE = "DY";
export const MONTH_VALUE = "MN";

export const DATE_OPTION = [
  { label: "day(s)", value: DAY_VALUE },
  { label: "month(s)", value: MONTH_VALUE },
];

export const ANDOR = [
  { label: "ALL", value: "AND" },
  { label: "ANY", value: "OR" },
];

export const EVENT_STATUS_OPTION = [
  { value: "AL", label: "All" },
  { value: "AC", label: "Active" },
  { value: "IN", label: "Inactive" },
  { value: "EX", label: "Expired" },
];

export enum ValueType {
  MultiOptionType = "multiOptionType",
  InputType = "inputType",
  IntInputType = "intInputType",
  InputRangeType = "inputRangeType",
  DateType = "dateType",
  DateRangeType = "dateRangeType",
  UpLoadFile = "upLoadFile",
  NoValueType = "noValueType",
}

export enum Type {
  Int = "int",
  Date = "datetime",
  Double = "double",
  String = "string",
}

export enum NotificationType {
  success = "success",
  error = "error",
}

export const MuitipleType = ["Club", "Tier", "Plan", "Addon"];
export const Between = "Between";
export const MemberListCriteria = "MemberList";
export const WithinEventDuration = "WithinEventDuration";
export const IsToday = "IsToday";

export enum EventType {
  MemberCredit = "MemberCredit",
  DynamicPricing = "DynamicPricing",
  MemberBenefit = "MemberBenefit",
}

export const Status = {
  Active: "AC",
  InActive: "IN",
};

export const OperatorName = {
  Is: "is",
  IsNot: "isnot",
};

export const ReasonType = {
  OTHER_REASON_CODE: "BT",
  MB_CODE: "BE",
  EXPIRY_DATE_REASON_CODE: "EX",
  EXPIRY_DATE_GUEST_PASS_CODE: "EP",
};

export const GuestPassActionCode = {
  GIVE_GUEST_PASS: "GP",
  EXTEND_GUEST_PASS: "EP",
};

export const EXTEND_MEMBER_BENEFIT = "EB";
export const GIVE_MEMBER_BENEFIT = "GB";

export const EventActionCode = {
  GIVE_MEMBER_CREDIT: "MC",
  EXTEND_EXPIRE_DURATION: "ED",
};

export const PostMessageTableType = {
  MEMBER_BENEFIT_TABLE: "MB",
  MEMBER_CREDIT_TABLE: "MC",
  MEMBER_HEAD_MEMBER_TABLE: "HM",
  MEMBER_LINKED_MEMBER_TABLE: "LM",
};

export const MEET_CRITERIA = 0;
export const ADD_DELAY = 2;
export const MEET_CRITERIA_EXACT = 1;

export const MEMBER_DELAY_OPTION = [
  {
    label: "TS.WHEN.MEMBER.MEETS.CRITERIA",
    value: MEET_CRITERIA,
  },
  {
    label: "TS.DAY.WHEN.MEMBER.MEET.CRITERIA",
    value: MEET_CRITERIA_EXACT,
  },
  {
    label: "TS.ADD.DELAYS",
    value: ADD_DELAY,
  },
];

export const ITEMS = "IT";
export const LEDGER = "LG";

export const ITEM_LEDGER_OPTION = [
  {
    label: "Items",
    value: ITEMS,
  },
  {
    label: "Ledger Group",
    value: LEDGER,
  },
];

export const MEMBER_ANNIVERSARY_CRITERIA = {
  criteria: "MemberAnniversary",
  suffix: "year(s)",
};

export const MEMBER_DURATION_CRITERIA = {
  defaultDropDownDateType: "YR",
  criteria: "MembershipDuration",
};

export const DURATION_TYPE_OPTION = [
  {
    label: "year(s)",
    value: "YR",
  },
  {
    label: "month(s)",
    value: "MN",
  },
];

export enum DURATION_TYPE_MEET_CRITERIA {
  Exact = 1,
  First = 2,
  Last = 3,
}

export const DURATION_TYPE_WHEN_MEMBER_MEET_CRITERIA = [
  {
    label: "exact",
    value: DURATION_TYPE_MEET_CRITERIA.Exact,
  },
  {
    label: "first",
    value: DURATION_TYPE_MEET_CRITERIA.First,
  },
  {
    label: "last",
    value: DURATION_TYPE_MEET_CRITERIA.Last,
  },
];

export const OPERATOR_EQUAL_TO = "EqualTo";
export const OPERATOR_GREATER_THAN = "GreaterThan";
export const EVENT_TYPE = {
  MemberBenefit: "Member Benefit",
  DynamicPricing: "Dynamic Pricing",
  GuestPass: "Guest Pass",
  MemberCredit: "Member Credit",
};
