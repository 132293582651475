import { Button, Popover } from "antd";
import InformationTooltip from "components/InformationTooltip";
import styled, { SimpleInterpolation } from "styled-components";
import { mixin } from "utils/styles";

interface StatusProps {
  status: string;
}
export const StatusStyled = styled.span`
  ${(props: StatusProps): SimpleInterpolation => {
    return props.status.toLowerCase() === "success"
      ? "color: var(--success-color);"
      : "color: var(--danger-color);";
  }}
`;

export const EventListContainerStyled = styled.div`
  background-color: var(--white-color);
  margin-top: 10px;
`;

export const GroupStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
export const AmountStyled = styled.div`
  text-align: right;
`;

export const InformationTooltipStyled = styled(InformationTooltip)`
  margin-left: 5px;
`;

export const RedirectStyled = styled.div`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const FormContainerStyled = styled.div`
  max-width: 917px;
  input,
  textarea {
    ${mixin.placeholderColor("var(--gray-color)")}
  }
  .ant-select-selection-placeholder {
    color: var(--gray-color);
    opacity: 1;
  }
`;

export const InputTitleStyled = styled.p<{ $textAlign?: string }>`
  font-size: 12px;
  color: var(--gray-color);
  margin: 7px 0 0 0;
  text-align: right;
  ${(props): SimpleInterpolation => (props.$textAlign ? `text-align: ${props.$textAlign};` : 'text-align: right;')}
`;

export const DetailsButtonStyled = styled(Button)`
    font-size: 11px;
    border: none;
    color: var(--secondary-color);
    background: transparent;
    height: 20px;
    padding: 0px;
    box-shadow: none;
    text-decoration: underline;
      &:focus{
       color: var(--secondary-color);
       font-weight: bold;
      }
      &:hover{
        background: transparent;
        box-shadow: none;
        font-weight: bold;
        color: var(--secondary-color);
      }
  `
export const DetailsTextStyled = styled.span`
  text-decoration: underline;
`

export const SearchButtonStyle = styled.button`
    font-size: 12px;
    padding: 2px 12px;
    font-weight: bold;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    cursor: pointer;
    &:hover{
        background-color: var(--secondary-color);
      }
`