import styled, { SimpleInterpolation } from "styled-components";
import ButtonStyled from "styled/ButtonStyled";

interface StatusProps {
  status: string;
}
export const StatusStyled = styled.span`
  ${(props: StatusProps): SimpleInterpolation => {
    return props.status.toLowerCase() === "success"
      ? "color: var(--success-color);"
      : "color: var(--danger-color);";
  }}
`;

export const TransactionListStyled = styled.div`
  background-color: var(--white-color);
  margin-top: 30px;
`;

export const GroupStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
export const AmountStyled = styled.div`
  text-align: right;
`;

export const PSPRefStyled = styled.div`
  word-break: break-word;
`;

export const CustomButtonStyled = styled(ButtonStyled)`
  white-space: normal;
`;
