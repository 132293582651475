import React from "react";
import { InfoBlockStyled, InfoItemStyled, InfoItemKeyStyled } from "./Styled";

const InfoBlock: React.FC<InfoBlockProps> = ({ infos }) => {
  return (
    <InfoBlockStyled>
      {infos.map((info) => (
        <InfoItemStyled key={info.key}>
          <InfoItemKeyStyled>{info.key}:</InfoItemKeyStyled>
          <span>{info.value}</span>
        </InfoItemStyled>
      ))}
    </InfoBlockStyled>
  );
};

export default InfoBlock;
