import React from 'react';

import HeadingGroupStyled, { DescriptionStyled } from './Styled';

interface HeadingGroupProps {
  title: string;
  description?: string;
  language?: JSX.Element;
}
const HeadingGroup: React.FC<HeadingGroupProps> = ({
  title,
  description,
}) => (
  <HeadingGroupStyled>
    <h3>{title}</h3>
    <DescriptionStyled>
      {description ? <p>{description}</p> : null}
    </DescriptionStyled>
  </HeadingGroupStyled>
);

export default HeadingGroup;
