import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: 'en',
    load: 'currentOnly',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `/data/{{lng}}.json`,
      requestOptions: {
        cache: 'no-store'
      }
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
