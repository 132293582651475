import { Select } from "antd";
import React, { useMemo } from "react";

import PaginationStyled, {
  LiStyled,
  PaginationTitleStyled,
  PaginationPageSizeStyled,
} from "./Styled";

import Icon from "components/Icon";
import ButtonStyled from "styled/ButtonStyled";
import { DEFAULT_PAGING } from "utils/constants";

interface PaginationProps {
  totalPages: number;
  pageSize: number;
  pageIndex: number;
  showNPage?: number;
  totalItemsCount: number;
  showPageInfo?: boolean;
  style?: object;
  color?: string;
  setPage(page: number): void;
  changePageSize(size: number): void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  pageSize = DEFAULT_PAGING.pageSize,
  pageIndex,
  showNPage = 3,
  totalItemsCount,
  showPageInfo = true,
  style = {},
  color,
  setPage,
  changePageSize,
}) => {
  const offset = useMemo(
    () =>
      Math.max(
        1,
        Math.min(
          totalPages - showNPage + 1,
          pageIndex - Math.floor(showNPage / 2)
        )
      ),
    [pageIndex, showNPage, totalPages]
  );

  const toItem = useMemo(
    () => Math.min((pageIndex + 1) * pageSize, totalItemsCount),
    [totalItemsCount, pageIndex, pageSize]
  );

  const maxItem = useMemo(
    () => Math.min(totalItemsCount * pageSize, totalItemsCount),
    [totalItemsCount, pageSize]
  );

  return (
    <PaginationStyled style={style}>
      {showPageInfo ? (
        <PaginationPageSizeStyled>
          <span>Rows per page</span>
          <Select
            value={pageSize}
            onChange={(size: number): void => {
              changePageSize(size);
              setPage(0);
            }}
          >
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
        </PaginationPageSizeStyled>
      ) : null}

      <ul>
        <LiStyled>
          <ButtonStyled
            size="small"
            icon={<Icon icon="icon-double-arrow-left-icon" />}
            disabled={pageIndex === 0}
            onClick={(): void => setPage(0)}
          />
        </LiStyled>
        <LiStyled>
          <ButtonStyled
            disabled={pageIndex === 0}
            icon={<Icon icon="icon-arrow-left-icon" />}
            size="small"
            onClick={(): void => setPage(pageIndex - 1)}
          />
        </LiStyled>
        {Array.from({ length: Math.min(showNPage, totalPages) })
          .map((_, idx) => idx + offset)
          .map((page) => (
            <LiStyled key={page} isActive={pageIndex === page - 1} color={color}>
              <ButtonStyled
                size="small"
                onClick={(): void => setPage(page - 1)}
              >
                {page}
              </ButtonStyled>
            </LiStyled>
          ))}

        <LiStyled>
          <ButtonStyled
            disabled={pageIndex === totalPages - 1}
            icon={<Icon icon="icon-arrow-right-icon" />}
            size="small"
            onClick={(): void => setPage(pageIndex + 1)}
          />
        </LiStyled>
        <LiStyled>
          <ButtonStyled
            disabled={pageIndex === totalPages - 1}
            icon={<Icon icon="icon-double-arrow-right-icon" />}
            size="small"
            onClick={(): void => setPage(totalPages - 1)}
          />
        </LiStyled>
      </ul>
      {showPageInfo ? (
        <PaginationTitleStyled>
          <span>{`${pageIndex * pageSize + 1} - ${toItem} of ${maxItem}`}</span>
        </PaginationTitleStyled>
      ) : null}
    </PaginationStyled>
  );
};

export default Pagination;
