import Swal from "sweetalert2";

import i18n from "./i18n";

export const Message = {
  confirmMessage(
    title: string,
    content: string,
    width: number,
    callBackOk: () => void,
    callBackCancel: () => void
  ) {
    Swal.fire({
      title,
      html: content,
      showCancelButton: true,
      confirmButtonText: i18n.t("SS.OK"),
      cancelButtonText: i18n.t("SS.CANCEL"),
      reverseButtons: true,
      width: width,
      customClass: {
        title: "swal2-title-custom",
        confirmButton: "swal2-confirm-custom swal2-confirm-80",
        cancelButton: "swal2-cancel-custom swal2-cancel-80",
        htmlContainer: "swal2-text-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callBackOk();
      } else if (result.isDenied) {
        callBackCancel();
      }
    });
  },
};
