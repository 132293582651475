import { GateProps, GetMasterData } from "pages/ConfigGateRule/types";
import { Action } from "redux";

export const GET_CLUB_GROUP_MASTER_DATA = "GET_CLUB_GROUP_MASTER_DATA";
export const SET_CLUB_GROUP_MASTER_DATA = "SET_CLUB_GROUP_MASTER_DATA";
export const GET_CURRENT_CLUB = "GET_CURRENT_CLUB";
export const SET_CURRENT_CLUB = "SET_CURRENT_CLUB";
export const GET_MASTER_DATA = "GET_MASTER_DATA";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const GET_CHOSEN_GATES = "GET_CHOSEN_GATES";
export const SET_CHOSEN_GATES = "SET_CHOSEN_GATES";

export type GetClubGroupMasterDataAction = Action<
  typeof GET_CLUB_GROUP_MASTER_DATA
>;
export interface SetClubGroupMasterDataAction
  extends Action<typeof SET_CLUB_GROUP_MASTER_DATA> {
  data: ClubGroup[];
}

export type GetCurrentClubAction = Action<typeof GET_CURRENT_CLUB>;
export interface SetCurrentClubAction
  extends Action<typeof SET_CURRENT_CLUB> {
  data: string;
}

export type GetMasterDataAction = Action <typeof GET_MASTER_DATA>;
export interface SetMasterDataAction extends Action<typeof SET_MASTER_DATA>{
  data: GetMasterData;
}

export type GetChosenGatesAction = Action <typeof GET_CHOSEN_GATES>;
export interface SetChosenGatesAction extends Action<typeof SET_CHOSEN_GATES>{
  data: GateProps[];
}


export type GateMasterDataTypes =
  | GetClubGroupMasterDataAction
  | SetClubGroupMasterDataAction
  | GetCurrentClubAction
  | SetCurrentClubAction
  | GetMasterDataAction
  | SetMasterDataAction
  | GetChosenGatesAction
  | SetChosenGatesAction;
