import { Col, Form, Input, Row, Select } from "antd";
import GroupButtons from "components/GroupButtons";
import HeadingGroup from "components/HeadingGroup";
import Icon from "components/Icon";
import {
  ButtonWithBottomSpacingStyled,
  ColTitleStyled,
  FormContainerStyled,
  FormListItemStyled,
  FormListStyled,
} from "pages/CreateUpdateEvent/Styled";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { getReasonType, submitMemberCredit } from "services/transaction";
import ButtonStyled from "styled/ButtonStyled";
import { DownLineStyled } from "styled/EventStyled";
import { FormItemStyled } from "styled/FormStyled";
import {
  DAY_VALUE,
  MEET_CRITERIA,
  NotificationType,
  OPTION_REASON_OTHERS,
} from "utils/constants";
import { showNotification } from "utils/helpers";
import ActionItem from "./ActionItem";
import { ColStyled, GroupStyled, InputTitleStyled, RowStyled } from "./Styled";

const InitalData = {
  reasonCode: null,
  lookupAndReasonCodeID: null,
  actions: [{ amount: "", validDuration: null, durationType: DAY_VALUE }],
  when: MEET_CRITERIA,
};

const AddMemberCredit: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useNavigate();
  const [reasonOptions, setReasonOptions] = useState<OptionProps[]>([]);
  const [otherInput, setOtherInput] = useState<boolean>(false);

  const customReason = (reasons: ReasonProps[]): OptionProps[] => {
    const reasonTypeList = reasons.map((reason) => ({
      label: reason.reasonName,
      value: reason.lookupAndReasonCodeID,
      code: reason.reasonCode,
    }));

    reasonTypeList.push(OPTION_REASON_OTHERS);
    return reasonTypeList;
  };

  const { operatorId, memberId, audience } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "",
      memberId: params.get("memberId") || "",
      audience: params.get("audience") || "",
    };
  }, [window.location.search]);

  const getReason = async (): Promise<void> => {
    const res = await getReasonType("RA");
    setReasonOptions(customReason(res));
  };

  useEffect(() => {
    getReason();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ InitalData });
    addField();
  }, [form]);

  const onSubmit = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const { reasonCode, actions, lookupAndReasonCodeID, otherReason } =
        values;
      actions.shift();
      let changeReason;

      if (reasonCode === "Others") {
        changeReason = {
          reasonText: otherReason,
          reasonType: reasonCode,
        };
      } else {
        changeReason = {
          reasonType: "RA",
          reasonCode,
          lookupAndReasonCodeID,
        };
      }

      const dataSubmit = {
        actions,
        changeReason,
        operatorId,
        memberId,
      };

      await submitMemberCredit(dataSubmit).then((_) => {
        showNotification(
          t("TS.SUCCESS"),
          t("SS.ADD_MEMBER_CREDIT.SUCCESSFULLY_DESCRIPTION"),
          NotificationType.success
        );
        setTimeout(() => {
          if (audience === "pos") {
            history(-1);
          } else {
            window.parent.location.href =
              _env.REACT_APP_EMBEDDED_BASE_URL +
                "/Members/MembershipDetails.aspx" || "";
          }
        }, 400);
      });
    } catch (error) {}
  };

  const addField = (): void => {
    const actions = form.getFieldValue("actions") || [];
    form.setFieldsValue({ actions: [...actions, { durationType: DAY_VALUE }] });
  };

  const onChangeReason = (_: string, item: any): void => {
    setOtherInput(item.code === "Others" ? true : false);
    form.setFieldsValue({ reasonCode: item.code });
  };

  return (
    <>
      <HeadingGroup title={t("SS.ADD_MEMBER_CREDIT")} />
      <Form
        name="basic"
        form={form}
        initialValues={InitalData}
        layout="vertical"
        hideRequiredMark
      >
        <DownLineStyled />
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 2 }} xs={{ span: 2 }}></Col>
            <Col className="d-flex" md={{ span: 5 }} xs={{ span: 5 }}>
              <InputTitleStyled>{t("SS.REASON")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
              <FormItemStyled name="reasonCode" hidden noStyle>
                <Input type="hidden"></Input>
              </FormItemStyled>
              <FormItemStyled
                name="lookupAndReasonCodeID"
                rules={[
                  {
                    required: true,
                    message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                      key: t("SS.CREATE_EVENT.REASON_TYPE"),
                    }),
                  },
                ]}
              >
                <Select
                  options={reasonOptions}
                  onChange={onChangeReason}
                  placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                    key: t("SS.CREATE_EVENT.REASON_TYPE"),
                  })}
                />
              </FormItemStyled>
            </Col>
            {otherInput ? (
              <>
                <Col className="d-flex" md={{ span: 7 }} xs={{ span: 7 }}></Col>
                <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
                  <FormItemStyled
                    name="otherReason"
                    rules={[
                      {
                        required: true,
                        message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                          key: t("SS.ADD_MEMBER_CREDIT.OTHER_REASON"),
                        }),
                      },
                      {
                        min: 3,
                        message: t("SS.EVENT_MANAGEMENT.MINIMUM_INPUT", {
                          key: 3,
                        }),
                      },
                      {
                        max: 100,
                        message: t("SS.EVENT_MANAGEMENT.MAXIMUM_INPUT", {
                          key: 100,
                        }),
                      },
                    ]}
                  >
                    <Input
                      autoFocus
                      autoComplete="off"
                      placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                        key: t("SS.ADD_MEMBER_CREDIT.OTHER_REASON"),
                      })}
                    />
                  </FormItemStyled>
                </Col>
              </>
            ) : null}
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={2} xs={{ span: 2 }}></ColStyled>
            <ColStyled md={10} xs={{ span: 10 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.AMOUNT")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={7} xs={{ span: 7 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.VALID_DURATION")}
              </ColTitleStyled>
            </ColStyled>
          </RowStyled>
          <FormListStyled name="actions">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <ActionItem
                      form={form}
                      remove={remove}
                      groupField={field}
                      parentIdx={parentIdx}
                      fieldsLength={fields.length}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
        </FormContainerStyled>
        <FormListItemStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={18} xs={{ span: 18 }}></ColStyled>
            <ColStyled md={4} xs={{ span: 4 }}>
              <ButtonWithBottomSpacingStyled
                onClick={(): void => {
                  addField();
                }}
                icon={<Icon icon="icon-plus-ico" />}
                type="default"
                size="small"
              >
                {" "}
                {t("SS.CREATE_EVENT.ADD_ACTION")}
              </ButtonWithBottomSpacingStyled>
            </ColStyled>
          </RowStyled>
        </FormListItemStyled>
        <FormContainerStyled>
          <GroupButtons position="center">
            <ButtonStyled type="default" onClick={() => history(-1)}>
              {t("SS.CANCEL")}
            </ButtonStyled>
            <ButtonStyled type="primary" onClick={onSubmit}>
              {t("SS.SAVE")}
            </ButtonStyled>
          </GroupButtons>
        </FormContainerStyled>
      </Form>
    </>
  );
};
export default AddMemberCredit;
