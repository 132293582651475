import { FormInstance } from "antd";
import Icon from "components/Icon";
import {
  RowLedgerGroup,
  TitleSelectStyled,
} from "pages/CreateMemberBenefitEvent/Styled";
import {
  ButtonWithBottomSpacingStyled,
  ColStyled,
  ColTitleStyled,
  ConditionGroupStyled,
  ConditionsStyled,
  FormListItemStyled,
  FormListStyled,
  SubTitleStyled,
} from "pages/CreateUpdateEvent/Styled";
import { GroupStyled } from "pages/TransactionList/Styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import {
  DATE_OPTION,
  GIVE_MEMBER_BENEFIT,
  ITEM_LEDGER_OPTION,
} from "utils/constants";
import ActionItem from "./Action";

interface IProps {
  form: FormInstance;
}

const RuleAction: React.FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const [disableAddAction, setDisableAddAction] = useState(false);
  const [isLedger, setIsLedger] = useState<boolean>(false);
  const addField = (): void => {
    const actions = form.getFieldValue("actions") || [];
    form.setFieldsValue({
      actions: [
        ...actions,
        { actionCode: GIVE_MEMBER_BENEFIT, durationType: DATE_OPTION[0].value },
      ],
    });
  };

  useEffect(() => {
    const actions = form.getFieldValue("actions") || [];
    if (
      actions.some(
        (action: MemberBenefitEvent) =>
          action.ledgerGroupIds && action.ledgerGroupIds.length > 0
      )
    ) {
      setIsLedger(true);
    }
  }, [form]);

  const handleChange = (value: any) => {
    setIsLedger(value === ITEM_LEDGER_OPTION[1].value);
    form.setFieldsValue({
      actions: [
        {
          actionCode: GIVE_MEMBER_BENEFIT,
          durationType: DATE_OPTION[0].value,
          itemIds: [],
          ledgerGroupIds: [],
        },
      ],
    });
  };

  return (
    <>
      <SubTitleStyled>{t("SS.CREATE_EVENT.PERFORM_ACTION")}</SubTitleStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <RowLedgerGroup $hasSpacingRight={true}>
            <ColStyled md={8} xs={{ span: 8 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.ACTION")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={5} xs={{ span: 5 }}>
              <FormItemStyled name="ledgerGroup">
                {!disableAddAction && (
                  <TitleSelectStyled
                    showArrow
                    options={ITEM_LEDGER_OPTION}
                    onChange={handleChange}
                  />
                )}
              </FormItemStyled>
            </ColStyled>
            <ColStyled md={3} xs={{ span: 3 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.MEMBER_BENEFIT.QUANTITY")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={8} xs={{ span: 8 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.VALID_DURATION")}
              </ColTitleStyled>
            </ColStyled>
          </RowLedgerGroup>
        </ConditionsStyled>
      </ConditionGroupStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <FormListStyled name="actions">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <ActionItem
                      form={form}
                      remove={remove}
                      groupField={field}
                      parentIdx={parentIdx}
                      fieldsLength={fields.length}
                      setDisableAddAction={setDisableAddAction}
                      isLedger={isLedger}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
          <FormListItemStyled>
            <ButtonWithBottomSpacingStyled
              onClick={(): void => {
                addField();
              }}
              icon={<Icon icon="icon-plus-ico" />}
              type="default"
              size="small"
              disabled={disableAddAction}
            >
              {t("SS.CREATE_EVENT.ADD_ACTION")}
            </ButtonWithBottomSpacingStyled>
          </FormListItemStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
    </>
  );
};

export default RuleAction;
