import React, { useCallback, useEffect, useMemo, useState } from "react";

import TableData from "./TableData";

import LinkedMember from "models/LinkedMember";

import {
  COLUMNS,
  COLUMNS_LINK_MEMBER,
  COLUM_HEAD_MEMBER,
} from "constants/LinkedMembership";
import {
  deleteLinkedMembership,
  getAllRole,
  getLinkedMember,
} from "services/linkedMembership";

import { NotificationType, PostMessageTableType } from "utils/constants";
import Iframe from "utils/iframe";

import { LinkedMemberStyled } from "./Styled";
import { showNotification } from "utils/helpers";
import EditLinkedMembershipModal from "./EditLinkedMembershipModal";
import { useTranslation } from "react-i18next";
import { Message } from "utils/message";
import Swal from "sweetalert2";
import { EDIT_LINKED_MEMBERSHIP, REMOVE_LINKED_MEMBERSHIP } from "constants/role";

const LinkedMemberView = () => {
  const [isLoading, setLoading] = useState(false);
  const [listLinkedMember, setListLinkedMember] = useState<LinkedMember[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [currentLinkedMember, setCurrentLinkedMember] =
    useState<LinkedMember>();
  const [roles, setRoles] = useState<string[]>([]);
  const { t } = useTranslation();

  const { memberId, isHeadMember, operatorId } = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      memberId: params.get("memberId") || "",
      isHeadMember: params.get("isHeadMember") || "",
      operatorId: params.get("operatorId") || "0",
    };
  }, [window.location.search]);

  useEffect(() => {
    getDataLinkedMember();
    handleLoadRoles();
  }, []);

  const handleLoadRoles = () => {
    if (operatorId) {
      getAllRole(operatorId).then((data) => {
        const rolesList = data
          .map((role) => {
            if (role.rights.length > 0) {
              return role.rights;
            }
            return null;
          })
          .filter((role) => role)
          .reduce((newRoleList, currentRoleList) => {
            if (newRoleList && currentRoleList) {
              return newRoleList.concat(currentRoleList);
            }
            return []
          }, []);

        setRoles(rolesList ?? [])
      });
    }
  };

  const getDataLinkedMember = async () => {
    setLoading(true);
    try {
      const data = await getLinkedMember(memberId!);
      if (data.length) {
        setListLinkedMember(data);
      } else {
        setListLinkedMember([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      sendHeightPostMessageToLinkMemberTable();
    }
  }, [isLoading]);

  const sendHeightPostMessageToLinkMemberTable = useCallback(() => {
    Iframe.sendHeightPostMessage(
      listLinkedMember,
      "linkedMemberTable",
      PostMessageTableType.MEMBER_LINKED_MEMBER_TABLE
    );
  }, []);

  const handleAction = useCallback(
    (type: string, currentLinkedMember: LinkedMember) => {
      setCurrentLinkedMember(currentLinkedMember);
      if (type === "Remove") {
        handleRemove(currentLinkedMember);
      }
      if (type === "Edit") {
        handleEdit();
      }
      if (type === "Clone") {
        window.parent.location.href =
          _env.REACT_APP_EMBEDDED_BASE_URL +
          "/Members/Wizards/AddLinkedMember.aspx?MemberId=" +
          currentLinkedMember.memberId;
      }
    },
    []
  );

  const handleRemove = (currentLinkedMember: LinkedMember) => {
    Message.confirmMessage(
      t("TS.REMOVE.LINKED.MEMBERSHIP"),
      t("TS.CONFIRM.REMOVE.MEMBERSHIP.LINK"),
      300,
      () => handleConfirmRemove(currentLinkedMember),
      () => Swal.close()
    );
  };

  const onToggleModal = useCallback((toggle: boolean) => {
    setOpenEditModal(toggle);
    getDataLinkedMember();
  }, []);

  const handleEdit = () => {
    setOpenEditModal(true);
  };

  const closeModal = useCallback(() => {
    setOpenEditModal(false);
  }, []);

  const handleConfirmRemove = async (currentLinkedMember: LinkedMember) => {
    if (currentLinkedMember) {
      const res = await deleteLinkedMembership(
        memberId,
        currentLinkedMember.memberId.toString(),
        operatorId
      );
      if (res) {
        showNotification(
          t("TS.SUCCESS"),
          t("TS.MEMBERSHIP.REMOVE.SUCCESSFULLY"),
          NotificationType.success
        );
      }
      getDataLinkedMember();
    }
  };

  const formatColumnsByRole = useMemo(() => {
    return COLUMNS.filter(column => {
      if(column.dataIndex === "Edit" && roles.includes(EDIT_LINKED_MEMBERSHIP)) {
        return column
      } else if(column.dataIndex === "Remove" && roles.includes(REMOVE_LINKED_MEMBERSHIP)) {
        return column;
      } else if(column.dataIndex !== "Remove" && column.dataIndex !== "Edit") {
        return column;
      }
    })
}, [roles]) 

  return (
    <>
      <LinkedMemberStyled id="linkedMemberTable">
        <TableData
          columns={
            isHeadMember.toLowerCase() === "false" ? COLUM_HEAD_MEMBER : formatColumnsByRole
          }
          columnChild={COLUMNS_LINK_MEMBER}
          data={listLinkedMember}
          isLoading={isLoading}
          handleAction={handleAction}
          adjustHeightIframe={sendHeightPostMessageToLinkMemberTable}
          roles={roles}
        />
      </LinkedMemberStyled>
      {openEditModal && currentLinkedMember && (
        <EditLinkedMembershipModal
          closeModal={closeModal}
          setOpenModal={onToggleModal}
          parentId={memberId}
          currentLinkedMember={currentLinkedMember}
          operatorId={operatorId}
        />
      )}
    </>
  );
};

export default LinkedMemberView;
