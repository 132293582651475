export const routeUrls = {
  forbidden: '/forbidden',
  root: '/',
  transactionList: '/transaction-list',
  transactionDetail: '/transaction-list/:id',
  transactionAllMemberList: '/transaction-list-all-member',
  transactionAllMemberDetail: '/transaction-list-all-member/:id',
  previewEventDetail: '/preview-event-detail/:id/:isCreateEvent',
  dynamicPricingEventDetail:
    '/preview-dynamic-pricing-event-detail/:id/:isCreateEvent',
  createEvent: '/create-event',
  editEvent: '/edit-event/:id',
  manageEvents: '/manage-events',
  addMemberCredit: '/create-member-credit',
  viewMemberCreditSpending: '/view-member-credit',
  createDynamicPricingEvent: '/create-dynamic-pricing-event',
  editDynamicPricingEvent: '/edit-dynamic-pricing-event/:id',
  manageGate: '/manage-gate',
  viewGateRuleDetail: '/gate-rule/:id/:createdBy',
  configGateRule: '/config-gate-rule/:id/:createdBy',
  createMemberBenefitEvent: '/create-member-benefit-event',
  editMemberBenefitEvent: '/edit-member-benefit-event/:id',
  memberBenefitEventDetail:
    '/preview-member-benefit-event-detail/:id/:isCreateEvent',
  viewMemberBenefitSpending: '/view-member-benefit',
  addMemberBenefit: '/create-member-benefit',
  createSurvey: '/create-survey',
  surveyDashboard: '/survey-dashboard',
  createGuestPassEvent: '/create-guest-pass-event',
  editGuestPassEvent: '/edit-guest-pass-event/:id',
  previewGuestPassEvent: '/preview-guest-pass-event/:id/:isCreateEvent',
  createGuestPass: '/create-guest-pass',
  viewGuestPassSpending: '/view-guest-pass',
  viewMemberSurveyHistory: '/member-feedback-histories',
  linkedMember: '/linked-member',
  headMember: '/head-member',
  processMember: '/process-member'
};
