import { Input, Select } from 'antd';
import { Club, SearchMember } from './type';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SearchProps {
  clubs: Club[];
  search: SearchMember;
  onSearch: (field: string, data: any) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const Search = (props: SearchProps) => {
  const { clubs, search, onSearch, onKeyPress } = props;
  const { t } = useTranslation();

  return (
    <div className='process-member__search-wrapper'>
      <div className='process-member__search-item'>
        <label>{t('SS.PREVIEW_EVENT.CLUB')}</label>
        <div className='process-member__search-select'>
          <Select
            onChange={(value) => onSearch('clubId', value)}
            value={search.clubId}
            options={[
              { value: '', label: 'All' },
              ...clubs.map((club) => ({
                value: club.id,
                label: club.name,
              })),
            ]}
          />
        </div>
      </div>
      <div className='process-member__search-item'>
        <label>{t('TS.NAME_OR_MEMBER_NUMBER')}</label>
        <Input
          value={search.searchKey}
          onChange={(e) => onSearch('searchKey', e.target.value)}
          defaultValue=''
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
};

export default Search;
