import React, { useEffect, useMemo, useState } from "react";

import TableData from "./TableData";

import Iframe from "utils/iframe";
import { PostMessageTableType } from "utils/constants";

import LinkedMember from "models/LinkedMember";

import {
  COLUMNS_LINK_MEMBER,
  COLUM_HEAD_MEMBER,
} from "constants/LinkedMembership";
import { getHeadMember } from "services/linkedMembership";

import { LinkedMemberStyled } from "./Styled";

const HeadMember = () => {
  const [isLoading, setLoading] = useState(false);
  const [listHeadMember, setListHeadMember] = useState<LinkedMember[]>([]);
  const memberId = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("memberId") || "";
  }, [window.location.search]);

  useEffect(() => {
    getDataHeadMember();
  }, []);

  const getDataHeadMember = async () => {
    setLoading(true);
    try {
      const data = await getHeadMember(memberId!);
      if (data) {
        setListHeadMember(listHeadMember.concat([data]));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      sendHeightPostMessageToMemberTable();
    }
  }, [isLoading]);

  const sendHeightPostMessageToMemberTable = () => {
    Iframe.sendHeightPostMessage(
      listHeadMember,
      "headMemberTable",
      PostMessageTableType.MEMBER_HEAD_MEMBER_TABLE
    );
  };

  return (
    <LinkedMemberStyled id="headMemberTable">
      <TableData
        columns={COLUM_HEAD_MEMBER}
        columnChild={COLUMNS_LINK_MEMBER}
        data={listHeadMember}
        isLoading={isLoading}
        adjustHeightIframe={sendHeightPostMessageToMemberTable}
      />
    </LinkedMemberStyled>
  );
};

export default HeadMember;
