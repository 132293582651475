import styled from "styled-components";

export const LinkedMemberStyled = styled.div`
  font-size: 12px;

  .list-member {

    &__table {
      .list-member__table-content{
        .ant-table-container {
          max-height: 84vh;
          overflow-y: auto;
        }
      }

      .ant-table-cell {
        color: var(--navi-color);
        text-align: left;
        padding-left: 10px;
      }

      .sub-table {
        .ant-table {
          width: 100%;
          margin: 10px auto !important;x
        }
        .ant-table-container {
          min-height: auto;
        }
      }

      .ant-table-container {
        &:after {
          box-shadow: none;
          width: 0;
        }
      }

      .empty {
        .ant-table-cell {
          text-align: center!important;
        }
      }
      
      .link {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
      }
      .action {
        cursor: pointer;
      }

      .text-status {
        &__ok {
          color: var(--success-color);
        }
        &__no {
          color: var(--danger-color);
        }
      }
    }
  }
`;
