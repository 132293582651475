import React from "react";

import { Col, Row, Tabs } from "antd";
import History from "models/History";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { HistoryStyled } from "./Styled";
import Todo from "models/Todo";
import ButtonStyled from "styled/ButtonStyled";

const { TabPane } = Tabs;

interface IHistoryItem {
  history: History;
  type?: string;
  onViewEmail: (todo: Todo) => void;
}

const HistoryItem = (props: IHistoryItem) => {
  const { t } = useTranslation();
  const { history, onViewEmail } = props;

  return (
    <HistoryStyled>
      <Row
        className={`history-item history-item--${history.getStatusByPoint()}`}
      >
        <Col span={3}>
          {!history.isSent() ? (
            <div className="history-item__point">
              <span className="history-item__point-text">{t("TS.POINTS")}</span>
              <span className="history-item__point-number">
                {history.getPoint()}
              </span>
            </div>
          ) : (
            <div className="history-item__send">
              <span className="history-item__send-text">{t("TS.SURVEY")}</span>
              <span className="history-item__send-label">{t("TS.SENT")}</span>
            </div>
          )}
        </Col>
        <Col span={10}>
          <div className="history-item__survey">
            <div className="history-item__survey-header">
              <span className="history-item__survey-name">
                {history.clubName}
              </span>
              <span className="history-item__survey-label">
                {history.surveyName}
              </span>
            </div>
            {!history.isSent() && (
              <div className="history-item__survey-description">
                <span>{history.feedbackContent}</span>
              </div>
            )}
          </div>
        </Col>
        <Col span={11}>
          <div className="history-item__info">
            <span className="history-item__date">
              {moment.utc(history.getFeedbackDate()).format("MMM DD")}
            </span>
            {!history.isSent() && history.hasTodo() && (
              <Tabs className="history-item__tab" type="card" size="large">
                {history.surveyToDos?.map((surveyToDo, index) => (
                  <TabPane
                    tab={surveyToDo.contactType}
                    key={surveyToDo.contactType + index.toString()}
                  >
                    {surveyToDo.contactType === "Email" ? (
                      <ButtonStyled
                        size="small"
                        onClick={() => onViewEmail(surveyToDo)}
                      >
                        {t("TS.VIEW.EMAIL")} ({surveyToDo.getTotalEmail()})
                      </ButtonStyled>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: surveyToDo.getComment(),
                        }}
                      />
                    )}
                  </TabPane>
                ))}
              </Tabs>
            )}
          </div>
        </Col>
      </Row>
      <div className="history__tree" />
    </HistoryStyled>
  );
};

export default HistoryItem;
