import { combineReducers } from "redux";
import clubReducer from "./Club";
import eventReducer from "./Event";
import searchFilterReducer from "./SearchFilter";
import gateReducer from "./Gate"

export default combineReducers({
  club: clubReducer,
  event: eventReducer,
  searchFilter: searchFilterReducer,
  gate: gateReducer
});
