import { ConfigGateRuleProps } from "./types";

export const INIT_CONFIG_GATE_RULE: ConfigGateRuleProps = {
  RuleName: null,
  ClubGroupId: null,
  Gates: [],
  RuleType: "AND",
  Conditions: [{ Criteria: null, Operator: null, Values: [] }],
};


