import { Spin } from "antd";
import styled from "styled-components/macro";

export const SpinWrapperStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--white-color);
`;

export const SpinStyled = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
