import { GateProps, GetMasterData } from "pages/ConfigGateRule/types";
import {
  GateMasterDataTypes,
  GET_CHOSEN_GATES,
  GET_CLUB_GROUP_MASTER_DATA,
  GET_CURRENT_CLUB,
  GET_MASTER_DATA,
  SET_CHOSEN_GATES,
  SET_CLUB_GROUP_MASTER_DATA,
  SET_CURRENT_CLUB,
  SET_MASTER_DATA,
} from "./types";

export const getClubGroupMasterDataAction = (): GateMasterDataTypes => ({
  type: GET_CLUB_GROUP_MASTER_DATA,
});
export const setClubGroupMasterDataAction = (
  data: ClubGroup[]
): GateMasterDataTypes => ({
  type: SET_CLUB_GROUP_MASTER_DATA,
  data,
});

export const getCurrentClubAction = (): GateMasterDataTypes => ({
  type: GET_CURRENT_CLUB,
});
export const setCurrentClubAction = (
  data: string
): GateMasterDataTypes => ({
  type: SET_CURRENT_CLUB,
  data,
});

export const getMasterDataAction = () : GateMasterDataTypes => ({
  type: GET_MASTER_DATA,
});

export const setMasterDataAction = (data: GetMasterData) : GateMasterDataTypes => ({
  type: SET_MASTER_DATA,
  data
});

export const getChosenGatesAction = () : GateMasterDataTypes => ({
  type: GET_CHOSEN_GATES,
});

export const setChosenGatesAction = (data: GateProps[]) : GateMasterDataTypes => ({
  type: SET_CHOSEN_GATES,
  data
})
