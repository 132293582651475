import styled from 'styled-components';

export const ProcessMemberDiv = styled.div`
  .process-member {
    &__action {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .ant-table-thead .ant-table-cell {
      vertical-align: middle;
    }
    .ant-table-fixed {
      table-layout: fixed;
    }
    .ant-table-column-title, .ant-table-cell {
      text-transform: none;
    }
    th.ant-table-cell {
      font-weight: bold; 
    }
    .ant-input, .ant-select  {
      font-size: 12px;
    }
    .text-status__ok {
      color: var(--success-color);
    }

    &__ellipsis {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__column {
      &-name { 
        width: 60px;
        max-width: 65px;
      }

      &-email {
        width: 80px;
        max-width: 90px;
      }
        
      &-club {
        max-width: 80px;
      }

      &-plan {
        max-width: 80px;
      }

      &-mm-number {
        min-width: 115px;
        display: block;
      }
    }

    &__search {
      &-clear {
        background-color: var(--gray-color);
        border: var(--gray-color);
        color: var(--gray3-color);
      }
      &-select {
        width: 100%;
        .ant-select {
          width: 150px;
        }
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 70%;
        margin: 0 auto;
      }

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;

        label {
          width: 50%;
          text-align: right;
        }
      }
      &-button {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: end;
        gap: 15px;
      }
    }
    &__table {
      margin-top: 25px;
    }
  }
`;