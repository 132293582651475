import { Input, Row, Select } from "antd";
import styled from "styled-components/macro";

export const BorderTitleStyled = styled.p`
  font-size: 13px;
  color: var(--white-color);
  font-weight: bold;
  text-align: center;
  background-color: var(--gray-color);
  padding: 3px;
  border-radius: 5px;
  width: 100%;
`;

export const SubTitleStyled = styled.p<{ $padding?: number; $margin?: number }>`
  font-size: 13px;
  color: var(--gray-color);
  font-weight: bold;
  margin: 5px 5px 5px 0;
  padding-left: 0;
`;

export const DelayContainer = styled.div`
  margin-bottom: 30px;
  margin-left: 24px;

  .delay-input {
    width: 190px;
  }

  .after-start-date,
  label {
    font-size: 12px;
    color: rgb(112, 112, 112);
  }

  .after-start-date {
    margin-left: 2rem;
    margin-top: 5px;
  }

  .delay-select {
    width: "100%";
    margin-left: 5px;
  }

  .delay-input {
    width: "100%";
  }
`;

export const MeetCriteriaContainer = styled.div`
  display: flex;
  align-items: baseline;
  .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error.select {
    margin-bottom: -35px;
    margin-right: 0;
    width: 100px;
  }
  .option-container {
    .ant-form-item-explain-error,
    .ant-form-item-explain-success {
      width: 100px;
      height: 70px;
    }
  }
`;

export const ExactContainer = styled.div`
  display: flex;
  .ant-form-item-control-input-content {
    .ant-input {
      width: 115px;
    }
  }
  align-items: baseline;
  .ant-form-item-explain-error,
  .ant-form-item-explain-success {
    width: 115px;
  }
`;

export const SelectDelayTime = styled(Select)`
  &.ant-select.ant-select-show-arrow {
    width: 90px;
    margin-left: 0px;
    margin-right: 5px;
    height: 30px;
  }
`;
export const DayInputDelay = styled(Input)`
  &.ant-input {
    width: 200px;
    margin-left: 0;
    margin-right: 5px;
  }
`;
