import Column from 'models/utils/Column';

const SORT_DIRECTIONS = ['ascend', 'descend', 'ascend'];

export const COLUMNS: Column[] = [
  {
    title: 'SS.PREVIEW_EVENT.FIRST_NAME',
    dataIndex: 'FirstName',
    width: '80px',
    sorter: true,
    sortDirections: SORT_DIRECTIONS,
  },
  {
    title: 'SS.PREVIEW_EVENT.LAST_NAME',
    dataIndex: 'LastName',
    width: '80px',
    sorter: true,
    sortDirections: SORT_DIRECTIONS,
  },
  {
    title: 'TS.MEMBER_NUMBER',
    dataIndex: 'MembershipNumber',
    width: '115px',
    sorter: true,
    sortDirections: SORT_DIRECTIONS,
  },
  {
    title: 'TS.EMAIL',
    dataIndex: 'Email',
    sorter: true,
    sortDirections: SORT_DIRECTIONS,
  },
  {
    title: 'SS.TRANSACTION.HOME_CLUB',
    dataIndex: 'Club',
  },
  {
    title: 'TS.PLAN',
    dataIndex: 'Plan',
  },
  {
    title: 'SS.CREATE_EVENT.START_DATE',
    dataIndex: 'StartDate',
    sorter: true,
    sortDirections: SORT_DIRECTIONS,
  },
  {
    title: 'SS.PREVIEW_EVENT.STATUS',
    dataIndex: 'Status',
    sorter: true,
    sortDirections: SORT_DIRECTIONS,
  },
  {
    title: 'TS.ACTION',
    dataIndex: 'convert',
    width: '95px',
    fixed: 'right',
  }
];

export default { COLUMNS };