import styled from "styled-components";

export const HistoryStyled = styled.div`
  width: 100%;
  display: flex;

  &:last-child {
    .history-item {
      border-bottom: 0;
    }
  }

  .history-item--positive .history-item__point-text:before {
    background-color: #32b770;
  }

  .history-item--negative .history-item__point-text:before {
    background-color: #ef6161;
  }

  .history-item--neutral .history-item__point-text:before {
    background-color: #8b8b8b;
  }

  .history-item__point-text {
    :before {
      width: 0.5rem;
      height: 0.5rem;
      content: "";
      display: block;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .history-item {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    margin-right: 2rem;
    position: relative;
    width: 100%;
    padding-bottom: 1rem;
  }

  .history-item__point,
  .history-item__send {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .history-item__point-text,
  .history-item__send-text {
    align-items: center;
    color: #8b8b8b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
  }

  .history-item__survey {
    line-height: 1.375rem;
    padding-right: 2rem;
  }

  .history-item__survey-header {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1.4375rem;
  }

  .history-item__survey-name {
    font-weight: 700;
    font-size: 12px;
  }

  .history-item__survey-label {
    color: #747474;
    font-style: italic;
  }

  .history-item__info {
    text-align: right;
  }

  .history__tree {
    border-right: 1px dashed #d9d9d9;
    left: -6px;
    position: relative;
    top: 10px;
  }

  .history-item:before {
    background: #32b770 0 0 no-repeat padding-box;
    border-radius: 0.625rem;
    box-shadow: 0 0 0 0.3125rem #c9f6e3;
    content: "";
    display: block;
    height: 1rem;
    position: absolute;
    right: -35px;
    top: 0.1rem;
    width: 1rem;
    z-index: 10;
  }

  .history-item__date {
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
  }

  .history-item__send-label {
    color: black;
    font-weight: 700;
    text-transform: uppercase;
  }

  .history-item__point-number {
    font-size: 20px;
    font-weight: 700;
    line-height: 16px;
    padding-left: 16px;
  }

  .history-item__survey-description {
    font-size: 12px;
  }

  .history-item .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #fff;
    background-color: white;
    border: 1px dashed #d3d3d3;
    border-bottom: 0;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: var #e7e7e7;
    border: 0;
    border-radius: 0;
    color: #818181;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }

  .ant-tabs-tab-btn,
  .ant-tabs-tabpane {
    font-size: 12px;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: white;
    border: 1px dashed #d3d3d3;
    border-bottom: 0;
    .ant-tabs-tab-btn {
      color: black;
      font-weight: bold;
    }

    :first-child {
      border-top-left-radius: rem(5);
    }

    :last-right {
      border-top-right-radius: rem(5);
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    justify-content: center;
    text-align: center;
    width: 33.3333333333%;
  }

  .history-item__tab {
    margin-top: 1rem;
  }

  .history-item__tab .ant-tabs-content {
    border: 1px dashed #d3d3d3;
    border-bottom-left-radius: 0.3125rem;
    border-top: 1px solid #d3d3d3;
    border-bottom-right-radius: 0.3125rem;
    border-top-style: solid;
    padding: 1rem;
    text-align: left;
  }
  .ant-btn {
    background: white;
    border-color: var(--gray-color);
    color: var(--gray-color);
    text-transform: initial;
  }
`;
