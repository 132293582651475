import React from "react";

import Pagination from "components/Pagination";

interface FooterProps {
  paging?: {
    color?: string;
    pageIndex: number;
    totalPages: number;
    pageSize: number;
    totalItemsCount: number;
    setPageIndex: (pageIndex: number) => void;
    setPageSize: (pageSize: number) => void;
  };
}

const Footer = (props: FooterProps) => {
  const { paging } = props;

  if (!paging) {
    return null;
  }
  const {
    color,
    pageIndex,
    totalPages,
    pageSize,
    totalItemsCount,
    setPageIndex,
    setPageSize,
  } = paging;

  return (
    <Pagination
      pageIndex={pageIndex}
      totalPages={totalPages}
      pageSize={pageSize}
      color={color}
      totalItemsCount={totalItemsCount}
      setPage={setPageIndex}
      changePageSize={setPageSize}
    />
  );
};

export default Footer;
