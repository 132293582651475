import { Button } from "antd";
import styled, { SimpleInterpolation } from "styled-components";

export const EventStatusTextStyled = styled.span<{ $isCancel?: string }>`
  ${(props): SimpleInterpolation => {
    switch (props.$isCancel) {
      case "Cancelled":
      case "Pending":
      case "Express":
      case "Banned":
      case "Deceased":
      case "Frozen":
      case "Grace":
        return "color: var(--danger-color)";
      case "Suspended":
        return "color: var(--warning-color)";
      default:
        return "color: var(--success-color)";
    }
  }};
`;

export const RowTableFontStyled = styled.h3`
  font-size: 12px;
  margin: 0;
`;

export const TitleStyled = styled.span<{ $width?: string }>`
  ${(props): SimpleInterpolation =>
    props.$width ? `width: ${props.$width}; ` : ``}
  font-size: 14px;
  font-weight: 600;
`;

export const DateRangeStyled = styled.p`
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  background: gray;
  padding: 4px 16px;
  color: var(--white-color);
  border-radius: 10px;
  opacity: 0.5;
  margin: 0;
`;

export const TextStyled = styled.p<{ $rightAlign?: boolean }>`
  color: var(--gray-color);
  font-size: 12px;
  ${(props): SimpleInterpolation =>
    props.$rightAlign ? "text-align: right;" : "text-align: left;"};

  .delay-text {
    display: inline;
  }
  .delay-text b {
    color: black;
  }
`;

export const PaymentTextStyled = styled.span<{ $payment?: string }>`
  ${(props): SimpleInterpolation =>
    props.$payment === "No Arrears" || props.$payment === "OK"
      ? "color: var(--success-color)"
      : "color: var(--danger-color)"};
`;
export const DownLineStyled = styled.br``;
export const InformationContainerStyled = styled.div`
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
`;
export const MemberListStyled = styled.div`
  background-color: var(--white-color);
  margin-top: 30px;
`;

export const FooterPaginationStyled = styled.div`
  margin: 0 -10px -10px -10px;
`;

export const ContentCollumStyled = styled.span`
  color: var(--black-color);
`;
export const TitleCollumStyled = styled.span`
  color: var(--black-color);
`;
export const SpaceBetweenContainerStyled = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 10px 0;
`;

export const StatusContainerStyled = styled.div`
  display: inline-block;
  width: 130px;
`;
export const StatusTitleContainer = styled.div`
  width: 50px;
  text-align: right;
  display: inline-block;
`;
export const ToolTipTitleStyled = styled.span`
  font-size: 11px;
  color: var(--black-color);
`;

export const CustomButtonStyled = styled(Button)`
  font-size: 14px;
  border: none;
  height: 20px;
  color: var(--primary-color);
  background: var(--white-color);
  box-shadow: none;
  padding: 0px;
  display: inline;
  text-decoration: none;
  &:focus {
    color: var(--primary-color);
  }
  &:hover {
    background: var(--white-color);
    box-shadow: none;
  }
`;

export const TextRightStyled = styled.div`
  text-align: right;
`;

export const TextWrapStyled = styled.div`
  word-break: break-word;
  max-width: 405px;
  min-width: 150px;
`;

export const ApplicableTitleStyled = styled.b`
  display: block;
  margin-bottom: 7px;
`;
