import { Action } from "redux";

export const GET_CLUB_LIST = "GET_CLUB_LIST";
export const SET_CLUB_LIST = "SET_CLUB_LIST";

export interface GetClubListAction extends Action<typeof GET_CLUB_LIST> {
  operatorId: string;
}

interface SetClubListAction extends Action<typeof SET_CLUB_LIST> {
  data: ClubProps[];
}

export type ClubTypes = GetClubListAction | SetClubListAction;

export interface ClubListAction {
  data: ClubProps[];
  type: string;
}
