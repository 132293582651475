import styled from "styled-components";

export const ViewMemberSurveyHistoryContainer = styled.div`
  .ant-picker-input > input {
    font-size: 12px;
  }
  .custom-range-date-picker__wrapper {
    display: flex;
    justify-content: right;
  }

  .custom-range-date-picker__input {
    justify-content: center;
    border: 1px solid #c7c7c7;
    border-radius: 1.25rem;
    padding-left: 0.9375rem;
    margin-bottom: 1rem;
  }

  .ant-picker-range-separator {
    font-size: 12px;
    justify-content: center;
    padding: 0;
    text-align: center;
    width: 20px;
  }
  .ant-picker-input {
    width: auto;
  }

  .ant-picker-input > input {
    width: 65px;
  }

  .custom-range-date-picker__label {
    color: black;
    font-weight: 400;
  }

  .ant-picker {
    border: 0;
    background: transparent;
  }

  .ant-picker-input > input:hover {
    border-color: 0;
  }

  .ant-picker-focused {
    box-shadow: none;
  }
`;
