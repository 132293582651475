import Column from "models/utils/Column";

export const COLUMNS: Column[] = [
  {
    title: "TS.MANAGE_GATE.CLUB",
    dataIndex: "clubName",
  },
  {
    title: "TS.MEMBERSHIP.NUMBER",
    dataIndex: "membershipNumber",
    width: "120px",
  },
  {
    title: "SS.PREVIEW_EVENT.FIRST_NAME",
    dataIndex: "firstName",
  },
  {
    title: "SS.PREVIEW_EVENT.LAST_NAME",
    dataIndex: "lastName",
  },
  {
    title: "TS.MANAGE_GATE.STATUS",
    dataIndex: "memberStatus",
  },
  {
    title: "TS.PAYMENT.STATUS",
    dataIndex: "paymentStatus",
  },
  {
    title: "TS.LINK.TYPE",
    dataIndex: "linkType",
  },
  {
    title: "TS.PAYMENT.LINK",
    dataIndex: "paymentLink",
  },
  {
    title: "",
    dataIndex: "Clone",
  },
  {
    title: "",
    dataIndex: "Edit",
  },
  {
    title: "",
    dataIndex: "Remove",
  },
];

export const COLUM_HEAD_MEMBER = COLUMNS.filter(
  (column) =>
    column.dataIndex !== "Clone" &&
    column.dataIndex !== "Edit" &&
    column.dataIndex !== "Remove"
);

export const COLUMNS_LINK_MEMBER: Column[] = [
  {
    title: "SS.MEMBER_CREDIT_SPENDING.REDEEM_DATE",
    dataIndex: "actionDate",
  },
  {
    title: "TS.FEE.STATUS",
    dataIndex: "feeStatus",
  },
  {
    title: "TS.FEE.DESCRIPTION",
    dataIndex: "feeDescription",
  },
  {
    title: "TS.AMOUNT",
    dataIndex: "amount",
    width: "30px",
    align: "right",
  },
];

export default { COLUMNS };
