import { Table } from "antd";
import styled, { SimpleInterpolation } from "styled-components/macro";

const TableStyled = styled(Table)<{ $customStyle?: any }>`
  .ant-pagination {
    ant-pagination-options {
      font-size: 14px;
    }
    > li {
      .ant-select {
        .ant-select-selector {
          font-size: 12px;
        }
      }
    }
  }
  .ant-table {
    .ant-table {
      width: 615px;
    }
    font-size: 11px;
    border: 1px solid var(--white1-color);
    ${(props): SimpleInterpolation =>
      props.$customStyle && props.$customStyle.isSubTable
        ? `margin: -8px -8px -8px 24px !important;`
        : ``}
    .ant-table-tbody .ant-table-cell {
      a > span {
        display: inline-block;
        vertical-align: middle;
      }
      a,
      a > span {
        text-decoration: underline;
      }
      a {
        &:hover,
        &:focus,
        &:active {
          & > span {
            text-decoration: none;
          }
        }
      }
    }
  }
  .ant-table-thead {
    > tr > th {
      padding: 10px 4px;
      color: var(--gray-color);
      background-color: var(--white1-color);
      text-transform: uppercase;
      ${(props): SimpleInterpolation =>
        props.$customStyle && props.$customStyle.noUpperCase
          ? `text-transform: none;`
          : `text-transform: uppercase;`}
      &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        height: 2.4em;
      }
    }
    th.ant-table-column-has-sorters::before {
      background-color: rgba(0, 0, 0, 0.06) !important;
    }
    .ant-table-cell {
      .ant-table-column-sorters {
        padding: 0 10px 0 0;
      }
      vertical-align: top;
      color: var(--black-color);
      ${(props): SimpleInterpolation =>
        props.$customStyle && props.$customStyle.boldTitle
          ? `font-weight: bold;`
          : ``}
      ${(props): SimpleInterpolation =>
        props.$customStyle && props.$customStyle.centerTitle
          ? `text-align: center;`
          : ``}
      .ant-table-column-sorter {
        right: 2px;
      }
    }
  }

  .ant-table-tbody {
    > tr > td {
      min-height: 36px;
      padding: 8px 8px;
      ${(props): SimpleInterpolation =>
        props.$customStyle && props.$customStyle.centerCellTitle
          ? `text-align: center;`
          : ``}
      color: var(--black-color);
      border-bottom-color: var(--white1-color);
    }
  }
  .ant-table-footer {
    padding: 8px 15px;
    background-color: transparent;
    color: inherit;
    &:empty {
      display: none;
    }
  }
  .ant-empty-description {
    font-size: 11px;
  }
  .ant-empty {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ant-table-empty .ant-table-content {
    &,
    & table {
      height: 100%;
    }
    .ant-table-cell {
      border-bottom: none;
    }
  }
  .ant-table-container {
    ${(props): SimpleInterpolation =>
      props.$customStyle && props.$customStyle.height
        ? `max-height: ${props.$customStyle.height}vh;`
        : `max-height: 47vh;`}
    min-height: 10vh;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: var(--white2-color);
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray6-color);
      border-radius: 4px;
    }
  }
  .ant-table-column {
    &-has-sorters {
      position: relative;
    }
    &-sorters {
      padding: 0 5px 0 0;
    }
    &-sorter {
      position: absolute;
      right: 0;
      color: var(--gray-color);
      &-up,
      &-down {
        font-size: 10px;
        &.active {
          color: var(--primary-color);
        }
      }
      &-down {
        margin-top: -4px;
      }
    }
  }
`;

export default TableStyled;
