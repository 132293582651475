import { EditOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import GroupButtons from "components/GroupButtons";
import HeadingGroup from "components/HeadingGroup";
import Icon from "components/Icon";
import Table from "components/Table";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteMemberBenefitEvent,
  getMemberBenefitDownloadFile,
  getMemberBenefitEvent,
  getMemberBenefitEventDetail,
} from "services/memberBenefitEvent";
import { updateStatusEvent } from "services/transaction";
import ButtonStyled, {
  BlankButtonStyled,
  SwitchStyled,
} from "styled/ButtonStyled";
import {
  EventStatusTextStyled,
  ContentCollumStyled,
  DateRangeStyled,
  DownLineStyled,
  InformationContainerStyled,
  MemberListStyled,
  PaymentTextStyled,
  SpaceBetweenContainerStyled,
  StatusContainerStyled,
  StatusTitleContainer,
  TextRightStyled,
  TextStyled,
  TitleStyled,
  ToolTipTitleStyled,
} from "styled/EventStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import Swal from "sweetalert2";
import {
  DATE_FORMAT,
  DAY_VALUE,
  EVENT_LIST_PAGING,
  MONTH_VALUE,
  NotificationType,
} from "utils/constants";
import { showNotification } from "utils/helpers";
import { routeUrls } from "utils/routes";
import ApplicableSaleItem from "./ApplicableSaleItem";
import ChangeEndDate from "./ChangeEndDate";

export const renderDuration = (quantity: number, durationType: string) => {
  switch (durationType) {
    case DAY_VALUE:
      return `${quantity} day(s)`;
    case MONTH_VALUE:
      return `${quantity} month(s)`;
    default:
      return "";
  }
};

const MemberBenefitEventDetail = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { id, isCreateEvent } = useParams<{
    id: string;
    isCreateEvent: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [pagingIndex, setPageIndex] = useState(0);
  const [active, setActive] = useState<boolean>(false);
  const [data, setData] = useState<EventDataProps[]>([]);
  const [information, setInformation] = useState<string[]>([]);
  const [pagingData, setPagingData] = useState(EVENT_LIST_PAGING);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const [isAllowChangeStatus, setIsAllowChangeStatus] = useState(false);
  const [isAllowEditEvent, setIsAllowEditEvent] = useState(false);
  const [isAllowDeleteEvent, setIsAllowDeleteEvent] = useState(false);
  const [isChangeEndDate, setChangeEndDate] = useState(false);
  const [actionExtends, setActionExtends] = useState<ActionExtendProps[]>([]);
  const [hasActionNotExtend, setHasActionNotExtend] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");

  const getData = useCallback(async () => {
    setLoading(true);
    if (!information.length) {
      setLoadingPage(true);
    }
    try {
      const result: any = await getMemberBenefitEventDetail(id!, {
        pageIndex: pagingIndex,
        pageSize,
      });
      setLoading(false);
      setData(result.data);
      setCurrentStatus(result.status);
      setPagingData({ ...result.paging, pageIndex: pagingIndex });
      if (!information.length) {
        updateInformation(result);
      }
    } catch {
      setLoading(false);
      setLoadingPage(false);
    }
  }, [pagingIndex, pageSize]);

  const updateInformation = (info: any) => {
    setIsAllowChangeStatus(info.isAllowChangeStatus);
    setIsAllowEditEvent(info.isAllowEdit);
    setIsAllowDeleteEvent(info.isAllowDelete);
    setActive(info.status === "AC" ? true : false);
    setInformation([
      info.eventName,
      info.dateRange,
      info.totalMatchMemberText,
      JSON.stringify(info.criteriaText),
      JSON.stringify(info.actionText),
      info.status,
      info.delayText,
    ]);
    setLoadingPage(false);
  };

  const goBack = () => {
    let backToURL = routeUrls.manageEvents;

    if (JSON.parse(isCreateEvent!)) {
      backToURL = routeUrls.editMemberBenefitEvent.replace(":id", id!);
    }
    history(backToURL);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const getActionRule = useCallback(async () => {
    try {
      const res = await getMemberBenefitEvent(id!);
      const _actionExtends = res.actions.filter(
        (action: any) => action.actionCode === "EB"
      );
      setActionExtends(_actionExtends);
      setHasActionNotExtend(res.actions.length !== _actionExtends.length);
    } catch {}
  }, [id]);

  useEffect(() => {
    getActionRule();
  }, [getActionRule]);

  const dateRange = useMemo(() => {
    let dateArray: any = [];
    if (information.length) {
      dateArray = information[1].split(" - ");
    }
    return {
      startDate: dateArray[0],
      endDate: dateArray[1],
    };
  }, [information]);

  const minDate = useMemo(() => {
    const startDate = moment(dateRange.startDate, DATE_FORMAT);
    const currentDate = moment(new Date(), DATE_FORMAT);
    return startDate.isBefore(currentDate) ? currentDate : startDate;
  }, [dateRange.startDate, dateRange.endDate]);

  const allowToChangeEndDate = useMemo(() => {
    return (
      !isAllowEditEvent &&
      currentStatus !== "EX" &&
      moment(dateRange.endDate, DATE_FORMAT).isSameOrAfter(
        moment(new Date()),
        "day"
      )
    );
  }, [dateRange.endDate, isAllowEditEvent]);

  const columns = useMemo(
    () => [
      {
        title: t("SS.PREVIEW_EVENT.CLUB"),
        dataIndex: "clubName",
        render: (clubName: string) => (
          <ContentCollumStyled>{clubName}</ContentCollumStyled>
        ),
      },
      {
        title: t("SS.PREVIEW_EVENT.MEMBER_NO"),
        dataIndex: "membershipNo",
        render: (membershipNo: string) => (
          <ContentCollumStyled>{membershipNo}</ContentCollumStyled>
        ),
      },
      {
        title: t("SS.PREVIEW_EVENT.FIRST_NAME"),
        dataIndex: "firstName",
        render: (firstName: string) => (
          <ContentCollumStyled>{firstName}</ContentCollumStyled>
        ),
      },
      {
        title: t("SS.PREVIEW_EVENT.LAST_NAME"),
        dataIndex: "lastName",
        render: (lastName: string) => (
          <ContentCollumStyled>{lastName}</ContentCollumStyled>
        ),
      },
      {
        title: t("SS.PREVIEW_EVENT.DATE_OF_BIRTH"),
        dataIndex: "dateOfBirth",
        render: (dateOfBirth: string) => (
          <ContentCollumStyled>
            {moment(dateOfBirth).format(DATE_FORMAT)}
          </ContentCollumStyled>
        ),
      },
      {
        title: t("SS.PREVIEW_EVENT.STATUS"),
        dataIndex: "status",
        render: (status: string) => (
          <EventStatusTextStyled $isCancel={status}>
            {status}
          </EventStatusTextStyled>
        ),
      },
      {
        title: t("SS.PREVIEW_EVENT.PAYMENT_STATUS"),
        dataIndex: "paymentStatus",
        render: (paymentStatus: string) => (
          <PaymentTextStyled $payment={paymentStatus}>
            {paymentStatus}
          </PaymentTextStyled>
        ),
      },
    ],
    [t]
  );

  const onSubmit = async () => {
    const res = await updateStatusEvent("AC", Number(id));
    if (res.isSuccess) {
      showNotification(
        t("TS.SUCCESS"),
        t("TS.ACTIVE_EVENT_SUCCESSFULLY_MESSAGE"),
        NotificationType.success
      );
      setTimeout(() => {
        window.parent.location.href =
          _env.REACT_APP_EMBEDDED_BASE_URL + "/Events/EventManagement.aspx" ||
          "";
      }, 1000);
    }
  };

  const editEventAction = () => {
    history(routeUrls.editMemberBenefitEvent.replace(":id", id!));
  };

  const deleteEventAction = () => {
    Swal.fire({
      title: t("TS.MEMBER_BENEFIT_EVENT.DELETE_TITLE"),
      html: t("TS.MEMBER_BENEFIT_EVENT.DELETE_CONFIRM"),
      showCancelButton: true,
      icon: "question",
      confirmButtonText: t("SS.YES"),
      cancelButtonText: t("SS.NO"),
      reverseButtons: true,
      width: 300,
      customClass: {
        title: "swal2-title-custom",
        confirmButton: "swal2-confirm-custom",
        cancelButton: "swal2-cancel-custom",
        icon: "swal2-warning-custom",
        htmlContainer: "swal2-text-custom",
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await deleteMemberBenefitEvent(id!)
          .then((res) => {
            if (res.data.isSuccess) {
              showNotification(
                t("TS.SUCCESS"),
                t("TS.MEMBER_BENEFIT_EVENT.DELETE_EVENT_SUCCESSFULLY_MESSAGE"),
                NotificationType.success
              );
            } else {
              showNotification(
                t("TS.ERROR"),
                t("TS.MEMBER_BENEFIT_EVENT.DELETE_EVENT_FAILED_MESSAGE"),
                NotificationType.error
              );
            }
          })
          .catch(() => {
            showNotification(
              t("TS.ERROR"),
              t("TS.MEMBER_BENEFIT_EVENT.DELETE_EVENT_FAILED_MESSAGE"),
              NotificationType.error
            );
          })
          .finally(() => {
            history(routeUrls.manageEvents);
          });
      }
    });
  };

  const status = useMemo(() => {
    return t(active ? "SS.ACTIVE" : "SS.INACTIVE");
  }, [active]);

  const changeEndDate = () => {
    setChangeEndDate(true);
  };

  const showConfirmChangeStatus = (active: boolean) => {
    Swal.fire({
      title: t("TS.MEMBER_BENEFIT_EVENT.CONFIRM_CHANGE_STATUS"),
      html: t("SS.PREVIEW_EVENT.CONFIRM_CHANGE_STATUS_MESSAGE"),
      showCancelButton: true,
      icon: "question",
      confirmButtonText: t("SS.OK"),
      reverseButtons: true,
      width: 300,
      customClass: {
        title: "swal2-title-custom",
        confirmButton: "swal2-confirm-custom",
        cancelButton: "swal2-cancel-custom",
        icon: "swal2-warning-custom",
        htmlContainer: "swal2-text-custom",
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setActive(active);
        const res = await updateStatusEvent(active ? "AC" : "IN", Number(id));
        if (res.isSuccess) {
          showNotification(
            t("TS.SUCCESS"),
            t(
              "TS.MEMBER_BENEFIT_EVENT.UPDATE_STATUS_EVENT_SUCCESSFULLY_MESSAGE"
            ),
            NotificationType.success
          );
        }
      }
    });
  };
  const hoverEditContent = () => {
    return (
      <ToolTipTitleStyled>
        {t("TS.MEMBER_BENEFIT_EVENT.EDIT_EVENT_TITLE")}
      </ToolTipTitleStyled>
    );
  };

  const hoverDeleteContent = () => {
    return (
      <ToolTipTitleStyled>
        {t("TS.MEMBER_BENEFIT_EVENT.DELETE_EVENT_TITLE")}
      </ToolTipTitleStyled>
    );
  };

  const callbackAfterUpdate = (isOpen: boolean, isChanged: boolean) => {
    setChangeEndDate(isOpen);
    if (isChanged) {
      getData();
    }
  };

  const handleDownloadFile = async () => {
    const res = await getMemberBenefitDownloadFile(id!);
    const type = res.headers["content-type"];
    const blob = new Blob([res.data], { type: type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "MemberList.csv";
    link.click();
  };

  if (loadingPage) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }
  return (
    <>
      <SpaceBetweenContainerStyled>
        <HeadingGroup
          title={t(
            isCreateEvent === "false"
              ? "SS.PREVIEW_EVENT.DETAIL_EVENT"
              : "SS.PREVIEW_EVENT.PREVIEW_EVENT"
          )}
        />
      </SpaceBetweenContainerStyled>
      <SpaceBetweenContainerStyled>
        <TitleStyled $width={"75%"}>
          {information[0]}
          {isCreateEvent === "false" && isAllowEditEvent ? (
            <Tooltip
              title={hoverEditContent}
              color={"white"}
              placement="topRight"
            >
              <BlankButtonStyled onClick={editEventAction}>
                <EditOutlined style={{ marginLeft: 5 }} />
              </BlankButtonStyled>
            </Tooltip>
          ) : null}
          {isCreateEvent === "false" && isAllowDeleteEvent ? (
            <Tooltip
              title={hoverDeleteContent}
              color={"white"}
              placement="topLeft"
            >
              <BlankButtonStyled
                onClick={deleteEventAction}
                $color={"danger"}
                $marginLeft={10}
              >
                <Icon icon="icon-delete" />
              </BlankButtonStyled>
            </Tooltip>
          ) : null}
        </TitleStyled>
        {isCreateEvent === "false" ? (
          <StatusContainerStyled>
            <TitleStyled>{t("SS.STATUS")}</TitleStyled>
            <SwitchStyled
              size="small"
              checked={active}
              onChange={showConfirmChangeStatus}
              disabled={!isAllowChangeStatus}
            />
            <StatusTitleContainer>
              {information[5] === "EX" ? (
                <TitleStyled>{t("SS.EXPIRED")}</TitleStyled>
              ) : (
                <TitleStyled>{status}</TitleStyled>
              )}
            </StatusTitleContainer>
          </StatusContainerStyled>
        ) : null}
      </SpaceBetweenContainerStyled>
      <DateRangeStyled>{information[1]}</DateRangeStyled>
      {allowToChangeEndDate ? (
        <Tooltip
          title={() => (
            <ToolTipTitleStyled>
              {t("TS.MEMBER_BENEFIT_EVENT.CHANGE_END_DATE.TITLE")}
            </ToolTipTitleStyled>
          )}
          color={"white"}
          placement="topRight"
        >
          <BlankButtonStyled onClick={changeEndDate}>
            <EditOutlined />
          </BlankButtonStyled>
        </Tooltip>
      ) : null}

      <DownLineStyled />
      <DownLineStyled />
      <InformationContainerStyled>
        <TextStyled
          dangerouslySetInnerHTML={{ __html: information[2] }}
        ></TextStyled>
        {information[3] &&
          JSON.parse(information[3]).map((text: any, key: number) => {
            return <TextStyled key={key}>- {text.rule}</TextStyled>;
          })}
        {information[6] && (
          <TextStyled>
            <div
              className="delay-text"
              dangerouslySetInnerHTML={{ __html: information[6] }}
            ></div>
          </TextStyled>
        )}
        <TextStyled>{t("SS.PREVIEW_EVENT.THEY_WILL_RECEIVE")}</TextStyled>
        {information[4] &&
          JSON.parse(information[4]).map((text: any, key: number) => {
            return <TextStyled key={key}>- {text.action}</TextStyled>;
          })}
        {actionExtends.map((item) => (
          <TextStyled key={item.itemId}>
            {t("TS.MEMBER_BENEFIT_EVENT.EXTEND_EXPIRE_DATE", {
              key: renderDuration(item.duration, item.durationType),
            })}
          </TextStyled>
        ))}
        {hasActionNotExtend ? (
          <Row>
            <Col span={22}>
              <ApplicableSaleItem eventId={id!} />
            </Col>
          </Row>
        ) : null}
      </InformationContainerStyled>
      <MemberListStyled>
        <TextRightStyled>
          <ButtonStyled type="link" onClick={handleDownloadFile}>
            <Icon icon="icon-download-ico" />
            <span>{t("TS.EXPORT_MEMBER_LIST_TO_CSV")}</span>
          </ButtonStyled>
        </TextRightStyled>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="membershipNo"
          loading={loading}
          customStyle={{
            noUpperCase: true,
            boldTitle: true,
          }}
          paging={{
            ...pagingData,
            pageSize,
            setPageIndex: setPageIndex,
            setPageSize: setPageSize,
          }}
        />
      </MemberListStyled>
      <DownLineStyled />
      <GroupButtons position="center">
        <ButtonStyled onClick={goBack}>{t("BACK")}</ButtonStyled>
        {isCreateEvent === "true" ? (
          <ButtonStyled type="primary" onClick={onSubmit}>
            {t(`CREATE`)}
          </ButtonStyled>
        ) : null}
      </GroupButtons>
      {isChangeEndDate ? (
        <ChangeEndDate
          isOpen={isChangeEndDate}
          callbackAfterUpdate={callbackAfterUpdate}
          eventId={id!}
          minDate={minDate}
          endDate={dateRange.endDate}
        />
      ) : null}
    </>
  );
};

export default MemberBenefitEventDetail;
