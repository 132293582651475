import styled from "styled-components/macro";

export const HiddenTooltipStyled = styled.div`
  font-size: 11px;
  > span {
    font-style: italic;
  }
`;

export default HiddenTooltipStyled;
