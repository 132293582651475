import { http } from './common';
import { EDIT_DELETE_LINKED_MEMBERSHIP, GET_ALL_ROLE, GET_LINKED_TYPES, HEAD_MEMBER, LINK_MEMBER } from './common/apis';
import LinkedMember from '../models/LinkedMember';
import LinkedType from 'models/LinkedType';

export const getLinkedMember = async (
  memberId: string
): Promise<LinkedMember[]> => {
  const response = await http.get(LINK_MEMBER.replace('{memberId}', memberId));
  return response.data;
};

export const getHeadMember = async (
  memberId: string
): Promise<LinkedMember> => {
  const response = await http.get(HEAD_MEMBER.replace('{memberId}', memberId));
  return response.data;
};

export const getLinkTypes = async (): Promise<LinkedType[]> => {
  const response = await http.get(GET_LINKED_TYPES);
  if (response.data && response.data.linkTypeMasterData) {
    return response.data.linkTypeMasterData;
  }
  return response.data;
};

export const editLinkedMembership = async (data: any, parentId: string, linkedMemberId: string): Promise<any> => {
  const response = await http.put(EDIT_DELETE_LINKED_MEMBERSHIP
    .replace('{parentId}', parentId).replace('{linkedMemberId}', linkedMemberId), data);
  return response.data;
};

export const deleteLinkedMembership = async (parentId: string, linkedMemberId: string, operatorId: string): Promise<any> => {
  const response = await http.delete(EDIT_DELETE_LINKED_MEMBERSHIP
    .replace('{parentId}', parentId).replace('{linkedMemberId}', linkedMemberId), { data: { OperatorId: operatorId } });
  return response.data;
};



export const getAllRole = async (operatorId: string): Promise<{
  id: number;
  name: string;
  clubs: number[];
  rights: string[];
}[]> => {
  const res = await http.get(GET_ALL_ROLE.replace('{operatorId}', operatorId));

  return res.data.data;
};
