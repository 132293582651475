import styled from "styled-components";

export const EmailModalStyled = styled.div`
  .email-modal {
    position: relative;

    .ant-carousel .slick-list {
      max-height: 100%;
    }
  }

  .email-modal__body {
    max-height: 400px;
    overflow-y: auto;
    padding: 0 ;
  }

  .ant-modal-footer {
    padding: 10px;
  } 
`;

export const EmailModalFooterStyle = styled.div`
  .email-modal__footer {
    display: flex;
    justify-content: space-between;
  }

  .email__previous {
    
  }

  .email__next {

  }
`;
