import { isUndefined } from "lodash";
import moment from "moment";
import { SaleItem } from "pages/MemberBenefitEventDetail/ApplicableSaleItem/types";
import {
  DATE_FORMAT,
  DAY_VALUE,
  MemberListCriteria,
  MEMBER_ANNIVERSARY_CRITERIA,
  MEMBER_DURATION_CRITERIA,
  OPERATOR_EQUAL_TO,
  OPERATOR_GREATER_THAN,
  REGEX_0_99,
  REGEX_1_99,
  REGEX_GREATER_THAN_0_NUMBER,
  REGEX_INTEGER_NUMBER,
  Type,
} from "utils/constants";
import i18n from "utils/i18n";

export const formatDelayEventForApi = (
  delayDuration: string,
  delayDurationType: string,
  dateSentCompareType: number | string,
  dateSent: number | string
) => {
  return {
    delayDuration: !delayDuration ? null : parseInt(delayDuration, 0),
    delayDurationType: !delayDuration
      ? null
      : !delayDurationType
        ? DAY_VALUE
        : delayDurationType,
    dateSentCompareType: !dateSentCompareType
      ? null
      : parseInt(dateSentCompareType.toString()),
    dateSent: !dateSent ? null : parseInt(dateSent.toString()),
  };
};

export const formatRulesEventForApi = (rules: any[]) => {
  return [
    ...rules.map((rule) => {
      const durationType = rule.durationType;
      if (rule.criteria === MEMBER_DURATION_CRITERIA.criteria) {
        rule.values = [
          {
            value:
              rule.values[0].value +
              ";" +
              (durationType
                ? durationType
                : MEMBER_DURATION_CRITERIA.defaultDropDownDateType),
          },
        ];
      } else if (rule.dataType === Type.Date && rule.values) {
        rule.values = rule.values.map((value: any) => ({
          label: value.label.format("YYYY-MM-DD") + "T00:00:00.000Z",
          value: value.value.format("YYYY-MM-DD") + "T00:00:00.000Z",
        }));
      }
      delete rule.durationType;
      return rule;
    }),
  ];
};

export const formatRuleForForm = (rule: any, id: number) => {
  if (rule.criteria === MemberListCriteria && id) {
    return {
      ...rule,
      values: new File([""], "LastMemberList.csv"),
    };
  } else if (rule.criteria === MEMBER_DURATION_CRITERIA.criteria && id) {
    return {
      ...rule,
      values: rule.values[0].split(";")[0],
      durationType: rule.values[0].split(";")[1],
    };
  } else {
    return {
      ...rule,
    };
  }
};

export const validationInputInt = (rule: any, value: number | string, t: any) => {
  const validation: {
    pattern: RegExp;
    message: string;
  } = {
    pattern: new RegExp(""),
    message: "",
  };
  if (
    !isUndefined(rule?.criteria) &&
    rule.criteria === MEMBER_ANNIVERSARY_CRITERIA.criteria &&
    value !== ""
  ) {
    if (rule.operator === OPERATOR_EQUAL_TO) {
      validation.pattern = REGEX_1_99;
    } else if (rule.operator === OPERATOR_GREATER_THAN) {
      validation.pattern = REGEX_0_99;
    }
    validation.message = t("SS.CREATE_EVENT.INVALID_FORMAT", {
      key: t("SS.CREATE_EVENT.VALUE"),
    });
  } else {
    if (rule.criteria === MEMBER_DURATION_CRITERIA.criteria) {
      validation.pattern = REGEX_GREATER_THAN_0_NUMBER;
    } else {
      validation.pattern = REGEX_INTEGER_NUMBER;
    }
    validation.message =
      value || value === 0
        ? i18n.t("SS.CREATE_EVENT.INVALID_FORMAT", {
          key: i18n.t("SS.CREATE_EVENT.VALUE"),
        })
        : "";
  }
  return validation;
};

export const formatDataSaleItemEvent = (saleItems: SaleItem[]) => {
  let dataAfterFormatted: SaleItem[] = [];

  saleItems.forEach((saleItem, _) => {
    if (
      dataAfterFormatted.some(
        (itemExist) => itemExist.combineGroupId === saleItem.combineGroupId
      )
    ) {
      dataAfterFormatted = dataAfterFormatted.map((saleItemTemp) => {
        if (saleItem.combineGroupId === saleItemTemp.combineGroupId) {
          return {
            ...saleItem,
            name: saleItemTemp.name + ", " + saleItem.name,
          };
        }
        return saleItemTemp;
      });
    } else {
      dataAfterFormatted.push({ ...saleItem });
    }
  });

  return dataAfterFormatted.map((saleItem, index) => ({
    ...saleItem,
    key: index + 1,
  }));
};

export const validateExactDate = (
  startDate: Date,
  endDate: Date,
  exactDate: number
) => {
  const startCheckDate: Date = moment(startDate, DATE_FORMAT)
    .subtract(1, "days")
    .toDate();
  const dateStart = new Date(startCheckDate);
  const dateEnd = new Date(endDate);
  const yearStart = dateStart.getUTCFullYear();
  const yearEnd = dateEnd.getUTCFullYear();
  const monthStart = dateStart.getMonth() + 1;
  const monthEnd = dateEnd.getMonth() + 1;
  if (yearEnd - yearStart === 1 && monthStart === 12 && monthEnd === 1) {
    const isValid = formDate(exactDate, monthStart, yearStart).isBetween(
      startCheckDate,
      endDate
    );
    if (!isValid) {
      return formDate(exactDate, monthEnd, yearEnd).isBetween(
        startCheckDate,
        endDate
      );
    }
    return isValid;
  }
  if (yearEnd > yearStart) {
    return true;
  }
  for (let i = monthStart; i <= monthEnd; i++) {
    if (formDate(exactDate, i, yearStart).isBetween(startCheckDate, endDate)) {
      return true;
    }
  }
  return false;
};

export const formDate = (exactDate: number, month: number, year: number) => {
  return moment(
    exactDate.toString() + "/" + month.toString() + "/" + year.toString(),
    DATE_FORMAT
  );
};
