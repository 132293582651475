import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { getEventTypeAction } from "store/reducers/Event/actions";
import { DATE_FORMAT, EVENT_STATUS_OPTION } from "utils/constants";
import {
  FormContainerStyled,
  GroupStyled,
  InputTitleStyled,
  SearchButtonStyle,
} from "../Styled";

const EvenListFilter: React.FC<TransactionFilterProps> = ({ onSearch }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { eventType } = useAppSelector((state) => state.event);
  const { evenSearchFilter } = useAppSelector((state) => state.searchFilter);
  const [formInitValues, setFormInitValues] = useState<any>();

  const updateFormValues = useCallback((): void => {
    const dateFrom = moment(new Date(evenSearchFilter.dateFrom));
    const dateTo = moment(new Date(evenSearchFilter.dateTo));

    form.setFieldsValue({
      ...evenSearchFilter,
      dateFrom,
      dateTo,
    });

    setFormInitValues({
      ...evenSearchFilter,
      dateFrom,
      dateTo,
    });
  }, [form, evenSearchFilter]);

  useEffect(() => {
    updateFormValues();
  }, [updateFormValues]);

  useEffect(() => {
    if (!eventType.length) {
      dispatch(getEventTypeAction());
    }
  }, [eventType.length]);

  useEffect(() => {
    if (!evenSearchFilter.eventType && eventType.length) {
      form.setFieldsValue({ evenSearchFilter });
    }
  }, [eventType]);

  const onFilter = (e: any) => {
    e.preventDefault();
    onSearch(form.getFieldsValue());
  };

  const onFromDateChange = (date: any, _: string) => {
    const dateTo = form.getFieldValue("dateTo");
    if (!date) return;
    if (moment(date).isBefore(moment(dateTo))) return;
    form.setFieldsValue({
      dateFrom: date,
      dateTo: date,
    });
  };

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        hideRequiredMark
        initialValues={formInitValues}
        form={form}
        onSubmitCapture={onFilter}
      >
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={9} xs={9}>
              <InputTitleStyled>
                {t("SS.EVENT_MANAGEMENT.EVENT_TYPE")}
              </InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={11} xs={11}>
              <FormItemStyled name="eventType">
                <Select
                  options={eventType}
                  placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                    key: t("SS.EVENT_MANAGEMENT.EVENT_TYPE"),
                  })}
                />
              </FormItemStyled>
            </Col>
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={9} xs={9}>
              <InputTitleStyled>{t("SS.STATUS")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={11} xs={11}>
              <FormItemStyled name="status">
                <Select
                  autoFocus
                  options={EVENT_STATUS_OPTION}
                  placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                    key: t("SS.STATUS"),
                  })}
                />
              </FormItemStyled>
            </Col>
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={9} xs={9}>
              <InputTitleStyled>{t("SS.FROM")}</InputTitleStyled>
            </Col>
            <Col
              className="gutter-row"
              md={5}
              xs={5}
              style={{ paddingRight: 0 }}
            >
              <FormItemStyled name="dateFrom">
                <DatePicker
                  size="middle"
                  format={DATE_FORMAT}
                  allowClear={false}
                  placeholder=""
                  onChange={onFromDateChange}
                />
              </FormItemStyled>
            </Col>
            <Col className="d-flex" md={1} xs={1} style={{ padding: 0 }}>
              <InputTitleStyled $textAlign="center">
                {t("SS.TO")}
              </InputTitleStyled>
            </Col>
            <Col
              className="gutter-row"
              md={5}
              xs={5}
              style={{ paddingLeft: 0 }}
            >
              <FormItemStyled name="dateTo">
                <DatePicker
                  size="middle"
                  format={DATE_FORMAT}
                  allowClear={false}
                  placeholder=""
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(
                      moment(form.getFieldValue("dateFrom"), DATE_FORMAT)
                    )
                  }
                />
              </FormItemStyled>
            </Col>
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 9 }} xs={{ span: 9 }}>
              <InputTitleStyled>{t("SS.SEARCH")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItemStyled
                name="eventName"
                rules={[
                  {
                    max: 100,
                    message: t("SS.EVENT_MANAGEMENT.MAXIMUM_INPUT", {
                      key: 100,
                    }),
                  },
                ]}
              >
                <Input
                  autoFocus
                  autoComplete="off"
                  max={100}
                  placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                    key: t("SS.SEARCH"),
                  })}
                />
              </FormItemStyled>
            </Col>
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="gutter-row" xs={{ span: 24 }}>
              <GroupStyled>
                <SearchButtonStyle type="submit">
                  {t("SS.SEARCH")}
                </SearchButtonStyle>
              </GroupStyled>
            </Col>
          </Row>
        </FormContainerStyled>
      </Form>
    </>
  );
};

export default EvenListFilter;
