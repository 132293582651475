import { ClubTypes, GET_CLUB_LIST, SET_CLUB_LIST } from "./types";

export const getClubListAction = (operatorId: string): ClubTypes => ({
  type: GET_CLUB_LIST,
  operatorId,
});

export const setClubListAction = (data: ClubProps[]): ClubTypes => ({
  type: SET_CLUB_LIST,
  data,
});
