import {
  EditGateRule,
  GetMasterData,
  InsertGateRule,
} from "pages/ConfigGateRule/types";
import { http } from "./common";
import {
  DELETE_GATE_RULE,
  EDIT_GATE_RULE,
  GET_CLUB_GROUP,
  GET_GATE_RULE,
  GET_GATE_RULE_DETAIL,
  GET_MASTER_DATA,
  INSERT_GATE_CONFIG,
  UPDATE_GATE_RULE_STATUS,
} from "./common/apis";
import { httpConman } from "./common";
import { UpdateProps, UpdateStatusResult } from "pages/GateRuleDetail/types";

export const getClubGroup = async (): Promise<ClubGroup[]> => {
  const res = await httpConman.get(GET_CLUB_GROUP);
  return res.data;
};

export const getGateRule = async (
  clubId: string,
  sortColumn: string,
  sortType: string
): Promise<GateRule[]> => {
  const res = await httpConman.get(
    GET_GATE_RULE.replace("{clubId}", clubId)
      .replace("{columnName}", sortColumn)
      .replace("{type}", sortType)
  );
  return res.data;
};

export const getMasterData = async (): Promise<GetMasterData> => {
  const res = await http.get(GET_MASTER_DATA);

  return res.data;
};

export const insertGateConfig = async (data: InsertGateRule): Promise<any> => {
  const res = await http.post(INSERT_GATE_CONFIG, data);
  return res.data;
};

export const getGateRuleDetail = async (ruleId: string): Promise<any> => {
  const res = await http.get(GET_GATE_RULE_DETAIL.replace("{ruleId}", ruleId));
  return res.data.data;
};

export const updateEventStatus = async (
  status: UpdateProps
): Promise<UpdateStatusResult> => {
  const res = await http.put(UPDATE_GATE_RULE_STATUS, status);
  return res.data;
};

export const editGateRule = async (data: EditGateRule): Promise<any> => {
  const res = await http.put(EDIT_GATE_RULE, data);
  return res.data;
};

export const deleteGateRule = async (
  gateId: string,
  operatorId: string
): Promise<any> => {
  const res = await http.delete(
    DELETE_GATE_RULE.replace("{gateId}", gateId).replace(
      "{operatorId}",
      operatorId
    )
  );
  return res.data;
};
