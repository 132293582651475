import { Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData } from "antd/lib/form/FormList";
import Icon from "components/Icon";
import TagSelect from "components/TagSelect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLedgerList } from "services/memberBenefitEvent";
import { useAppSelector } from "store";
import { FormItemStyled } from "styled/FormStyled";
import {
  GIVE_MEMBER_BENEFIT,
  DATE_OPTION,
  EXTEND_MEMBER_BENEFIT,
  REGEX_POSITIVE_INTEGER,
} from "utils/constants";
import { onFormatAmount } from "utils/helpers";
import {
  ColStyled,
  IconButtonContainer,
  PresetGroupSelectStyled,
  RoundButtonStyle,
  RowStyled,
} from "../../../../CreateUpdateEvent/Styled";

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
  setDisableAddAction: (isDisable: boolean) => void;
  isLedger: boolean;
}

const ActionItem: React.FC<ListItemProps> = ({
  groupField,
  fieldsLength,
  form,
  parentIdx,
  remove,
  setDisableAddAction,
  isLedger,
}) => {
  const { t } = useTranslation();
  const event = useAppSelector((state) => state.event);
  const [action, setAction] = useState<MemberCreditActionEvent>();
  const [ledgers, setLedgers] = useState<OptionProps[] | []>([]);

  useEffect(() => {
    setDisableAddAction(action?.actionCode === EXTEND_MEMBER_BENEFIT);
  }, [action]);

  const getLedgerData = async () => {
    try {
      const res = await getLedgerList();
      const data = res.map((ledger: Ledger) => ({
        label: ledger.ledgerGroupName,
        value: ledger.ledgerGroupId,
      }));
      setLedgers(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLedger) {
      getLedgerData();
    }
  }, [isLedger]);

  useEffect(() => {
    setTimeout(() => {
      const { actions } = form.getFieldsValue();
      if (actions) {
        setAction(actions[parentIdx] || null);
      }
    }, 200);
  }, [form, parentIdx, ledgers.length > 0]);

  const onChange = () => {
    const { actions } = form.getFieldsValue();
    setAction(actions[parentIdx] || null);
    if (actions[0]?.actionCode === EXTEND_MEMBER_BENEFIT) {
      setDisableAddAction(true);
      form.setFieldsValue({
        actions: [
          {
            actionCode: EXTEND_MEMBER_BENEFIT,
            duration: "",
            durationType: DATE_OPTION[0].value,
            quantity: "0",
          },
        ],
      });
    }
  };

  const onChangeFormat = (value: React.ChangeEvent<HTMLInputElement>) => {
    const { actions } = form.getFieldsValue();
    const formatValue = onFormatAmount(value.currentTarget.value);
    actions[parentIdx] = { ...actions[parentIdx], quantity: formatValue };
    form.setFieldsValue({
      actions: [...actions],
    });
  };

  return (
    <>
      <RowStyled $hasSpacingRight={fieldsLength <= 1}>
        <ColStyled md={8} xs={8}>
          {parentIdx === 0 ? (
            <FormItemStyled
              {...groupField}
              name={[groupField.name, "actionCode"]}
              fieldKey={[groupField.fieldKey as number, "ActionCode"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.ACTION"),
                  }),
                },
              ]}
            >
              <Select options={event.actions} onChange={onChange} />
            </FormItemStyled>
          ) : null}
        </ColStyled>
        <ColStyled md={5} xs={5}>
          {action && action.actionCode == GIVE_MEMBER_BENEFIT ? (
            isLedger ? (
              <FormItemStyled
                name={[groupField.name, "ledgerGroupIds"]}
                fieldKey={[groupField.fieldKey as number, "LedgerGroupIds"]}
                rules={[
                  {
                    required: true,
                    message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                      key: t("TS.CREATE_MEMBER_BENEFIT.LEDGER"),
                    }),
                  },
                ]}
              >
                <PresetGroupSelectStyled
                  showArrow
                  mode="multiple"
                  optionFilterProp="label"
                  options={ledgers}
                  placeholder={t("TS.SELECT_PLACE", { key: t("TS.DETAIL") })}
                  tagRender={(props) => <TagSelect {...props} />}
                />
              </FormItemStyled>
            ) : (
              <FormItemStyled
                name={[groupField.name, "itemIds"]}
                fieldKey={[groupField.fieldKey as number, "ItemIds"]}
                rules={[
                  {
                    required: true,
                    message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                      key: t("TS.MEMBER_BENEFIT.ITEMS"),
                    }),
                  },
                ]}
              >
                <PresetGroupSelectStyled
                  showArrow
                  mode="multiple"
                  optionFilterProp="label"
                  options={event.items}
                  placeholder={t("TS.SELECT_PLACE", { key: t("TS.DETAIL") })}
                  tagRender={(props) => <TagSelect {...props} />}
                />
              </FormItemStyled>
            )
          ) : null}
        </ColStyled>
        <ColStyled md={3} xs={3}>
          {action && action.actionCode == GIVE_MEMBER_BENEFIT ? (
            <FormItemStyled
              name={[groupField.name, "quantity"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("TS.MEMBER_BENEFIT.QUANTITY"),
                  }),
                },
                {
                  pattern: REGEX_POSITIVE_INTEGER,
                  message: t("TS.MEMBER_BENEFIT.INVALID_QUANTITY"),
                },
              ]}
            >
              <Input min="1" autoComplete="off" onChange={onChangeFormat} />
            </FormItemStyled>
          ) : null}
        </ColStyled>
        <ColStyled md={4} xs={4}>
          <FormItemStyled
            name={[groupField.name, "duration"]}
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.VALID_DURATION"),
                }),
              },
              {
                pattern: REGEX_POSITIVE_INTEGER,
                message: t("TS.MEMBER_BENEFIT.INVALID_QUANTITY"),
              },
            ]}
          >
            <Input min="1" autoComplete="off" />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={3} xs={3}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "durationType"]}
            fieldKey={[groupField.fieldKey as number, "type"]}
          >
            <Select options={DATE_OPTION} />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={1} xs={{ span: 1 }}>
          <IconButtonContainer>
            {fieldsLength > 1 ? (
              <RoundButtonStyle
                shape="circle"
                color="red"
                onClick={(): void => {
                  remove(groupField.name);
                }}
                icon={<Icon icon="icon-minus-ico" />}
              />
            ) : null}
          </IconButtonContainer>
        </ColStyled>
      </RowStyled>
    </>
  );
};

export default ActionItem;
