import { http } from "./common";
import {
  CREATE_GUEST_PASS,
  GET_GUEST_PASS,
  GET_GUEST_PASS_APPLICABLE,
  GET_GUEST_PASS_DETAIL,
  GET_GUEST_PASS_EVENT_DETAIL,
  GET_GUEST_PASS_EVENT_MASTER_DATA_URL,
  GET_PREVIEW_GUEST_PASS_EVENT,
  POST_GUEST_PASS_EVENT_DATA,
  POST_GUEST_PASS_EVENT_DATA_WITH_FILE,
  UPDATE_GUEST_PASS_EVENT,
  UPDATE_GUEST_PASS_EVENT_WITH_FILE
} from "./common/apis";

export const getGuestPassEventMasterData = async (): Promise<any> => {
  const res = await http.get(GET_GUEST_PASS_EVENT_MASTER_DATA_URL);
  return res.data;
};

export const getPreviewGuestPassEvent = async (
  eventId: string,
  pageIndex: string,
  pageSize: string
) => {
  const res = await http.get(
    GET_PREVIEW_GUEST_PASS_EVENT.replace("{eventId}", eventId)
      .replace("{pageIndex}", pageIndex)
      .replace("{pageSize}", pageSize)
  );
  return res;
};

export const submitGuestPassEventData = async (
  data: GuestPassEventProps
): Promise<any> => {
  const res = await http.post(POST_GUEST_PASS_EVENT_DATA, data);
  return res.data;
};

export const submitGuestPassEventDataWithFile = async (
  data: any
): Promise<any> => {
  const header = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await http.post(
    POST_GUEST_PASS_EVENT_DATA_WITH_FILE,
    data,
    header
  );
  return res.data;
};

export const updateGuestPassEvent = async (body: any): Promise<any> => {
  const res = await http.put(UPDATE_GUEST_PASS_EVENT, body);
  return res.data;
};
export const updateGuestPassEventWithFile = async (body: any): Promise<any> => {
  const header = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await http.put(UPDATE_GUEST_PASS_EVENT_WITH_FILE, body, header);
  return res.data;
};

export const getGuestPassEvent = async (eventId: string): Promise<any> => {
  const res = await http.get(
    GET_GUEST_PASS_EVENT_DETAIL.replace("{eventId}", eventId)
  );
  return res.data;
};

export const getGuestPassApplicable = async (
  eventId: string,
  pagingInfo: { pageIndex: number; pageSize: number }
): Promise<any> => {
  const res = await http.get(
    GET_GUEST_PASS_APPLICABLE.replace("{eventId}", eventId)
      .replace("{pageIndex}", `${pagingInfo.pageIndex}`)
      .replace("{pageSize}", `${pagingInfo.pageSize}`)
  );
  return res.data;
};

export const createGuestPass = async (data: any) => {
  const res = await http.post(CREATE_GUEST_PASS, data);
  return res.data;
};

export const getGuestPass = async (filterData: any): Promise<any> => {
  const res = await http.post(GET_GUEST_PASS, filterData);
  return res.data;
};

export const getGuestPassDetail = async (guestPassId: string): Promise<any> => {
  const res = await http.get(
    GET_GUEST_PASS_DETAIL.replace("{guestPassId}", guestPassId)
  );
  return res.data;
};
