import { EditOutlined } from "@ant-design/icons";
import { ConfigProvider, Popover } from "antd";
import TableParent from "components/Table";
import TableStyled from "components/Table/Styled";
import moment from "moment";
import { TransactionListStyled } from "pages/TransactionList/Styled";
import {
  AlignTextStyled,
  BlankIconButtonStyled,
  Boundary,
  Container,
  EmptyListStyled,
} from "pages/ViewMemberCreditSpending/Styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMemberBenefit, getMemberBenefitDetail } from "services/transaction";
import { TitleColumnButtonStyled } from "styled/ButtonStyled";
import { PopoverContentStyled, PopoverTextStyled } from "styled/PopoverStyled";
import {
  DATE_FORMAT,
  EVENT_LIST_PAGING,
  REACT_APP_STANDARD_HEIGHT,
  PostMessageTableType,
  ITEM_LEDGER_OPTION,
} from "utils/constants";
import { formatDate, onFormatSeparateThousand } from "utils/helpers";
import ChangeMemberBenefitExpiryDate from "./ChangeExpiryDate";

const ViewMemberBenefitSpending: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [pagingIndex, setPageIndex] = useState(0);
  const [pagingData, setPagingData] = useState(EVENT_LIST_PAGING);
  const [data, setData] = useState<MemberBenefitSpendingProps[]>([]);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const [sortType, setSortType] = useState("Descending");
  const [sortColumn, setSortColumn] = useState("CreatedDate");
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [memberBenefitDetail, setMemberBenefitDetail] = useState<any>({});
  const [detailLoading, setDetailLoading] = useState<any>({});
  const [memberBenefitId, setMemberBenefitId] = useState<number>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [expiryDate, setExpiryDate] = useState<string>("");
  const [actionItemType, setActionItemType] = useState<string>("");

  const { operatorId, memberId } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "",
      memberId: params.get("memberId") || "",
    };
  }, [window.location.search]);

  const onToggleModal = (toggle: boolean) => {
    setIsOpenModal(toggle);
    getData();
  };

  const onOpenChangeExpiryDate = (item: any) => {
    setMemberBenefitId(item.memberBenefitsId);
    setExpiryDate(item.expiredDate);
    setIsOpenModal(true);
  };
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getMemberBenefit({
        memberId,
        pageIndex: pagingIndex,
        pageSize,
        columnName: sortColumn,
        sortType,
      });
      setData(
        res.transactions.map((item: any, key: number) => ({
          ...item,
          key: key,
        }))
      );
      setPagingData(res.paging);
      setLoading(false);
      setExpandedRowKeys([]);
    } catch {
      setLoading(false);
    }
  }, [pagingIndex, pageSize, sortType, sortColumn]);

  const hoverContent = (eventName: string) => {
    return <PopoverContentStyled>{eventName}</PopoverContentStyled>;
  };

  const columns = useMemo(
    () => [
      {
        title: (
          <AlignTextStyled>{t("SS.EVENT_MANAGEMENT.NO.")}</AlignTextStyled>
        ),
        key: "index",
        render: (value: any, item: any, index: number) => (
          <AlignTextStyled>{index + 1 + pagingIndex * 10}</AlignTextStyled>
        ),
        width: 10,
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.EVENT_MANAGEMENT.EVENT_NAME")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "EventName",
        dataIndex: "eventName",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (eventName: string, item: any) => (
          <Popover content={hoverContent(eventName)} placement="topLeft">
            <PopoverTextStyled $color={"var(--navi-color)"}>
              {eventName}
            </PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.EVENT_MANAGEMENT.EVENT_REASON")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "EventReason",
        dataIndex: "eventReason",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 80,
        render: (eventReason: string, item: any) => (
          <Popover content={hoverContent(eventReason)} placement="topLeft">
            <PopoverTextStyled $color={"var(--navi-color)"}>
              {eventReason}
            </PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("TS.MEMBER_BENEFIT_EVENT.ITEM_OR_LEDGER_GROUP")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "ItemName",
        dataIndex: "itemName",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text: string) => (
          <AlignTextStyled $textAlign={"left"}>{text}</AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.TRANSACTION.AMOUNT")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "Amount",
        dataIndex: "amount",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (credit: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(credit.toString())}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REDEEM_DATE")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "CreatedDate",
        dataIndex: "createdDate",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (createdDate: string) => (
          <AlignTextStyled $textAlign={"center"}>
            {formatDate(createdDate)}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REMAINING")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "Remaining",
        dataIndex: "remaining",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (remaining: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(remaining.toString())}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.EXPIRED_DATE")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "ExpiredDate",
        dataIndex: "expiredDate",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (expiredDate: string, item: any) => (
          <AlignTextStyled $textAlign={"center"}>
            {moment(expiredDate).utcOffset(0).format(DATE_FORMAT)}
            <BlankIconButtonStyled
              disabled={item.remaining ? false : true}
              onClick={() => onOpenChangeExpiryDate(item)}
            >
              <EditOutlined />
            </BlankIconButtonStyled>
          </AlignTextStyled>
        ),
        width: 100,
      },
    ],
    [t]
  );

  const onSort = (_: any, __: any, sort: any) => {
    const order = sort?.order;
    if (order == "ascend") {
      setSortType("Ascending");
    } else {
      setSortType("Descending");
    }
    setSortColumn(sort.columnKey);
  };

  const columnsSubTable = useMemo(
    () => [
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.TRANSACTIONID")}
          </TitleColumnButtonStyled>
        ),
        key: "transactionReferenceNumber",
        dataIndex: "transactionReferenceNumber",
        render: (transactionReferenceNumber: number) => (
          <AlignTextStyled $textAlign={"center"}>
            {transactionReferenceNumber}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.USEDAMOUNT")}
          </TitleColumnButtonStyled>
        ),
        key: "usedAmount",
        dataIndex: "usedAmount",
        render: (amount: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(amount.toString())}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.DATE")}
          </TitleColumnButtonStyled>
        ),

        key: "usedDate",
        dataIndex: "usedDate",
        render: (usedDate: string) => (
          <AlignTextStyled $textAlign={"center"}>
            {moment(usedDate).format(DATE_FORMAT)}
          </AlignTextStyled>
        ),
      },
    ],
    [t]
  );

  const addColumnLedgerGroup = useCallback(() => {
    const itemIndex = columnsSubTable.findIndex(
      (col: any) => col.dataIndex === "itemName"
    );
    if (itemIndex > -1) {
      columnsSubTable.splice(itemIndex, 1);
    }
    if (actionItemType === ITEM_LEDGER_OPTION[1].value) {
      const itemSubColumn = {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("TS.DYNAMIC_PRICING_EVENT.ITEM")}
          </TitleColumnButtonStyled>
        ),
        key: "itemName",
        dataIndex: "itemName",
        render: (itemName: string) => (
          <AlignTextStyled $textAlign={"center"}>{itemName}</AlignTextStyled>
        ),
      };
      columnsSubTable.splice(1, 0, itemSubColumn);
    }
  }, [actionItemType]);

  const getMemberBenefitTransactionDetail = useCallback(async () => {
    if (memberBenefitId === undefined) {
      return;
    }

    try {
      setDetailLoading((oldLoading: any) => ({
        ...oldLoading,
        [memberBenefitId]: true,
      }));
      const result = await getMemberBenefitDetail(memberBenefitId.toString());
      if (result.isSuccess) {
        setMemberBenefitDetail((oldMemberBenefitDetail: any) => ({
          ...oldMemberBenefitDetail,
          [memberBenefitId]: result.detailTransactions,
        }));
      } else {
        setMemberBenefitDetail((oldMemberBenefitDetail: any) => ({
          ...oldMemberBenefitDetail,
          [memberBenefitId]: [],
        }));
      }
      setDetailLoading((oldLoading: any) => ({
        ...oldLoading,
        [memberBenefitId]: false,
      }));
    } catch (error) {
      setDetailLoading((oldLoading: any) => ({
        ...oldLoading,
        [memberBenefitId]: false,
      }));
    }
  }, [memberBenefitId]);

  useEffect(() => {
    getMemberBenefitTransactionDetail();
  }, [getMemberBenefitTransactionDetail]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    addColumnLedgerGroup();
  }, [addColumnLedgerGroup]);

  const expandedRow = (row: any) => {
    const memberBenefitsId = row.memberBenefitsId;
    const details = memberBenefitDetail[memberBenefitsId] || [];
    const isLoading = detailLoading[memberBenefitsId];

    return (
      <ConfigProvider renderEmpty={() => customizeRenderEmpty(true)}>
        <TableStyled
          key="id"
          columns={columnsSubTable}
          loading={isLoading}
          dataSource={details}
          $customStyle={{
            isSubTable: true,
            noUpperCase: true,
          }}
          pagination={false}
        />
      </ConfigProvider>
    );
  };

  const customizeRenderEmpty = (isExpandRow: boolean) => (
    <EmptyListStyled>
      <AlignTextStyled>
        {isExpandRow
          ? t("TS.NO_TRANSACTION_FOUND")
          : t("TS.NO_MEMBER_BENEFIT_FOUND")}
      </AlignTextStyled>
    </EmptyListStyled>
  );

  const onTableRowExpand = (expanded: any, record: any) => {
    const newKey = record.key as number;
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey]);
      setMemberBenefitId(record.memberBenefitsId);
      setActionItemType(record.actionItemType);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== newKey));
      setMemberBenefitId(undefined);
      setActionItemType("");
    }
  };

  const sendHeightPostMessage = () => {
    let height = document.getElementById("memberBenefitTable")?.offsetHeight;
    if (data.length >= 10) {
      height = REACT_APP_STANDARD_HEIGHT;
    }
    let postMessage: MemberBenefitPostMessage = {
      height: height,
      tableType: PostMessageTableType.MEMBER_BENEFIT_TABLE,
    };
    window.parent.postMessage(postMessage, "*");
  };

  useEffect(() => {
    if (!loading) {
      sendHeightPostMessage();
    }
  }, [loading]);

  return (
    <Boundary id="memberBenefitTable">
      <ConfigProvider renderEmpty={() => customizeRenderEmpty(false)}>
        <Container>
          <TransactionListStyled>
            <TableParent
              columns={columns}
              dataSource={data}
              key="key"
              loading={loading}
              onExpand={onTableRowExpand}
              expandedRowKeys={expandedRowKeys}
              expandedRowRender={expandedRow}
              customStyle={{
                height: 84,
                noUpperCase: true,
                boldTitle: true,
                centerTitle: true,
              }}
              paging={{
                ...pagingData,
                pageSize,
                color: "var(--navi-color)",
                setPageIndex: setPageIndex,
                setPageSize: setPageSize,
              }}
              onTableChange={onSort}
            />
          </TransactionListStyled>
        </Container>
      </ConfigProvider>
      <ChangeMemberBenefitExpiryDate
        isOpenModal={isOpenModal}
        setOpenModal={(res) => onToggleModal(res)}
        memberBenefitId={memberBenefitId}
        operatorId={operatorId}
        expiryDate={expiryDate}
        memberId={memberId}
      />
    </Boundary>
  );
};

export default ViewMemberBenefitSpending;
