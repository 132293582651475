//@ts-nocheck
import React from "react";
import Icon from "components/Icon";
import { Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import { FormListFieldData } from "antd/lib/form/FormList";
import {
  ColStyled,
  IconButtonContainer,
  RoundButtonStyle,
  RowStyled,
} from "../Styled";
import { DATE_OPTION, REGEX_INTEGER_NUMBER } from "utils/constants";

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
}

const ActionItem: React.FC<ListItemProps> = ({
  groupField,
  remove,
  fieldsLength,
  form,
  parentIdx,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {parentIdx === 0 ? null : (
        <RowStyled $hasSpacingRight={fieldsLength <= 1}>
          <ColStyled md={2} xs={2}></ColStyled>
          <ColStyled md={10} xs={10}>
            <FormItemStyled
              name={[groupField.name, "quantity"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("TS.QUANTITY"),
                  }),
                },
                {
                  pattern: REGEX_INTEGER_NUMBER,
                  message: t("TS.INTEGER_GREATER_THAN_0"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                min={1}
                step={1}
                type="number"
                placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                  key: t("TS.QUANTITY"),
                })}
              />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={5} xs={5}>
            <FormItemStyled
              name={[groupField.name, "validDuration"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.VALID_DURATION"),
                  }),
                },
                {
                  pattern: REGEX_INTEGER_NUMBER,
                  message: t("TS.INTEGER_GREATER_THAN_0"),
                },
              ]}
            >
              <Input
                min="0"
                placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                  key: t("SS.CREATE_EVENT.VALID_DURATION"),
                })}
                autoComplete="off"
                type="number"
              />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={4} xs={4} style={{ paddingRight: 0 }}>
            <FormItemStyled
              {...groupField}
              name={[groupField.name, "durationType"]}
              fieldKey={[groupField.fieldKey as number, "type"]}
            >
              <Select options={DATE_OPTION} />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={2} xs={2}>
            <IconButtonContainer>
              {fieldsLength > 2 ? (
                <RoundButtonStyle
                  shape="circle"
                  color="red"
                  onClick={(): void => {
                    remove(groupField.name);
                  }}
                  icon={<Icon icon="icon-minus-ico" />}
                />
              ) : null}
            </IconButtonContainer>
          </ColStyled>
        </RowStyled>
      )}
    </>
  );
};

export default ActionItem;
