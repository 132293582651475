import { Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData } from "antd/lib/form/FormList";
import Icon from "components/Icon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { FormItemStyled } from "styled/FormStyled";
import {
  DATE_OPTION,
  DAY_VALUE,
  GuestPassActionCode,
  REGEX_GREATER_THAN_0_NUMBER,
  REGEX_POSITIVE_INTEGER,
} from "utils/constants";
import { onFormatAmount } from "utils/helpers";
import {
  ColStyled,
  IconButtonContainer,
  RoundButtonStyle,
  RowStyled,
} from "../../../../CreateUpdateEvent/Styled";

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
  setDisabledButton: (disabled: boolean) => void;
}

const ActionItem: React.FC<ListItemProps> = ({
  groupField,
  remove,
  fieldsLength,
  form,
  parentIdx,
  setDisabledButton,
}) => {
  const { t } = useTranslation();
  const event = useAppSelector((state) => state.event);
  const [action, setAction] = useState<GuestPassEventActions>();

  const onChange = () => {
    const { actions } = form.getFieldsValue();
    if (actions[0].actionCode === GuestPassActionCode.EXTEND_GUEST_PASS) {
      setDisabledButton(true);
      form.setFieldsValue({
        actions: [
          {
            actionCode: GuestPassActionCode.EXTEND_GUEST_PASS,
            durationType: DAY_VALUE,
            quantity: "",
          },
        ],
      });
    } else {
      setDisabledButton(false);
    }
    setAction(actions[parentIdx] || null);
  };

  useEffect(() => {
    setTimeout(() => {
      const { actions } = form.getFieldsValue();

      if (actions) {
        setAction(actions[parentIdx] || null);
      }
      if (
        actions &&
        actions[0].actionCode == GuestPassActionCode.EXTEND_GUEST_PASS
      ) {
        setDisabledButton(true);
      }
    }, 200);
  }, [form, parentIdx]);

  const onChangeFormat = (value: React.ChangeEvent<HTMLInputElement>) => {
    const { actions } = form.getFieldsValue();
    const formatValue = onFormatAmount(value.currentTarget.value);
    if (actions[0].actionCode === GuestPassActionCode.EXTEND_GUEST_PASS) {
      actions[parentIdx] = { ...actions[parentIdx], quantity: "" };
    } else {
      actions[parentIdx] = { ...actions[parentIdx], quantity: formatValue };
    }

    form.setFieldsValue({
      actions: [...actions],
    });
  };

  return (
    <>
      <RowStyled $hasSpacingRight={fieldsLength <= 1}>
        <ColStyled md={8} xs={8}>
          {parentIdx === 0 && (
            <FormItemStyled
              {...groupField}
              name={[groupField.name, "actionCode"]}
              fieldKey={[groupField.fieldKey as number, "ActionCode"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.ACTION"),
                  }),
                },
              ]}
            >
              <Select options={event.actions} onChange={onChange} />
            </FormItemStyled>
          )}
        </ColStyled>
        <ColStyled md={6} xs={6}>
          {action?.actionCode === GuestPassActionCode.GIVE_GUEST_PASS && (
            <FormItemStyled
              name={[groupField.name, "quantity"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("TS.MEMBER_BENEFIT.QUANTITY"),
                  }),
                },
                {
                  pattern: REGEX_POSITIVE_INTEGER,
                  message: t("TS.MEMBER_BENEFIT.INVALID_QUANTITY"),
                },
              ]}
            >
              <Input min="0" autoComplete="off" onChange={onChangeFormat} />
            </FormItemStyled>
          )}
        </ColStyled>
        <ColStyled md={5} xs={5}>
          <FormItemStyled
            name={[groupField.name, "duration"]}
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.VALID_DURATION"),
                }),
              },
              {
                pattern: REGEX_GREATER_THAN_0_NUMBER,
                message: t("TS.MEMBER_BENEFIT.INVALID_QUANTITY"),
              },
            ]}
          >
            <Input min="0" autoComplete="off" />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={4} xs={4}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "durationType"]}
            fieldKey={[groupField.fieldKey as number, "type"]}
          >
            <Select options={DATE_OPTION} />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={1} xs={{ span: 1 }}>
          <IconButtonContainer>
            {fieldsLength > 1 ? (
              <RoundButtonStyle
                shape="circle"
                color="red"
                onClick={(): void => {
                  remove(groupField.name);
                }}
                icon={<Icon icon="icon-minus-ico" />}
              />
            ) : null}
          </IconButtonContainer>
        </ColStyled>
      </RowStyled>
    </>
  );
};

export default ActionItem;
