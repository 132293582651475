import React, { useEffect, useState } from "react";
import { Col, Radio, Row, Space, Input, Select, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import {
  BorderTitleStyled,
  DayInputDelay,
  DelayContainer,
  ExactContainer,
  MeetCriteriaContainer,
  SelectDelayTime,
  SubTitleStyled,
} from "./Styled";
import {
  ADD_DELAY,
  DATE_OPTION,
  DURATION_TYPE_MEET_CRITERIA,
  DURATION_TYPE_WHEN_MEMBER_MEET_CRITERIA,
  MEET_CRITERIA_EXACT,
  MEMBER_DELAY_OPTION,
  REGEX_GREATER_THAN_0_NUMBER,
  REGEX_INTEGER_1_31,
} from "utils/constants";
import { FormItemStyled, InputGroupStyle } from "styled/FormStyled";
import { validateExactDate } from "services/event";

interface IDelay {
  title: string;
  form: FormInstance;
  startDate: Date;
  endDate: Date;
}

const { Option } = Select;

const Delay = (props: IDelay) => {
  const { t } = useTranslation();
  const { title, form, startDate, endDate } = props;
  const [delayOption, setDelayOption] = useState<number>(
    form.getFieldValue("when")
  );

  const [defaultCriteriaDayOfMonth, setDefaultCriteriaDayOfMonth] =
    useState<number>(DURATION_TYPE_MEET_CRITERIA.First);
  const [disabledSelect, setDisabledSelect] = useState<boolean>(true);
  const [isValidDuration, setIsValidDuration] = useState<boolean>(true);
  const [isValidDateSent, setIsValidDateSent] = useState(true);

  useEffect(() => {
    const dateSentCompareType = form.getFieldValue("dateSentCompareType");
    const dateSent = form.getFieldValue("dateSent");
    validateDelayTime(dateSent, dateSentCompareType);
  }, [startDate, endDate]);

  const handleChangeDelayOption = (option: number) => {
    // change delay option for radio
    setDelayOption(option);
    if (option === 1) {
      setIsValidDateSent(true);
      setDisabledSelect(false);
      form.setFieldsValue({
        dateSentCompareType: DURATION_TYPE_MEET_CRITERIA.First,
        dateSent: null,
      });
      const startDate = form.getFieldValue("startDate");
      const endDate = form.getFieldValue("endDate");
      if (!validateExactDate(startDate, endDate, 1)) {
        setIsValidDuration(false);
      }
    } else {
      form.setFieldsValue({
        dateSentCompareType: null,
        dateSent: null,
      });
      setDefaultCriteriaDayOfMonth(DURATION_TYPE_MEET_CRITERIA.First);
      setDisabledSelect(true);
      setIsValidDuration(true);
    }
  };

  useEffect(() => {
    if (form.getFieldValue("when") === MEET_CRITERIA_EXACT) {
      setDisabledSelect(false);
    }
    if (
      form.getFieldValue("dateSentCompareType") ===
      DURATION_TYPE_MEET_CRITERIA.Exact
    ) {
      setDefaultCriteriaDayOfMonth(DURATION_TYPE_MEET_CRITERIA.Exact);
    }
  }, []);

  const onChangeCriteriaDate = (option: any) => {
    // change first, last, exact
    validateDelayTime("", option);
    if (option !== DURATION_TYPE_MEET_CRITERIA.Exact) {
      form.setFieldsValue({
        dateSent: null,
      });
    }
  };

  const validateDelayTime = (input: any, option: number) => {
    const startDate = form.getFieldValue("startDate");
    const endDate = form.getFieldValue("endDate");
    if (option !== DURATION_TYPE_MEET_CRITERIA.Exact) {
      setIsValidDateSent(true);
    }
    if (option === DURATION_TYPE_MEET_CRITERIA.First) {
      if (!validateExactDate(startDate, endDate, 1)) {
        setIsValidDuration(false);
      } else {
        setIsValidDuration(true);
      }
    }
    if (option === DURATION_TYPE_MEET_CRITERIA.Last) {
      const lastExactDay = new Date(
        startDate.clone().endOf("month")
      ).getUTCDate();
      if (!validateExactDate(startDate, endDate, lastExactDay)) {
        setIsValidDuration(false);
      } else {
        setIsValidDuration(true);
      }
    }
    if (option === DURATION_TYPE_MEET_CRITERIA.Exact) {
      setIsValidDuration(true);
      if (
        input &&
        input.toString().match(REGEX_INTEGER_1_31) &&
        !validateExactDate(startDate, endDate, input)
      ) {
        setIsValidDateSent(false);
      } else {
        setIsValidDateSent(true);
      }
    }
    setDefaultCriteriaDayOfMonth(option);
  };

  const onChangeInputDelay = (event: any) => {
    validateDelayTime(event.target.value, DURATION_TYPE_MEET_CRITERIA.Exact);
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={2} style={{ paddingRight: 0 }}>
          <BorderTitleStyled>{t("TS.WHEN")}</BorderTitleStyled>
        </Col>
        <Col span={22}>
          <SubTitleStyled>{title}</SubTitleStyled>
          <FormItemStyled name="when" $marginBottom={0}>
            <Radio.Group
              onChange={(event) => handleChangeDelayOption(event.target.value)}
            >
              <Space direction="vertical">
                {MEMBER_DELAY_OPTION.map((option) => {
                  return (
                    <MeetCriteriaContainer>
                      <Radio
                        key={option.value + "_" + option.label}
                        value={option.value}
                        style={{ marginRight: "0px" }}
                      >
                        {option.value !== 1 ? (
                          t(option.label)
                        ) : (
                          <>{t(option.label)}</>
                        )}
                      </Radio>
                      {option.value === DURATION_TYPE_MEET_CRITERIA.Exact && (
                        <>
                          <div className="option-container">
                            <FormItemStyled
                              className="select"
                              name="dateSentCompareType"
                              $marginBottom={0}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (isValidDuration) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        t(
                                          "TS.DELAY.NOT.MATCH.SELECT.DATE_RANGE"
                                        )
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <SelectDelayTime
                                options={
                                  DURATION_TYPE_WHEN_MEMBER_MEET_CRITERIA
                                }
                                onChange={onChangeCriteriaDate}
                                disabled={disabledSelect}
                              />
                            </FormItemStyled>
                          </div>
                          {defaultCriteriaDayOfMonth ===
                            DURATION_TYPE_WHEN_MEMBER_MEET_CRITERIA[0]
                              .value && (
                            <ExactContainer>
                              <FormItemStyled
                                $marginBottom={0}
                                className="input"
                                name="dateSent"
                                rules={[
                                  {
                                    required: true,
                                    message: t("TS.DELAY.DAY.VALIDATE"),
                                  },
                                  {
                                    pattern: REGEX_INTEGER_1_31,
                                    message: t("TS.DELAY.DAY.VALIDATE"),
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (isValidDateSent) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(
                                          t("TS.DELAY.NOT.MATCH.DATE_RANGE")
                                        )
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <DayInputDelay
                                  autoComplete="off"
                                  min={1}
                                  max={31}
                                  step={1}
                                  type="number"
                                  autoFocus
                                  onChange={onChangeInputDelay}
                                />
                              </FormItemStyled>
                            </ExactContainer>
                          )}
                        </>
                      )}
                      {
                        option.value === MEET_CRITERIA_EXACT && <span>{t("TS.DAY.OF.THE.MONTH")}</span>
                      }
                    </MeetCriteriaContainer>
                  );
                })}
              </Space>
            </Radio.Group>
          </FormItemStyled>
          {delayOption === ADD_DELAY && (
            <DelayContainer>
              <label>{t("TS.APPLY.EVENT")}</label>
              <InputGroupStyle compact>
                <FormItemStyled
                  name="delayDuration"
                  className="delay-input"
                  rules={[
                    {
                      required: true,
                      message: t("TS.VALIDATE.GREATER.THAN.0"),
                    },
                    {
                      pattern: REGEX_GREATER_THAN_0_NUMBER,
                      message: t("TS.VALIDATE.GREATER.THAN.0"),
                    },
                  ]}
                >
                  <Input
                    defaultValue=""
                    type="number"
                    className="delay-input"
                    min={1}
                  />
                </FormItemStyled>
                <FormItemStyled name="delayDurationType">
                  <Select
                    defaultValue={DATE_OPTION[0].value}
                    className="delay-select"
                  >
                    {DATE_OPTION.map((option) => (
                      <Option
                        value={option.value}
                        key={option.value + "_" + option.label}
                      >
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </FormItemStyled>
                <span className="after-start-date">
                  {t("TS.AFTER.MEMBERSHIP.START.DATE")}
                </span>
              </InputGroupStyle>
            </DelayContainer>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Delay;
