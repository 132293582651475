import { IHistory } from "models/History";
import { http, httpConman } from "./common";
import {
  DELETE_MEMBER_BENEFIT_EVENT,
  GET_MEMBER_BENEFIT_APPLICABLE_SALE_LINE_ITEM,
  GET_DOWNLOAD_FILE,
  GET_MEMBER_BENEFIT_DOWNLOAD_FILE,
  GET_MEMBER_BENEFIT_EVENT_CONFIG,
  GET_MEMBER_BENEFIT_EVENT,
  GET_EVENT,
  GET_MEMBER_BENEFIT_EVENT_PREVIEW_DETAIL,
  UPDATE_MEMBER_BENEFIT_EVENT_WITH_FILE,
  UPDATE_MEMBER_BENEFIT_EVENT_WITHOUT_FILE,
  GET_MEMBER_BENEFIT_ITEMS,
  CREATE_MEMBER_BENEFIT,
  GET_LEDGER_GROUP,
  HISTORY_RESPONSE,
} from "./common/apis";

export const updateEndDate = async (
  eventId: string,
  body: any
): Promise<any> => {
  const res = await http.put(GET_EVENT.replace("{eventId}", eventId), body);
  return res.data;
};

export const getDownloadFile = async (eventId: string): Promise<any> => {
  const res = await http.get(GET_DOWNLOAD_FILE.replace("{eventId}", eventId));
  return res;
};

export const getMemberBenefitEventDetail = async (
  eventId: string,
  pagingInfo: { pageIndex: number; pageSize: number }
): Promise<any> => {
  const res = await http.get(
    GET_MEMBER_BENEFIT_EVENT_PREVIEW_DETAIL.replace("{eventId}", eventId)
      .replace("{pageIndex}", `${pagingInfo.pageIndex}`)
      .replace("{pageSize}", `${pagingInfo.pageSize}`)
  );
  return res.data;
};

export const getMemberBenefitApplicableSaleItem = async (
  eventId: string,
  pagingInfo: { pageIndex: number; pageSize: number }
): Promise<any> => {
  const res = await http.get(
    GET_MEMBER_BENEFIT_APPLICABLE_SALE_LINE_ITEM.replace("{eventId}", eventId)
      .replace("{pageIndex}", `${pagingInfo.pageIndex}`)
      .replace("{pageSize}", `${pagingInfo.pageSize}`)
  );
  return res.data;
};

export const getMemberBenefitDownloadFile = async (
  eventId: string
): Promise<any> => {
  const res = await http.get(
    GET_MEMBER_BENEFIT_DOWNLOAD_FILE.replace("{eventId}", eventId)
  );
  return res;
};

export const deleteMemberBenefitEvent = async (
  eventId: string
): Promise<any> => {
  const res = await http.delete(
    DELETE_MEMBER_BENEFIT_EVENT.replace("{eventId}", eventId)
  );
  return res;
};

export const getMemberBenefitEventConfig = async (
  eventId: string
): Promise<any> => {
  const res = await http.get(
    GET_MEMBER_BENEFIT_EVENT_CONFIG.replace("{eventId}", eventId)
  );
  return res.data;
};

export const getMemberBenefitEvent = async (eventId: string): Promise<any> => {
  const res = await http.get(
    GET_MEMBER_BENEFIT_EVENT.replace("{eventId}", eventId)
  );
  return res.data;
};

export const updateMemberBenefitEventWithFile = async (
  data: any
): Promise<any> => {
  const header = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await http.put(
    UPDATE_MEMBER_BENEFIT_EVENT_WITH_FILE,
    data,
    header
  );
  return res.data;
};

export const updateMemberBenefitEventWithoutFile = async (
  data: any
): Promise<any> => {
  const res = await http.put(UPDATE_MEMBER_BENEFIT_EVENT_WITHOUT_FILE, data);
  return res.data;
};

export const getMemberBenefitItems = async (): Promise<any> => {
  const res = await http.get(GET_MEMBER_BENEFIT_ITEMS);
  return res.data;
};

export const addMemberBenefit = async (data: any) => {
  const res = await http.post(CREATE_MEMBER_BENEFIT, data);
  return res.data;
};

export const getLedgerList = async (): Promise<Ledger[]> => {
  const res = await http.get(GET_LEDGER_GROUP);
  return res.data;
};

export const getAllHistoryOfMember = async (body: {
  membershipNumber: string;
  startDate: string;
  endDate: string;
}): Promise<{ isSuccess: boolean; histories: IHistory[] }> => {
  const res = await httpConman.post(HISTORY_RESPONSE, body);
  return res.data;
};
