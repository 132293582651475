import React, { useEffect, useState } from "react";
import Icon from "components/Icon";
import { Input, Select } from "antd";
import { useAppSelector } from "store";
import { FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import { FormListFieldData } from "antd/lib/form/FormList";
import {
  ColStyled,
  IconButtonContainer,
  RoundButtonStyle,
  RowStyled,
} from "../../../Styled";
import {
  REGEX_POSITIVE_NUMBER,
  REGEX_POSITIVE_SEPARATE_NUMBER,
  DATE_OPTION,
  EventActionCode,
  DAY_VALUE,
} from "utils/constants";
import { onFormatAmount } from "utils/helpers";

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
  setDisableAddAction: (isDisable: boolean) => void;
}

const ActionItem: React.FC<ListItemProps> = ({
  groupField,
  fieldsLength,
  form,
  parentIdx,
  remove,
  setDisableAddAction,
}) => {
  const { t } = useTranslation();
  const event = useAppSelector((state) => state.event);
  const [action, setAction] = useState<MemberCreditActionEvent>();

  useEffect(() => {
    setDisableAddAction(
      action?.actionCode === EventActionCode.EXTEND_EXPIRE_DURATION
    );
  }, [action]);

  useEffect(() => {
    setTimeout(() => {
      const { actions } = form.getFieldsValue();
      if (actions) {
        setAction(actions[parentIdx] || null);
      }
    }, 200);
  }, [form, parentIdx]);

  const onChange = () => {
    const { actions } = form.getFieldsValue();
    setAction(actions[parentIdx] || null);
    if (actions[0]?.actionCode === EventActionCode.EXTEND_EXPIRE_DURATION) {
      form.setFieldsValue({
        actions: [
          {
            actionCode: EventActionCode.EXTEND_EXPIRE_DURATION,
            duration: "",
            durationType: DAY_VALUE,
          },
        ],
      });
    }
  };

  const onChangeFormat = (value: React.ChangeEvent<HTMLInputElement>) => {
    const { actions } = form.getFieldsValue();
    const formatValue = onFormatAmount(value.currentTarget.value);
    actions[parentIdx] = { ...actions[parentIdx], amount: formatValue };
    form.setFieldsValue({
      actions: [...actions],
    });
  };

  return (
    <>
      <RowStyled $hasSpacingRight={fieldsLength <= 1}>
        <ColStyled md={8} xs={8}>
          {parentIdx === 0 ? (
            <FormItemStyled
              {...groupField}
              name={[groupField.name, "actionCode"]}
              fieldKey={[groupField.fieldKey as number, "ActionCode"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.ACTION"),
                  }),
                },
              ]}
            >
              <Select
                placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                  key: t("SS.CREATE_EVENT.ACTION"),
                })}
                options={event.actions}
                onChange={onChange}
              />
            </FormItemStyled>
          ) : null}
        </ColStyled>
        <ColStyled md={6} xs={6}>
          {action &&
            action.actionCode === EventActionCode.GIVE_MEMBER_CREDIT ? (
            <FormItemStyled
              name={[groupField.name, "amount"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.AMOUNT"),
                  }),
                },
                {
                  pattern: REGEX_POSITIVE_SEPARATE_NUMBER,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("SS.CREATE_EVENT.AMOUNT"),
                  }),
                },
              ]}
            >
              <Input
                min="0"
                autoComplete="off"
                placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                  key: t("SS.CREATE_EVENT.AMOUNT"),
                })}
                onChange={onChangeFormat}
              />
            </FormItemStyled>
          ) : null}
        </ColStyled>
        <ColStyled md={5} xs={5}>
          <FormItemStyled
            name={[groupField.name, "duration"]}
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.VALID_DURATION"),
                }),
              },
              {
                pattern: REGEX_POSITIVE_NUMBER,
                message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                  key: t("SS.CREATE_EVENT.VALID_DURATION"),
                }),
              },
            ]}
          >
            <Input
              min="0"
              autoComplete="off"
              placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                key: t("SS.CREATE_EVENT.DURATION"),
              })}
            />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={4} xs={4}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "durationType"]}
            fieldKey={[groupField.fieldKey as number, "type"]}
          >
            <Select options={DATE_OPTION} />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={1} xs={{ span: 1 }}>
          <IconButtonContainer>
            {fieldsLength > 1 ? (
              <RoundButtonStyle
                shape="circle"
                color="red"
                onClick={(): void => {
                  remove(groupField.name);
                }}
                icon={<Icon icon="icon-minus-ico" />}
              />
            ) : null}
          </IconButtonContainer>
        </ColStyled>
      </RowStyled>
    </>
  );
};

export default ActionItem;
