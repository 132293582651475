import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Col, Form, Input, Row, DatePicker, Select } from "antd";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from "moment";

import { FormItemStyled } from "styled/FormStyled";
import {
  DATE_FILTER_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_PAGING,
  STATUS_OPTIONS,
} from "utils/constants";
import { routeUrls } from "utils/routes";
import { getTransactionList } from "services/transaction";
import { formatNumber, formatUTCTime, getCurrentCurrency } from "utils/helpers";

import HeadingGroup from "components/HeadingGroup";
import InfoBlock from "components/Icon/InfoBlock";
import Table from "components/Table";
import {
  GroupStyled,
  StatusStyled,
  TransactionListStyled,
  AmountStyled,
  PSPRefStyled,
} from "./Styled";
import ButtonStyled from "styled/ButtonStyled";

const TransactionFilter: React.FC<TransactionFilterProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const initFormValues = useMemo(
    () => ({
      pspRef: "",
      status: "fail",
      from: moment(new Date(), DATE_FORMAT),
      to: moment(new Date(), DATE_FORMAT),
    }),
    []
  );

  const onFromDateChange = (date: any, _: string) => {
    const toData = form.getFieldValue("to");
    if (!date.isBefore(toData)) {
      form.setFieldsValue({
        to: date,
      });
    }
  };

  const onFilter = () => {
    onSearch(form.getFieldsValue());
  };

  const onReset = () => {
    form.resetFields();
    onSearch(form.getFieldsValue());
  };

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        hideRequiredMark
        initialValues={initFormValues}
        form={form}
      >
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemStyled
              label={t("SS.TRANSACTION.PSP_REFERENCE")}
              name="pspRef"
            >
              <Input
                placeholder={t("SS.SEARCH")}
                maxLength={255}
                autoFocus={true}
              />
            </FormItemStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemStyled label={t("SS.TRANSACTION.STATUS")} name="status">
              <Select
                options={STATUS_OPTIONS.map((i) => ({
                  ...i,
                  label: t(i.label),
                }))}
                optionFilterProp="label"
              />
            </FormItemStyled>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemStyled label={t("SS.TRANSACTION.FROM")} name="from">
              <DatePicker
                size="middle"
                format={DATE_FORMAT}
                onChange={onFromDateChange}
                allowClear={false}
              />
            </FormItemStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemStyled label={t("SS.TRANSACTION.TO")} name="to">
              <DatePicker
                size="middle"
                format={DATE_FORMAT}
                disabledDate={(d) =>
                  !d || d.isBefore(form.getFieldValue("from"))
                }
                allowClear={false}
              />
            </FormItemStyled>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" xs={{ span: 24 }}>
            <GroupStyled>
              <ButtonStyled
                type="primary"
                title={t("SS.RESET")}
                onClick={onReset}
              >
                {t("SS.RESET")}
              </ButtonStyled>
              <ButtonStyled
                type="primary"
                title={t("SS.SEARCH")}
                onClick={onFilter}
              >
                {t("SS.SEARCH")}
              </ButtonStyled>
            </GroupStyled>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const TransactionList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<TransactionDataProps[]>([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGING.pageSize);
  const [pagingIndex, setPageIndex] = useState(0);
  const [pagingData, setPagingData] = useState(DEFAULT_PAGING);
  const [filterData, setFilterData] = useState<FilterDataProps>({
    pspRef: "",
    status: "fail",
    from: moment(new Date(), DATE_FORMAT).toString(),
    to: moment(new Date(), DATE_FORMAT).toString(),
  });
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("createdUTC=desc");
  const { memberId, memberName, membershipNumber } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      memberName: params.get("Name") || "",
      memberId: params.get("MemberId") || "",
      membershipNumber: params.get("MembershipNumber") || "",
    };
  }, []);
  const history = useNavigate();

  const currencyInfo = useMemo(() => {
    return getCurrentCurrency();
  }, [getCurrentCurrency]);

  const getStatusText = useCallback(
    (isSuccess: string) => {
      return isSuccess ? t("SS.TRANSACTION.SUCCESS") : t("SS.TRANSACTION.FAIL");
    },
    [t]
  );

  const customizeFilterData = (data: FilterDataProps): any[] => {
    return Object.keys(data).reduce((newData: any, key: string) => {
      const value = data[key];

      if (!value) {
        return newData;
      }

      switch (key) {
        case "pspRef":
          return [
            ...newData,
            {
              operation: "contains",
              queryType: "text",
              queryKey: "pspRef",
              queryValue: filterData.pspRef,
            },
          ];
        case "status":
          return [
            ...newData,
            {
              operation: "eq",
              queryType: "boolean",
              queryKey: "isSuccess",
              queryValue: filterData.status === "success",
            },
          ];
        case "from":
          return [
            ...newData,
            {
              operation: "ge",
              queryType: "datetime",
              queryKey: "createdUtc",
              queryValue: `${moment(filterData.from).format(
                DATE_FILTER_FORMAT
              )} 00:00:00`,
            },
          ];
        case "to":
          return [
            ...newData,
            {
              operation: "le",
              queryType: "datetime",
              queryKey: "createdUtc",
              queryValue: `${moment(filterData.to).format(
                DATE_FILTER_FORMAT
              )} 23:59:59`,
            },
          ];
      }
    }, [] as any[]);
  };

  const getData = useCallback(async () => {
    setLoading(true);
    const customFilter = {
      and: [
        {
          operation: "eq",
          queryType: "number",
          queryKey: "memberId",
          queryValue: memberId,
        },
        ...customizeFilterData(filterData),
      ],
    };
    try {
      const res = await getTransactionList({
        pageIndex: pagingIndex,
        pageSize,
        filter: JSON.stringify(customFilter),
        sorts: sort,
      });
      setData(res.data);
      setPagingData(res.paging);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [pagingIndex, pageSize, filterData, sort]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      {
        title: t("SS.TRANSACTION.TRANSACTION_NO"),
        dataIndex: "transactionNumber",
        render: (transactionNumber: number) => (
          <>{transactionNumber === 0 ? "" : transactionNumber}</>
        ),
      },
      {
        title: t("SS.TRANSACTION.DATE_TIME"),
        dataIndex: "createdUtc",
        sorter: true,
        render: (createdUtc: string) => (
          <>{moment(formatUTCTime(createdUtc)).format(DATE_TIME_FORMAT)}</>
        ),
      },
      {
        title: t("SS.TRANSACTION.PSP_REFERENCE"),
        dataIndex: "pspRef",
        render: (pspRef: string) => <PSPRefStyled>{pspRef}</PSPRefStyled>,
      },
      {
        title: t("SS.TRANSACTION.AMOUNT"),
        dataIndex: "amount",
        render: (amount: number) => (
          <AmountStyled>{formatNumber(amount, currencyInfo)}</AmountStyled>
        ),
      },
      {
        title: t("SS.TRANSACTION.ACTION_BY"),
        dataIndex: "operator",
      },
      {
        title: t("SS.TRANSACTION.STATUS"),
        dataIndex: "isSuccess",
        render: (status: string, item: any) => (
          <ButtonStyled
            type="link"
            size="small"
            title={getStatusText(status)}
            onClick={() => {
              history(routeUrls.transactionDetail.replace(":id", item.id));
            }}
          >
            <StatusStyled status={getStatusText(status)}>
              {getStatusText(status)}
            </StatusStyled>
          </ButtonStyled>
        ),
      },
    ],
    [t]
  );

  const onSort = (_: any, __: any, sort: any) => {
    const order = sort?.order;
    if (order === "ascend") {
      setSort(`${sort.field}=asc`);
    } else {
      setSort(`${sort.field}=desc`);
    }
  };

  const onSearchData = (newFilterData: FilterDataProps) => {
    setFilterData(newFilterData);
    setPageIndex(0);
  };

  return (
    <>
      <HeadingGroup title={t("SS.TRANSACTION.TITLE")} />
      <InfoBlock
        infos={[
          { key: t("SS.TRANSACTION.MEMBER_NAME"), value: memberName },
          { key: t("SS.TRANSACTION.MEMBER_NUMBER"), value: membershipNumber },
        ]}
      />
      <TransactionFilter onSearch={onSearchData} />
      <TransactionListStyled>
        <Table
          columns={columns}
          dataSource={data}
          key="transactionNumber"
          loading={loading}
          paging={{
            ...pagingData,
            pageSize,
            setPageIndex: setPageIndex,
            setPageSize: setPageSize,
          }}
          onTableChange={onSort}
        />
      </TransactionListStyled>
    </>
  );
};

export default TransactionList;
