import { Col, Row, Select } from "antd";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import {
  getClubGroupMasterDataAction,
  setCurrentClubAction
} from "store/reducers/Gate/action";
import { FormItemStyled } from "styled/FormStyled";
import { routeUrls } from "utils/routes";
import { HeaderStyled } from "../Styled";


const ManageGateFilter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clubGroup, chosenClub } = useAppSelector((state) => state.gate);
   const { operatorId } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "0",
    };
  }, []);
    
 

  const handleOnChange = (value: string) => {
    dispatch(setCurrentClubAction(value));
  };

  useEffect(() => {
    if (!clubGroup) {
      dispatch(getClubGroupMasterDataAction());
    }
    if (!chosenClub && clubGroup && clubGroup.length) {
      dispatch(setCurrentClubAction(clubGroup[0].value));
    }
  }, [clubGroup]);

  return (
    <>
      <Row gutter={24}>
        <Col className="d-flex" md={9} xs={9}>
         <Link to={routeUrls.configGateRule.replace(":createdBy", operatorId).replace(":id", "0")}>
          <HeaderStyled $fontSize={14}>
            {t("TS.MANAGE_GATE.ADD_NEW_RULE")}
          </HeaderStyled>
         </Link>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col className="gutter-row" md={9} xs={9}>
          <FormItemStyled>
            {chosenClub && (
              <Select
                options={clubGroup || []}
                onChange={handleOnChange}
                defaultValue={chosenClub}
              />
            )}
          </FormItemStyled>
        </Col>
      </Row>
    </>
  );
};

export default ManageGateFilter;
