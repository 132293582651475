import { Popover } from "antd";
import React from "react";
import { PopoverContentStyled } from "styled/PopoverStyled";
import { TagStyled, TagTitleStyled } from "./Styled";

const TagSelect = (props: any) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const hoverDetailLabel = (label: string) => {
    return <PopoverContentStyled>{label}</PopoverContentStyled>;
  };

  return (
    <TagStyled
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      <Popover content={hoverDetailLabel(label)} placement="topLeft">
        <TagTitleStyled> {label}</TagTitleStyled>
      </Popover>
    </TagStyled>
  );
};

export default TagSelect;
