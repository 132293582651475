import { Card } from "antd";
import styled from "styled-components";

export const DetailContainer = styled.div`
  width: "300px";
  border: 1px solid black;
`;

export const HeaderContainer = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: flex;
`;

export const CardDetail = styled(Card)`
  margin-bottom: 10px;
  font-size: 11px;
  color: var(--gray-color);
  .ant-card-head-title {
    font-size: 11px;
    font-weight: bold;
  }
  .ant-card-head {
    min-height: 20px;
  }
`;

export const DetailContent = styled.div`
  display: flex;
  align-items: center;
  .anticon-check {
    margin-right: 0.5rem;
  }
`;

export const DotIcon = styled.span``;
