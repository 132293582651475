import React, { useCallback, useEffect, useMemo, useState } from "react";

import Icon from "components/Icon";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import { PercentageOutlined } from "@ant-design/icons";
import { FormListFieldData } from "antd/lib/form/FormList";
import { FormInstance, Input, Select } from "antd";
import {
  ColStyled,
  RowStyled,
  RoundButtonStyle,
  IconButtonContainer,
  PresetGroupSelectStyled,
} from "pages/CreateUpdateEvent/Styled";
import {
  REGEX_NUMBER_FROM_1_TO_100,
  REGEX_POSITIVE_SEPARATE_NUMBER,
} from "utils/constants";
import { onFormatAmount } from "utils/helpers";
import TagSelect from "components/TagSelect";

interface IProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
}

const DynamicPricingItem: React.FC<IProps> = ({
  form,
  fieldsLength,
  groupField,
  remove,
  parentIdx,
}) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const event = useAppSelector((state) => state.event);
  const [isPercentage, setIsPercentage] = useState(false);

  useEffect(() => {
    const actions = form.getFieldValue("actions") || [];
    setIsPercentage(actions[parentIdx].discountType === "PC");
    const formatValue = onFormatAmount(actions[parentIdx].amount.toString());
    actions[parentIdx] = { ...actions[parentIdx], amount: formatValue };
    form.setFieldsValue({
      actions: [...actions],
    });
  }, [form, setIsPercentage]);

  const handleChangeTypeDiscount = useCallback(() => {
    const actions = form.getFieldValue("actions") || [];
    setIsPercentage(actions[parentIdx].discountType === "PC");
    actions[parentIdx] = { ...actions[parentIdx], amount: "" };
    form.setFieldsValue({ actions });
    form.setFields([
      {
        name: ["actions", parentIdx, "amount"],
        errors: [],
      },
    ]);
  }, [form.getFieldValue("actions"), setIsPercentage]);

  const handleChangeType = useCallback(() => {
    const actions = form.getFieldValue("actions") || [];
    actions[parentIdx] = { ...actions[parentIdx], detailIds: [] };
    form.setFieldsValue({ actions });
    form.setFields([
      {
        name: ["actions", parentIdx, "detailIds"],
        errors: [],
      },
    ]);
  }, [form.getFieldValue("actions")]);

  const detailOption = useMemo(() => {
    const actions = form.getFieldValue("actions") || [];
    const option =
      actions[parentIdx].actionCode === "LG" ? event.ledgerGroups : event.items;
    return option;
  }, [form.getFieldValue("actions"), event]);

  const priorityList = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => ({
      value: i + 1,
      lable: i + 1,
    }));
  }, []);

  const onChangeFormat = (value: React.ChangeEvent<HTMLInputElement>) => {
    const { actions } = form.getFieldsValue();
    const formatValue = onFormatAmount(value.currentTarget.value);
    actions[parentIdx] = { ...actions[parentIdx], amount: formatValue };
    form.setFieldsValue({
      actions: [...actions],
    });
    form.validateFields([["actions", parentIdx, "amount"]]);
  };

  return (
    <>
      <RowStyled $hasSpacingRight={fieldsLength <= 1}>
        <ColStyled md={6} xs={6}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "actionCode"]}
            fieldKey={[groupField.fieldKey as number, "actionCode"]}
            rules={[
              {
                required: true,
                message: t("TS.REQUIRED_FIELD", { key: t("TS.TYPE") }),
              },
            ]}
          >
            <Select
              options={event.actions}
              onChange={handleChangeType}
              placeholder={t("TS.SELECT_PLACE", { key: t("TS.TYPE") })}
            />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={6} xs={6}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "detailIds"]}
            fieldKey={[groupField.fieldKey as number, "detailIds"]}
            rules={[
              {
                required: true,
                message: t("TS.REQUIRED_FIELD", { key: t("TS.DETAIL") }),
              },
            ]}
          >
            <PresetGroupSelectStyled
              showArrow
              mode="multiple"
              optionFilterProp="label"
              options={detailOption}
              placeholder={t("TS.SELECT_PLACE", { key: t("TS.DETAIL") })}
              tagRender={(props) => <TagSelect {...props} />}
            />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={5} xs={5}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "discountType"]}
            fieldKey={[groupField.fieldKey as number, "discountType"]}
            rules={[
              {
                required: true,
                message: t("TS.REQUIRED_FIELD", { key: t("TS.TYPE_DISCOUNT") }),
              },
            ]}
          >
            <Select
              onChange={handleChangeTypeDiscount}
              placeholder={t("TS.SELECT_PLACE", { key: t("TS.TYPE_DISCOUNT") })}
              options={event.discountTypes}
            />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={3} xs={3} $maxHeight={65}>
          <FormItemStyled
            $errorWidth={100}
            name={[groupField.name, "amount"]}
            rules={[
              {
                required: true,
                message: t("TS.REQUIRED_FIELD", { key: t("TS.AMOUNT") }),
              },
              {
                pattern: isPercentage
                  ? REGEX_NUMBER_FROM_1_TO_100
                  : REGEX_POSITIVE_SEPARATE_NUMBER,
                message: t(
                  `${
                    isPercentage
                      ? "TS.MAXIMUM_100_WARNING"
                      : "TS.MINIMUM_0_WARNING"
                  }`
                ),
              },
            ]}
          >
            <Input
              min="0"
              autoComplete="off"
              onChange={onChangeFormat}
              style={{ paddingRight: isPercentage ? 20 : 10 }}
            />
          </FormItemStyled>
          {isPercentage ? (
            <PercentageOutlined
              style={{
                fontSize: 12,
                position: "absolute",
                top: 10,
                right: 10,
                color: "var(--gray-color)",
              }}
            />
          ) : null}
        </ColStyled>
        <ColStyled md={3} xs={3}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "priority"]}
            fieldKey={[groupField.fieldKey as number, "priority"]}
            rules={[
              {
                required: true,
                message: t("TS.REQUIRED_FIELD", { key: t("TS.PRIORITY") }),
              },
            ]}
          >
            <Select
              placeholder={t("TS.SELECT_PLACE", { key: t("TS.PRIORITY") })}
            >
              {priorityList.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.lable}
                  </Option>
                );
              })}
            </Select>
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={1} xs={{ span: 1 }}>
          <IconButtonContainer>
            {fieldsLength > 1 ? (
              <RoundButtonStyle
                shape="circle"
                color="red"
                onClick={(): void => {
                  remove(groupField.name);
                }}
                icon={<Icon icon="icon-minus-ico" />}
              />
            ) : null}
          </IconButtonContainer>
        </ColStyled>
      </RowStyled>
    </>
  );
};
export default DynamicPricingItem;
