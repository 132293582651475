import styled from "styled-components";

export const InfoBlockStyled = styled.div`
  margin-bottom: 20px;
`;

export const InfoItemStyled = styled.p`
  margin-bottom: 4px;
`;

export const InfoItemKeyStyled = styled.span`
  margin-right: 15px;
  font-weight: 600;
`;
