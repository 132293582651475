import Icon from "components/Icon";
import { GroupStyled } from "pages/TransactionList/Styled";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DAY_VALUE, GuestPassActionCode } from "utils/constants";
import {
  ButtonWithBottomSpacingStyled,
  ColStyled,
  ColTitleStyled,
  ConditionGroupStyled,
  ConditionsStyled,
  FormListItemStyled,
  FormListStyled,
  RowStyled,
  SubTitleStyled,
} from "../../../CreateUpdateEvent/Styled";
import ActionItem from "./Action";

const ActionGroup: React.FC<ActionsGroupProps> = ({ form }) => {
  const { t } = useTranslation();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const addField = (): void => {
    const actions = form.getFieldValue("actions") || [];
    form.setFieldsValue({
      actions: [
        ...actions,
        {
          actionCode: GuestPassActionCode.GIVE_GUEST_PASS,
          durationType: DAY_VALUE,
        },
      ],
    });
  };

  return (
    <>
      <SubTitleStyled>{t("SS.CREATE_EVENT.PERFORM_ACTION")}</SubTitleStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={8} xs={{ span: 8 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.ACTION")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={6} xs={{ span: 6 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.MEMBER_BENEFIT.QUANTITY")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={8} xs={{ span: 8 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.VALID_DURATION")}
              </ColTitleStyled>
            </ColStyled>
          </RowStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <FormListStyled name="actions">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <ActionItem
                      form={form}
                      remove={remove}
                      groupField={field}
                      parentIdx={parentIdx}
                      fieldsLength={fields.length}
                      setDisabledButton={setDisabledButton}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
          <FormListItemStyled>
            <ButtonWithBottomSpacingStyled
              onClick={(): void => {
                addField();
              }}
              icon={<Icon icon="icon-plus-ico" />}
              type="default"
              size="small"
              disabled={disabledButton}
            >
              {" "}
              {t("SS.CREATE_EVENT.ADD_ACTION")}
            </ButtonWithBottomSpacingStyled>
          </FormListItemStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
    </>
  );
};

export default ActionGroup;
