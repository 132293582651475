import React from "react";
import moment from "moment";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { DATE_FORMAT } from "utils/constants";
import Iframe from "utils/iframe";

import LinkedMember from "models/LinkedMember";
import Column from "models/utils/Column";
import LinkMembershipPayment from "models/LinkMembershipPayment";
import { BlankButtonStyled } from "styled/ButtonStyled";
import { EditOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { EDIT_LINKED_MEMBERSHIP, REMOVE_LINKED_MEMBERSHIP } from "constants/role";

interface TableDataProps {
  data: LinkedMember[];
  columns: Column[];
  columnChild: Column[];
  isLoading: boolean;
  adjustHeightIframe: () => void;
  handleAction?: (type: string, currentLinkedMember: LinkedMember) => void;
  roles?: string[];
}

const TableData = (props: TableDataProps) => {
  const {
    columns,
    data,
    columnChild,
    isLoading,
    handleAction,
    adjustHeightIframe,
    roles = [],
  } = props;
  const { t } = useTranslation();

  const handleEvent = (type: string, currentLinkedMember: LinkedMember) => {
    if (handleAction) {
      handleAction(type, currentLinkedMember);
    }
  };

  const renderColumn = (
    column: Column,
    record: LinkedMember | LinkMembershipPayment
  ) => {
    const value = record[column.dataIndex as keyof typeof record];

    if (
      (column.dataIndex === "lastName" || column.dataIndex === "firstName") &&
      "memberId" in record
    ) {
      return (
        <span
          className="link"
          onClick={() => Iframe.redirectToMemberDetail(record.memberId)}
        >
          {value}
        </span>
      );
    }

    if (column.dataIndex === "amount") {
      return Number(value).toFixed(2);
    }

    if (column.dataIndex === "actionDate") {
      return moment(value).format(DATE_FORMAT);
    }

    if (
      column.dataIndex === "memberStatus" ||
      column.dataIndex === "paymentStatus"
    ) {
      return (
        <span
          className={classNames("text-status", {
            "text-status__ok": value === "OK" || value === "No Arrears",
            "text-status__no": value !== "OK" && value !== "No Arrears",
          })}
        >
          {value}
        </span>
      );
    }
    if (
      (column.dataIndex === "Clone" ||
        column.dataIndex === "Edit" ||
        column.dataIndex === "Remove") &&
      "memberId" in record
    ) {
      return (
        <span
          className="action"
          onClick={() => handleEvent(column.dataIndex, record)}
        >
          <BlankButtonStyled>
            <Tooltip placement="top" title={column.dataIndex}>
              {renderIconAction(column.dataIndex)}
            </Tooltip>
          </BlankButtonStyled>
        </span>
      );
    }
    return value;
  };

  const renderIconAction = (action: string) => {
    if (action === "Edit" && roles.includes(EDIT_LINKED_MEMBERSHIP)) {
      return <EditOutlined />;
    }
    if (action === "Remove" && roles.includes(REMOVE_LINKED_MEMBERSHIP)) {
      return <DeleteOutlined />;
    }
    if (action === "Clone") {
      return <CopyOutlined />;
    }
  };

  const formatColumns = (columns: Column[]) => {
    return columns.map((column) => {
      return {
        ...column,
        title: t(column.title),
        render: (_: any, record: LinkedMember | LinkMembershipPayment) =>
          renderColumn(column, record),
      };
    });
  };

  return (
    <div className="list-member">
      <div className="list-member__table">
        <Table
          loading={isLoading}
          columns={formatColumns(columns)}
          className={classNames("list-member__table-content", {
            empty: data.length === 0,
          })}
          dataSource={data}
          emptyText={t("TS.NO.MEMBER")}
          expandable={{
            onExpandedRowsChange: () => {
              adjustHeightIframe();
            },
            expandedRowRender: (record: LinkedMember) => (
              <Table
                columns={formatColumns(columnChild)}
                dataSource={record.linkMembershipPayments}
                emptyText={t("TS.NO.FEE")}
                className={classNames("sub-table", {
                  empty: record.linkMembershipPayments.length === 0,
                })}
                customStyle={{
                  height: 60,
                  boldTitle: true,
                  centerTitle: true,
                  isSubTable: true,
                  noUpperCase: true,
                }}
              />
            ),
          }}
          rowKey="memberId"
          customStyle={{
            height: 60,
            boldTitle: true,
            noUpperCase: true,
            centerTitle: true,
          }}
        />
      </div>
    </div>
  );
};

TableData.defaultProps = {
  roles: []
}

export default TableData;
