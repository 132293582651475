import {
  DAY_VALUE,
  ITEM_LEDGER_OPTION,
  MEET_CRITERIA,
} from "./../../utils/constants";
import { GIVE_MEMBER_BENEFIT } from "utils/constants";

export const INIT_MEMBER_BENEFIT_EVENT = {
  eventName: "",
  description: "",
  reasonCode: null,
  lookupAndReasonCodeID: null,
  startDate: "",
  endDate: "",
  ruleConditionType: "AND",
  ledgerGroup: ITEM_LEDGER_OPTION[0].value,
  rules: [
    {
      criteria: null,
      operator: null,
      dataType: "",
      values: [],
    },
  ],
  actions: [
    {
      actionCode: GIVE_MEMBER_BENEFIT,
      itemIds: [],
      quantity: "",
      duration: "",
      ledgerGroupIds: [],
      durationType: DAY_VALUE,
    },
  ],
  when: MEET_CRITERIA,
  delayDurationType: DAY_VALUE,
};
