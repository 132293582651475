import { FormInstance, Select } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import Icon from "components/Icon";
import moment from "moment";
import ValueInputType from "pages/CreateUpdateEvent/EventRule/RuleCondition/Condition/ValueInput";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { FormItemStyled } from "styled/FormStyled";
import Swal from "sweetalert2";
import {
  Between,
  IsToday,
  MemberListCriteria,
  MuitipleType,
  Type,
  ValueType,
  WithinEventDuration,
} from "utils/constants";
import {
  ColStyled,
  ColTitleStyled,
  IconButtonContainer,
  RoundButtonStyle,
  RowStyled,
} from "../../../../CreateUpdateEvent/Styled";

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
  setDisableButon: (disable: boolean) => void;
  isFocus: boolean;
}

const ConditionItem: React.FC<ListItemProps> = ({
  groupField,
  parentIdx,
  remove,
  form,
  fieldsLength,
  setDisableButon,
  isFocus,
}) => {
  const event = useAppSelector((state) => state.event);
  const { id } = useParams<{ id: string }>();
  const eventId = id || event.eventId;
  const { t } = useTranslation();
  const [criteria, setCriteria] = useState<any>();
  const { Option } = Select;

  useEffect(() => {
    setTimeout(() => {
      const { rules } = form.getFieldsValue();
      if (rules) {
        setCriteria(rules[parentIdx] || null);
      }
      if (rules && valueType === ValueType.UpLoadFile) {
        setDisableButon(true);
      }
    }, 200);
  }, [form, parentIdx]);

  const valueType = useMemo(() => {
    const { rules } = form.getFieldsValue();
    let value = "";
    if (rules) {
      const criteria = rules[parentIdx];
      const requiredCondition = criteria && criteria.operator;
      const multiOptionType =
        requiredCondition && MuitipleType.includes(criteria.criteria);
      const inputType =
        requiredCondition &&
        !multiOptionType &&
        criteria.dataType === Type.Double &&
        criteria.operator !== Between;
      const intInputType =
        requiredCondition &&
        !multiOptionType &&
        criteria.dataType === Type.Int &&
        criteria.operator !== Between;
      const inputRangeType =
        requiredCondition &&
        !multiOptionType &&
        criteria.dataType === Type.Double &&
        criteria.operator === Between;
      const dateType =
        requiredCondition &&
        !multiOptionType &&
        criteria.dataType === Type.Date &&
        criteria.operator !== Between;
      const dateRangeType =
        requiredCondition &&
        !multiOptionType &&
        criteria.dataType === Type.Date &&
        criteria.operator === Between;
      const upLoadFile = criteria.criteria === MemberListCriteria;
      const noValue =
        requiredCondition &&
        !multiOptionType &&
        criteria.dataType === Type.Date &&
        [WithinEventDuration, IsToday].includes(criteria.operator);

      if (multiOptionType) {
        value = ValueType.MultiOptionType;
      }
      if (inputType) {
        value = ValueType.InputType;
      }
      if (intInputType) {
        value = ValueType.IntInputType;
      }
      if (inputRangeType) {
        value = ValueType.InputRangeType;
      }
      if (dateType) {
        value = ValueType.DateType;
      }
      if (dateRangeType) {
        value = ValueType.DateRangeType;
      }
      if (upLoadFile) {
        value = ValueType.UpLoadFile;
      }
      if (noValue) {
        value = ValueType.NoValueType;
      }
    }
    return value;
  }, [form.getFieldValue("rules"), criteria]);

  const handleChangeCriteria = (value: any) => {
    const rules = form.getFieldValue("rules") || [];
    const getCriteria = event.criterias.filter(
      (criteria: any) => criteria.criteriaName === value
    );

    if (getCriteria[0].criteriaName === MemberListCriteria) {
      if (rules.length === 1) {
        rules[parentIdx] = {
          ...rules[parentIdx],
          operator: "Is",
          dataType: Type.String,
        };
        form.setFieldsValue({ rules });
        return setDisableButon(true);
      }
      Swal.fire({
        title: t("SS.CREATE_EVENT.WARNING_WHEN_SELECT_MEMBER_LIST_CRITERIA"),
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: t("SS.REMOVE"),
        customClass: {
          title: "swal2-title-custom",
          confirmButton: "swal2-confirm-custom",
          cancelButton: "swal2-cancel-custom",
          icon: "swal2-warning-custom",
        },
      }).then((result: any) => {
        if (result.isConfirmed) {
          form.setFieldsValue({
            rules: [{ criteria: value, operator: "Is", dataType: Type.String }],
          });
          setCriteria(form.getFieldValue("rules")[0]);
          setDisableButon(true);
        } else {
          rules[parentIdx] = { ...rules[parentIdx], criteria: null };
          form.setFieldsValue({ rules });
          setCriteria(form.getFieldValue("rules"));
        }
      });
    } else {
      rules[parentIdx] = {
        ...rules[parentIdx],
        operator: null,
        values: [],
        dataType: getCriteria[0].dataType,
      };
      form.setFieldsValue({ rules });
      setCriteria(form.getFieldValue("rules")[parentIdx]);
      setDisableButon(false);
    }
  };

  const handleChangeOperator = () => {
    const rules = form.getFieldValue("rules") || [];
    let values: any = [];
    if (rules[parentIdx].dataType === "datetime") {
      values = moment(new Date());
    }
    if (
      rules[parentIdx].dataType === "datetime" &&
      rules[parentIdx].operator === Between
    ) {
      values = [moment(new Date()), moment(new Date())];
    }
    rules[parentIdx] = { ...rules[parentIdx], values: values };
    form.setFieldsValue({ rules });
    setCriteria(form.getFieldValue("rules")[parentIdx]);
  };

  const operatorOptions = useMemo(() => {
    let operatorOptions = [];
    if (criteria) {
      const seachCriteria = event.criterias.filter(
        (option: CriteriaOptionProps) =>
          option.criteriaName === criteria?.criteria
      );
      operatorOptions =
        seachCriteria.length && seachCriteria[0].operators.length
          ? seachCriteria[0].operators.map((x: any) => ({
              value: x.operatorName,
              label: x.displayName,
            }))
          : [];
    }
    return operatorOptions;
  }, [form.getFieldValue("rules"), event, criteria]);

  const usedCriterias = useMemo(() => {
    const { rules } = form.getFieldsValue();
    return rules ? rules.map((criteria: any) => criteria.criteria) : [];
  }, [form.getFieldValue("rules")]);

  const removeRule = () => {
    remove(groupField.name);
  };

  return (
    <>
      <RowStyled $hasSpacingRight={fieldsLength <= 1}>
        <ColStyled md={8} xs={8}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "criteria"]}
            fieldKey={[groupField.fieldKey as number, "criteria"]}
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.CRITERIA"),
                }),
              },
            ]}
          >
            <Select
              autoFocus={isFocus}
              placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                key: t("SS.CREATE_EVENT.CRITERIA"),
              })}
              onChange={handleChangeCriteria}
            >
              {event.criterias &&
                event.criterias.map(
                  (criteria: CriteriaOptionProps, key: number) => {
                    const isDisable = usedCriterias.includes(criteria.value);
                    return (
                      <Option
                        key={key}
                        value={criteria.value}
                        style={{ background: `${isDisable ? "#dddddd" : ""}` }}
                        disabled={isDisable}
                      >
                        {criteria.label}
                      </Option>
                    );
                  }
                )}
            </Select>
          </FormItemStyled>
        </ColStyled>
        {valueType === ValueType.UpLoadFile ? (
          <ColStyled md={7} xs={7} style={{ marginTop: 6 }}>
            <ColTitleStyled $noMarginLeft={true}>
              <span>{t("SS.CREATE_EVENT.CSV_SAMPLE")}</span>
              <Link to="/download/InsertMembers.csv" target="_blank" download>
                {" "}
                {t("SS.CREATE_EVENT.DOWNLOAD")}
              </Link>
            </ColTitleStyled>
          </ColStyled>
        ) : (
          <ColStyled md={7} xs={7}>
            <FormItemStyled
              {...groupField}
              name={[groupField.name, "operator"]}
              fieldKey={[groupField.fieldKey as number, "operator"]}
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.OPERATOR"),
                  }),
                },
              ]}
            >
              <Select
                placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                  key: t("SS.CREATE_EVENT.OPERATOR"),
                })}
                options={operatorOptions || []}
                onChange={handleChangeOperator}
              />
            </FormItemStyled>
          </ColStyled>
        )}
        <ColStyled md={8} xs={8}>
          <ValueInputType
            typeInput={valueType}
            form={form}
            groupField={groupField}
            parentIdx={parentIdx}
          />
        </ColStyled>
        {fieldsLength > 1 ? (
          <ColStyled md={1} xs={1}>
            <IconButtonContainer>
              <RoundButtonStyle
                shape="circle"
                color="red"
                onClick={removeRule}
                icon={<Icon icon="icon-minus-ico" />}
              />
            </IconButtonContainer>
          </ColStyled>
        ) : null}
      </RowStyled>
    </>
  );
};

export default ConditionItem;
