import { Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import { FormContainerStyled } from "pages/CreateUpdateEvent/Styled";
import { InputTitleStyled } from "pages/ManageEventList/Styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateExpiryDate } from "services/transaction";
import { useAppSelector } from "store";
import { getReasonTypeAction } from "store/reducers/Event/actions";
import { FormItemStyled } from "styled/FormStyled";
import { ModalStyled, ModalTitleStyled } from "styled/ModalStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import { DATE_FORMAT, NotificationType } from "utils/constants";
import { showNotification } from "utils/helpers";
import { InputStyled, ModalContent } from "../Styled";
import "../styles.css";

interface IProps {
  isOpenModal: boolean;
  setOpenModal: (open: boolean) => void;
  memberCreditId: string;
  operatorId: string;
  expiryDate: string;
}

const InitialValue = {
  changeReasonText: "",
  changeReasonCode: null,
  expiredDate: moment(new Date()),
};

const ChangeExpiryDate: React.FC<IProps> = ({
  isOpenModal,
  setOpenModal,
  memberCreditId,
  operatorId,
  expiryDate,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reasonType } = useAppSelector((state) => state.event);
  const [showOtherInput, setIsShowOtherInput] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!reasonType.length) {
      dispatch(getReasonTypeAction("EX"));
    }
    form.setFieldsValue({
      ...InitialValue,
      expiredDate: moment(expiryDate).utcOffset(0).isAfter(moment(new Date()))
        ? moment(expiryDate).utcOffset(0)
        : moment(new Date()),
    });
  }, [expiryDate]);

  const handleOk = async (): Promise<void> => {
    const values = await form.validateFields();
    const { changeReasonText, changeReasonCode, expiredDate } = values;
    setLoading(true);
    const dataSubmit = {
      changeReasonText: changeReasonText ? changeReasonText : null,
      changeReasonCode: changeReasonCode !== "Others" ? changeReasonCode : null,
      expiredDate: expiredDate
        .set({ hour: 23, minute: 59, second: 59 })
        .utcOffset(0),
      memberCreditId,
      operatorId,
    };
    await updateExpiryDate(dataSubmit).then((res) => {
      form.resetFields();
      setOpenModal(false);
      setIsShowOtherInput(false);
      setLoading(false);
      if (res.isSuccess) {
        showNotification(
          t("TS.SUCCESS"),
          t("SS.CHANGE_EXPIRY_DATE.UPDATE_SUCCESS_MESSAGE"),
          NotificationType.success,
          2
        );
      } else {
        showNotification(
          t("TS.ERROR"),
          res.message || t("SS.CHANGE_EXPIRY_DATE.UPDATE_FAIL_MESSAGE"),
          NotificationType.error
        );
      }
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
    setIsShowOtherInput(false);
  };

  const disabledDateStart = (current: any) => {
    return current && current < moment(new Date(), DATE_FORMAT);
  };

  const onChangeReason = (option: string) => {
    setIsShowOtherInput(option === "Others" ? true : false);
  };

  if(loading){
     return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  return (
    <>
      <ModalStyled
        title={
          <ModalTitleStyled>
            {t("SS.CHANGE_EXPIRY_DATE.TITLE")}
          </ModalTitleStyled>
        }
        visible={isOpenModal}
        onOk={handleOk}
        width="400px"
        okText={t("SS.SAVE")}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <ModalContent>{t("SS.CHANGE_EXPIRY_DATE.CONTENT")}</ModalContent>
        <Form
          name="basic"
          layout="vertical"
          hideRequiredMark
          initialValues={{
            ...InitialValue,
            expiredDate: moment(expiryDate).utcOffset(0),
          }}
          form={form}
        >
          <FormContainerStyled style={{ marginTop: 20 }}>
            <Row gutter={24}>
              <Col className="d-flex" md={{ span: 7 }} xs={{ span: 7 }}>
                <InputTitleStyled>
                  {t("SS.MEMBER_CREDIT_SPENDING.EXPIRED_DATE")}
                </InputTitleStyled>
              </Col>
              <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
                <FormItemStyled
                  name="expiredDate"
                  $color="var(--primary-color)"
                >
                  <DatePicker
                    size="middle"
                    autoFocus
                    disabledDate={disabledDateStart}
                    format={DATE_FORMAT}
                    allowClear={false}
                  />
                </FormItemStyled>
              </Col>
              <Col className="d-flex" md={{ span: 7 }} xs={{ span: 7 }}>
                <InputTitleStyled>{t("SS.REASON")}</InputTitleStyled>
              </Col>
              <Col className="gutter-row" md={{ span: 16 }} xs={{ span: 16 }}>
                <FormItemStyled
                  name="changeReasonCode"
                  $color="var(--primary-color)"
                  rules={[
                    {
                      required: true,
                      message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                        key: t("SS.CREATE_EVENT.REASON_TYPE"),
                      }),
                    },
                  ]}
                >
                  <Select
                    options={reasonType}
                    onChange={onChangeReason}
                    placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                      key: t("SS.REASON"),
                    })}
                  />
                </FormItemStyled>
              </Col>
              {showOtherInput ? (
                <>
                  <Col
                    className="d-flex"
                    md={{ span: 7 }}
                    xs={{ span: 7 }}
                  ></Col>
                  <Col
                    className="gutter-row"
                    md={{ span: 16 }}
                    xs={{ span: 16 }}
                  >
                    <FormItemStyled
                      name="changeReasonText"
                      $color="var(--primary-color)"
                      rules={[
                        {
                          required: true,
                          message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                            key: t("SS.ADD_MEMBER_CREDIT.OTHER_REASON"),
                          }),
                        },
                        {
                          min: 3,
                          message: t("SS.EVENT_MANAGEMENT.MINIMUM_INPUT", {
                            key: 3,
                          }),
                        },
                        {
                          max: 100,
                          message: t("SS.EVENT_MANAGEMENT.MAXIMUM_INPUT", {
                            key: 100,
                          }),
                        },
                      ]}
                    >
                      <InputStyled
                        autoFocus
                        autoComplete="off"
                        placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", {
                          key: t("SS.ADD_MEMBER_CREDIT.OTHER_REASON"),
                        })}
                      />
                    </FormItemStyled>
                  </Col>
                </>
              ) : null}
            </Row>
          </FormContainerStyled>
        </Form>
      </ModalStyled>
    </>
  );
};
export default ChangeExpiryDate;
