import { GateProps, GetMasterData } from "pages/ConfigGateRule/types";
import {
  GET_CHOSEN_GATES,
  GET_CLUB_GROUP_MASTER_DATA,
  GET_CURRENT_CLUB,
  GET_MASTER_DATA,
  SET_CHOSEN_GATES,
  SET_CLUB_GROUP_MASTER_DATA,
  SET_CURRENT_CLUB,
  SET_MASTER_DATA,
} from "./types";

interface InitialStateProps {
  loading: boolean;
  clubGroup: OptionProps[] | null;
  chosenClub: string;
  masterData: GetMasterData | null;
  chosenGates: GateProps[];
}

interface IActionProps {
  type: string;
  data: any;
}
const initialState: InitialStateProps = {
  loading: true,
  clubGroup: null,
  chosenClub: "",
  masterData: null,
  chosenGates: [],
};

export default (state = initialState, action: IActionProps) => {
  switch (action.type) {
    case GET_CLUB_GROUP_MASTER_DATA:
      return { ...state, loading: true };
    case SET_CLUB_GROUP_MASTER_DATA:
      return { ...state, loading: false, clubGroup: customClubGroup(action.data) };
    case GET_CURRENT_CLUB:
      return { ...state, loading: true };
    case SET_CURRENT_CLUB:
      return { ...state, loading: false, chosenClub: action.data };
    case GET_MASTER_DATA:
      return { ...state, loading: true };
    case SET_MASTER_DATA:
      return { ...state, loading: false, masterData: action.data };
    case GET_CHOSEN_GATES:
      return { ...state, loading: true };
    case SET_CHOSEN_GATES:
      return { ...state, loading: false, chosenGates: action.data };
    default:
      return state;
  }
};

const customClubGroup = (clubs: ClubGroup[]): OptionProps[] => {
  const sortedClub = clubs.sort((club1, club2) =>
    club1.clubGroupName > club2.clubGroupName ? 1 : -1
  );
  return sortedClub.map((club) => ({
    label: club.clubGroupName,
    value: club.clubGroupId.toString(),
  }));
};
