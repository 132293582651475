import { FormInstance, Select } from "antd";
import Icon from "components/Icon";
import { MEMBER_TYPE } from "constants/Criteria";
import Criteria from "models/Criteria";
import ConditionItem from "pages/ConfigGateRule/Condition";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { FormItemStyled } from "styled/FormStyled";
import { ANDOR } from "utils/constants";
import {
  ButtonWithBottomSpacingStyled,
  ColTitleStyled,
  ConditionsStyled,
  FormContainerStyled,
  FormListItemStyled,
  SessionTitleStyled,
  SubTitleStyled,
} from "../../CreateUpdateEvent/Styled";
import { RowStyled, FormListStyled, GroupStyled, ColStyled } from "../Styled";

interface IProps {
  form: FormInstance;
}

const RuleSetting: React.FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const [criteriaExist, setCriteriaExist] = useState<string[]>([]);
  const { masterData } = useAppSelector((state) => state.gate);

  const addField = (): void => {
    const conditions = form.getFieldValue("Conditions") ?? [];
    form.setFieldsValue({
      Conditions: [...conditions, {}],
    });
  };

  const updateCriteriaExist = (criteriaList: string[]) => {
    setCriteriaExist(criteriaList.filter((criteria) => criteria));
  };

  return (
    <>
      <SessionTitleStyled>
        {t("SS.CREATE_EVENT.RULE_SETTINGS")}
      </SessionTitleStyled>
      <FormContainerStyled>
        <ConditionsStyled>
          <RowStyled>
            <ColStyled md={{ span: 4 }} xs={{ span: 4 }}>
              <FormItemStyled name="RuleType">
                <Select options={ANDOR} optionFilterProp="label" />
              </FormItemStyled>
            </ColStyled>
            <ColStyled md={{ span: 20 }} xs={{ span: 20 }}>
              <SubTitleStyled $padding={0} $margin={0}>
                {t("SS.CREATE_EVENT.FOLLOWING_CONDITION")}
              </SubTitleStyled>
            </ColStyled>
          </RowStyled>
        </ConditionsStyled>
        <ConditionsStyled>
          <RowStyled>
            <ColStyled md={7} xs={{ span: 7 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.CRITERIA")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={7} xs={{ span: 7 }}>
              <ColTitleStyled
                $noMarginLeft={true}
                style={{ marginLeft: "2px" }}
              >
                {t("SS.CREATE_EVENT.OPERATOR")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={10} xs={{ span: 10 }}>
              <ColTitleStyled
                $noMarginLeft={true}
                style={{ marginLeft: "5px" }}
              >
                {t("SS.CREATE_EVENT.VALUE")}
              </ColTitleStyled>
            </ColStyled>
          </RowStyled>
        </ConditionsStyled>
        <>
          <FormListStyled name="Conditions">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <ConditionItem
                      remove={remove}
                      groupField={field}
                      index={parentIdx}
                      fieldsLength={fields.length}
                      form={form}
                      criteriaExist={criteriaExist}
                      updateCriteriaExist={updateCriteriaExist}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
          <FormListItemStyled>
            <ButtonWithBottomSpacingStyled
              onClick={() => addField()}
              disabled={
                masterData
                  ? criteriaExist.length ===
                    masterData.criterias.filter(
                      (criteria: Criteria) =>
                        criteria.criteriaName !== MEMBER_TYPE,
                    ).length
                  : false
              }
              icon={<Icon icon="icon-plus-ico" />}
              type="default"
              size="small"
            >
              {t("SS.CREATE_EVENT.ADD_CONDITION")}
            </ButtonWithBottomSpacingStyled>
          </FormListItemStyled>
        </>
      </FormContainerStyled>
    </>
  );
};

export default RuleSetting;
