import { Popover } from "antd";
import Table from "components/Table";
import {
  DetailsButtonStyled,
  DetailsTextStyled,
} from "pages/ManageEventList/Styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { getGateRule } from "services/gate";
import { useAppSelector } from "store";
import { PopoverContentStyled, PopoverTextStyled } from "styled/PopoverStyled";
import { MANAGE_GATE_PAGING } from "utils/constants";
import { routeUrls } from "utils/routes";
import ManageGateFilter from "./SearchFilter";
import { RuleListContainerStyled, TextField } from "./Styled";

const ManageGate = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<GateRule[]>([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({
    queryKey: "createdDate",
    operation: "desc",
  });
  const history = useNavigate();
  const { chosenClub } = useAppSelector((state) => state.gate);
  const { operatorId } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "0",
    };
  }, []);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      if (chosenClub) {
        const res = await getGateRule(
          chosenClub,
          sort.queryKey,
          sort.operation
        );
        setData(res.map((item) => ({ ...item, key: item.ruleId })));
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  }, [chosenClub, sort]);

  useEffect(() => {
    getData();
  }, [getData]);

  const goToDetail = (id: string) => {
    history(
      routeUrls.viewGateRuleDetail
        .replace(":id", id!)
        .replace(":createdBy", operatorId)
    );
  };

  const hoverContent = (ruleName: string) => {
    return <PopoverContentStyled>{ruleName}</PopoverContentStyled>;
  };

  const columns = useMemo(
    () => [
      {
        title: t("TS.MANAGE_GATE.RULE_NAME"),
        dataIndex: "ruleName",
        width: 150,
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        render: (ruleName: string) => (
          <Popover content={hoverContent(ruleName)} placement="topLeft">
            <PopoverTextStyled $width={100}>{ruleName}</PopoverTextStyled>
          </Popover>
        ),
      },

      {
        title: t("TS.MANAGE_GATE.GATE_NAME"),
        dataIndex: "gateNames",
        width: 200,
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        render: (gateName: string) => <>{gateName}</>,
      },
      {
        title: t("TS.MANAGE_GATE.CLUB"),
        dataIndex: "clubGroupName",
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        width: 150,
        render: (clubGroupName: string) => <>{clubGroupName}</>,
      },
      {
        title: t("TS.MANAGE_GATE.STATUS"),
        dataIndex: "status",
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        width: 150,
        render: (status: string) => <TextField>{status}</TextField>,
      },

      {
        title: "",
        dataIndex: "",
        width: 100,
        render: (item: GateRule) => (
          <TextField>
            <DetailsButtonStyled>
              <DetailsTextStyled
                onClick={() => goToDetail(item.ruleId.toString())}
              >
                {t("SS.DETAILS")}
              </DetailsTextStyled>
            </DetailsButtonStyled>
          </TextField>
        ),
      },
    ],
    [t]
  );

  const onSort = (_: any, __: any, sort: any) => {
    const order = sort?.order;
    if (order === "ascend") {
      const sortValue = { queryKey: sort.field, operation: "asc" };
      setSort(sortValue);
    } else {
      const sortValue = { queryKey: sort.field, operation: "desc" };
      setSort(sortValue);
    }
  };

  return (
    <>
      <ManageGateFilter />
      <RuleListContainerStyled>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          customStyle={{
            height: Number.MAX_SAFE_INTEGER,
            boldTitle: true,
            noUpperCase: true,
            centerTitle: true,
          }}
          paging={{
            ...MANAGE_GATE_PAGING,
            pageSize: 0,
            setPageIndex: () => {},
            setPageSize: () => {},
          }}
          onTableChange={onSort}
        />
      </RuleListContainerStyled>
    </>
  );
};

export default ManageGate;
