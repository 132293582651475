import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ProcessMemberDiv } from './Styled';
import { SearchButtonStyle } from 'pages/ManageEventList/Styled';

import Search from './Search';
import Table from 'components/Table';

import Column from 'models/utils/Column';

import { COLUMNS } from './constants';
import useProcessMember from './useProcessMember';

const ProcessMember = () => {
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    pagingData,
    clubs,
    search,
    resetSearch,
    onSearch,
    handleSearch,
    handleSetPageIndex,
    handleSetPageSize,
    archiveMember,
    convertMember,
    onSort,
    handleKeyPress,
  } = useProcessMember();

  const renderColumn = (column: Column, record: any) => {
    const value = record[column.dataIndex as keyof typeof record];
    if (
      ['lastName', 'firstName', 'plan', 'club', 'email'].includes(
        column.dataIndex,
      )
    ) {
      return (
        <Tooltip
          overlayClassName='description-tooltip'
          placement='top'
          title={value}
        >
          <span
            className={classNames('process-member__ellipsis', {
              'process-member__column-name':
                column.dataIndex === 'lastName' ||
                column.dataIndex === 'firstName',
              'process-member__column-email': column.dataIndex === 'email',
              'process-member__column-plan': column.dataIndex === 'plan',
              'process-member__column-club': column.dataIndex === 'club',
            })}
          >
            {value}
          </span>
        </Tooltip>
      );
    }

    if (column.dataIndex === 'convert' && record.status === 'Uncompleted') {
      return (
        <a onClick={() => archiveMember(record.key)} className='action'>
          {t('TS.ARCHIVE')}
        </a>
      );
    }

    if (column.dataIndex === 'convert' && record.status === 'Paid') {
      return ( 
        <div className='process-member__action'>
          <a onClick={() => convertMember(record.key)} className='action'>
            {t('TS.CONVERT')}
          </a>
          <a onClick={() => archiveMember(record.key)} className='action'>
            {t('TS.ARCHIVE')}
          </a>
        </div>
      );
    }

    if (column.dataIndex === 'status') {
      return (
        <span
          className={classNames({
            'text-status__ok': record.status === 'Paid',
          })}
        >
          {value}
        </span>
      );
    }

    return (
      <span
        className={classNames({
          'process-member__column-mm-number':
            column.dataIndex === 'membershipNumber',
        })}
      >
        {value}
      </span>
    );
  };

  return (
    <ProcessMemberDiv>
      <div className='process-member'>
        <Search
          clubs={clubs}
          search={search}
          onSearch={onSearch}
          onKeyPress={handleKeyPress}
        />
        <div className='process-member__search-button'>
          <SearchButtonStyle
            onClick={resetSearch}
            className='process-member__search-clear'
          >
            {t('TS.CLEAR')}
          </SearchButtonStyle>
          <SearchButtonStyle onClick={() => handleSearch()}>
            {t('SS.SEARCH')}
          </SearchButtonStyle>
        </div>
        <div className='process-member__table'>
          <Table
            loading={isLoading}
            columns={COLUMNS.map((column) => ({
              ...column,
              title: t(column.title),
              render: (_: any, record: any) => renderColumn(column, record),
            }))}
            dataSource={data}
            paging={{
              ...pagingData,
              setPageIndex: handleSetPageIndex,
              setPageSize: handleSetPageSize,
            }}
            onTableChange={onSort}
          />
        </div>
      </div>
    </ProcessMemberDiv>
  );
};

export default ProcessMember;
