interface DefaultInfo {
  [key: string]: string;
}

let accessToken = "";
let operatorId = "";
let originPath = "";
let oneTimeToken: string;
let accountInfo: DefaultInfo;

export const getAccessToken = (): string | null => accessToken;

export const setAccessToken = (token: string): void => {
  accessToken = token;
};

export const getOperatorId = (): string | null => operatorId;

export const setOperatorId = (id: string): void => {
  operatorId = id;
};

export const removeAccessToken = (): void => {
  accessToken = "";
};

export const getDefaultUserInfo = (): DefaultInfo => accountInfo;

export const setDefaultUserInfo = (info: DefaultInfo): void => {
  accountInfo = info;
};

export const setOneTimeToken = (token: string): void => {
  oneTimeToken = token;
};

export const getOneTimeToken = (): string => oneTimeToken;
