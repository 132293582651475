import React, { ReactNode } from 'react';

import GroupButtonsStyled from './Styled';

interface GroupButtonsProps {
  children: ReactNode;
  position: string | undefined;
  style?: object;
}

const GroupButtons: React.FC<GroupButtonsProps> = ({ children, position, style }) => (
  <GroupButtonsStyled position={position} style={style}>
    {children}
  </GroupButtonsStyled>
);

export default GroupButtons;
