import { Col, DatePicker, FormInstance, Input, Row, Select } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { FormItemStyled } from "styled/FormStyled";
import { DATE_FORMAT } from "utils/constants";
import {
  DashedStyled,
  FormContainerStyled,
  InputTitleStyled,
  TextareaCustomStyled,
} from "../../CreateUpdateEvent/Styled";

interface IProps {
  form: FormInstance;
  onFromDateChange: (date: any, _: string, isChangeEndDate: boolean) => void ;
}
const EventInformation: React.FC<IProps> = ({ form, onFromDateChange}) => {
  const { t } = useTranslation();
  const { reasons } = useAppSelector((state) => state.event);



  const disabledDateStart = (current: any) => {
    return (
      current && current < moment(new Date(), DATE_FORMAT).subtract(1, "days")
    );
  };

  const handleUpdateReasonCode = (_: never, item: any) => {
    form.setFieldsValue({ reasonCode: item.code });
  };

  return (
    <>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
            <InputTitleStyled>
              {t("SS.CREATE_EVENT.EVENT_NAME")}
            </InputTitleStyled>
          </Col>
          <Col className="gutter-row" md={{ span: 20 }} xs={{ span: 20 }}>
            <FormItemStyled
              name="eventName"
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.EVENT_NAME"),
                  }),
                },
                {
                  min: 2,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("SS.CREATE_EVENT.EVENT_NAME"),
                  }),
                },
                {
                  max: 100,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("SS.CREATE_EVENT.EVENT_NAME"),
                  }),
                },
              ]}
            >
              <Input autoFocus autoComplete="off" />
            </FormItemStyled>
          </Col>
        </Row>
      </FormContainerStyled>

      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
            <InputTitleStyled>
              {t("SS.CREATE_EVENT.DESCRIPTION")}
            </InputTitleStyled>
          </Col>
          <Col className="gutter-row" md={{ span: 20 }} xs={{ span: 20 }}>
            <FormItemStyled
              name="description"
              rules={[
                {
                  min: 2,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("SS.CREATE_EVENT.DESCRIPTION"),
                  }),
                },
                {
                  max: 500,
                  message: t("SS.CREATE_EVENT.INVALID_FORMAT", {
                    key: t("SS.CREATE_EVENT.DESCRIPTION"),
                  }),
                },
              ]}
            >
              <TextareaCustomStyled autoComplete="off" />
            </FormItemStyled>
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
            <InputTitleStyled>
              {t("SS.CREATE_EVENT.REASON_TYPE")}
            </InputTitleStyled>
          </Col>
          <Col className="gutter-row" md={{ span: 20 }} xs={{ span: 20 }}>
            <FormItemStyled name="reasonCode" hidden noStyle>
              <Input type="hidden"></Input>
            </FormItemStyled>
            <FormItemStyled
              name="lookupAndReasonCodeID"
              rules={[
                {
                  required: true,
                  message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                    key: t("SS.CREATE_EVENT.REASON_TYPE"),
                  }),
                },
              ]}
            >
              <Select options={reasons} onChange={handleUpdateReasonCode} />
            </FormItemStyled>
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
            <InputTitleStyled>
              {t("SS.CREATE_EVENT.START_DATE")}
            </InputTitleStyled>
          </Col>
          <Col className="gutter-row" md={{ span: 8 }} xs={{ span: 8 }}>
            <FormItemStyled name="startDate">
              <DatePicker
                size="middle"
                format={DATE_FORMAT}
                allowClear={false}
                onChange={(e) => onFromDateChange(e, '', false)}
                disabledDate={disabledDateStart}
              />
            </FormItemStyled>
          </Col>
          <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
            <InputTitleStyled>{t("SS.CREATE_EVENT.END_DATE")}</InputTitleStyled>
          </Col>
          <Col className="gutter-row" md={{ span: 8 }} xs={{ span: 8 }}>
            <FormItemStyled name="endDate">
              <DatePicker
                size="middle"
                format={DATE_FORMAT}
                allowClear={false}
                onChange={(e) => onFromDateChange(e, '', true)}
                disabledDate={(d) =>
                  !d ||
                  d.isBefore(
                    moment(form.getFieldValue("startDate"), DATE_FORMAT)
                  )
                }
              />
            </FormItemStyled>
          </Col>
        </Row>
      </FormContainerStyled>

      <DashedStyled />
    </>
  );
};

export default EventInformation;
