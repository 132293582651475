import { GetMasterData } from "pages/ConfigGateRule/types";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { getClubGroup, getMasterData } from "services/gate";
import { setClubGroupMasterDataAction, setMasterDataAction } from "store/reducers/Gate/action";
import { GET_CLUB_GROUP_MASTER_DATA, GET_MASTER_DATA } from "store/reducers/Gate/types";

function* getClubGroupMasterData() {
  const data: ClubGroup[] = yield call(getClubGroup);
  yield put (setClubGroupMasterDataAction(data));
}

function* getMasterDataGateRule(){
  const data: GetMasterData = yield call(getMasterData);
  yield put(setMasterDataAction(data));
}

function* watchFetchProducts(){
  yield all([
    takeEvery(GET_CLUB_GROUP_MASTER_DATA, getClubGroupMasterData),
    takeEvery(GET_MASTER_DATA, getMasterDataGateRule),
  ])
}

export default watchFetchProducts;