import React from 'react';
import Icon from 'components/Icon';
import { Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { FormItemStyled } from 'styled/FormStyled';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColStyled, IconButtonContainer, RoundButtonStyle, RowStyled } from '../Styled';
import { REGEX_POSITIVE_NUMBER, REGEX_POSITIVE_SEPARATE_NUMBER, DATE_OPTION } from 'utils/constants';
import { onFormatAmount } from 'utils/helpers';

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
}

const ActionItem: React.FC<ListItemProps> = ({ groupField, remove, fieldsLength, form, parentIdx }) => {
  const { t } = useTranslation();

  const onChangeFormat = (value: React.ChangeEvent<HTMLInputElement>) => {
    const { actions } = form.getFieldsValue();
    const formatValue = onFormatAmount(value.currentTarget.value);
    actions[parentIdx] = { ...actions[parentIdx], amount: formatValue };
    form.setFieldsValue({
      actions: [
        ...actions,
      ],
    });
  }

  

  return (
    <>
      {parentIdx === 0 ? null : (
        <RowStyled $hasSpacingRight={fieldsLength <= 1}>
          <ColStyled md={2} xs={2}></ColStyled>
          <ColStyled md={10} xs={10}>
            <FormItemStyled
              name={[groupField.name, 'amount']}
              rules={[
                { required: true, message: t("SS.CREATE_EVENT.REQUIRED_FIELD", { key: t("SS.CREATE_EVENT.AMOUNT") }) },
                { pattern: REGEX_POSITIVE_SEPARATE_NUMBER, message: t("SS.CREATE_EVENT.INVALID_FORMAT", { key: t("SS.CREATE_EVENT.AMOUNT") }) }
              ]}>
              <Input
                min="0"
                autoComplete="off"
                placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", { key: t("SS.CREATE_EVENT.AMOUNT") })}
                onChange={onChangeFormat} />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={5} xs={5}>
            <FormItemStyled
              name={[groupField.name, 'validDuration']}
              rules={[
                { required: true, message: t("SS.CREATE_EVENT.REQUIRED_FIELD", { key: t("SS.CREATE_EVENT.VALID_DURATION") }) },
                { pattern: REGEX_POSITIVE_NUMBER, message: t("SS.CREATE_EVENT.INVALID_FORMAT", { key: t("SS.CREATE_EVENT.VALID_DURATION") }) }
              ]}>
              <Input
                min="0"
                placeholder={t("SS.CREATE_EVENT.INPUT_PLACE", { key: t("SS.CREATE_EVENT.VALID_DURATION") })}
                autoComplete="off"
                type="number" />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={4} xs={4} style={{ paddingRight: 0 }} >
            <FormItemStyled
              {...groupField}
              name={[groupField.name, 'durationType']}
              fieldKey={[groupField.fieldKey as number, 'type']} >
              <Select options={DATE_OPTION} />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={2} xs={2}>
            <IconButtonContainer>
              {fieldsLength > 2 ? (
                <RoundButtonStyle shape="circle" color="red" onClick={(): void => {
                  remove(groupField.name);
                }}
                  icon={<Icon icon="icon-minus-ico" />} />
              ) : null}
            </IconButtonContainer>
          </ColStyled>
        </RowStyled>
      )}
    </>
  );
};

export default ActionItem;
