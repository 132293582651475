import { notification } from "antd";
import moment from "moment";
import { getDefaultUserInfo } from "services/common/accessToken";
import { CURRENCY_FORMAT_BY_COUTRY, DATE_FORMAT } from "./constants";

export const formatNumber = (
  num: number,
  format = {
    languageTag: "en-US",
    currency: "USD",
    prefix: "$",
  }
) => {
  return new Intl.NumberFormat(format.languageTag, {
    style: "currency",
    currency: format.currency,
  })
    .format(num)
    .replace(format.prefix, "")
    .trim();
};

export const formatUTCTime = (time: string) =>
  time.indexOf("Z") > -1 ? time : `${time}Z`;

export const getCurrentCurrency = () => {
  const country = getDefaultUserInfo()?.domain || "th";
  return CURRENCY_FORMAT_BY_COUTRY[country];
};

export const sortList = (list: any, property: string): any => {
  return list.sort((a: any, b: any) => a[property].localeCompare(b[property]));
};

export const onFormatSeparateThousand = (x: string) => {
  const formatNumber = x.replaceAll(",", "");
  return formatNumber
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const showNotification = (
  message: string,
  description: string,
  type: string,
  duration = 3
) => {
  notification.open({
    className: `ant-notification-${type}`,
    message: message,
    description: description,
    duration: duration,
  });
};

export const showNotificationWithCallback = (
  message: string,
  description: string,
  type: string,
  duration = 3,
  onClose: () => void | undefined
) => {
  notification.open({
    className: `ant-notification-${type}`,
    message: message,
    description: description,
    duration: duration,
    onClose: onClose,
  });
};

export const onFormatAmount = (x: string) => {
  let number = x;
  if (x.charAt(0) === "0" && x.charAt(1) && x.charAt(1) !== ".") {
    number = x.substring(1);
  }
  const formatNumber = number.replaceAll(",", "");
  return formatNumber
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDate = (date: string) => {
  return moment.utc(date).format(DATE_FORMAT);
};

export const formatRouteEventDetail = (
  rootUrl: string,
  eventId: number,
) => {
  return rootUrl
    .replace(":id", eventId.toString())
    .replace(":isCreateEvent", "false");
};
