import React, { useCallback, useEffect, useMemo, useState } from "react";
import _, { pickBy } from "lodash";

import moment from "moment";
import { Form } from "antd";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import HeadingGroup from "components/HeadingGroup";
import { useNavigate, useParams } from 'react-router-dom';
import { getOperatorId } from "services/common/accessToken";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import { INIT_DYNAMIC_PRICING_EVENT, INIT_MEMBER_CREDIT_EVENT } from "./data";
import {
  getMemberCreditMasterDataAction,
  getDynamicPricingMasterDataAction,
  getEventIdAction,
} from "store/reducers/Event/actions";
import {
  Type,
  Between,
  EventType,
  DATE_FORMAT,
  DATE_FORMAT_FOR_SUBMIT,
  MuitipleType,
  MemberListCriteria,
  WithinEventDuration,
  IsToday,
  NotificationType,
  ADD_DELAY,
  MEET_CRITERIA,
  DURATION_TYPE_MEET_CRITERIA,
  MEET_CRITERIA_EXACT,
} from "utils/constants";
import {
  getEvent,
  submitEventData,
  submitEventDataWithFile,
  updateEventCredit,
  updateEventCreditWithFile,
} from "services/transaction";
import EventRule from "./EventRule";
import { FormContainerStyled } from "./Styled";
import ButtonStyled from "styled/ButtonStyled";
import GroupButtons from "components/GroupButtons";
import EventInformation from "./EventInformation";
import { getDynamicPricingEventConfig } from "services/dynamicPricingEvent";
import { routeUrls } from "utils/routes";
import { showNotification } from "utils/helpers";
import {
  formatDelayEventForApi,
  formatRuleForForm,
  formatRulesEventForApi,
  validateExactDate,
} from "../../services/event";

interface IProps {
  eventType: string;
}

const CreateUpdateEvent: React.FC<IProps> = ({ eventType }) => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const event = useAppSelector((state) => state.event);
  const [formInitValues, setFormInitValues] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(form.getFieldValue("startDate"));
  const [endDate, setEndDate] = useState(form.getFieldValue("endDate"));

  const isMemberCreditEventType = useMemo(() => {
    return eventType === EventType.MemberCredit ? true : false;
  }, [eventType]);

  useEffect(() => {
    if (!event.criterias.length) {
      if (eventType === EventType.MemberCredit) {
        dispatch(getMemberCreditMasterDataAction());
      } else {
        dispatch(getDynamicPricingMasterDataAction());
      }
    }
  }, []);

  const getInitalEventData = async () => {
    const eventId = id || event.eventId;
    if (eventId) {
      setLoading(true);
      const data = isMemberCreditEventType
        ? await getEvent(eventId!)
        : await getDynamicPricingEventConfig(eventId!);
      updateFormValues(data.isSuccess);
    } else {
      updateFormValues(
        isMemberCreditEventType
          ? INIT_MEMBER_CREDIT_EVENT
          : INIT_DYNAMIC_PRICING_EVENT
      );
    }
    setLoading(false);
  };

  const updateFormValues = useCallback(
    (data: any): void => {
      const nextStartDate = moment(new Date(), DATE_FORMAT).add(1, "days");
      const startDate = data.startDate
        ? moment(new Date(data.startDate), DATE_FORMAT).utcOffset(0)
        : moment(new Date(), DATE_FORMAT);
      const endDate = data.endDate
        ? moment(new Date(data.endDate.substring(0, 10) + "T00:00:00Z"), DATE_FORMAT).utcOffset(0)
        : moment(nextStartDate, DATE_FORMAT);
      const covertRules = data.rules.map((x: any) => ({
        ...x,
        values:
          x.dataType === Type.Date &&
            x.operator !== Between &&
            x.values.length > 0
            ? moment(x.values[0].value)
            : x.values.map((v: any) =>
              x.dataType === Type.Date
                ? moment(v.value)
                : v.value
            ),
      }));

      const rules = covertRules.map((rule: any) =>
        formatRuleForForm(rule, id || event.eventId)
      );

      const actions = data.actions.map((action: any) => {
        if (action.detailIds) {
          return {
            ...action,
            detailIds: action.detailIds.map((detailId: any) => `${detailId}`),
          };
        }
        return action;
      });
      if (data.delayDuration && data.delayDuration !== 0) {
        data.when = ADD_DELAY;
      } else if (data.dateSentCompareType) {
        data.when = MEET_CRITERIA_EXACT
      } else {
        data.when = MEET_CRITERIA;
      }
      if (data.dateSentCompareType === DURATION_TYPE_MEET_CRITERIA.Exact && data.dateSent) {
        data.dateSentCompareType = DURATION_TYPE_MEET_CRITERIA.Exact;
      }

      form.setFieldsValue({
        ...data,
        actions,
        rules,
        startDate,
        endDate,
      });

      setFormInitValues({
        ...data,
        rules,
        startDate,
        endDate,
      });
    },
    [form]
  );

  useEffect(() => {
    getInitalEventData();
  }, [setFormInitValues]);

  const onSubmit = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const {
        description = "",
        eventName = "",
        reasonCode = "",
        lookupAndReasonCodeID = "",
        startDate = "",
        endDate = "",
        rules,
        actions,
        ruleConditionType = "",
        delayDuration,
        delayDurationType,
        dateSentCompareType,
        dateSent,
      } = values;

      const converValuestoArray = rules.map((rule: any) => ({
        ...rule,
        values:
          !rule.values.length || typeof rule.values === "string"
            ? [rule.values]
            : rule.values,
      }));

      const converValuesToObject = converValuestoArray.map((rule: any) => ({
        ...rule,
        values: rule.values.map((value: any) =>
          MuitipleType.includes(rule.criteria)
            ? event[rule.criteria.toLowerCase() + "s"].filter(
              (x: any) => x.value === value
            )[0]
            : { value: value, label: value }
        ),
      }));

      const convertOperatorWithinDuration = converValuesToObject.map(
        (rule: any) => ({
          ...rule,
          values: (() => {
            switch (rule.operator) {
              case WithinEventDuration:
                return [
                  { value: startDate, label: startDate },
                  { value: endDate, label: endDate },
                ];
              case IsToday:
                return null;
              default:
                return rule.values;
            }
          })(),
        })
      );

      const criteriaWithValue = convertOperatorWithinDuration;

      let file: any;
      let criteriaWithNoValue;

      if (rules.length === 1 && rules[0].criteria === MemberListCriteria) {
        file = rules[0].values[0] || rules[0].values;
        criteriaWithNoValue = rules.map((rule: any) => ({
          ...rule,
          values: [],
        }));
      }

      let customAction = actions;
      if (eventType === EventType.DynamicPricing) {
        customAction = actions.reduce((acc: any, cur: any) => {
          const detailList = cur.detailIds.map((detail: any) => ({
            ...cur,
            detailId: detail,
          }));
          detailList.map((detail: any) => acc.push(detail));
          return acc;
        }, []);
      }

      const currentReason = event.reasons.find((reason: any) => reason.value === lookupAndReasonCodeID);

      const dataSubmit = pickBy(
        {
          description,
          eventName,
          reasonCode: currentReason.code || '',
          lookupAndReasonCodeID,
          startDate: startDate.format(DATE_FORMAT_FOR_SUBMIT),
          endDate: endDate.format(DATE_FORMAT_FOR_SUBMIT),
          actions: customAction,
          ruleConditionType,
          operatorId: getOperatorId(),
          eventType: eventType === EventType.MemberCredit ? "MC" : "DP",
          rules: formatRulesEventForApi(
            file ? criteriaWithNoValue : criteriaWithValue
          ),
          ...formatDelayEventForApi(delayDuration, delayDurationType, dateSentCompareType, dateSent),
        },
        (data: any) => data !== null && data !== undefined
      ) as MemberCreditEventProps | DynamicPricingEventProps;

      let result;
      if (id || event.eventId) {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          formData.append(
            "body",
            JSON.stringify({
              eventId: Number(id) | event.eventId,
              memberCredit: dataSubmit,
              ["actions"]: isMemberCreditEventType ? undefined : customAction,
            })
          );
          result = updateEventCreditWithFile(eventType, formData);
        } else {
          const body = {
            eventId: Number(id) | event.eventId,
            memberCredit: dataSubmit,
            ["actions"]: isMemberCreditEventType ? undefined : customAction,
          };
          result = updateEventCredit(eventType, body);
        }
      } else {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          formData.append("body", JSON.stringify(dataSubmit));
          result = submitEventDataWithFile(formData, eventType);
        } else {
          result = submitEventData(dataSubmit, eventType);
        }
      }

      result.then((res) => {
        dispatch(getEventIdAction(Number(res.id) || event.eventId));
        if (id) {
          showNotification(
            t("TS.SUCCESS"),
            t("TS.EDIT_EVENT_SUCCESSFULLY_MESSAGE"),
            NotificationType.success
          );
          window.history.back();
        } else {
          const url =
            eventType === EventType.MemberCredit
              ? routeUrls.previewEventDetail
              : routeUrls.dynamicPricingEventDetail;
          history(
            url
              .replace(":id", id || event.eventId || res.id.toString())
              .replace(":isCreateEvent", "true")
          );
        }
      });
    } catch (error) { }
  };



  const onFromDateChange = (date: any, _: string, isChangeEndDate: boolean) => {
    if (!isChangeEndDate) {
      const StartDate = form.getFieldValue("startDate");
      const nextStartDate = moment(StartDate, DATE_FORMAT).add(1, "days");
      if (!date.isBefore(StartDate)) {
        form.setFieldsValue({
          startDate: date,
          endDate: moment(nextStartDate),
        });
      }
      setStartDate(date);
      return;
    }
    setEndDate(date);
  };

  const disabledDateStart = (current: any) => {
    return (
      current && current < moment(new Date(), DATE_FORMAT).subtract(1, "days")
    );
  };

  if (isLoading) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  return (
    <>
      <HeadingGroup
        title={t(
          `${isMemberCreditEventType
            ? id
              ? "TS.EDIT_CREDIT_EVENT"
              : "TS.CREATE_CREDIT_EVENT"
            : id
              ? "TS.EDIT_DYNAMIC_PRICING_EVENT"
              : "TS.CREATE_DYNAMIC_PRICING_EVENT"
          }`
        )}
      />
      <Form
        name="basic"
        form={form}
        initialValues={formInitValues}
        layout="vertical"
        hideRequiredMark
      >
        <EventInformation
          eventType={eventType}
          form={form}
          onFromDateChange={onFromDateChange}
          disabledDateStart={disabledDateStart}
        />
        <EventRule typeOfEvent={eventType} form={form}
          startDate={startDate} endDate={endDate} />
        <FormContainerStyled>
          <GroupButtons position="center">
            <ButtonStyled type="default" onClick={() => window.history.back()}>
              {t("TS.MEMBER_BENEFIT.CANCEL_BUTTON")}
            </ButtonStyled>
            <ButtonStyled type="primary" onClick={onSubmit}>
              {id
                ? t("TS.MEMBER_BENEFIT.SAVE_BUTTON")
                : t("TS.MEMBER_BENEFIT.NEXT_BUTTON")}
            </ButtonStyled>
          </GroupButtons>
        </FormContainerStyled>
      </Form>
    </>
  );
};
export default CreateUpdateEvent;
