import { Row, Select } from "antd";
import { ClubGroupSelectStyled } from "pages/CreateUpdateEvent/Styled";
import styled, { SimpleInterpolation } from "styled-components";

export const TitleSelectStyled = styled(ClubGroupSelectStyled)`
  &.ant-select.ant-select-show-arrow {
    max-width: 75%;
    margin-bottom: -18px;
  }
`;

export const RowLedgerGroup = styled(Row)<{
  $hasSpacingRight?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 5px;
  padding-right: 5px;
  ${(props): SimpleInterpolation =>
    props.$hasSpacingRight ? "margin-right: -10px;" : null}
`;
