import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Popover, Tooltip } from "antd";
import GroupButtons from "components/GroupButtons";

import {
  StatusContainerStyled,
  StatusTitleContainer,
  TitleStyled,
  SpaceBetweenContainerStyled,
  ToolTipTitleStyled,
} from "styled/EventStyled";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteGateRule,
  getGateRuleDetail,
  updateEventStatus,
} from "services/gate";
import ButtonStyled, {
  BlankButtonStyled,
  SwitchStyled,
} from "styled/ButtonStyled";
import { PopoverContentStyled, PopoverTextStyled } from "styled/PopoverStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import Swal from "sweetalert2";
import { Between, NotificationType, Status, Type } from "utils/constants";
import { showNotification } from "utils/helpers";
import { routeUrls } from "utils/routes";
import { CardDetail, DetailContent, HeaderContainer } from "./Styled";
import { Condition, Gate, Value } from "./types";
import Icon from "components/Icon";
import { useAppSelector } from "store";
import { getMasterDataAction } from "store/reducers/Gate/action";
import { useDispatch } from "react-redux";
import { findCriteriaByName } from "utils/criterias";

const GateRuleDetail = () => {
  const [details, setDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { createdBy } = useParams<{ createdBy: string }>();
  const [active, setActive] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useNavigate();

  const dispatch = useDispatch();
  const { masterData } = useAppSelector((state) => state.gate);
  
  useEffect(() => {
    if (!masterData) {
      dispatch(getMasterDataAction());
    }
  }, [])

  const getData = useCallback(async () => {
    setLoading(true);

    if(!masterData) {
      return
    }

    try {
      const res = await getGateRuleDetail(id!);
      res.ruleStatus == Status.Active ? setActive(true) : setActive(false);
      const sortedGates = res.gates.sort((gate1: Gate, gate2: Gate) =>
        gate1.gateName > gate2.gateName ? 1 : -1,
      );
      const gateData: string[] = sortedGates.map(
        (gate: Gate) => `${gate.gateName}`,
      );

      const { conditions } = res;
      const conditionData =  conditions.map((condition: Condition, index: number) => {
        const ruleType = index !== conditions.length - 1 ? res.ruleType : "";
        const criteria = findCriteriaByName(masterData.criterias, condition.criteria);
        const isDate = criteria.dataType === Type.Date
        let values = "";
        if(criteria.dataType === Type.Int) {
          values = condition.values.map((value: Value) => value.valueDescription).join(", ");
        } else if(condition.operator !== Between && isDate) {
          values = condition.values[0].value1;
        } else if(condition.operator === Between && isDate) {
          values = condition.values[0].value1 + " - " + condition.values[0].value2;
        }
        return `${criteria.displayName} ${condition.displayName} ${values} ${ruleType}`;
      });

      const data: any = {
        RuleName: res.ruleName,
        ClubName: res.clubGroupName,
        GateName: gateData,
        Condition: conditionData,
      };
      setDetails(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id, masterData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const hoverEditContent = () => {
    return (
      <ToolTipTitleStyled>
        {t("TS.CONFIG_GATE_RULE.EDIT_GATE_RULE")}
      </ToolTipTitleStyled>
    );
  };

  const showConfirmChangeStatus = (active: boolean) => {
    Swal.fire({
      title: t("TS.DYNAMIC_PRICING_EVENT.CONFIRM_CHANGE_STATUS"),
      html: t("SS.CONFIG_GATE_RULE.CONFIRM_CHANGE_STATUS_MESSAGE"),
      showCancelButton: true,
      icon: "question",
      confirmButtonText: t("SS.OK"),
      reverseButtons: true,
      width: 300,
      customClass: {
        title: "swal2-title-custom",
        confirmButton: "swal2-confirm-custom",
        cancelButton: "swal2-cancel-custom",
        icon: "swal2-warning-custom",
        htmlContainer: "swal2-text-custom",
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setActive(active);
        const res = await updateEventStatus({
          ruleId: Number(id),
          status: active ? "AC" : "IN",
        });
        if (res.isSuccess) {
          showNotification(
            t("TS.SUCCESS"),
            t("TS.CONFIG_GATE_RULE.UPDATE_STATUS_EVENT_SUCCESSFULLY_MESSAGE"),
            NotificationType.success,
          );
        }
      }
    });
  };

  const goBack = (event: any) => {
    event.preventDefault();
    window.location.href = `${routeUrls.manageGate}?operatorId=${createdBy}`;
  };

  const goToEditPage = (event: any) => {
    event.preventDefault();
    window.location.href = routeUrls.configGateRule
      .replace(":id", id!)
      .replace(":createdBy", createdBy!);
  };

  const hoverContent = (ruleName: string) => {
    return <PopoverContentStyled>{ruleName}</PopoverContentStyled>;
  };

  const hoverDeleteContent = () => {
    return (
      <ToolTipTitleStyled>{t("TS.DELETE_EVENT_TITLE")}</ToolTipTitleStyled>
    );
  };

  const deleteEventAction = () => {
    Swal.fire({
      title: t("TS.MANAGE_GATE.DELETE_GATE_RULE_TITLE"),
      html: t("TS.MANAGE_GATE.DELETE_GATE_RULE_CONFIRM"),
      showCancelButton: true,
      icon: "question",
      confirmButtonText: t("SS.YES"),
      cancelButtonText: t("SS.NO"),
      reverseButtons: true,
      width: 300,
      customClass: {
        title: "swal2-title-custom",
        confirmButton: "swal2-confirm-custom",
        cancelButton: "swal2-cancel-custom",
        icon: "swal2-warning-custom",
        htmlContainer: "swal2-text-custom",
      },
    }).then((result: any) => {
      if (result.isConfirmed) {
        deleteGateRule(id!, createdBy!)
          .then((res) => {
            if (res.status) {
              showNotification(
                t("TS.SUCCESS"),
                t("TS.MANAGE_GATE.DELETE_SUCCESS_MESSAGE"),
                NotificationType.success,
              );
            } else {
              showNotification(
                t("TS.ERROR"),
                t("TS.DELETE_EVENT_FAILED_MESSAGE"),
                NotificationType.error,
              );
            }
          })
          .finally(() => {
            history(routeUrls.manageGate);
          });
      }
    });
  };

  if (loading) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  return (
    <>
      <SpaceBetweenContainerStyled>
        <HeaderContainer>
          <Popover content={hoverContent(details.RuleName)} placement="topLeft">
            <PopoverTextStyled $maxWidth={400}>
              {details.RuleName}
            </PopoverTextStyled>
          </Popover>
          <Tooltip
            title={hoverEditContent}
            color={"white"}
            placement="topRight"
          >
            <BlankButtonStyled>
              <EditOutlined
                style={{ marginLeft: "5px", marginTop: "7px" }}
                onClick={goToEditPage}
              />
            </BlankButtonStyled>
          </Tooltip>
          <Tooltip title={hoverDeleteContent} color="white" placement="topLeft">
            <BlankButtonStyled
              onClick={deleteEventAction}
              $color={"danger"}
              $marginLeft={10}
            >
              <Icon icon="icon-delete" $marginTop="6px" />
            </BlankButtonStyled>
          </Tooltip>
        </HeaderContainer>
        <StatusContainerStyled>
          <TitleStyled>{t("SS.STATUS")}</TitleStyled>
          <SwitchStyled
            size="small"
            checked={active}
            onChange={showConfirmChangeStatus}
          />
          <StatusTitleContainer>
            <TitleStyled>{active ? "Active" : "Inactive"}</TitleStyled>
          </StatusTitleContainer>
        </StatusContainerStyled>
      </SpaceBetweenContainerStyled>

      <CardDetail type="inner" title="Club">
        <DetailContent>
          <CheckOutlined /> {details.ClubName}
        </DetailContent>
      </CardDetail>
      <CardDetail type="inner" title="Gate Name">
        {details.GateName &&
          details.GateName.map((gate: string) => (
            <DetailContent key={gate}>
              <CheckOutlined />
              {gate}
            </DetailContent>
          ))}
      </CardDetail>
      <CardDetail type="inner" title="Condition">
        {details.Condition && 
          details.Condition.map((condition: string) => (
          <DetailContent key={condition}>
            <CheckOutlined />
            {condition}
          </DetailContent>
        ))}
      </CardDetail>

      <GroupButtons position="center" style={{ marginTop: "30px" }}>
        <ButtonStyled onClick={goBack}>{t("BACK")}</ButtonStyled>
      </GroupButtons>
    </>
  );
};

export default GateRuleDetail;
