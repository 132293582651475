import moment from "moment";

import { Condition, Value } from "pages/GateRuleDetail/types";
import { HOME_CLUB, TIER } from "constants/Criteria";
import { Between, DATE_FORMAT, Type } from "./constants";
import { findCriteriaByName } from "./criterias";

export const formatGateConditionsForApi = (data: any, masterData: any) => {
  const condition = data.Conditions.map((item: any) => {
    const criteria = findCriteriaByName(masterData.criterias, item.Criteria);
    if (criteria.dataType === Type.Int) {
      return {
        Criteria: item.Criteria,
        Operator: item.Operator,
        Values: item.Values.map((value: string) => {
          let optionItem;
          if(item.Criteria === TIER) { 
             optionItem = masterData.tiers.find(
              (itemEl: any) => itemEl.tierId === Number(value),
            );
            return {
              Value1: optionItem.tierId,
              Value2: "",
              ValueDescription: optionItem.tierName,
            };
          } else if(item.Criteria === HOME_CLUB) {
            optionItem = masterData.activeClubs.find(
              (itemEl: any) => itemEl.clubId === Number(value),
            );
            return {
              Value1: optionItem.clubId,
              Value2: "",
              ValueDescription: optionItem.clubName,
            };
          }
        }),
      };
    }

    if (criteria.dataType === Type.Date && item.Operator === Between) {
      return {
        Criteria: item.Criteria,
        Operator: item.Operator,
        Values: [
          {
            Value1: moment(item.Values[0]).format(DATE_FORMAT),
            Value2: moment(item.Values[1]).format(DATE_FORMAT),
          },
        ],
      };
    }

    if (criteria.dataType === Type.Date && item.Operator !== Between) {
      return {
        Criteria: item.Criteria,
        Operator: item.Operator,
        Values: [{ Value1: moment(item.Values).format(DATE_FORMAT) }],
      };
    }

    return {
      Criteria: item.Criteria,
      Operator: item.Operator,
      Values: [],
    };
  });

  return condition;
};

export const formatFromApi = (data: any, masterData: any) => {
  return data.conditions.map((condition: Condition) => {
    const criteria = findCriteriaByName(masterData.criterias, condition.criteria);
    if (criteria.dataType === Type.Int) {
      return {
        Criteria: condition.criteria,
        Operator: condition.operator,
        Values: condition.values.map((value: Value) => value.value1),
      };
    }

    if (criteria.dataType === Type.Date && condition.operator !== Between) {
      return {
        Criteria: condition.criteria,
        Operator: condition.operator,
        Values: moment(condition.values[0].value1, DATE_FORMAT),
      };
    }

    if (criteria.dataType === Type.Date && condition.operator === Between) {
      return {
        Criteria: condition.criteria,
        Operator: condition.operator,
        Values: [
          moment(condition.values[0].value1, DATE_FORMAT),
          moment(condition.values[0].value2, DATE_FORMAT),
        ],
      };
    }

    return {
      Criteria: condition.criteria,
      Operator: condition.operator,
      Values: [],
    };
  });
};
