import React, { useState } from "react";
import { Button, Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { EmailModalFooterStyle, EmailModalStyled } from "./Styled";
import { ModalStyled } from "styled/ModalStyled";
import moment from "moment";

interface IEmailModal {
  onCloseEmailModal: () => void;
  emailDetail: { content: string; contactDate: string }[];
}

const EmailModal = (props: IEmailModal) => {
  const { onCloseEmailModal, emailDetail } = props;
  const carousel = useRef<CarouselRef>(null);
  const [timeSent, setTimeSent] = useState<string>(
    emailDetail[0].contactDate || ""
  );
  const { t } = useTranslation();

  const onNextEmail = () => {
    if (carousel.current) {
      carousel.current.next();
    }
  };

  const onPreviousEmail = () => {
    if (carousel.current) {
      carousel.current.prev();
    }
  };

  const onchangeTitle = (current: number) => {
    setTimeSent(emailDetail[current].contactDate || "");
  };

  return (
    <ModalStyled
      title={`${t("TS.EMAIL.AT")}  ${moment
        .utc(timeSent)
        .format("DD MMM YYYY, HH:mm A")}`}
      visible
      width={800}
      footer={
        <EmailModalFooterStyle>
          <div className="email-modal__footer">
            <Button onClick={onPreviousEmail} className="email__previous">
              {t("TS.PREV.EMAIL")}
            </Button>
            <Button className="email__next" onClick={onNextEmail}>
              {t("TS.NEXT.EMAIL")}
            </Button>
          </div>
        </EmailModalFooterStyle>
      }
      onCancel={onCloseEmailModal}
    >
      <EmailModalStyled className="email-modal">
        <div className="email-modal__body">
          <Carousel
            dots={false}
            ref={carousel}
            afterChange={(current: number) => onchangeTitle(current)}
          >
            {emailDetail.map((email) => (
              <div key={email.contactDate}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: email.content,
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </EmailModalStyled>
    </ModalStyled>
  );
};

export default EmailModal;
