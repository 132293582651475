import { call, put, takeLatest } from "redux-saga/effects";

import { getClubList } from "services/transaction";
import { setClubListAction } from "store/reducers/Club/actions";
import { GetClubListAction, GET_CLUB_LIST } from "store/reducers/Club/types";

function* getClubListData(action: GetClubListAction) {
  const data: ClubProps[] = yield call(getClubList, action.operatorId);
  yield put(setClubListAction(data));
}

function* watchFetchProducts() {
  yield takeLatest(GET_CLUB_LIST, getClubListData);
}

export default watchFetchProducts;
