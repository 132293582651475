import { css, SimpleInterpolation } from "styled-components/macro";

export const sizes = {
  appNavBarLeftWidth: 160,
  minViewportWidth: 1000,
  secondarySideBarWidth: 230,
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  navLeft: 100,
};

export const font = {
  regular: 'font-family: "Poppins", sans-serif; font-weight: 400;',
  medium: 'font-family: "Poppins", sans-serif; font-weight: 500;',
  bold: 'font-family: "Poppins", sans-serif; font-weight: 700;',
  size: (size: number): string => `font-size: ${size / 16}rem;`,
};

export const fontSize = {
  title: 25,
  description: 12,
  regular: 12,
  small: 11,
};

export const mixin = {
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  clickable: css`
    cursor: pointer;
    user-select: none;
  `,

  hardwareAccelerate: css`
    transform: translateZ(0);
  `,

  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,

  placeholderColor: (colorValue: string): SimpleInterpolation => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
      font-style: italic;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
      font-style: italic;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
      font-style: italic;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
      font-style: italic;
    }
    ::placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
      font-style: italic;
    }
  `,

  backgroundImage: css`
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  `,

  link: (
    colorValue = `var(--primary-color);`,
    activeColor = `var(--secondary-color);`
  ): SimpleInterpolation => css`
    cursor: pointer;
    color: ${colorValue};

    &:hover,
    &:visited,
    &:active {
      color: ${activeColor};
    }
  `,

  ellipsisText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  headingH1: css`
    ${font.bold}
    ${font.size(25)}
    line-height: 30px;
  `,

  headingH2: css`
    ${font.bold}
    ${font.size(20)}
    line-height: 25px;
  `,

  headingH3: css`
    ${font.bold}
    ${font.size(14)}
    line-height: 20px;
  `,

  headingH4: css`
    ${font.bold}
    font-size: 11px;
    line-height: 18px;
  `,

  textRegular: css`
    ${font.regular}
    font-size: 11px;
    line-height: 18px;
  `,
};

export const color = {
  primary: "#00D6D0",
  secondary: "#14B3B8",
  success: "#29a638",
  danger: "#e13c3c",
  warning: "#f89c1c",
  accent: "#8a46d7",
  black: "#000000",
  white: "#ffffff",
  white1: "#F2F2F2",
  gray: "#707070",
  gray1: "#EBEBEB",
  gray2: "#CCCCCC",
  gray3: "#dedede",
  gray4: "#CBCBCB",
  gray5: "#C2C2C2",
  red: "#EA0303",
  green: "#1eccc2",
  yellow: "#FFBA00",

  placeholderColor: "#a7a2a2",
};
