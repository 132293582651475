import { DAY_VALUE, DURATION_TYPE_MEET_CRITERIA, MEET_CRITERIA } from "utils/constants";

export const INIT_MEMBER_CREDIT_EVENT: MemberCreditEventProps = {
  eventName: "",
  description: "",
  startDate: "",
  endDate: "",
  reasonCode: null,
  lookupAndReasonCodeID: null,
  ruleConditionType: "AND",
  rules: [
    {
      criteria: null,
      operator: null,
      dataType: "",
      values: [],
    },
  ],
  actions: [
    {
      actionCode: "MC",
      amount: "",
      duration: "",
      durationType: DAY_VALUE,
    },
  ],
  when: MEET_CRITERIA,
  delayDurationType: DAY_VALUE,
};

export const INIT_DYNAMIC_PRICING_EVENT: DynamicPricingEventProps = {
  eventName: "",
  description: "",
  startDate: "",
  endDate: "",
  reasonCode: null,
  lookupAndReasonCodeID: null,
  ruleConditionType: "AND",
  rules: [
    {
      criteria: null,
      operator: null,
      dataType: "",
      values: [],
    },
  ],
  actions: [
    {
      actionCode: "LG",
      amount: "",
      priority: 1,
      discountType: "PC",
      detailId: [],
    },
  ],
  when: MEET_CRITERIA,
  delayDurationType: DAY_VALUE,
};
