import { Tag } from "antd";
import styled from "styled-components";

export const TagStyled = styled(Tag)`
  background-color: var(--gray8-color);
  padding: 0px 5px;
  margin: 2px 3px 2px 0;
  border: 1px solid var(--gray1-color);
  font-size: 11px;
  width: 100%;
  display: -webkit-box;
`;

export const TagTitleStyled = styled.div`
  max-width: 90%;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
`;
