import styled, { SimpleInterpolation } from "styled-components/macro";

const PaginationStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ul {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    padding: 0 5px;
    li {
      list-style: none;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    > * {
      margin-bottom: 10px;

      &:last-child {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }
`;

export const LiStyled = styled.li`
  margin-right: 5px;
  .ant-btn {
    width: 25px;
    height: 24px;
    padding: 0;
    font-weight: 500;

    &.ant-btn-sm {
      &[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
          background-color: var(--white1-color);
          color: var(--gray3-color);
        }
      }
    }

    > span {
      vertical-align: middle;
    }
    &.ant-btn-icon-only {
      > * {
        font-size: 18px;
      }
    }
  }
  ${(props: { isActive?: boolean, color?: string }): SimpleInterpolation => {
    if (props.isActive) {
      return `
      .ant-btn.ant-btn-sm {
        background-color: ${props.color ? props.color : "var(--primary-color)"};
        color: var(--white-color);
        border: 1px solid ${props.color ? props.color : "var(--primary-color)"};
        &:hover {
          opacity: 0.7;
          background-color: ${props.color ? props.color : "var(--primary-color)"};
          color: var(--white-color);
          border-color: ${props.color ? props.color : "var(--primary-color)"};
        }
        &:focus{
          background-color: ${props.color ? props.color : "var(--primary-color)"};
          color: var(--white-color);
          border-color: ${props.color ? props.color : "var(--primary-color)"};
        }
      `;
    }
    return `
      .ant-btn.ant-btn-sm {
        background-color: var(--white-color);
        color: var(--gray-color);
        border: 1px solid var(--gray2-color);
        &:not(:disabled):hover {
          background-color: var(--primary-color);
          color: var(--white-color);
          border-color:  var(--primary-color)
        }
      `;
  }}
`;

export const PaginationTitleStyled = styled.div`
  span {
    font-size: 11px;
  }

  @media screen and (min-width: 426px) {

    position: relative;
    padding-left: 15px;
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        top: -5px;
        bottom: -3px;
        left: 0;
        border-left: 1px solid var(--white1-color);
      }
    }
  }
  
`;

export const PaginationPageSizeStyled = styled.div`
  .ant-select {
    margin-left: 10px;
    .ant-select-arrow {
      font-size: 22px;
      font-family: "icomoon" !important;
      speak: never;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      &:after {
        content: "\\e901";
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--gray-color);
      }
      > span {
        opacity: 0;
        display: none;
      }
    }
    &:hover {
      .ant-select-selector {
        border-color: var(--primary-color);
      }
    }
  }

  .ant-select-focused,
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: var(--primary-color);
      box-shadow: none;
    }
  }

  .ant-select-single .ant-select-selector {
    height: 25px;
    padding: 0 8px;
    min-width: 60px;
    > span {
      line-height: 25px;
      font-size: 11px;
    }
  }
`;

export default PaginationStyled;
