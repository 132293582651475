import Table from "components/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApplicableSaleItem } from "services/dynamicPricingEvent";
import { EVENT_LIST_PAGING } from "utils/constants";
import { formatNumber } from "utils/helpers";
import {
  ApplicableTitleStyled,
  TextRightStyled,
  TextWrapStyled,
} from "styled/EventStyled";

const ApplicableSaleItem: React.FC<{ eventId: string }> = ({ eventId }) => {
  const [data, setData] = useState<ApplicableSaleItemProps[]>([]);
  const [pagingInfo, setPagingInfo] = useState(EVENT_LIST_PAGING);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        title: t("TS.DYNAMIC_PRICING_EVENT.NO"),
        dataIndex: "order",
      },
      {
        title: t("TS.DYNAMIC_PRICING_EVENT.ITEM"),
        dataIndex: "itemName",
        render: (name: string) => <TextWrapStyled>{name}</TextWrapStyled>,
      },
      {
        title: t("TS.DYNAMIC_PRICING_EVENT.TYPE_DISCOUNT"),
        dataIndex: "discountTypeName",
      },
      {
        title: t("TS.DYNAMIC_PRICING_EVENT.AMOUNT"),
        dataIndex: "amount",
        render: (_: any, item: ApplicableSaleItemProps) => {
          return (
            <TextRightStyled>
              {item.discountTypeCode === "PC"
                ? `${item.amount}%`
                : `-${formatNumber(Number(item.amount) || 0)}`}
            </TextRightStyled>
          );
        },
      },
      {
        title: t("TS.DYNAMIC_PRICING_EVENT.PRIORITY"),
        dataIndex: "priority",
        render: (priority: any) => (
          <TextRightStyled>{priority}</TextRightStyled>
        ),
      },
    ];
  }, []);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getApplicableSaleItem(eventId, {
        ...pagingInfo,
        pageSize,
      });
      setData(
        res.data.map((item: ApplicableSaleItemProps, index: number) => ({
          ...item,
          order: pagingInfo.pageIndex * pageSize + index + 1,
        }))
      );
      setPagingInfo(res.paging);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [pagingInfo.pageIndex, pageSize, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const updatePageIndex = (newPageIndex: number) => {
    setPagingInfo({
      ...pagingInfo,
      pageIndex: newPageIndex,
    });
  };

  return (
    <>
      <ApplicableTitleStyled>
        {t("TS.DYNAMIC_PRICING_EVENT.APPLICABLE_SALE_ITEM")}
      </ApplicableTitleStyled>
      <Table
        columns={columns}
        dataSource={data}
        key="applicableSaleItem"
        loading={isLoading}
        customStyle={{
          noUpperCase: true,
          boldTitle: true,
        }}
        paging={{
          ...pagingInfo,
          setPageIndex: updatePageIndex,
          setPageSize: setPageSize,
        }}
      />
    </>
  );
};

export default ApplicableSaleItem;
