import { Col, Form, Input, Row, Select } from "antd";
import HeadingGroup from "components/HeadingGroup";
import {
  FormContainerStyled,
  InputTitleStyled
} from "pages/CreateUpdateEvent/Styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";

const CreateSurvey: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadingGroup title={t("TS.SURVEY.CREATE_SURVEY")} />
      <Form name="basic" layout="vertical" hideRequiredMark>
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
              <InputTitleStyled>{t("TS.SURVEY.SURVEY_NAME")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 20 }} xs={{ span: 20 }}>
              <FormItemStyled name="eventName">
                <Input autoFocus autoComplete="off" />
              </FormItemStyled>
            </Col>
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 4 }} xs={{ span: 4 }}>
              <InputTitleStyled>{t("SS.REASON")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 20 }} xs={{ span: 20 }}>
              <FormItemStyled name="reasonCode">
                <Select />
              </FormItemStyled>
            </Col>
          </Row>
        </FormContainerStyled>
      </Form>
    </>
  );
};

export default CreateSurvey;
