import { Checkbox, Col, Form, Row, Select } from "antd";
import LinkedMember from "models/LinkedMember";
import LinkedType from "models/LinkedType";
import {
  FormContainerStyled,
  InputTitleStyled,
} from "pages/ManageEventList/Styled";
import { ModalContent } from "pages/ViewMemberCreditSpending/Styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { editLinkedMembership, getLinkTypes } from "services/linkedMembership";
import { FormItemStyled } from "styled/FormStyled";
import { ModalStyled, ModalTitleStyled } from "styled/ModalStyled";
import { NotificationType } from "utils/constants";
import { showNotification } from "utils/helpers";

interface IProps {
  setOpenModal: (open: boolean) => void;
  parentId: string;
  currentLinkedMember: LinkedMember;
  operatorId: string;
  closeModal: () => void;
}
const EditLinkedMembershipModal: React.FC<IProps> = ({
  setOpenModal,
  parentId,
  currentLinkedMember,
  operatorId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [linkTypeList, setLinkTypeList] = useState<OptionProps[]>([]);
  const [showMessageCanNotLinkPayment, setShowMessageCanNotLinkPayment] =
    useState<boolean>(false);

  const getLinkTypeList = async () => {
    const res = await getLinkTypes();
    const linkTypes = res.map((linkType: LinkedType) => ({
      value: linkType.lookupReasonId.toString(),
      label: linkType.description,
    }));
    setLinkTypeList(linkTypes);
    form.setFieldsValue({
      LinkTypeId: currentLinkedMember.linkTypeId.toString(),
      IsLinkedPayment: currentLinkedMember.isLinkedPayment,
    });
  };

  useEffect(() => {
    getLinkTypeList();
  }, []);

  const handleOk = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const res = await editLinkedMembership(
        { ...values, OperatorId: operatorId },
        parentId,
        currentLinkedMember.memberId.toString()
      );
      if (res && res.linkedMemberId) {
        setShowMessageCanNotLinkPayment(!res.canChangeLinkPayment);
        if (res.canChangeLinkPayment) {
          showNotification(
            t("TS.SUCCESS"),
            t("TS.MEMBERSHIP.EDIT.SUCCESSFULLY"),
            NotificationType.success
          );
        }
        setOpenModal(!res.canChangeLinkPayment);
      }
    } catch {
      setOpenModal(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
  };
  return (
    <ModalStyled
      title={
        <ModalTitleStyled>{t("TS.EDIT.LINK.MEMBERSHIP")}</ModalTitleStyled>
      }
      onOk={handleOk}
      visible={true}
      width="400px"
      okText={t("SS.SAVE")}
      maskClosable={false}
      onCancel={handleCancel}
      style={{ top: 10 }}
    >
      <ModalContent>{t("SS.CHANGE_EXPIRY_DATE.CONTENT")}</ModalContent>
      <Form name="basic" layout="vertical" form={form}>
        <FormContainerStyled style={{ marginTop: 20 }}>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 9 }} xs={{ span: 9 }}>
              <InputTitleStyled>{t("TS.EDIT.LINK.TYPE")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
              <FormItemStyled name="LinkTypeId" $color="var(--primary-color)">
                <Select options={linkTypeList} listHeight={90} />
              </FormItemStyled>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 9 }} xs={{ span: 9 }}>
              <InputTitleStyled>{t("TS.EDIT.LINK.PAYMENT")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
              <FormItemStyled
                name="IsLinkedPayment"
                valuePropName="checked"
                $color="var(--primary-color)"
                style={{ marginBottom: 0 }}
              >
                <Checkbox />
              </FormItemStyled>
              {showMessageCanNotLinkPayment && (
                <span className="no-link-payment" color="orange">
                  {t("TS.CANNOT.LINKED.PAYMENT")}
                </span>
              )}
            </Col>
          </Row>
        </FormContainerStyled>
      </Form>
    </ModalStyled>
  );
};

export default EditLinkedMembershipModal;
