import React from "react";
import Icon from "components/Icon";
import { Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import { FormListFieldData } from "antd/lib/form/FormList";
import {
  ColStyled,
  IconButtonContainer,
  RoundButtonStyle,
  RowStyled,
} from "../Styled";
import { DATE_OPTION, ITEMS, REGEX_INTEGER_NUMBER } from "utils/constants";
import { PresetGroupSelectStyled } from "pages/CreateUpdateEvent/Styled";
import TagSelect from "components/TagSelect";

interface ListItemProps {
  optionType: string;
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  itemOptions: OptionProps[];
  remove: (name: number) => void;
}

const ActionItem: React.FC<ListItemProps> = ({
  optionType,
  groupField,
  remove,
  fieldsLength,
  parentIdx,
  itemOptions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {parentIdx === 0 ? null : (
        <RowStyled $hasSpacingRight={fieldsLength <= 1}>
          <ColStyled md={2} xs={2}></ColStyled>
          <ColStyled md={6} xs={6}>
            <FormItemStyled
              {...groupField}
              name={[
                groupField.name,
                optionType === ITEMS ? "itemIds" : "ledgerGroupIds",
              ]}
              fieldKey={
               [groupField.fieldKey as number,
                optionType === ITEMS ? "itemIds" : "ledgerGroupIds",
              ]}
              rules={[
                {
                  required: true,
                  message: t("TS.MEMBER_BENEFIT.REQUIRED_FIELD", {
                    key: t("TS.MEMBER_BENEFIT.ITEMS"),
                  }),
                },
              ]}
            >
              <PresetGroupSelectStyled
                showArrow
                mode="multiple"
                optionFilterProp="label"
                options={itemOptions}
                placeholder={t("TS.SELECT_PLACE", { key: t("TS.DETAIL") })}
                tagRender={(props) => <TagSelect {...props} />}
              />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={4} xs={4}>
            <FormItemStyled
              name={[groupField.name, "quantity"]}
              rules={[
                {
                  required: true,
                  message: t("TS.MEMBER_BENEFIT.REQUIRED_FIELD", {
                    key: t("TS.MEMBER_BENEFIT.QUANTITY"),
                  }),
                },
                {
                  pattern: REGEX_INTEGER_NUMBER,
                  message: t("TS.MEMBER_BENEFIT.INVALID_QUANTITY"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                min={1}
                step={1}
                type="number"
                placeholder={t("TS.MEMBER_BENEFIT.INPUT_QUANTITY")}
              />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={5} xs={5}>
            <FormItemStyled
              name={[groupField.name, "validDuration"]}
              rules={[
                {
                  required: true,
                  message: t("TS.MEMBER_BENEFIT.REQUIRED_FIELD", {
                    key: t("TS.MEMBER_BENEFIT.VALID_DURATION"),
                  }),
                },
                {
                  pattern: REGEX_INTEGER_NUMBER,
                  message: t("TS.MEMBER_BENEFIT.INVALID_QUANTITY"),
                },
              ]}
            >
              <Input
                min={1}
                step={1}
                placeholder={t("TS.MEMBER_BENEFIT.INPUT_PLACE", {
                  key: t("TS.MEMBER_BENEFIT.VALID_DURATION"),
                })}
                autoComplete="off"
                type="number"
              />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={4} xs={4} style={{ paddingRight: 0 }}>
            <FormItemStyled
              {...groupField}
              name={[groupField.name, "durationType"]}
              fieldKey={[groupField.fieldKey as number, "type"]}
            >
              <Select options={DATE_OPTION} />
            </FormItemStyled>
          </ColStyled>
          <ColStyled md={2} xs={2}>
            <IconButtonContainer>
              {fieldsLength > 2 ? (
                <RoundButtonStyle
                  shape="circle"
                  color="red"
                  onClick={(): void => {
                    remove(groupField.name);
                  }}
                  icon={<Icon icon="icon-minus-ico" />}
                />
              ) : null}
            </IconButtonContainer>
          </ColStyled>
        </RowStyled>
      )}
    </>
  );
};

export default ActionItem;
