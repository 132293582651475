import { REACT_APP_STANDARD_HEIGHT } from "./constants";

export const Iframe = {
  sendHeightPostMessage(data: any[], tableId: string, tableType: string) {
    let height = document.getElementById(tableId)?.offsetHeight;
    if (data.length >= 3) {
      height = REACT_APP_STANDARD_HEIGHT;
    }
    window.parent.postMessage(
      {
        height: height,
        tableType: tableType,
      },
      "*"
    );
  },

  redirectToMemberDetail(memberId: number) {
    window.parent.postMessage(
      {
        action: "redirectToMemberDetails",
        value: memberId,
      },
      "*"
    );
  },
};

export default Iframe;
