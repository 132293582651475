import Form, { useForm } from "antd/lib/form/Form";
import GroupButtons from "components/GroupButtons";
import HeadingGroup from "components/HeadingGroup";
import moment from "moment";
import { FormContainerStyled } from "pages/CreateUpdateEvent/Styled";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { getOperatorId } from "services/common/accessToken";
import {
  getMemberBenefitEvent,
  updateMemberBenefitEventWithFile,
  updateMemberBenefitEventWithoutFile,
} from "services/memberBenefitEvent";
import { submitEventData, submitEventDataWithFile } from "services/transaction";
import { useAppSelector } from "store";
import { getMemberBenfitMasterDataAction } from "store/reducers/Event/actions";
import ButtonStyled from "styled/ButtonStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import {
  ADD_DELAY,
  Between,
  DATE_FORMAT,
  DATE_FORMAT_FOR_SUBMIT,
  DURATION_TYPE_MEET_CRITERIA,
  EventType,
  IsToday,
  ITEMS,
  LEDGER,
  MEET_CRITERIA,
  MEET_CRITERIA_EXACT,
  MemberListCriteria,
  MuitipleType,
  NotificationType,
  Type,
  WithinEventDuration,
} from "utils/constants";
import { onFormatAmount, showNotification } from "utils/helpers";
import { routeUrls } from "utils/routes";
import { INIT_MEMBER_BENEFIT_EVENT } from "./data";
import EventInformation from "./EventInformation";
import RuleSetting from "./RuleSetting";
import { pickBy } from "lodash";
import {
  formatDelayEventForApi,
  formatRuleForForm,
  formatRulesEventForApi,
} from "services/event";

const CreateMemberBenefitEvent: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(true);
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [formInitValues, setFormInitValues] = useState<any>(
    INIT_MEMBER_BENEFIT_EVENT
  );
  const [startDate, setStartDate] = useState(form.getFieldValue("startDate"));
  const [endDate, setEndDate] = useState(form.getFieldValue("endDate"));
  const event = useAppSelector((state) => state.event);
  const history = useNavigate();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(getMemberBenfitMasterDataAction());
  }, []);

  const updateFormValues = useCallback(
    (data: any): void => {
      const nextStartDate = moment(new Date(), DATE_FORMAT).add(1, "days");
      const startDate = data.startDate
        ? moment(new Date(data.startDate), DATE_FORMAT).utcOffset(0)
        : moment(new Date(), DATE_FORMAT);
      const endDate = data.endDate
        ? moment(
          new Date(data.endDate.substring(0, 10) + "T00:00:00Z"),
          DATE_FORMAT
        ).utcOffset(0)
        : moment(nextStartDate, DATE_FORMAT);

      const covertRules = data.rules.map((item: any) => ({
        ...item,
        values:
          item.dataType === Type.Date &&
            item.operator !== Between &&
            item.values.length > 0
            ? moment(item.values[0].value)
            : item.values.map((v: any) =>
              item.dataType === Type.Date
                ? moment(v.value)
                : v.value
            ),
      }));

      const rules = covertRules.map((rule: any) =>
        formatRuleForForm(rule, id || event.eventId)
      );

      let ledgerGroup = ITEMS;

      if (
        data.actions.some(
          (action: MemberBenefitEvent) =>
            action.ledgerGroupIds && action.ledgerGroupIds.length
        )
      ) {
        ledgerGroup = LEDGER;
      }

      const actions = data.actions.map((item: any) => ({
        ...item,
        quantity: onFormatAmount(item.quantity.toString()),
      }));

      if (data.delayDuration && data.delayDuration !== 0) {
        data.when = ADD_DELAY;
      } else if (data.dateSentCompareType) {
        data.when = MEET_CRITERIA_EXACT;
      } else {
        data.when = MEET_CRITERIA;
      }
      if (
        data.dateSentCompareType === DURATION_TYPE_MEET_CRITERIA.Exact &&
        data.dateSent
      ) {
        data.dateSentCompareType = DURATION_TYPE_MEET_CRITERIA.Exact;
      }

      form.setFieldsValue({
        ...data,
        ledgerGroup,
        rules,
        startDate,
        endDate,
        actions,
      });

      setFormInitValues({
        ...data,
        ledgerGroup,
        rules,
        startDate,
        endDate,
        actions,
      });
    },

    [form]
  );

  const getInitalEventData = async () => {
    setLoading(true);
    if (id) {
      const data = await getMemberBenefitEvent(id);
      setIsCreate(data.status === "PD");
      updateFormValues(data);
    } else {
      updateFormValues(INIT_MEMBER_BENEFIT_EVENT);
      setIsCreate(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitalEventData();
  }, []);

  const onSubmit = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const {
        description = "",
        eventName = "",
        reasonCode = "",
        lookupAndReasonCodeID = "",
        startDate = "",
        endDate = "",
        rules,
        actions,
        ruleConditionType = "",
        delayDuration,
        delayDurationType,
        dateSentCompareType,
        dateSent,
      } = values;

      const converValuesToArray = rules.map((rule: any) => ({
        ...rule,
        values:
          !rule.values.length || typeof rule.values === "string"
            ? [rule.values]
            : rule.values,
      }));

      const converValuesToObject = converValuesToArray.map((rule: any) => ({
        ...rule,
        values: rule.values.map((value: any) =>
          MuitipleType.includes(rule.criteria)
            ? event[rule.criteria.toLowerCase() + "s"].filter(
              (x: any) => x.value === value
            )[0]
            : { value: value, label: value }
        ),
      }));

      const criteriaWithValue = converValuesToObject.map((rule: any) => ({
        ...rule,
        values: (() => {
          switch (rule.operator) {
            case WithinEventDuration:
              return [
                { value: startDate, label: startDate },
                { value: endDate, label: endDate },
              ];
            case IsToday:
              return null;
            default:
              return rule.values;
          }
        })(),
      }));

      const actionWithQuantity = actions.map((action: any) => ({
        ...action,
        quantity: action.quantity?.replaceAll(",", ""),
      }));

      let file: any;
      let criteriaWithNoValue;

      if (rules.length === 1 && rules[0].criteria === MemberListCriteria) {
        file = rules[0].values[0] || rules[0].values;
        criteriaWithNoValue = rules.map((rule: any) => ({
          ...rule,
          values: [],
        }));
      }

      const currentReason = event.reasons.find((reason: any) => reason.value === lookupAndReasonCodeID);

      const dataSubmit = pickBy(
        {
          description,
          eventName,
          reasonCode: currentReason.code || '',
          lookupAndReasonCodeID,
          startDate: startDate.format(DATE_FORMAT_FOR_SUBMIT),
          endDate: endDate.format(DATE_FORMAT_FOR_SUBMIT),
          actions: actionWithQuantity,
          ruleConditionType,
          operatorId: getOperatorId() || "",
          rules: formatRulesEventForApi(
            file ? criteriaWithNoValue : criteriaWithValue
          ),
          ...formatDelayEventForApi(
            delayDuration,
            delayDurationType,
            dateSentCompareType,
            dateSent
          ),
        },
        (data: any) => data !== null && data !== undefined
      ) as MemberCreditEventProps | DynamicPricingEventProps;

      let result;

      if (id || event.id) {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          formData.append(
            "body",
            JSON.stringify({
              eventId: Number(id) | event.eventId,
              ...dataSubmit,
            })
          );
          result = await updateMemberBenefitEventWithFile(formData);
        } else {
          const body = {
            eventId: Number(id) | event.eventId,
            ...dataSubmit,
          };
          result = await updateMemberBenefitEventWithoutFile(body);
        }
        !isCreate &&
          result.isSuccess &&
          showNotification(
            t("TS.SUCCESS"),
            t("TS.EDIT_EVENT_SUCCESSFULLY_MESSAGE"),
            NotificationType.success
          );
      } else {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          formData.append("body", JSON.stringify(dataSubmit));
          result = await submitEventDataWithFile(
            formData,
            EventType.MemberBenefit
          );
        } else {
          result = await submitEventData(dataSubmit, EventType.MemberBenefit);
        }
      }
      if (result.isSuccess) {
        history(
          routeUrls.memberBenefitEventDetail
            .replace(":id", result.eventId || id || event.id)
            .replace(":isCreateEvent", isCreate.toString())
        );
      } else {
        showNotification(
          t("SS_NOTIFICATION_SERVICES"),
          t("SS_NOTIFICATION_SERVICES_NOTIFICATION"),
          NotificationType.error
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  const _handleCancelButton = () => {
    if (isCreate) {
      window.parent.location.href =
        _env.REACT_APP_EMBEDDED_BASE_URL + "/Events/EventManagement.aspx" || "";
    } else {
      history(
        routeUrls.memberBenefitEventDetail
          .replace(":id", id!)
          .replace(":isCreateEvent", isCreate.toString())
      );
    }
  };

  const onFromDateChange = (date: any, _: string, isChangeEndDate: boolean) => {
    if (!isChangeEndDate) {
      const StartDate = form.getFieldValue("startDate");
      const nextStartDate = moment(StartDate, DATE_FORMAT).add(1, "days");
      if (!date.isBefore(StartDate)) {
        form.setFieldsValue({
          startDate: date,
          endDate: moment(nextStartDate),
        });
      }
      setStartDate(date);
      return;
    }
    setEndDate(date);
  };

  return (
    <>
      <HeadingGroup
        title={
          isCreate
            ? t("TS.MEMBER_BENEFIT.CREATE_MEMBER_BENEFIT")
            : t("TS.MEMBER_BENEFIT.EDIT_MEMBER_BENEFIT")
        }
      />

      <Form
        name="basic"
        form={form}
        initialValues={formInitValues}
        layout="vertical"
        hideRequiredMark
      >
        <EventInformation form={form} onFromDateChange={onFromDateChange} />
        <RuleSetting form={form} startDate={startDate} endDate={endDate} />
        <FormContainerStyled>
          <GroupButtons position="center">
            <ButtonStyled type="default" onClick={_handleCancelButton}>
              {t("TS.MEMBER_BENEFIT.CANCEL_BUTTON")}
            </ButtonStyled>
            <ButtonStyled type="primary" onClick={onSubmit}>
              {isCreate
                ? t("TS.MEMBER_BENEFIT.NEXT_BUTTON")
                : t("TS.MEMBER_BENEFIT.SAVE_BUTTON")}
            </ButtonStyled>
          </GroupButtons>
        </FormContainerStyled>
      </Form>
    </>
  );
};

export default CreateMemberBenefitEvent;
