import React from "react";

import ActionGroup from "./RuleAction";
import { EventType } from "utils/constants";
import ConditionGroup from "./RuleCondition";
import { Col, FormInstance, Row } from "antd";
import { useTranslation } from "react-i18next";
import Delay from "components/Delay";
import DynamicPricingGroup from "./RuleDynamicPricing";
import {
  BorderTitleStyled,
  FormContainerStyled,
  SessionTitleStyled,
} from "../Styled";
import moment from "moment";

interface IProps {
  typeOfEvent: string;
  form: FormInstance;
  startDate: Date;
  endDate: Date;
}

const EventRule: React.FC<IProps> = ({ typeOfEvent, form, startDate, endDate }) => {
  const { t } = useTranslation();
  return (
    <>
      <SessionTitleStyled>
        {t("SS.CREATE_EVENT.RULE_SETTINGS")}
      </SessionTitleStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 2 }}
            xs={{ span: 2 }}
            style={{ paddingLeft: 10, paddingRight: 0 }}
          >
            <BorderTitleStyled>{t("SS.CREATE_EVENT.IF")}</BorderTitleStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ paddingLeft: 0, paddingRight: 7 }}
          >
            <ConditionGroup form={form} />
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 2 }}
            xs={{ span: 2 }}
            style={{ paddingLeft: 10, paddingRight: 0 }}
          >
            <BorderTitleStyled>{t("SS.CREATE_EVENT.THEN")}</BorderTitleStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ paddingLeft: 0, paddingRight: 7 }}
          >
            {typeOfEvent === EventType.MemberCredit ? (
              <ActionGroup form={form} />
            ) : (
              <DynamicPricingGroup form={form} />
            )}
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Delay
          title={
            typeOfEvent === EventType.MemberCredit
              ? t("TS.MEMBER.CREDIT.SEND")
              : typeOfEvent === EventType.DynamicPricing
                ? t("TS.DYNAMIC.PRICING.APPLIED")
                : t("TS.GUEST.PASS.SENT")
          }
          form={form}
          startDate={startDate}
          endDate={endDate}
        />
      </FormContainerStyled>
    </>
  );
};

export default EventRule;
