import styled, { SimpleInterpolation } from "styled-components";

export const PopoverTextStyled = styled.span<{
  $maxWidth?: number;
  $underline?: boolean;
  $width?: number;
  $color?: string;
}>`
  margin: 0;
  display: block;
  text-align: left;
  padding-top: 4px;
  .ant-btn {
    white-space: initial;
    > span {
      text-align: left;
    }
  }
  ${(props): SimpleInterpolation =>
    props.$underline ? `text-decoration: underline;` : ``}
  ${(props): SimpleInterpolation => `width: ${props.$width}px;`}
  ${(props): SimpleInterpolation => `color: ${props.$color};`}
  ${(props): SimpleInterpolation => `max-width: ${props.$maxWidth}px;`}
`;

export const PopoverContentStyled = styled.div`
  font-size: 11px;
  max-width: 300px;
  word-break: break-all;
`;
