import { Col, FormInstance, Row } from "antd";
import Delay from "components/Delay";
import {
  BorderTitleStyled,
  FormContainerStyled,
  SessionTitleStyled,
} from "pages/CreateUpdateEvent/Styled";
import React from "react";
import { useTranslation } from "react-i18next";
import RuleAction from "./RuleAction";
import ConditionGroup from "./RuleCondition";

interface IProps {
  form: FormInstance;
  startDate: Date;
  endDate: Date;
}

const RuleSetting: React.FC<IProps> = ({ form, startDate, endDate }) => {
  const { t } = useTranslation();
  return (
    <>
      <SessionTitleStyled>
        {t("SS.CREATE_EVENT.RULE_SETTINGS")}
      </SessionTitleStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 2 }}
            xs={{ span: 2 }}
            style={{ paddingLeft: 10, paddingRight: 0 }}
          >
            <BorderTitleStyled>{t("SS.CREATE_EVENT.IF")}</BorderTitleStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ paddingLeft: 0, paddingRight: 7 }}
          >
            <ConditionGroup form={form} />
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Row gutter={24}>
          <Col
            className="gutter-row"
            md={{ span: 2 }}
            xs={{ span: 2 }}
            style={{ paddingLeft: 10, paddingRight: 0 }}
          >
            <BorderTitleStyled>{t("SS.CREATE_EVENT.THEN")}</BorderTitleStyled>
          </Col>
          <Col
            className="gutter-row"
            md={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ paddingLeft: 0, paddingRight: 7 }}
          >
            <RuleAction form={form} />
          </Col>
        </Row>
      </FormContainerStyled>
      <FormContainerStyled>
        <Delay title={t("TS.MEMBER.BENEFIT.SENT")} form={form} startDate={startDate} endDate={endDate}/>
      </FormContainerStyled>
    </>
  );
};

export default RuleSetting;
