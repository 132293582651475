import { Button, Input, Select } from "antd";
import styled, { SimpleInterpolation } from "styled-components";

export const BlankIconButtonStyled = styled(Button)`
  font-size: 13px;
  border: none;
  height: 20px;
  color: var(--navi-color);
  background: transparent;
  box-shadow: none;
  padding: 0 0 0 3px;
  text-decoration: underline;
  &:focus {
    color: var(--blue-color);
    background: transparent !important;
  }
  &:hover {
    background: transparent !important;
    box-shadow: none;
  }
  &.ant-btn[disabled] {
    background: transparent !important;
  }
`;
export const HeaderTitleStyled = styled.p<{ $fontSize?: number }>`
  ${(props): SimpleInterpolation => `font-size: ${props.$fontSize}px;`}
  font-weight: bold;
  margin-top: 2px;
  color: var(--navi-color);
`;

export const AlignTextStyled = styled.p<{ $textAlign?: string }>`
  color: var(--navi-color);
  margin: 0;
  ${(props): SimpleInterpolation => `text-align: ${props.$textAlign};`}
`;

export const Container = styled.div`
  margin-left: -12px;
  margin-top: -20px;
`;

export const TransactionListStyled = styled.div`
  background-color: var(--white-color);
`;

export const EmptyListStyled = styled.div`
  text-align: center;
`;

export const ModalContent = styled.span`
  font-size: 12px;
  color: var(--gray-color);
`;

export const InputStyled = styled(Input)`
  &.ant-input {
    &:hover {
      border-color: var(--primary-color);
    }
    &:focus {
      border-color: var(--primary-color);
    }
  }
`;

export const Boundary = styled.div``;

export const BoundaryInputStyled = styled.div<{ $displayType?: string }>`
  ${(props): SimpleInterpolation => `display: ${props.$displayType};`}
  justify-content: flex-start;
`;
