import { Form } from "antd";
import _, { pickBy } from "lodash";
import GroupButtons from "components/GroupButtons";
import HeadingGroup from "components/HeadingGroup";
import moment from "moment";
import { FormContainerStyled } from "pages/CreateUpdateEvent/Styled";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { getOperatorId } from "services/common/accessToken";
import {
  getGuestPassEvent,
  submitGuestPassEventData,
  submitGuestPassEventDataWithFile,
  updateGuestPassEvent,
  updateGuestPassEventWithFile,
} from "services/guestPass";
import { useAppSelector } from "store";
import { getGuestPassEventMasterDataAction } from "store/reducers/Event/actions";
import ButtonStyled from "styled/ButtonStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import {
  ADD_DELAY,
  Between,
  DATE_FORMAT,
  DATE_FORMAT_FOR_SUBMIT,
  DURATION_TYPE_MEET_CRITERIA,
  GuestPassActionCode,
  IsToday,
  MEET_CRITERIA,
  MEET_CRITERIA_EXACT,
  MemberListCriteria,
  MuitipleType,
  NotificationType,
  Type,
  WithinEventDuration,
} from "utils/constants";
import { onFormatAmount, showNotification } from "utils/helpers";
import { routeUrls } from "utils/routes";
import { INIT_GUEST_PASS_EVENT } from "./data";
import EventInformation from "./EventInformation";
import EventRule from "./EventRule";
import {
  formatDelayEventForApi,
  formatRuleForForm,
  formatRulesEventForApi,
} from "services/event";

const CreateGuestPassEvent: React.FC = () => {
  const [formInitValues, setFormInitValues] = useState<any>(
    INIT_GUEST_PASS_EVENT
  );
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const event = useAppSelector((state) => state.event);
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [startDate, setStartDate] = useState(form.getFieldValue("startDate"));
  const [endDate, setEndDate] = useState(form.getFieldValue("endDate"));

  useEffect(() => {
    dispatch(getGuestPassEventMasterDataAction());
  }, []);

  const updateFormValues = useCallback(
    (data: any): void => {
      const nextStartDate = moment(new Date(), DATE_FORMAT).add(1, "days");
      const startDate = data.startDate
        ? moment(new Date(data.startDate), DATE_FORMAT).utcOffset(0)
        : moment(new Date(), DATE_FORMAT);
      const endDate = data.endDate
        ? moment(
          new Date(data.endDate.substring(0, 10) + "T00:00:00Z"),
          DATE_FORMAT
        ).utcOffset(0)
        : moment(nextStartDate, DATE_FORMAT);

      const covertRules = data.rules.map((item: any) => ({
        ...item,
        values:
          item.dataType === Type.Date &&
            item.operator !== Between &&
            item.values.length > 0
            ? moment(item.values[0].value)
            : item.values.map((v: any) =>
              item.dataType === Type.Date
                ? moment(v.value)
                : v.value
            ),
      }));

      const rules = covertRules.map((rule: any) =>
        formatRuleForForm(rule, id || event.eventId)
      );
      const actions = data.actions.map((item: any) => ({
        ...item,
        quantity: onFormatAmount(item.quantity.toString()),
      }));

      if (data.delayDuration && data.delayDuration !== 0) {
        data.when = ADD_DELAY;
      } else if (data.dateSentCompareType) {
        data.when = MEET_CRITERIA_EXACT;
      } else {
        data.when = MEET_CRITERIA;
      }
      if (
        data.dateSentCompareType === DURATION_TYPE_MEET_CRITERIA.Exact &&
        data.dateSent
      ) {
        data.dateSentCompareType = DURATION_TYPE_MEET_CRITERIA.Exact;
      }

      form.setFieldsValue({
        ...data,
        rules,
        actions,
        startDate,
        endDate,
      });

      setFormInitValues({
        ...data,
        rules,
        actions,
        startDate,
        endDate,
      });
    },

    [form]
  );

  const getInitalEventData = async () => {
    setLoading(true);
    if (id) {
      const data = await getGuestPassEvent(id!);
      setIsCreate(data.status === "PD");
      updateFormValues(data);
    } else {
      updateFormValues(INIT_GUEST_PASS_EVENT);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitalEventData();
  }, []);

  const onSubmit = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const {
        description = "",
        eventName = "",
        reasonCode = "",
        lookupAndReasonCodeID = "",
        startDate = "",
        endDate = "",
        rules,
        actions,
        ruleConditionType = "",
        delayDuration,
        delayDurationType,
        dateSentCompareType,
        dateSent,
      } = values;

      const converValuestoArray = rules.map((rule: any) => ({
        ...rule,
        values:
          !rule.values.length || typeof rule.values === "string"
            ? [rule.values]
            : rule.values,
      }));

      const converValuesToObject = converValuestoArray.map((rule: any) => ({
        ...rule,
        values: rule.values.map((value: any) =>
          MuitipleType.includes(rule.criteria)
            ? event[rule.criteria.toLowerCase() + "s"].filter(
              (x: any) => x.value === value
            )[0]
            : { value: value, label: value }
        ),
      }));

      const convertOperatorWithinDuration = converValuesToObject.map(
        (rule: any) => ({
          ...rule,
          values: (() => {
            switch (rule.operator) {
              case WithinEventDuration:
                return [
                  { value: startDate, label: startDate },
                  { value: endDate, label: endDate },
                ];
              case IsToday:
                return null;
              default:
                return rule.values;
            }
          })(),
        })
      );

      const criteriaWithValue = convertOperatorWithinDuration;

      let file: any;
      let criteriaWithNoValue;

      if (rules.length === 1 && rules[0].criteria === MemberListCriteria) {
        file = rules[0].values[0] || rules[0].values;
        criteriaWithNoValue = rules.map((rule: any) => ({
          ...rule,
          values: [],
        }));
      }

      const convertActions = actions.map((action: ActionProps) => ({
        ...action,
        quantity:
          action.actionCode == GuestPassActionCode.EXTEND_GUEST_PASS
            ? 0
            : action.quantity.toString().replaceAll(",", ""),
      }));

      const currentReason = event.reasons.find((reason: any) => reason.value === lookupAndReasonCodeID);

      const dataSubmit = pickBy(
        {
          description,
          eventName,
          reasonCode: currentReason.code || '',
          lookupAndReasonCodeID,
          startDate: startDate.format(DATE_FORMAT_FOR_SUBMIT),
          endDate: endDate.format(DATE_FORMAT_FOR_SUBMIT),
          actions: convertActions,
          ruleConditionType,
          operatorId: getOperatorId() || "0",
          rules: formatRulesEventForApi(
            file ? criteriaWithNoValue : criteriaWithValue
          ),
          ...formatDelayEventForApi(
            delayDuration,
            delayDurationType,
            dateSentCompareType,
            dateSent
          ),
        },
        (data) => data !== null && data !== undefined
      ) as GuestPassEventProps;

      let result: ResultProps;

      if (id || event.id) {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          formData.append(
            "body",
            JSON.stringify({
              eventId: Number(id) | event.eventId,
              ...dataSubmit,
            })
          );
          result = await updateGuestPassEventWithFile(formData);
        } else {
          const body = {
            eventId: Number(id) | event.eventId,
            ...dataSubmit,
          };
          result = await updateGuestPassEvent(body);
        }
        !isCreate &&
          result.isSuccess &&
          showNotification(
            t("TS.SUCCESS"),
            t("TS.EDIT_EVENT_SUCCESSFULLY_MESSAGE"),
            NotificationType.success
          );
      } else {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          formData.append("body", JSON.stringify(dataSubmit));
          result = await submitGuestPassEventDataWithFile(formData);
        } else {
          result = await submitGuestPassEventData(dataSubmit);
        }
      }
      if (result.isSuccess) {
        history(
          routeUrls.previewGuestPassEvent
            .replace(":id", result.eventId || id || event.id)
            .replace(":isCreateEvent", `${isCreate}`)
        );
      } else {
        showNotification(
          t("SS_NOTIFICATION_SERVICES"),
          t("SS_NOTIFICATION_SERVICES_NOTIFICATION"),
          NotificationType.error
        );
      }
    } catch (error) { }
  };

  if (loading) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  const _handleCancelButton = () => {
    if (isCreate) {
      window.parent.location.href =
        _env.REACT_APP_EMBEDDED_BASE_URL + "/Events/EventManagement.aspx" || "";
    } else {
      history(
        routeUrls.previewGuestPassEvent
          .replace(":id", id!)
          .replace(":isCreateEvent", `${isCreate}`)
      );
    }
  };

  const onFromDateChange = (date: any, _: string, isChangeEndDate: boolean) => {
    if (!isChangeEndDate) {
      const StartDate = form.getFieldValue("startDate");
      const nextStartDate = moment(StartDate, DATE_FORMAT).add(1, "days");
      if (!date.isBefore(StartDate)) {
        form.setFieldsValue({
          startDate: date,
          endDate: moment(nextStartDate),
        });
      }
      setStartDate(date);
      return;
    }
    setEndDate(date);
  };

  return (
    <>
      <HeadingGroup
        title={
          isCreate
            ? t("TS.GUEST_PASS_EVENT.TITLE")
            : t("TS.GUEST_PASS_EVENT.EDIT_TITLE")
        }
      />
      <Form
        name="basic"
        form={form}
        initialValues={formInitValues}
        layout="vertical"
        hideRequiredMark
      >
        <EventInformation form={form} onFromDateChange={onFromDateChange} />
        <EventRule form={form} startDate={startDate} endDate={endDate} />
        <FormContainerStyled>
          <GroupButtons position="center">
            <ButtonStyled type="default" onClick={_handleCancelButton}>
              {t("TS.MEMBER_BENEFIT.CANCEL_BUTTON")}
            </ButtonStyled>
            <ButtonStyled type="primary" onClick={onSubmit}>
              {isCreate
                ? t("TS.MEMBER_BENEFIT.NEXT_BUTTON")
                : t("TS.MEMBER_BENEFIT.SAVE_BUTTON")}
            </ButtonStyled>
          </GroupButtons>
        </FormContainerStyled>
      </Form>
    </>
  );
};

export default CreateGuestPassEvent;
