import { http } from "./common";
import {
  DELETE_DYNAMIC_PRICING_EVENT,
  GET_APPLICABLE_SALE_LINE_ITEM,
  GET_DOWNLOAD_FILE,
  GET_DYNAMIC_PRICING_DOWNLOAD_FILE,
  GET_DYNAMIC_PRICING_EVENT_CONFIG,
  GET_DYNAMIC_PRICING_EVENT_DETAIL,
  GET_EVENT,
} from "./common/apis";
export const updateEndDate = async (
  eventId: string,
  body: any
): Promise<any> => {
  const res = await http.put(GET_EVENT.replace("{eventId}", eventId), body);
  return res.data;
};

export const getDownloadFile = async (eventId: string): Promise<any> => {
  const res = await http.get(GET_DOWNLOAD_FILE.replace("{eventId}", eventId));
  return res;
};

export const getDynamicPricingEventDetail = async (
  eventId: string,
  pagingInfo: { pageIndex: number; pageSize: number }
): Promise<any> => {
  const res = await http.get(
    GET_DYNAMIC_PRICING_EVENT_DETAIL.replace("{eventId}", eventId)
      .replace("{pageIndex}", `${pagingInfo.pageIndex}`)
      .replace("{pageSize}", `${pagingInfo.pageSize}`)
  );
  return res.data;
};

export const getApplicableSaleItem = async (
  eventId: string,
  pagingInfo: { pageIndex: number; pageSize: number }
): Promise<any> => {
  const res = await http.get(
    GET_APPLICABLE_SALE_LINE_ITEM.replace("{eventId}", eventId)
      .replace("{pageIndex}", `${pagingInfo.pageIndex}`)
      .replace("{pageSize}", `${pagingInfo.pageSize}`)
  );
  return res.data;
};

export const getDynamicPricingDownloadFile = async (
  eventId: string
): Promise<any> => {
  const res = await http.get(
    GET_DYNAMIC_PRICING_DOWNLOAD_FILE.replace("{eventId}", eventId)
  );
  return res;
};

export const deleteDynamicPricingEvent = async (
  eventId: string
): Promise<any> => {
  const res = await http.delete(
    DELETE_DYNAMIC_PRICING_EVENT.replace("{eventId}", eventId)
  );
  return res;
};

export const getDynamicPricingEventConfig = async (
  eventId: string
): Promise<any> => {
  const res = await http.get(
    GET_DYNAMIC_PRICING_EVENT_CONFIG.replace("{eventId}", eventId)
  );
  return res.data;
};
