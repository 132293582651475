import SurveyTodo from "./SurveyTodo";

interface ITodo {
  contactType: string;
  surveyToDoDetails: SurveyTodo[];
  latestActionDate: string;
}

class Todo implements ITodo {
  contactType: string;
  surveyToDoDetails: SurveyTodo[];
  latestActionDate: string;
  constructor(todo: ITodo) {
    this.surveyToDoDetails = todo.surveyToDoDetails;
    this.latestActionDate = todo.latestActionDate;
    this.contactType = todo.contactType;
  }

  hasSurveyTodo = () => this.surveyToDoDetails.length > 0;

  getComment = (): string => {
    if (this.hasSurveyTodo()) {
      return this.surveyToDoDetails[0].comment;
    }
    return "";
  };

  getTotalEmail = () =>
    this.surveyToDoDetails.filter(
      (surveyTodo) => surveyTodo.contactType === "Email"
    ).length;
}

export default Todo;
