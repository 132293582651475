
import { Member, Paging } from 'pages/ProcessMember/type';
import { http } from './common';
import {
  GET_ALL_CLUBS,
  ARCHIVE_RECORD,
  CONVERT_RECORD,
  SEARCH_TEMP_MEMBERS
} from './common/apis';

export const searchTempMembers = async (body: {
  sorts: string,
  filter: string,
  pageIndex: number,
  pageSize: number,
  clubId?: number | string
}): Promise<{
  data: Member[],
  paging: Paging
}> => {
  const res = await http.post(SEARCH_TEMP_MEMBERS, { ...body });
  return res.data;
};


export const getAllClubs = async () => {
  const res = await http.get(GET_ALL_CLUBS);
  return res.data.data
}

export const updateArchiveRecord = async (id: string) => {
  const res = await http.put(ARCHIVE_RECORD.replace('{id}', id), {});
  return res.data
}

export const updateConvertRecord = async (id: string) => {
  const res = await http.post(CONVERT_RECORD, {
    Id: id
  });
  return res.data
}