import styled from 'styled-components/macro';

interface Props {
  position: string | undefined;
}

const GroupButtonsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${(props: Props): string | null => (props.position === 'right' ? 'justify-content: flex-end;' : null)}
  ${(props: Props): string | null => (props.position === 'center' ? 'justify-content: center;' : null)}
  .ant-btn {
    margin-bottom: 15px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
    .ant-btn {
      margin-right: 0;
      width: calc(50% - 7px);
      &:last-child:nth-child(2n + 1) {
        width: 100%;
      }
    }
  }
`;

export default GroupButtonsStyled;
