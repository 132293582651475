import 'antd/dist/antd.min.css';
import 'styles/index.css';
import 'styles/notification.css';
import 'styles/modal-confirm.css';
import 'styles/tooltip.css';
import 'styles/date-picker.css';
import './App.css';
import 'simplebar/dist/simplebar.min.css';

import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';

import ForbiddenPage from 'pages/Forbidden';
import { getTokenByOneTimeToken, getToken } from 'services/common';
import {
  setAccessToken,
  setDefaultUserInfo,
  setOneTimeToken,
  setOperatorId
} from 'services/common/accessToken';
import store from 'store';
import { routeUrls } from 'utils/routes';
import { SpinStyled, SpinWrapperStyled } from 'styled/SpinLoader';
import TransactionList from 'pages/TransactionList';
import TransactionDetail from 'pages/TransactionDetail';
import AllMemberTransactionList from 'pages/AllMemberTransactionList';
import AllMemberTransactionDetail from 'pages/AllMemberTransactionDetail';
import CreateUpdateEvent from 'pages/CreateUpdateEvent';
import PreviewEvent from 'pages/PreviewEvent';
import ManageEvenList from 'pages/ManageEventList';
import AddMemberCredit from 'pages/AddMemberCredit';
import ViewMemberCreditSpending from 'pages/ViewMemberCreditSpending';
import { EventType } from 'utils/constants';
import DynamicPricingEventDetail from 'pages/DynamicPricingEventDetail';
import ManageGate from 'pages/ManageGate';
import GateRuleDetail from 'pages/GateRuleDetail';
import ConfigGateRule from 'pages/ConfigGateRule';
import CreateMemberBenefitEvent from 'pages/CreateMemberBenefitEvent';
import MemberBenefitEventDetail from 'pages/MemberBenefitEventDetail';
import ViewMemberBenefitSpending from 'pages/ViewMemberBenefitSpending';
import AddMemberBenefit from 'pages/AddMemberBenefit';
import CreateGuestPassEvent from 'pages/CreateGuestPass';
import CreateSurvey from 'pages/CreateSurvey';
import SurveyDashboard from 'pages/SurveyDashboard';
import PreviewGuestPassEvent from 'pages/PreviewGuestPassEvent';
import AddGuestPass from 'pages/AddGuestPass';
import ViewGuestPassSpending from 'pages/ViewGuestPassSpending';
import ViewMemberSurveyHistory from 'pages/ViewMemberSurveyHistory';
import LinkedMember from 'pages/LinkedMembership/LinkedMember';
import HeadMember from 'pages/LinkedMembership/HeadMember';
import ProcessMember from 'pages/ProcessMember';

const RouterlessApp: React.FC = () => (
  <Suspense fallback={<></>}>
    <Routes>
      <Route path={routeUrls.root}>Welcome to Third Space</Route>
      <Route path={routeUrls.forbidden} element={<ForbiddenPage />} />
      <Route path={routeUrls.transactionList} element={<TransactionList />} />
      <Route
        path={routeUrls.transactionDetail}
        element={<TransactionDetail />}
      />
      <Route
        path={routeUrls.transactionAllMemberList}
        element={<AllMemberTransactionList />}
      />
      <Route
        path={routeUrls.transactionAllMemberDetail}
        element={<AllMemberTransactionDetail />}
      />
      <Route path={routeUrls.previewEventDetail} element={<PreviewEvent />} />
      <Route
        path={routeUrls.createEvent}
        element={<CreateUpdateEvent eventType={EventType.MemberCredit} />}
      />
      <Route
        path={routeUrls.editEvent}
        element={<CreateUpdateEvent eventType={EventType.MemberCredit} />}
      />
      <Route path={routeUrls.manageEvents} element={<ManageEvenList />} />
      <Route path={routeUrls.addMemberCredit} element={<AddMemberCredit />} />
      <Route
        path={routeUrls.viewMemberCreditSpending}
        element={<ViewMemberCreditSpending />}
      />
      <Route
        path={routeUrls.createDynamicPricingEvent}
        element={<CreateUpdateEvent eventType={EventType.DynamicPricing} />}
      />
      <Route
        path={routeUrls.dynamicPricingEventDetail}
        element={<DynamicPricingEventDetail />}
      />
      <Route
        path={routeUrls.editDynamicPricingEvent}
        element={<CreateUpdateEvent eventType={EventType.DynamicPricing} />}
      />
      <Route path={routeUrls.manageGate} element={<ManageGate />} />
      <Route path={routeUrls.viewGateRuleDetail} element={<GateRuleDetail />} />
      <Route path={routeUrls.configGateRule} element={<ConfigGateRule />} />
      <Route
        path={routeUrls.memberBenefitEventDetail}
        element={<MemberBenefitEventDetail />}
      />
      <Route
        path={routeUrls.createMemberBenefitEvent}
        element={<CreateMemberBenefitEvent />}
      />
      <Route
        path={routeUrls.editMemberBenefitEvent}
        element={<CreateMemberBenefitEvent />}
      />
      <Route
        path={routeUrls.viewMemberBenefitSpending}
        element={<ViewMemberBenefitSpending />}
      />
      <Route path={routeUrls.addMemberBenefit} element={<AddMemberBenefit />} />
      <Route
        path={routeUrls.createGuestPassEvent}
        element={<CreateGuestPassEvent />}
      />
      <Route path={routeUrls.createSurvey} element={<CreateSurvey />} />
      <Route path={routeUrls.surveyDashboard} element={<SurveyDashboard />} />
      <Route
        path={routeUrls.previewGuestPassEvent}
        element={<PreviewGuestPassEvent />}
      />
      <Route
        path={routeUrls.editGuestPassEvent}
        element={<CreateGuestPassEvent />}
      />
      <Route path={routeUrls.createGuestPass} element={<AddGuestPass />} />
      <Route
        path={routeUrls.viewGuestPassSpending}
        element={<ViewGuestPassSpending />}
      />
      <Route
        path={routeUrls.viewMemberSurveyHistory}
        element={<ViewMemberSurveyHistory />}
      />
      <Route path={routeUrls.processMember} element={<ProcessMember />} />
      <Route path={routeUrls.linkedMember} element={<LinkedMember />} />
      <Route path={routeUrls.headMember} element={<HeadMember />} />
    </Routes>
  </Suspense>
);

const LOGIN_INFO = 'LOGIN_INFO';

const getUrlParameter = (key: string) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(key) || '';
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  const getInfoFromStore = () => {
    const loginInfo = sessionStorage.getItem(LOGIN_INFO) || '';
    const data = JSON.parse(loginInfo);
    setAccessToken(data.accessToken);
    setDefaultUserInfo({
      'x-tenant-id': data.tenantId,
      'x-subtenant-id': data.subTenantId,
      domain: data.domain
    });
  };
  useEffect(() => {
    (async (): Promise<void> => {
      const accessToken = getUrlParameter('accessCode');
      setOperatorId(getUrlParameter('operatorId'));
      const token = accessToken;
      const isDev = window.location.hostname.indexOf('localhost') > -1;

      switch (true) {
        case !!token:
          setOneTimeToken(token);
          try {
            const res: any = await getTokenByOneTimeToken(token);
            const messageParse = JSON.parse(res.data.message);
            setAccessToken(messageParse.accessToken);

            const tenant = JSON.parse(messageParse.subTenants).find(
              (t: any) => t.domain === messageParse.domain
            );

            setDefaultUserInfo({
              'x-tenant-id': tenant?.tenantId,
              'x-subtenant-id': tenant?.subTenantId,
              domain: messageParse.domain
            });

            try {
              sessionStorage.setItem(
                LOGIN_INFO,
                JSON.stringify({
                  accessToken: messageParse.accessToken,
                  tenantId: tenant.tenantId,
                  subTenantId: tenant.subTenantId,
                  domain: messageParse.domain
                })
              );
            } catch {
              console.log('Info: ', 'Can not read session Storage');
            }
          } catch {
            if (!!sessionStorage.getItem(LOGIN_INFO)) {
              getInfoFromStore();
            }
          }
          break;
        case !!sessionStorage.getItem(LOGIN_INFO):
          {
            getInfoFromStore();
          }
          break;
        case isDev === true:
          {
            const res = await getToken();
            setAccessToken(res.data.access_token);
            setDefaultUserInfo({
              'x-tenant-id': 'D3F50F7C-AEAB-4A04-969E-67DBC9DE3701',
              'x-subtenant-id': '0F45D9A2-30C0-45D0-8AB4-B340B3A55660'
            });
          }
          break;
        default:
          history(routeUrls.forbidden);
          break;
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }
  return <RouterlessApp />;
};

const AppWrapper: React.FC = () => (
  <Suspense
    fallback={
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    }
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </Suspense>
);

export default AppWrapper;
