import React from 'react';

import ForbiddenStyled, { ForbiddenContentStyled } from './Styled';

import { NoTouchIcon } from 'components/Icon';

const ForbiddenPage = () => (
  <>
    <ForbiddenStyled>
      <NoTouchIcon />
    </ForbiddenStyled>
    <ForbiddenContentStyled>
      <h1>403</h1>
      <p>
        We are sorry, but you do not have access to this page or resource
      </p>
    </ForbiddenContentStyled>
  </>
);

export default ForbiddenPage;
