import React from "react";

import moment, { Moment } from "moment";
import { Form, Row, Col, DatePicker } from "antd";
import {
  DATE_FORMAT,
  DATE_FORMAT_FOR_SUBMIT,
  NotificationType,
} from "utils/constants";
import { InputTitleStyled } from "pages/ManageEventList/Styled";
import { FormContainerStyled } from "pages/CreateUpdateEvent/Styled";
import { useTranslation } from "react-i18next";
import {
  ModalHeadingStyled,
  ModalStyled,
  ModalTitleStyled,
} from "styled/ModalStyled";
import { FormItemStyled } from "styled/FormStyled";
import { updateEndDate } from "services/transaction";
import { showNotification } from "utils/helpers";

interface IProps {
  isOpen: boolean;
  callbackAfterUpdate: (open: boolean, isChanged: boolean) => void;
  eventId: string;
  minDate: Moment;
  endDate: string;
}

const ChangeEndDate: React.FC<IProps> = ({
  isOpen,
  callbackAfterUpdate,
  eventId,
  minDate,
  endDate,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleCancel = () => {
    form.resetFields();
    callbackAfterUpdate(false, false);
  };

  const changeEndDate = async () => {
    try {
      await form.validateFields();
      const { newEndDate } = await form.getFieldsValue();
      const res = await updateEndDate(eventId, {
        endDate: newEndDate.format(DATE_FORMAT_FOR_SUBMIT),
      });

      if (res.isSuccess) {
        form.resetFields();
        callbackAfterUpdate(false, true);
        showNotification(
          t("SS.SUCCESSFULLY"),
          t("TS.CHANGE_END_DATE.UPDATE_SUCCESS_MESSAGE"),
          NotificationType.success,
          2
        );
      } else {
        showNotification(
          t("SS.FAIL"),
          t("TS.CHANGE_END_DATE.UPDATE_FAIL_MESSAGE"),
          NotificationType.error
        );
      }
    } catch (error: any) {
      if (!error.errorFields) {
        callbackAfterUpdate(false, false);
        showNotification(
          t("SS.FAIL"),
          t("TS.CHANGE_END_DATE.UPDATE_FAIL_MESSAGE"),
          NotificationType.error
        );
      }
    }
  };

  return (
    <>
      <ModalStyled
        title={
          <ModalTitleStyled>
            {t("TS.DYNAMIC_PRICING_EVENT.CHANGE_END_DATE.TITLE")}
          </ModalTitleStyled>
        }
        visible={isOpen}
        onOk={changeEndDate}
        width="400px"
        okText={t("SS.SAVE")}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <ModalHeadingStyled>
          {t("TS.CHANGE_END_DATE.CONTENT")}
        </ModalHeadingStyled>
        <Form
          name="basic"
          layout="vertical"
          hideRequiredMark
          initialValues={{ newEndDate: moment(endDate, DATE_FORMAT) }}
          form={form}
        >
          <FormContainerStyled style={{ marginTop: 20 }}>
            <Row gutter={24}>
              <Col className="d-flex" md={{ span: 7 }} xs={{ span: 7 }}>
                <InputTitleStyled>
                  {t("SS.CREATE_EVENT.END_DATE")}
                </InputTitleStyled>
              </Col>
              <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
                <FormItemStyled
                  name="newEndDate"
                  $color="var(--navi-color)"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value.isAfter(moment(new Date()))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("TS.CHANGE_END_DATE.ERROR_MESSAGE"))
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    size="middle"
                    autoFocus
                    disabledDate={(d: Moment) => {
                      return d.isSameOrBefore(minDate);
                    }}
                    format={DATE_FORMAT}
                    allowClear={false}
                  />
                </FormItemStyled>
              </Col>
            </Row>
          </FormContainerStyled>
        </Form>
      </ModalStyled>
    </>
  );
};
export default ChangeEndDate;
