import styled, {SimpleInterpolation} from "styled-components";

export const HeaderStyled = styled.p<{ $textAlign?: string, $fontSize?:number}>`
  
  font-weight: bold;
  color: var(--gray-color);
  margin: 15px 0 15px 0 ;
  text-align: left;
  ${(props): SimpleInterpolation => (props.$textAlign ? `text-align: ${props.$textAlign};` : 'text-align: left;')}
  ${(props): SimpleInterpolation => (props.$fontSize ? `font-size: ${props.$fontSize}px;` : 'font-size: 12px;')}
`;
export const RuleListContainerStyled = styled.div`
  background-color: var(--white-color);
  margin-top: 10px;
`;
export const TextField = styled.div`
  text-align: center;
 
`