import { GET_CLUB_LIST, ClubListAction, SET_CLUB_LIST } from "./types";

interface StateProps {
  loading: boolean;
  data: ClubProps[];
}

const initState: StateProps = {
  loading: true,
  data: [],
};

export default (state = initState, action: ClubListAction): StateProps => {
  switch (action.type) {
    case GET_CLUB_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_CLUB_LIST:
      return {
        loading: false,
        data: action.data,
      };
    default:
      return state;
  }
};
