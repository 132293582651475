import { DatePicker, FormInstance, Select } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import Icon from "components/Icon";
import { MEMBER_TYPE, TIER } from "constants/Criteria";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { FormItemStyled } from "styled/FormStyled";
import {
  ColStyled,
  IconButtonContainer,
  PresetGroupSelectStyled,
  RangePickerStyled,
  RoundButtonStyle,
  RowStyled,
} from "../../CreateUpdateEvent/Styled";

import useInputType from "./hooks/useInputType";
import { Between, DATE_FORMAT, Type } from "utils/constants";
import { findCriteriaByName } from "utils/criterias";

interface ListItemProps {
  groupField: FormListFieldData;
  index: number;
  fieldsLength: number;
  remove: (name: number) => void;
  form: FormInstance;
  updateCriteriaExist: (criteria: string[]) => void;
  criteriaExist: string[];
}

const ConditionItem: React.FC<ListItemProps> = (props) => {
  const {
    groupField,
    fieldsLength,
    remove,
    form,
    index,
    updateCriteriaExist,
    criteriaExist,
  } = props;
  const { masterData } = useAppSelector((state) => state.gate);
  const [criterias, setCriterias] = useState<OptionProps[]>([]);
  const [operators, setOperators] = useState<OptionProps[]>([]);
  const [values, setValues] = useState<OptionProps[]>([]);
  const [currentCondition, setCurrentCondition] = useState<{
    Operator: string;
    Criteria: string;
    DateType: string;
  }>({
    Operator: "",
    Criteria: "",
    DateType: "",
  });
  const { Option } = Select;

  const { t } = useTranslation();

  useEffect(() => {
    const { Conditions } = form.getFieldsValue();
    updateCriteriaExist(
      Conditions ? Conditions.map((condition: any) => condition.Criteria) : [],
    );
  }, [form.getFieldValue("Conditions")]);

  const removeRule = () => {
    remove(groupField.name);
  };

  const buildCriteriaList = useCallback(() => {
    const criteriaList: OptionProps[] = masterData.criterias.map(
      (criteria: any) => ({
        label: criteria.displayName,
        value: criteria.criteriaName,
      }),
    );
    setCriterias(
      criteriaList.filter(
        (criteria: OptionProps) => criteria.value != MEMBER_TYPE,
      ),
    );
  }, [masterData]);

  useEffect(() => {
    if (masterData) {
      buildCriteriaList();
    }
    const currentCriteria = form.getFieldValue("Conditions")[index].Criteria;
    if (currentCriteria && masterData) {
      getOperatorsAndValues(currentCriteria);
      const criteria = findCriteriaByName(
        masterData.criterias,
        currentCriteria,
      );
      setCurrentCondition({
        ...form.getFieldValue("Conditions")[index],
        DateType: criteria.dataType,
      });
    }
  }, [masterData]);

  const getOperatorsAndValues = (value: string) => {
    const currentCriteria = masterData.criterias.find(
      (item: any) => item.criteriaName == value,
    );
    const operators = currentCriteria.operators.map((operator: any) => ({
      label: operator.displayName,
      value: operator.operatorName,
    }));
    setCurrentCondition(form.getFieldValue("Conditions"));
    if (currentCriteria.criteriaName === TIER) {
      setValues(
        masterData.tiers.map((value: any) => ({
          label: value.tierName,
          value: value.tierId.toString(),
        })),
      );
    } else {
      setValues([]);
    }
    setOperators(operators);
  };

  const { inputType } = useInputType(currentCondition);

  const handleChangeCriteria = (value: string) => {
    const values = form.getFieldsValue();
    if (values.Conditions[index]) {
      values.Conditions[index] = {
        ...values.Conditions[index],
        Operator: "",
        Values: [],
      };
    }
    getOperatorsAndValues(value);
  };

  const valueCol = useMemo(() => (fieldsLength > 1 ? 9 : 10), [fieldsLength]);

  const handleChangeOperator = () => {
    const conditions = form.getFieldValue("Conditions");
    const currentCondition = conditions[index];

    const criteria = findCriteriaByName(
      masterData.criterias,
      currentCondition.Criteria,
    );

    if (
      criteria.dataType === Type.Date &&
      currentCondition.Operator !== Between
    ) {
      conditions[index].Values = "";
      form.setFieldsValue({ ...conditions });
    }
    setCurrentCondition({ ...currentCondition, DateType: criteria.dataType });
  };

  const options = useMemo(() =>  {
    if(!masterData) {
      return  []
    }

    if(currentCondition.Criteria === TIER) {
      return values
    } else {
      return masterData.activeClubs.map((club: {clubId: number, clubName: string}) => ({
        label: club.clubName,
        value: club.clubId.toString(),
      }))
    }
  }, [masterData, currentCondition])

  return (
    <>
      <RowStyled $hasSpacingRight={fieldsLength === 1}>
        <ColStyled md={7} xs={7} style={{ paddingLeft: 0 }}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "Criteria"]}
            fieldKey={[groupField.fieldKey as number, "Criteria"]}
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.CRITERIA"),
                }),
              },
            ]}
          >
            <Select
              autoFocus={index ? true : false}
              placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                key: t("SS.CREATE_EVENT.CRITERIA"),
              })}
              onChange={handleChangeCriteria}
            >
              {criterias &&
                criterias.map((criteria: OptionProps) => {
                  const isDisable = criteriaExist.includes(criteria.value);
                  return (
                    <Option
                      key={criteria.value}
                      value={criteria.value}
                      style={{ background: `${isDisable ? "#dddddd" : ""}` }}
                      disabled={isDisable}
                    >
                      {criteria.label}
                    </Option>
                  );
                })}
            </Select>
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={7} xs={7}>
          <FormItemStyled
            {...groupField}
            name={[groupField.name, "Operator"]}
            fieldKey={[groupField.fieldKey as number, "Operator"]}
            rules={[
              {
                required: true,
                message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                  key: t("SS.CREATE_EVENT.OPERATOR"),
                }),
              },
            ]}
          >
            <Select
              placeholder={t("SS.CREATE_EVENT.SELECT_PLACE", {
                key: t("SS.CREATE_EVENT.OPERATOR"),
              })}
              onChange={handleChangeOperator}
              options={operators}
            />
          </FormItemStyled>
        </ColStyled>
        <ColStyled md={valueCol} xs={valueCol}>
          {currentCondition.Criteria !== "Companion" && (
            <>
              {inputType === "dateType" && (
                <FormItemStyled
                  {...groupField}
                  name={[groupField.name, "Values"]}
                  fieldKey={[groupField.fieldKey as number, "Values"]}
                  rules={[
                    {
                      required: true,
                      message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                        key: t("SS.CREATE_EVENT.VALUE"),
                      }),
                    },
                  ]}
                >
                  <DatePicker
                    size="middle"
                    format={DATE_FORMAT}
                    allowClear={false}
                  />
                </FormItemStyled>
              )}
              {inputType === "dateRangeType" && (
                <FormItemStyled
                  {...groupField}
                  name={[groupField.name, "Values"]}
                  fieldKey={[groupField.fieldKey as number, "Values"]}
                  rules={[
                    {
                      required: true,
                      message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                        key: t("SS.CREATE_EVENT.VALUE"),
                      }),
                    },
                  ]}
                >
                  <RangePickerStyled
                    size="middle"
                    format={DATE_FORMAT}
                    allowClear={false}
                  />
                </FormItemStyled>
              )}
              {inputType === "multiOptionType" && (
                <FormItemStyled
                  {...groupField}
                  name={[groupField.name, "Values"]}
                  fieldKey={[groupField.fieldKey as number, "Values"]}
                  rules={[
                    {
                      required: true,
                      message: t("SS.CREATE_EVENT.REQUIRED_FIELD", {
                        key: t("SS.CREATE_EVENT.VALUE"),
                      }),
                    },
                  ]}
                >
                  <PresetGroupSelectStyled
                    showArrow
                    mode="multiple"
                    options={options}
                    optionFilterProp="label"
                  />
                </FormItemStyled>
              )}
            </>
          )}
        </ColStyled>
        {fieldsLength > 1 && (
          <ColStyled md={1} xs={1} style={{ paddingRight: 0 }}>
            <IconButtonContainer>
              <RoundButtonStyle
                shape="circle"
                color="red"
                onClick={removeRule}
                icon={<Icon icon="icon-minus-ico" />}
              />
            </IconButtonContainer>
          </ColStyled>
        )}
      </RowStyled>
    </>
  );
};

export default ConditionItem;
