import { Col, Form, Row } from "antd";
import styled, { SimpleInterpolation } from "styled-components/macro";
import { mixin } from "utils/styles";

export const FormContainerStyled = styled.div`
  max-width: 917px;
  input,
  textarea {
    ${mixin.placeholderColor("var(--gray-color)")}
  }
  .ant-select-selection-placeholder {
    color: var(--gray-color);
    opacity: 1;
  }
`;

export const InputTitleStyled = styled.p`
  font-size: 12px;
  color: var(--gray-color);
  margin: 5px 0 5px 0;
`;

export const TitleStyle = styled.p<{ $textAlign?: string }>`
  font-size: 15px;
  font-weight: bold;
  color: var(--gray-color);
  margin: 15px 0 10px 0;
  text-align: left;
  ${(props): SimpleInterpolation =>
    props.$textAlign ? `text-align: ${props.$textAlign};` : "text-align: left;"}
`;
export const RowStyled = styled(Row)`
  margin-bottom: 5px;
`;

export const FormListStyled = styled(Form.List)`
  display: flex;
  flex-grow: 1;
`;

export const GroupStyled = styled.div`
  justify-content: space-between;
  > * {
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const ColStyled = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;
