import { Tooltip } from "antd";
import React from "react";

import { HiddenTooltipStyled } from "./Styled";

import Icon from "components/Icon";

const InformationTooltip: React.FC<{
  text: JSX.Element;
  className?: string;
}> = ({ text, className }) => (
  <Tooltip
    className={className}
    overlayClassName="description-tooltip"
    placement="top"
    title={<TooltipContent text={text} />}
  >
    <span style={{ color: "var(--green-color)" }}>
      <Icon icon="icon-detail-no-boder" />
    </span>
  </Tooltip>
);

const TooltipContent: React.FC<{ text: JSX.Element }> = ({ text }) => (
  <HiddenTooltipStyled>
    <div>{text}</div>
  </HiddenTooltipStyled>
);

export default InformationTooltip;
