import moment from 'moment';
import { SET_EVENT_SEARCH_FILTER } from './types';

interface InitialStateProps {
  evenSearchFilter: EventSearchFilterProps;
}

interface IActionProps {
  type: string;
  data: any;
}

const initialState: InitialStateProps = {
  evenSearchFilter: {
    eventType: "AL",
    eventName: "",
    status: "AC",
    dateFrom: moment(new Date()).subtract(30, 'days').format(),
    dateTo: moment(new Date()).add(30, 'days').format(),
  },
};

export default (state = initialState, action: IActionProps): InitialStateProps => {
  switch (action.type) {
    case SET_EVENT_SEARCH_FILTER:
      return {
        ...state,
        evenSearchFilter: action.data
      };

    default:
      return state;
  }
};