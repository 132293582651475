import React from "react";
import TableStyled from "./Styled";

import Footer from "./Footer";

interface TableProps {
  className?: string;
  loading?: boolean;
  rowKey?: string;
  columns: any | undefined;
  dataSource: object[];
  showHeader?: boolean;
  expandedRowRender?: any;
  expandedRowKeys?: number[];
  onExpand?: (expanded: any, record: any) => void;
  bordered?: boolean;
  expandable?: object;
  paging?: {
    color?: string;
    pageIndex: number;
    totalPages: number;
    pageSize: number;
    totalItemsCount: number;
    setPageIndex: (pageIndex: number) => void;
    setPageSize: (pageSize: number) => void;
  };
  customStyle?: {
    height?: number;
    boldTitle?: boolean;
    noUpperCase?: boolean;
    centerTitle?: boolean;
    centerCellTitle?: boolean;
    isSubTable?: boolean;
  };
  onTableChange?: (
    pagination: unknown,
    filters: unknown,
    sorter: unknown,
    extra: unknown
  ) => void;
  emptyText?: string;
  scrollY?: number
}

const Table: React.FC<TableProps> = (props) => {
  const {
    className = "",
    loading = false,
    columns,
    dataSource,
    rowKey,
    expandedRowRender,
    expandedRowKeys,
    showHeader,
    onExpand,
    paging,
    customStyle,
    onTableChange,
    bordered = false,
    expandable,
    emptyText,
    scrollY
  } = props;
  return (
    <TableStyled
      className={className}
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      pagination={false}
      showHeader={showHeader}
      onExpand={onExpand}
      $customStyle={customStyle}
      loading={loading}
      locale={{
        emptyText: emptyText,
      }}
      expandedRowKeys={expandedRowKeys}
      onChange={onTableChange}
      expandedRowRender={expandedRowRender}
      bordered={bordered}
      expandable={expandable}
      footer={() => <Footer paging={paging} />}
      scroll={{ y: scrollY }}
    />
  );
};

export default Table;
