import React from "react";

import Icon from "components/Icon";
import { FormInstance, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { GroupStyled } from "pages/TransactionList/Styled";
import { PopoverContentStyled } from "styled/PopoverStyled";
import {
  RowStyled,
  FormListStyled,
  SubTitleStyled,
  IconHoverStyled,
  ConditionsStyled,
  FormListItemStyled,
  ConditionGroupStyled,
  ColStyled,
  ColTitleStyled,
  ButtonWithBottomSpacingStyled,
} from "../../Styled";
import DynamicPricingItem from "./DynamicPricing";

interface IProps {
  form: FormInstance;
}

const DynamicPricingGroup: React.FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const addField = (): void => {
    const actions = form.getFieldValue("actions") || [];
    form.setFieldsValue({
      actions: [
        ...actions,
        {
          actionCode: "LG",
          detailId: [],
          discountType: "PC",
          amount: "",
          priority: 1,
        },
      ],
    });
  };

  const hoverContent = () => {
    return (
      <PopoverContentStyled>
        {t("TS.PRIORITY_DESCRIPTION")}
      </PopoverContentStyled>
    );
  };

  return (
    <>
      <SubTitleStyled>{t("SS.CREATE_EVENT.PERFORM_ACTION")}</SubTitleStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={6} xs={6}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.TYPE")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={6} xs={6}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.DETAIL")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={5} xs={5}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.TYPE_DISCOUNT")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={3} xs={3}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.AMOUNT")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={4} xs={4}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.PRIORITY")}
                <Popover content={hoverContent} placement="topLeft">
                  <IconHoverStyled>
                    &ensp;
                    <Icon icon="icon-detail-no-boder" />
                  </IconHoverStyled>
                </Popover>
              </ColTitleStyled>
            </ColStyled>
          </RowStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <FormListStyled name="actions">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <DynamicPricingItem
                      form={form}
                      remove={remove}
                      groupField={field}
                      parentIdx={parentIdx}
                      fieldsLength={fields.length}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
          <FormListItemStyled>
            <ButtonWithBottomSpacingStyled
              onClick={(): void => {
                addField();
              }}
              icon={<Icon icon="icon-plus-ico" />}
              type="default"
              size="small"
            >
              {" "}
              {t("SS.CREATE_EVENT.ADD_ACTION")}
            </ButtonWithBottomSpacingStyled>
          </FormListItemStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
    </>
  );
};

export default DynamicPricingGroup;
