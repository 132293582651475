export const CONNECT_TOKEN = '/connect/token';
export const GET_TOKEN_BY_TOKEN_URL = '/idp/accounts/sso?token={token}';
export const GET_TRANSATION_LIST_URL = '/ads/PaymentTransactions/search';
export const GET_TRANSATION_DETAIL_URL =
  '/ads/PaymentTransactions/details/{id}';
export const GET_CLUB_LIST_URL = '/usr/Operators/{operatorId}/clubs';
export const GET_MEMBER_CREDIT_MASTER_DATA_URL =
  '/cnm/membercreditEvent/masterdata';
export const GET_DYNAMIC_PRICING_MASTER_DATA_URL =
  '/cnm/dynamicpricingevent/masterdata';
export const GET_PREVIEW_EVENT =
  '/cnm/PreviewEvents/MemberCredits?eventId={eventId}&pageIndex={pageIndex}&pageSize={pageSize}';
export const POST_MEMBER_CREDIT_DATA = '/cnm/membercreditEvent';
export const POST_DYNAMIC_PRICING_DATA = '/cnm/dynamicpricingevent';
export const POST_MEMBER_CREDIT_DATA_FILE =
  '/cnm/membercreditevent/createimportfile';
export const POST_DYNAMIC_PRICING_DATA_FILE =
  '/cnm/dynamicpricingevent/createimportfile';
export const GET_EVENT_TYPE = '/cnm/membercreditevent/eventtype';
export const UPDATE_STATUS_EVENT = '/cnm/membercreditevent/{eventId}';
export const GET_EVENT_LIST = '/cnm/membercreditevent/listevent';
export const GET_MEMBER_CREDIT = '/mbs/membercredits/{memberId}';
export const UPDATE_CREDIT_EVENT = '/cnm/membercreditevent/update';
export const UPDATE_CREDIT_EVENT_WITH_FILE =
  '/cnm/membercreditevent/updatewithfile';
export const GET_EVENT = '/cnm/membercreditevent/{eventId}';
export const GET_REASON_TYPE =
  "cnm/lookups/type/{reasonType}";
export const POST_MEMBER_CREDIT = '/cnm/membercreditevent/directmembercredit';
export const UPDATE_EXPIRY_DATE = '/cnm/membercreditevent/directmembercredit';
export const GET_DOWNLOAD_FILE =
  '/cnm/previewevents/download?eventId={eventId}';
export const GET_DOWNLOAD_FILE_GUEST_PASS_EVENT =
  '/cnm/guestpassevent/download?eventId={eventId}';
export const DELETE_EVENT = '/cnm/membercreditevent/{eventId}';
export const GET_DYNAMIC_PRICING_EVENT_DETAIL =
  '/cnm/dynamicpricingevent/preview?eventId={eventId}&pageIndex={pageIndex}&pageSize={pageSize}';
export const GET_APPLICABLE_SALE_LINE_ITEM =
  '/cnm/dynamicpricingevent/items?eventId={eventId}&pageIndex={pageIndex}&pageSize={pageSize}';
export const GET_DYNAMIC_PRICING_DOWNLOAD_FILE =
  '/cnm/dynamicpricingevent/download?eventId={eventId}';
export const DELETE_DYNAMIC_PRICING_EVENT =
  '/cnm/dynamicpricingevent/{eventId}';
export const GET_DYNAMIC_PRICING_EVENT_CONFIG =
  '/cnm/dynamicpricingevent/detail/{eventId}';

export const UPDATE_DYNAMIC_PRICING_EVENT_WITH_FILE =
  '/cnm/dynamicpricingevent/withfile';
export const UPDATE_DYNAMIC_PRICING_EVENT =
  '/cnm/dynamicpricingevent/withoutfile';

export const GET_CLUB_GROUP = '/cnm/GateRule/GetClubgroups';
export const GET_GATE_RULE =
  '/cnm/GateRule/GetGateRules?clubGroupId={clubId}&sortColumn={columnName}&sortType={type}';
export const GET_MASTER_DATA = '/cnm/gaterule/masterdata';
export const INSERT_GATE_CONFIG = '/cnm/gaterule';
export const GET_GATE_RULE_DETAIL = 'cnm/gaterule/{ruleId}';
export const UPDATE_GATE_RULE_STATUS = '/cnm/gaterule/status';
export const EDIT_GATE_RULE = '/cnm/gaterule/detail';
export const GET_MEMBER_BENEFIT_MASTER_DATA_URL =
  'cnm/memberbenefit/masterdata';
export const POST_MEMEBER_BENEFIT_EVENT_DATA = '/cnm/memberbenefit';
export const POST_MEMEBER_BENEFIT_EVENT_DATA_WITH_FILE =
  '/cnm/memberbenefit/createimportfile';

export const GET_MEMBER_BENEFIT_EVENT_PREVIEW_DETAIL =
  '/cnm/memberbenefit/preview?eventId={eventId}&pageIndex={pageIndex}&pageSize={pageSize}';
export const GET_MEMBER_BENEFIT_APPLICABLE_SALE_LINE_ITEM =
  '/cnm/memberbenefit/matchitems?eventId={eventId}&pageIndex={pageIndex}&pageSize={pageSize}';

export const GET_MEMBER_BENEFIT_DOWNLOAD_FILE =
  '/cnm/memberbenefit/download?eventId={eventId}';
export const DELETE_MEMBER_BENEFIT_EVENT = '/cnm/memberbenefit/{eventId}';
export const GET_MEMBER_BENEFIT_EVENT_CONFIG =
  '/cnm/memberbenefit/detail/{eventId}';

export const UPDATE_MEMBER_BENEFIT_EVENT_WITH_FILE =
  '/cnm/memberbenefit/updatewithfile';
export const UPDATE_MEMBER_BENEFIT_EVENT_WITHOUT_FILE = '/cnm/memberbenefit';
export const UPDATE_MEMBER_BENEFIT_EVENT = '/cnm/memberbenefit/withoutfile';
export const GET_MEMBER_BENEFIT_EVENT = '/cnm/memberbenefit/{eventId}';
export const GET_MEMBER_BENEFIT = '/cnm/memberbenefit/direct/transactions';
export const GET_MEMBER_BENEFIT_DETAIL =
  '/cnm/memberbenefit/direct/transaction/detail?memberBenefitsId={memberBenefitsId}';
export const GET_MEMBER_BENEFIT_ITEMS = '/cnm/memberbenefit/items';
export const CREATE_MEMBER_BENEFIT = '/cnm/memberbenefit/directmemberbenefit';
export const UPDATE_MEMBER_BENEFIT_EXPIRY_DATE =
  '/cnm/memberbenefit/direct/expirydateitem';

export const GET_GUEST_PASS_EVENT_MASTER_DATA_URL =
  '/cnm/guestpassevent/masterdata';
export const POST_GUEST_PASS_EVENT_DATA = '/cnm/guestpassevent';
export const POST_GUEST_PASS_EVENT_DATA_WITH_FILE =
  '/cnm/guestpassevent/insertguesteventwithfile';
export const GET_PREVIEW_GUEST_PASS_EVENT =
  '/cnm/guestpassevent/preview?eventId={eventId}&pageIndex={pageIndex}&pageSize={pageSize}';
export const GET_GUEST_PASS_APPLICABLE =
  '/cnm/guestpassevent/actions?eventId={eventId}&pageSize={pageSize}&pageIndex={pageIndex}';
export const GET_GUEST_PASS_EVENT_DETAIL =
  '/cnm/guestpassevent/detail?eventId={eventId}';
export const UPDATE_GUEST_PASS_EVENT = '/cnm/guestpassevent';
export const UPDATE_GUEST_PASS_EVENT_WITH_FILE =
  '/cnm/guestpassevent/updatewithfile';
export const DELETE_GUEST_PASS_EVENT_URL = '/cnm/guestpassevent/{eventId}';
export const CREATE_GUEST_PASS = '/cnm/guestpassevent/directmemberguestpass';
export const GET_GUEST_PASS = '/cnm/guestpassevent/transactions';
export const GET_GUEST_PASS_DETAIL =
  '/cnm/guestpassevent/detailtransaction/{guestPassId}';
export const UPDATE_GUEST_PASS_EXPIRY_DATE =
  '/cnm/guestpassevent/extendexpireddate';
export const DELETE_GATE_RULE = '/cnm/gaterule/{gateId}/{operatorId}';
export const GET_LEDGER_GROUP = '/cnm/memberbenefit/ledgergroups';

export const HISTORY_RESPONSE =
  '/mbs/MemberSurveys/GetHistoryFeedbacksByMemberId';

export const LINK_MEMBER = '/mbs/linkmemberships/{memberId}/linkedMember';

export const HEAD_MEMBER = '/mbs/linkmemberships/{memberId}/headMember';

export const GET_LINKED_TYPES = 'mbs/linkmemberships/linkTypes';
export const EDIT_DELETE_LINKED_MEMBERSHIP = 'mbs/linkmemberships/{parentId}/editLinkedMember/{linkedMemberId}';
export const GET_ALL_ROLE = '/acm/operators/{operatorId}/roles';
export const GET_ALL_CLUBS = '/cnm/clubs';
export const ARCHIVE_RECORD = '/mbs/members/jol/{id}/archive';
export const CONVERT_RECORD = '/mbs/members/jol/confirm';
export const SEARCH_TEMP_MEMBERS = '/mbs/members/jol/temp-members/search';
