import { useMemo } from "react";
import { Between, Type, ValueType } from "utils/constants";

const useInputType = (condition: {
  Operator: string;
  Criteria: string;
  DateType: string;
}) => {
  const inputType = useMemo(() => {
    const isDate = condition.DateType === Type.Date;
    const isDateType = isDate && condition.Operator !== Between;
    const isDateRange = isDate && condition.Operator === Between;
    const isInt = condition.DateType === Type.Int;

    if (isDateType) {
      return ValueType.DateType;
    }

    if (isDateRange) {
      return ValueType.DateRangeType;
    }

    if (isInt) {
      return ValueType.MultiOptionType;
    }
  }, [condition]);

  return { inputType };
};

export default useInputType;
