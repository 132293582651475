import { Button, Col, Row } from "antd";
import styled, { SimpleInterpolation } from "styled-components";

export const RowStyled = styled(Row) <{
  $hasSpacingRight?: boolean;
}>`
  flex-grow: 1;
  padding-left: 5px;
  padding-right: 5px;
  ${(props): SimpleInterpolation => (props.$hasSpacingRight ? 'margin-right: -10px;' : null)
  }
`;

export const ColStyled = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

export const IconButtonContainer = styled.div`
    text-align: center;
    height: 100%;
    padding-top: 5px;
`

export const BlankBackgroundButtonStyled = styled(Button)`
  width: 100%;
  font-size: 11px;
`

export const RoundButtonStyle = styled(Button)`
  border: 1px solid var(--danger-color);
  height: 20px;
  width : 20px;
  &.ant-btn-icon-only {
    min-width: 15px;
    min-height: 15px;
    padding: 0;
    > * {
      font-size: 15px;
      color: var(--danger-color);
      position: relative;
      top: -1px;
    }
  }
}`
export const InputTitleStyled = styled.p`
  font-size: 12px;
  color: var(--gray-color);
  padding-left: 7px;
  margin: 5px 0 0 0;
`;

export const GroupStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;