import { Form } from "antd";
import GroupButtons from "components/GroupButtons";
import { FormContainerStyled } from "pages/CreateUpdateEvent/Styled";
import {  Gate } from "pages/GateRuleDetail/types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import {
  editGateRule,
  getGateRuleDetail,
  insertGateConfig,
} from "services/gate";
import { useAppSelector } from "store";
import { getMasterDataAction } from "store/reducers/Gate/action";
import ButtonStyled from "styled/ButtonStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";
import { NotificationType } from "utils/constants";
import { showNotification } from "utils/helpers";
import { INIT_CONFIG_GATE_RULE } from "./data";
import GateInformation from "./GateInformation";
import RuleSetting from "./RuleSetting";
import { formatFromApi, formatGateConditionsForApi } from "utils/gate";

const ConfigGateRule = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formInitValues, setFormInitValues] = useState<any>(
    INIT_CONFIG_GATE_RULE,
  );

  const { masterData, chosenGates } = useAppSelector((state) => state.gate);
  const { createdBy } = useParams<{ createdBy: string }>();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(Number(id) > 0);

  const onSubmit = async (): Promise<void> => {
    const data = await form.validateFields();
    const conditions = formatGateConditionsForApi(data, masterData);
    const requestData = {
      RuleName: data.RuleName,
      ClubGroupId: data.ClubGroupId,
      Gates: chosenGates,
      RuleType: data.RuleType,
      CreatedBy: createdBy,
      Conditions: conditions,
    };
    const requestFunction = !Number(id) ? insertGateConfig : editGateRule;
    const successMessage = !Number(id)
      ? t("TS.CONFIG_GATE_RULE.SUCCESS")
      : t("TS.CONFIG_GATE_RULE.SUCCESS_EDIT");
    const requestPayload = !Number(id)
      ? requestData
      : { ...requestData, RuleId: Number(id) };

    setLoading(true);
    // @ts-ignore
    requestFunction(requestPayload)
      .then((result) => {
        setLoading(false);
        if (result.isSuccess) {
          showNotification(
            t("TS.SUCCESS"),
            successMessage,
            NotificationType.success,
          );
          history(`/manage-gate?operatorId=${createdBy}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getCurrentGateRule = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getGateRuleDetail(id!);
      const gates = res.gates.map((gate: Gate) => `${gate.gateId}`);
      const currentGateRule: any = {
        RuleName: res.ruleName,
        ClubGroupId: res.clubGroupId.toString(),
        Gates: gates,
        Conditions: formatFromApi(res, masterData),
        RuleType: res.ruleType,
      };
      setFormInitValues(currentGateRule);
      form.setFieldsValue(currentGateRule);
    } finally {
      setLoading(false);
    }
  }, [id, masterData]);

  useEffect(() => {
    if (!masterData) {
      dispatch(getMasterDataAction());
    }
    if (Number(id) && masterData) {
      getCurrentGateRule();
    }
  }, [getCurrentGateRule]);

  if (loading) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  return (
    <Form
      name="basic"
      form={form}
      initialValues={formInitValues}
      layout="vertical"
      hideRequiredMark
    >
      <GateInformation form={form} data={masterData} />
      <RuleSetting form={form} />
      <FormContainerStyled>
        <GroupButtons position="center">
          <ButtonStyled type="default" onClick={() => history(-1)}>
            Cancel
          </ButtonStyled>
          <ButtonStyled type="primary" onClick={onSubmit}>
            Save
          </ButtonStyled>
        </GroupButtons>
      </FormContainerStyled>
    </Form>
  );
};

export default ConfigGateRule;
