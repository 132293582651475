import React from "react";

interface IconProps {
  icon: string;
  $marginTop?: string;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { icon, $marginTop } = props;
  return (
    <span
      className={icon}
      style={{
        marginTop: $marginTop || "0px",
      }}
    />
  );
};

export const IconClock: React.FC = () => (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 41.301 41.301">
    <path
      style={{ fill: "#707070" }}
      d="M20.642,0c5.698,0,10.857,2.317,14.602,6.047c3.73,3.746,6.047,8.905,6.047,14.603  c0,5.698-2.317,10.857-6.047,14.603c-3.746,3.73-8.904,6.047-14.602,6.047S9.786,38.983,6.056,35.253  C2.31,31.507,0.008,26.349,0.008,20.65c0-5.698,2.301-10.857,6.047-14.603C9.786,2.317,14.944,0,20.642,0L20.642,0z M31.166,19.523  c0.619,0,1.111,0.508,1.111,1.127c0,0.619-0.492,1.127-1.111,1.127H20.674h-0.032c-0.413,0-0.778-0.238-0.968-0.571l-0.016-0.016  l0,0l-0.016-0.032l0,0v-0.016l0,0l-0.016-0.032l0,0l-0.016-0.032l0,0v-0.016l0,0l-0.016-0.032l0,0l-0.016-0.016l0,0v-0.032l0,0  l-0.016-0.032l0,0v-0.016l0,0l-0.016-0.032l0,0v-0.032l0,0v-0.016v-0.016l-0.016-0.016l0,0v-0.032l0,0v-0.032l0,0V20.73l0,0v-0.016  l0,0v-0.032l0,0V20.65l0,0V7.206c0-0.619,0.492-1.111,1.111-1.111c0.619,0,1.127,0.492,1.127,1.111v12.317H31.166z M33.657,7.635  c-3.333-3.333-7.936-5.381-13.015-5.381S10.96,4.301,7.627,7.635C4.31,10.968,2.246,15.571,2.246,20.65  c0,5.079,2.063,9.682,5.381,13.016c3.333,3.333,7.936,5.381,13.015,5.381s9.682-2.048,13.015-5.381  c3.333-3.333,5.397-7.936,5.397-13.016C39.054,15.571,36.991,10.968,33.657,7.635L33.657,7.635z"
    />
  </svg>
);

export const FlagIcon: React.FC = () => (
  <svg version="1.1" id="Layer_1" viewBox="0 0 41.301 41.301">
    <g>
      <g>
        <path
          d="M387.126,176l71.467-112c4.267-6.4,4.267-14.933,1.067-21.333C456.46,36.267,448.993,32,441.526,32H82.059V21.333
          C82.059,9.6,72.459,0,60.726,0S39.393,9.6,39.393,21.333V480c0,11.733,9.6,21.333,21.333,21.333
          c10.667,0,20.267-9.6,21.333-20.267V310.4h358.4c11.733,0,21.333-8.533,21.333-21.333c0-5.333-2.133-10.667-5.333-13.867
          L387.126,176z"
        />
      </g>
    </g>
  </svg>
);

export const FitnessIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g
      transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M90 385 c-9 -10 -17 -12 -30 -5 -23 12 -46 -10 -28 -28 9 -9 7 -17
          -7 -32 -15 -16 -17 -25 -8 -38 7 -12 7 -22 -1 -34 -8 -13 -7 -21 2 -30 9 -9
          19 -5 45 20 18 18 37 32 43 32 5 0 47 -38 94 -85 l84 -85 -33 -34 c-22 -22
          -31 -40 -27 -51 5 -13 10 -14 26 -5 15 8 25 8 34 1 10 -8 20 -6 39 9 16 13 28
          16 32 10 3 -5 13 -10 22 -10 12 0 14 7 10 30 -4 16 -2 32 3 35 13 8 10 105 -3
          105 -6 0 -25 -13 -42 -30 l-31 -30 -89 90 -89 90 34 35 c27 28 31 38 22 47
          -10 10 -17 10 -32 0 -12 -7 -21 -8 -25 -2 -9 15 -31 12 -45 -5z"
      />
    </g>
  </svg>
);

export const ComplimentaryIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g
      transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M27 392 c-11 -12 -17 -36 -17 -69 0 -40 5 -54 25 -73 32 -30 32 -50
          0 -80 -21 -19 -25 -33 -25 -76 0 -85 -3 -84 250 -84 215 0 219 0 234 22 24 35
          20 91 -9 126 -32 38 -32 68 0 98 33 30 35 104 5 134 -19 19 -33 20 -233 20
          -195 0 -215 -2 -230 -18z m249 -89 c1 -25 30 -73 44 -73 11 0 37 25 42 42 2 6
          10 8 16 4 14 -8 16 -36 3 -36 -4 0 -16 -20 -25 -45 l-18 -45 -79 0 -80 0 -16
          38 c-8 20 -22 45 -31 55 -12 14 -13 20 -3 30 10 9 17 6 33 -15 11 -16 26 -28
          34 -28 16 0 44 50 44 80 0 30 35 22 36 -7z m74 -178 c0 -13 -15 -15 -92 -13
          -62 2 -93 7 -96 16 -3 9 20 12 92 12 80 0 96 -3 96 -15z"
      />
    </g>
  </svg>
);

export const EntryAccessIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g
      transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M91 378 c-77 -52 -100 -132 -62 -210 27 -56 117 -158 140 -158 23 0
          113 95 140 148 27 52 27 114 0 161 -30 53 -65 74 -129 79 -45 3 -61 0 -89 -20z
          m113 -80 c14 -23 -3 -52 -32 -56 -29 -4 -51 40 -32 63 16 20 49 16 64 -7z m21
          -90 c14 -6 25 -17 25 -24 0 -16 -51 -44 -80 -44 -26 0 -80 27 -80 40 0 17 44
          40 77 40 18 0 44 -5 58 -12z"
      />
    </g>
  </svg>
);

export const CalendarIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g
      transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M67 381 c-2 -11 -15 -21 -28 -23 l-24 -3 0 -170 0 -170 168 -3 167
          -2 10 25 c13 34 13 266 0 299 -6 16 -17 26 -29 26 -11 0 -23 9 -26 20 -3 11
          -12 20 -20 20 -7 0 -15 -9 -18 -20 -5 -18 -14 -20 -80 -20 -66 0 -75 2 -80 20
          -7 25 -33 26 -40 1z m263 -210 c0 -49 -3 -96 -6 -105 -5 -14 -27 -16 -140 -16
          l-134 0 0 105 0 105 140 0 140 0 0 -89z"
      />
      <path d="M90 199 c0 -14 5 -19 17 -17 26 5 29 38 4 38 -15 0 -21 -6 -21 -21z" />
      <path d="M170 199 c0 -14 5 -19 17 -17 26 5 29 38 4 38 -15 0 -21 -6 -21 -21z" />
      <path d="M250 199 c0 -14 5 -19 17 -17 26 5 29 38 4 38 -15 0 -21 -6 -21 -21z" />
    </g>
  </svg>
);

export const NoTouchIcon: React.FC = () => (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 455.111 455.111">
    <path
      style={{ fill: "#E24C4B" }}
      d="M455.111,227.556C455.111,102.4,352.711,0,227.556,0S0,102.4,0,227.556
      s102.4,227.556,227.556,227.556S455.111,352.711,455.111,227.556z"
    />
    <path
      style={{ fill: "#FFFFFF" }}
      d="M227.556,412.444c102.4,0,184.889-82.489,184.889-184.889S329.956,42.667,227.556,42.667
      S42.667,125.156,42.667,227.556S125.156,412.444,227.556,412.444z"
    />
    <path
      style={{ fill: "#3F4448" }}
      d="M334.222,256c-32.711,38.4-46.933,52.622-68.267,69.689v38.4H157.867V332.8
      c-9.956-12.8-17.067-28.444-17.067-44.089V152.178c0-4.267,1.422-7.111,4.267-9.956c2.844-2.844,5.689-4.267,9.956-4.267
      c8.533,0,14.222,7.111,14.222,14.222v56.889c0,2.844,1.422,4.267,4.267,4.267c1.422,0,4.267-1.422,4.267-4.267V128
      c0-4.267,1.422-7.111,4.267-9.956s5.689-4.267,9.956-4.267c8.533,0,14.222,7.111,14.222,14.222v81.067
      c0,2.844,1.422,4.267,4.267,4.267l0,0c1.422,0,4.267-1.422,4.267-4.267v-102.4c0-4.267,1.422-7.111,4.267-9.956
      c2.844-2.844,7.111-4.267,9.956-4.267c8.533,0,14.222,7.111,14.222,14.222v100.978c0,2.844,1.422,4.267,4.267,4.267
      c1.422,0,4.267-1.422,4.267-4.267v-81.067c0-4.267,1.422-7.111,4.267-9.956c2.844-2.844,7.111-4.267,9.956-4.267
      c8.533,0,15.644,7.111,15.644,14.222v125.156l21.333-22.756c2.844-4.267,8.533-5.689,14.222-5.689c4.267,0,9.956,1.422,12.8,5.689
      C339.911,236.089,341.333,247.467,334.222,256z"
    />
    <polygon
      style={{ fill: "#E24C4B" }}
      points="92.444,392.533 62.578,362.667 362.667,62.578 392.533,92.444 "
    />
  </svg>
);
export default Icon;
