import {
  Col,
  Input,
  Row,
  Form,
  Button,
  Select,
  Upload,
  DatePicker,
} from "antd";
import styled, { SimpleInterpolation } from "styled-components/macro";
import ButtonStyled from "styled/ButtonStyled";
import { mixin } from "utils/styles";

const { RangePicker } = DatePicker;

export const FormContainerStyled = styled.div`
  max-width: 917px;
  input,
  textarea {
    ${mixin.placeholderColor("var(--gray-color)")}
  }
  .ant-select-selection-placeholder {
    color: var(--gray-color);
    opacity: 1;
  }
`;
export const InputTitleStyled = styled.p`
  font-size: 12px;
  color: var(--gray-color);
  margin: 5px 0 0 0;
`;

export const TextareaCustomStyled = styled(Input.TextArea)`
  &::-webkit-input-placeholder,
  &:placeholder,
  &::placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    font-style: italic;
  }
`;
export const DashedStyled = styled.hr`
  border-style: dashed;
  color: var(--gray6-color);
`;

export const SessionTitleStyled = styled.p`
  font-size: 14px;
  color: var(--gray-color);
  margin: 5px 0 0 0;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const BorderTitleStyled = styled.p`
  font-size: 13px;
  color: var(--white-color);
  font-weight: bold;
  text-align: center;
  background-color: var(--gray-color);
  padding: 3px;
  border-radius: 5px;
  width: "100%";
`;
export const RowStyled = styled(Row)<{
  $hasSpacingRight?: boolean;
}>`
  flex-grow: 1;
  padding-left: 5px;
  padding-right: 5px;
  ${(props): SimpleInterpolation =>
    props.$hasSpacingRight ? "margin-right: -10px;" : null}
`;
export const ColStyled = styled(Col)<{ $maxHeight?: number }>`
  padding-left: 5px;
  padding-right: 5px;
  ${(props): SimpleInterpolation => `max-height: ${props.$maxHeight}px;`}
`;
export const ColTitleStyled = styled.p<{ $noMarginLeft?: boolean }>`
  font-size: 12px;
  color: var(--gray-color);
  ${(props): SimpleInterpolation =>
    props.$noMarginLeft ? "margin-left: 0;" : "margin-left: -10px;"};
  margin-bottom: 0;
  font-weight: 500;
`;
export const ConditionGroupStyled = styled.div`
  display: flex;
`;
export const ConditionsStyled = styled.div`
  flex-grow: 1;
`;
export const FormListStyled = styled(Form.List)`
  display: flex;
  flex-grow: 1;
`;

export const FormListItemStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormButtonIconStyled = styled(ButtonStyled)`
  min-height: 25px;
  min-width: 27px;
  &.ant-btn-icon-only {
    min-height: 32px;
    min-height: 32px;
    padding: 0;
    width: 30px;
    > * {
      font-size: 20;
    }
  }
  [class^="icon-"] {
    vertical-align: middle;
    color: "red";
  }
  > span:not([class^="icon-"]) {
    margin-left: 3px;
  }
  &.ant-btn-sm {
    [class^="icon-"] {
      font-size: 16
      margin-left: -5px;
    }
  }
`;

export const ButtonWithBottomSpacingStyled = styled(FormButtonIconStyled)``;

export const RoundButtonStyle = styled(Button)`
  border: 1px solid var(--danger-color);
  height: 20px;
  width : 20px;
  &.ant-btn-icon-only {
    min-width: 15px;
    min-height: 15px;
    padding: 0;
    > * {
      font-size: 15px;
      color: var(--danger-color);
      position: relative;
      top: -1px;
    }
  }
}
`;
export const IconButtonContainer = styled.div`
  text-align: right;
  height: 100%;
  padding-top: 5px;
`;

export const ClubGroupSelectStyled = styled(Select)`
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-color: var(--primary);
    }
  }
  &.ant-select-focused.ant-select-multiple {
    .ant-select-selector {
      border-color: var(--primary);
    }
  }
`;

export const PresetGroupSelectStyled = styled(ClubGroupSelectStyled)`
  > div.ant-select-selector {
    height: auto;
  }
`;

export const SelectedOptionStyled = styled.span`
  margin: 5px 0;
  :after {
    content: ", ";
    white-space: pre;
  }
`;

export const BlankBackgroundButtonStyled = styled(Button)`
  width: 100%;
  font-size: 11px;
`;

export const UploadStyled = styled(Upload)`
  .ant-upload-select {
    display: block;
  }
`;

export const DisplayBoxStyled = styled.div`
  height: 32px;
  border: 1px solid var(--gray7-color);
  border-radius: 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
  padding: 0 5px;
`;

export const RangePickerStyled = styled(RangePicker)`
  &.ant-picker-range {
    .ant-picker-range-separator {
      padding: 0;
    }
  }
`;
export const FileTextStyled = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubTitleStyled = styled.p<{ $padding?: number; $margin?: number }>`
  font-size: 13px;
  color: var(--gray-color);
  font-weight: bold;
  margin: 5px;
  padding-left: 5px;
  ${(props): SimpleInterpolation => `padding-left: ${props.$padding}px;`}}
  ${(props): SimpleInterpolation => `margin-left: ${props.$margin}px;`}}
`;

export const IconHoverStyled = styled.div`
  color: var(--secondary-color);
  display: inline;
`;
