import React, { useCallback, useEffect, useState } from "react";
import Icon from "components/Icon";
import moment from "moment";
import { RangePickerStyled } from "pages/CreateUpdateEvent/Styled";
import { ViewMemberSurveyHistoryContainer } from "./Styled";
import { DATE_FORMAT_FOR_SUBMIT } from "utils/constants";
import History, { IHistory } from "models/History";
import { useTranslation } from "react-i18next";
import { getAllHistoryOfMember } from "services/memberBenefitEvent";
import HistoryItem from "./HistoryItem";
import Todo from "models/Todo";
import EmailModal from "./EmailModal";

const ViewMemberSurveyHistory = () => {
  const [histories, setHistories] = useState<History[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = new URLSearchParams(window.location.search);
  const [emailDetail, setEmailDetail] = useState<{ content: string, contactDate: string }[]>([]);
  const [showEmailDetail, setShowEmailDetail] = useState<boolean>(false);
  const { t } = useTranslation();

  const [searchDate, setSearchDate] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract("30", "days"),
    moment(),
  ]);

  const handleChangeSearchDate = (dates: any) => {
    setSearchDate(dates);
  };

  const fetchDataHistory = useCallback(async () => {
    if (params.get("membershipNumber") && params.get("accessCode")) {
      try {
        setIsLoading(true);
        const historyResponse = await getAllHistoryOfMember({
          membershipNumber: params.get("membershipNumber") || "",
          endDate: searchDate[1].format(DATE_FORMAT_FOR_SUBMIT),
          startDate: searchDate[0].format(DATE_FORMAT_FOR_SUBMIT),
        });

        if (historyResponse.isSuccess) {
          setHistories(
            historyResponse.histories.map((data: IHistory) => new History(data))
          );
        } else {
          setHistories([]);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  }, [searchDate]);

  useEffect(() => {
    fetchDataHistory();
  }, [fetchDataHistory]);

  const handleViewEmail = (todo: Todo) => {
    setEmailDetail(
      todo.surveyToDoDetails.map((surveyTodo) => ({
        content: surveyTodo.comment,
        contactDate: surveyTodo.contactDate,
      })));
    setShowEmailDetail(true);
  };

  const getHistoryItemContent = () =>
    histories.length > 0 ? (
      histories.map((history, index) => (
        <HistoryItem
          history={history}
          key={history.memberSurveyEventId + index.toString()}
          onViewEmail={handleViewEmail}
        />
      ))
    ) : (
      <span>{t("TS.NO_HISTORY_FOUND") as string}</span>
    );

  return (
    <>
      <ViewMemberSurveyHistoryContainer>
        <div className="custom-range-date-picker__wrapper">
          <div className="custom-range-date-picker__input">
            <span className="custom-range-date-picker__label">
              Received responses date
            </span>
            <RangePickerStyled
              format="DD/MM/YYYY"
              onChange={handleChangeSearchDate}
              value={searchDate}
              allowClear={false}
              separator={<>{t("TS.TO")}</>}
            />
          </div>
        </div>
        {!isLoading ? getHistoryItemContent() : <Icon icon="loading" />}
      </ViewMemberSurveyHistoryContainer>
      {showEmailDetail && (
        <EmailModal
          onCloseEmailModal={() => setShowEmailDetail(false)}
          emailDetail={emailDetail}
        />
      )}
    </>
  );
};

export default ViewMemberSurveyHistory;
