import React, { useEffect, useState } from "react";

import {
  ConditionGroupStyled,
  ConditionsStyled,
  FormListItemStyled,
  FormListStyled,
  ButtonWithBottomSpacingStyled,
  RowStyled,
  ColStyled,
  ColTitleStyled,
  SubTitleStyled,
} from "../../../CreateUpdateEvent/Styled";

import { Select } from "antd";
import Icon from "components/Icon";
import { ANDOR, MemberListCriteria } from "utils/constants";
import ConditionItem from "./Condition";
import { useTranslation } from "react-i18next";
import { FormItemStyled } from "styled/FormStyled";
import { GroupStyled } from "pages/TransactionList/Styled";

const ConditionGroup: React.FC<ActionsGroupProps> = ({ form }) => {
  const [disableButton, setDisableButon] = useState(false);
  const [conditionFocus, setConditionFocus] = useState<number>();
  const { t } = useTranslation();

  const addField = (): void => {
    const rules = form.getFieldValue("rules") || []; 
    form.setFieldsValue({
      rules: [...rules, {}],
    });
    setConditionFocus(rules.length);
  };

  useEffect(() => {
    const rules = form.getFieldValue("rules") || []; 
    setDisableButon(
      !!(rules.length && rules[0].criteria === MemberListCriteria)
    );
  }, []);

  return (
    <>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={{ span: 4 }} xs={{ span: 4 }}>
              <FormItemStyled name="ruleConditionType">
                <Select options={ANDOR} optionFilterProp="label" />
              </FormItemStyled>
            </ColStyled>
            <ColStyled md={{ span: 20 }} xs={{ span: 20 }}>
              <SubTitleStyled $padding={0} $margin={0}>
                {t("SS.CREATE_EVENT.FOLLOWING_CONDITION")}
              </SubTitleStyled>
            </ColStyled>
          </RowStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={8} xs={{ span: 8 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.CRITERIA")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={7} xs={{ span: 7 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.OPERATOR")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={8} xs={{ span: 8 }}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("SS.CREATE_EVENT.VALUE")}
              </ColTitleStyled>
            </ColStyled>
          </RowStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
      <ConditionGroupStyled>
        <ConditionsStyled>
          <FormListStyled name="rules">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <ConditionItem
                      form={form}
                      remove={remove}
                      groupField={field}
                      parentIdx={parentIdx}
                      fieldsLength={fields.length}
                      setDisableButon={setDisableButon}
                      isFocus={conditionFocus === parentIdx}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
          <FormListItemStyled>
            <ButtonWithBottomSpacingStyled
              onClick={(): void => {
                addField();
              }}
              icon={<Icon icon="icon-plus-ico" />}
              type="default"
              size="small"
              disabled={disableButton}
            >
              {" "}
              {t("SS.CREATE_EVENT.ADD_CONDITION")}
            </ButtonWithBottomSpacingStyled>
          </FormListItemStyled>
        </ConditionsStyled>
      </ConditionGroupStyled>
    </>
  );
};

export default ConditionGroup;
