import { Input, Form, Select } from "antd";
import _ from "lodash";
import styled, { SimpleInterpolation } from "styled-components/macro";

import { font } from "utils/styles";

const { TextArea } = Input;

const commonCSS = `
  line-height: 18px;
  border-radius: 3px;
  color: var(--gray-color);
  font-size: 11px;
  ${font.regular};
  box-shadow: none;
  &:visited,
  &:active,
  &:focus,
  &:hover {
    border-color: var(--primary-color) ;
  }
`;

export const CustomInputStyled = styled(Input)`
  &.ant-input {
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    ${commonCSS}
  }
`;

export const CustomTextAreaStyled = styled(TextArea)`
  &.ant-input {
    ${commonCSS}
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: var(--gray1-color);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray2-color);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-color);
  }
`;

export const FormItemStyled = styled(Form.Item) <{
  $color?: string;
  $errorWidth?: number;
  $width?: number;
  $marginBottom?: number;
}>`

  ${(props): SimpleInterpolation =>
    `width: ${!_.isUndefined(props.$width) ? props.$width + "px" : "auto"};`}
  ${(props): SimpleInterpolation =>
    `margin-bottom: ${!_.isUndefined(props.$marginBottom) ? props.$marginBottom : 10
    }px;`}

  .ant-input-affix-wrapper {
    padding: 0 11px;
    height: 32px;
    .ant-input {
      height: 30px;
    }

    &:hover, 
    &:focus {
      border-color: var(--primary-color);
      box-shadow: unset;
      transition: none;
    }

    .ant-input-suffix {
      font-size: 11px;
      color: rgb(112, 112, 112);
    }
  }
  .ant-input-affix-wrapper-focused  {
   border-color: var(--primary-color);
   box-shadow: unset;
  }
  .ant-radio-group span {
    font-size: 12px;
    color: rgb(112, 112, 112);
  }

  &.full-label {
    .ant-form-item-label {
      label {
        display: flex;
        > span:first-child {
          flex-grow: 1;
        }
      }
    }
  }

  .ant-checkbox-checked {
    &::after {
      border-color: var(--primary-color);
    }
    .ant-checkbox-inner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-color);
  }
  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-picker {
    height: 32px;
    width: 100%;
    padding-right: 10px;
    ${commonCSS}
    &:hover{
      ${(props): SimpleInterpolation =>
    `border-color: ${props.$color ? props.$color : "var(--primary-color)"
    };`}
    }
  }

  .ant-picker-input > input {
    font-size: 11px;
    ${font.regular};
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: var(--gray2-color);
    }
  }
  .ant-select-selection-placeholder {
    font-style: italic;
  }

  .ant-select-focused,
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      .ant-form-item-has-error &, .ant-form-item-has-error & { 
      }
       ${(props): SimpleInterpolation =>
    `border-color: ${props.$color ? props.$color : "var(--primary-color)"
    };`}
      box-shadow: none;
    }
  }

  .ant-select:not(.ant-select-disabled):hover {
    .ant-select-selector:hover {
       ${(props): SimpleInterpolation =>
    `border-color: ${props.$color ? props.$color : "var(--primary-color)"
    };`}
      box-shadow: none;
    }
  }

  .ant-select:not(.ant-select-disabled).ant-select-focused {
    .ant-select-selector {
       ${(props): SimpleInterpolation =>
    `border-color: ${props.$color ? props.$color : "var(--primary-color)"
    };`}
      box-shadow: none;
    }
  }

  .ant-picker:not(.ant-picker-disabled).ant-picker-focused {
    ${(props): SimpleInterpolation =>
    `border-color: ${props.$color ? props.$color : "var(--primary-color)"};`}
    box-shadow: none;
  }

  textarea.ant-input {
    min-height: 70px;
  }

  .ant-input-number-input {
    height: 32px;
    padding: 0;
  }

  .ant-form-item-label {
    ${font.regular}
    margin-bottom: 0;
    padding-bottom: 3px;
    &,
    & > label {
      font-size: 11px;
      color: var(--gray-color);
    }
  }

  .ant-form-item-explain, .ant-form-item-extra{
      color: var(--danger-color);
      font-size: 11px; 
  }, 
  
  .ant-form-item-has-error {
    .ant-input,
    .ant-input-number,
    .ant-select-selector,
    .ant-picker { 
      box-shadow: none;
    }

    .ant-form-item-explain {
      color: var(--danger-color);
      font-size: 11px;
      line-height: 16px;
      ${(props): SimpleInterpolation => `width: ${props.$errorWidth}px;`}
    }
    .ant-form-item-explain-success{
      animation: none !important;
    }
    .ant-show-help-leave{
      animation: none !important;
    }
  }

  .ant-form-item-label > label.ant-form-item-required {
    &:before {
      display: none;
    }

    &:after {
      margin-left: 4px;
      color: var(--danger-color);
      content: "*";
    }
  }

  .ant-select {
    .ant-select-arrow {
      font-size: 22px;
      font-family: "icomoon" !important;
      speak: never;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      &:after {
        content: "\\e901";
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--gray-color);
      }
      > span {
        opacity: 0;
        display: none;
      }
  }
`;

export const InputGroupStyle = styled(Input.Group)`
  label {
    font-size: 12px;
    color: rgb(112, 112, 112);
  }

  .ant-input-group-compact {
    margin-left: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-group.ant-input-group-compact > * {
    vertical-align: center;
  }
`;

export const DropDownDateTypeInputStyled = styled(Select)`
  margin-left: 10px;
`;
