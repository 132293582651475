import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from "antd";
import moment from "moment";

import { routeUrls } from "utils/routes";
import { formatNumber, formatUTCTime, getCurrentCurrency } from "utils/helpers";
import { DATE_TIME_FORMAT } from "utils/constants";
import HeadingGroup from "components/HeadingGroup";
import InfoBlock from "components/Icon/InfoBlock";
import { GroupStyled } from "pages/TransactionList/Styled";
import { getTransactionDetails } from "services/transaction";
import ButtonStyled from "styled/ButtonStyled";
import { SpinStyled, SpinWrapperStyled } from "styled/SpinLoader";

const TransactionDetail = () => {
  const [transactionInfo, setTransactionInfo] =
    useState<TransactionDataProps>();
  const memberName = useMemo(() => {
    if (!transactionInfo) {
      return "";
    }
    return `${transactionInfo.firstName} ${transactionInfo.lastName}`;
  }, [transactionInfo]);

  const currencyInfo = useMemo(() => {
    return getCurrentCurrency();
  }, [getCurrentCurrency]);

  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();

  const getData = useCallback(async (id: string) => {
    try {
      const res = await getTransactionDetails(id!);
      setTransactionInfo(res);
    } catch {}
  }, []);

  useEffect(() => {
    getData(id!);
  }, [id]);

  if (!transactionInfo) {
    return (
      <SpinWrapperStyled>
        <SpinStyled />
      </SpinWrapperStyled>
    );
  }

  return (
    <>
      <HeadingGroup title={t("SS.TRANSACTION.DETAIL.TITLE")} />
      <InfoBlock
        infos={[
          { key: t("SS.TRANSACTION.MEMBER_NAME"), value: memberName },
          {
            key: t("SS.TRANSACTION.MEMBER_NUMBER"),
            value: transactionInfo.membershipNumber,
          },
          {
            key: t("SS.TRANSACTION.DATE_TIME"),
            value: moment(formatUTCTime(transactionInfo.createdUtc)).format(
              DATE_TIME_FORMAT
            ),
          },
          {
            key: t("SS.TRANSACTION.PSP_REFERENCE"),
            value: transactionInfo?.pspRef,
          },
          {
            key: t("SS.TRANSACTION.AMOUNT"),
            value: formatNumber(transactionInfo?.amount || 0, currencyInfo),
          },
          {
            key: t("SS.TRANSACTION.RESPONSE_MESSAGE"),
            value: transactionInfo?.isSuccess
              ? t("SS.TRANSACTION.APPROVED")
              : t(transactionInfo?.messages || ""),
          },
        ]}
      />
      <Row gutter={16}>
        <Col className="gutter-row" xs={{ span: 24 }}>
          <GroupStyled>
            <ButtonStyled
              type="primary"
              title={t("SS.BACK")}
              onClick={() => {
                history(
                  `${routeUrls.transactionList}?Name=${memberName}&MembershipNumber=${transactionInfo.membershipNumber}&MemberId=${transactionInfo.memberId}`
                );
              }}
            >
              {t("SS.BACK")}
            </ButtonStyled>
          </GroupStyled>
        </Col>
      </Row>
    </>
  );
};

export default TransactionDetail;
