import { Col, Form, Input, Row, Select } from "antd";
import GroupButtons from "components/GroupButtons";
import HeadingGroup from "components/HeadingGroup";
import Icon from "components/Icon";
import {
  ButtonWithBottomSpacingStyled,
  ColTitleStyled,
  FormContainerStyled,
  FormListItemStyled,
  FormListStyled,
} from "pages/CreateUpdateEvent/Styled";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {
  addMemberBenefit as addMemberBenefitService,
  getLedgerList,
  getMemberBenefitItems,
} from "services/memberBenefitEvent";
import { getReasonType } from "services/transaction";
import ButtonStyled from "styled/ButtonStyled";
import { DownLineStyled } from "styled/EventStyled";
import { FormItemStyled } from "styled/FormStyled";
import {
  DAY_VALUE,
  ITEMS,
  ITEM_LEDGER_OPTION,
  LEDGER,
  NotificationType,
  ReasonType,
  OPTION_REASON_OTHERS,
} from "utils/constants";
import { showNotification, showNotificationWithCallback } from "utils/helpers";
import ActionItem from "./ActionItem";
import { ColStyled, GroupStyled, InputTitleStyled, RowStyled } from "./Styled";

const InitalData = {
  reasonCode: null,
  lookupAndReasonCodeID: null,
  actions: [
    {
      ledgerGroupIds: [],
      itemIds: [],
      quantity: null,
      validDuration: null,
      durationType: DAY_VALUE,
    },
  ],
};

const AddMemberBenefit: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useNavigate();
  const [reasonOptions, setReasonOptions] = useState<OptionProps[]>([]);
  const [otherInput, setOtherInput] = useState<boolean>(false);
  const [items, setItems] = useState<OptionProps[]>([]);
  const [optionType, setOptionType] = useState(ITEMS);
  const customReason = (reasons: ReasonProps[]): OptionProps[] => {
    const reasonTypeList = reasons.map((reason) => ({
      label: reason.reasonName,
      value: reason.lookupAndReasonCodeID,
      code: reason.reasonCode,
    }));
    reasonTypeList.push(OPTION_REASON_OTHERS);
    return reasonTypeList;
  };

  const { operatorId, memberId, audience } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "",
      memberId: params.get("memberId") || "",
      audience: params.get("audience") || "",
    };
  }, [window.location.search]);

  const getReason = async (): Promise<void> => {
    const res = await getReasonType(ReasonType.MB_CODE);
    setReasonOptions(customReason(res));
  };

  useEffect(() => {
    getReason();
  }, []);

  const getItems = async () => {
    try {
      const res = await getMemberBenefitItems();
      setItems(
        res.map((item: any) => ({
          label: item.itemName,
          value: item.itemId,
        }))
      );
    } catch {

    }
  };

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ InitalData });
    addField();
  }, [form]);

  const onSubmit = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const { reasonCode, actions, lookupAndReasonCodeID, otherReason } =
        values;
      actions.shift();
      if (optionType === ITEMS) {
        actions.map((element: any) => {
          element.ledgerGroupIds = [];
          return element;
        });
      } else {
        actions.map((element: any) => {
          element.itemIds = [];
          return element;
        });
      }
      let changeReason;

      if (reasonCode === OPTION_REASON_OTHERS.label) {
        changeReason = {
          reasonText: otherReason,
          reasonCode,
          reasonType: ReasonType.MB_CODE,
        };
      } else {
        changeReason = {
          reasonType: ReasonType.MB_CODE,
          reasonCode,
          lookupAndReasonCodeID,
        };
      }

      const dataSubmit = {
        actions,
        changeReason,
        operatorId,
        memberId,
      };

      await addMemberBenefitService(dataSubmit);
      showNotificationWithCallback(
        t("TS.SUCCESS"),
        t("TS.ADD_MEMBER_BENEFIT.SUCCESSFULLY_DESCRIPTION"),
        NotificationType.success,
        3,
        () => {
          if (audience === "pos") {
            history(-1);
          } else {
            window.parent.location.href =
              _env.REACT_APP_EMBEDDED_BASE_URL +
                "/Members/MembershipDetails.aspx" || "";
          }
        }
      );
    } catch (error) { }
  };

  const addField = (): void => {
    const actions = form.getFieldValue("actions") || [];
    form.setFieldsValue({ actions: [...actions, { durationType: DAY_VALUE }] });
  };

  const onChangeReason = (_: string, item: any): void => {
    setOtherInput(item.code === OPTION_REASON_OTHERS.label ? true : false);
    form.setFieldsValue({ reasonCode: item.code });
  };

  const onChangeItems = (option: string) => {
    if (option === ITEMS) {
      getItems();
    } else if (option === LEDGER) {
      getOptionLedgeGroup();
    }
    setOptionType(option);
  };

  const getOptionLedgeGroup = async () => {
    try {
      const response = await getLedgerList();
      setItems(
        response.map((ledger) => ({
          label: ledger.ledgerGroupName,
          value: ledger.ledgerGroupId,
        }))
      );
    } catch (error) {
      showNotification(t("TS.ERROR"), t("TS.ERROR"), NotificationType.error);
    }
  };

  return (
    <>
      <HeadingGroup title={t("TS.ADD_MEMBER_BENEFIT")} />
      <Form
        name="basic"
        form={form}
        initialValues={InitalData}
        layout="vertical"
        hideRequiredMark
      >
        <DownLineStyled />
        <FormContainerStyled>
          <Row gutter={24}>
            <Col className="d-flex" md={{ span: 2 }} xs={{ span: 2 }}></Col>
            <Col className="d-flex" md={{ span: 5 }} xs={{ span: 5 }}>
              <InputTitleStyled>{t("SS.REASON")}</InputTitleStyled>
            </Col>
            <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
              <FormItemStyled name="reasonCode" hidden noStyle>
                <Input type="hidden"></Input>
              </FormItemStyled>
              <FormItemStyled
                name="lookupAndReasonCodeID"
                rules={[
                  {
                    required: true,
                    message: t("TS.MEMBER_BENEFIT.REQUIRED_FIELD", {
                      key: t("TS.MEMBER_BENEFIT.REASON_TYPE"),
                    }),
                  },
                ]}
              >
                <Select
                  options={reasonOptions}
                  onChange={onChangeReason}
                  placeholder={t("TS.MEMBER_BENEFIT.SELECT_PLACE", {
                    key: t("TS.MEMBER_BENEFIT.REASON_TYPE"),
                  })}
                />
              </FormItemStyled>
            </Col>
            {otherInput ? (
              <>
                <Col className="d-flex" md={{ span: 7 }} xs={{ span: 7 }}></Col>
                <Col className="gutter-row" md={{ span: 14 }} xs={{ span: 14 }}>
                  <FormItemStyled
                    name="otherReason"
                    rules={[
                      {
                        required: true,
                        message: t("TS.MEMBER_BENEFIT.REQUIRED_FIELD", {
                          key: t("TS.ADD_MEMBER_BENEFIT.OTHER_REASON"),
                        }),
                      },
                      {
                        min: 3,
                        message: t("SS.EVENT_MANAGEMENT.MINIMUM_INPUT", {
                          key: 3,
                        }),
                      },
                      {
                        max: 100,
                        message: t("SS.EVENT_MANAGEMENT.MAXIMUM_INPUT", {
                          key: 100,
                        }),
                      },
                    ]}
                  >
                    <Input
                      autoFocus
                      autoComplete="off"
                      placeholder={t("TS.MEMBER_BENEFIT.INPUT_PLACE", {
                        key: t("TS.MEMBER_BENEFIT.OTHER_REASON"),
                      })}
                    />
                  </FormItemStyled>
                </Col>
              </>
            ) : null}
          </Row>
        </FormContainerStyled>
        <FormContainerStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={2} xs={{ span: 2 }}></ColStyled>
            <ColStyled md={6} xs={{ span: 6 }}>
              <FormItemStyled name="items" $width={150}>
                <Select
                  options={ITEM_LEDGER_OPTION}
                  onChange={onChangeItems}
                  placeholder={t("TS.MEMBER_BENEFIT.SELECT_PLACE", {
                    key: t("TS.MEMBER_BENEFIT.REASON_TYPE"),
                  })}
                  defaultValue={ITEMS}
                />
              </FormItemStyled>
            </ColStyled>
            <ColStyled md={4} xs={{ span: 4 }} $marginTop={true}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.MEMBER_BENEFIT.QUANTITY")}
              </ColTitleStyled>
            </ColStyled>
            <ColStyled md={7} xs={{ span: 7 }} $marginTop={true}>
              <ColTitleStyled $noMarginLeft={true}>
                {t("TS.MEMBER_BENEFIT.VALID_DURATION")}
              </ColTitleStyled>
            </ColStyled>
          </RowStyled>
          <FormListStyled name="actions">
            {(fields, { remove }): JSX.Element => (
              <>
                {fields.map((field, parentIdx) => (
                  <GroupStyled key={field.fieldKey}>
                    <ActionItem
                      optionType={optionType}
                      form={form}
                      remove={remove}
                      groupField={field}
                      parentIdx={parentIdx}
                      fieldsLength={fields.length}
                      itemOptions={items}
                    />
                  </GroupStyled>
                ))}
              </>
            )}
          </FormListStyled>
        </FormContainerStyled>
        <FormListItemStyled>
          <RowStyled $hasSpacingRight={true}>
            <ColStyled md={18} xs={{ span: 18 }}></ColStyled>
            <ColStyled md={4} xs={{ span: 4 }}>
              <ButtonWithBottomSpacingStyled
                onClick={(): void => {
                  addField();
                }}
                icon={<Icon icon="icon-plus-ico" />}
                type="default"
                size="small"
              >
                {t("SS.CREATE_EVENT.ADD_ACTION")}
              </ButtonWithBottomSpacingStyled>
            </ColStyled>
          </RowStyled>
        </FormListItemStyled>
        <FormContainerStyled>
          <GroupButtons position="center">
            <ButtonStyled type="default" onClick={() => history(-1)}>
              {t("SS.CANCEL")}
            </ButtonStyled>
            <ButtonStyled type="primary" onClick={onSubmit}>
              {t("SS.SAVE")}
            </ButtonStyled>
          </GroupButtons>
        </FormContainerStyled>
      </Form>
    </>
  );
};
export default AddMemberBenefit;
