import { EditOutlined } from "@ant-design/icons";
import { ConfigProvider, Popover } from "antd";
import TableParent from "components/Table";
import TableStyled from "components/Table/Styled";
import moment from "moment";
import {
  AlignTextStyled,
  BlankIconButtonStyled,
  Boundary,
  Container,
  EmptyListStyled,
  HeaderTitleStyled,
  TransactionListStyled,
} from "pages/ViewMemberCreditSpending/Styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGuestPass, getGuestPassDetail } from "services/guestPass";
import { TitleColumnButtonStyled } from "styled/ButtonStyled";
import { PopoverContentStyled, PopoverTextStyled } from "styled/PopoverStyled";
import { DATE_FORMAT, EVENT_LIST_PAGING } from "utils/constants";
import { formatDate, onFormatSeparateThousand } from "utils/helpers";
import ChangeGuestPassExpiryDate from "./ChangeExpiryDate";

const ViewGuestPassSpending: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [pagingIndex, setPageIndex] = useState(0);
  const [pagingData, setPagingData] = useState(EVENT_LIST_PAGING);
  const [data, setData] = useState<GuestPassSpendingProps[]>([]);
  const [pageSize, setPageSize] = useState(EVENT_LIST_PAGING.pageSize);
  const [sortType, setSortType] = useState("Descending");
  const [sortColumn, setSortColumn] = useState("CreatedDate");
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [guestPassDetail, setGuestPassDetail] = useState<any>({});
  const [detailLoading, setDetailLoading] = useState<any>({});
  const [guestPassId, setGuestPassId] = useState<number>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [expiryDate, setExpiryDate] = useState<string>("");
  const [amountAvailable, setAmountAvailable] = useState<string>("0");

  const { operatorId, memberId } = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      operatorId: params.get("operatorId") || "",
      memberId: params.get("memberId") || "",
    };
  }, [window.location.search]);

  const onOpenChangeExpiryDate = (item: any) => {
    setGuestPassId(item.memberGuestPassId);
    setExpiryDate(item.expiredDate);
    setIsOpenModal(true);
  };

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getGuestPass({
        memberId,
        pageIndex: pagingIndex,
        pageSize,
        columnName: sortColumn,
        sortType,
      });

      setData(
        res.transactions.map((item: any, key: number) => ({
          ...item,
          key: key,
        }))
      );
      setPagingData({
        ...res.paging,
        totalItemsCount: res.paging.totalTransactionsCount,
      });
      setLoading(false);
      setAmountAvailable(res.totalRemainingAvailable.toString());
      setExpandedRowKeys([]);
    } catch {
      setLoading(false);
    }
  }, [pagingIndex, pageSize, sortType, sortColumn]);

  const hoverContent = (eventName: string) => {
    return <PopoverContentStyled>{eventName}</PopoverContentStyled>;
  };

  const columns = useMemo(
    () => [
      {
        title: (
          <AlignTextStyled>{t("SS.EVENT_MANAGEMENT.NO.")}</AlignTextStyled>
        ),
        key: "index",
        render: (value: any, item: any, index: number) => (
          <AlignTextStyled>{index + 1 + pagingIndex * 10}</AlignTextStyled>
        ),
        width: 10,
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.EVENT_MANAGEMENT.EVENT_NAME")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "EventName",
        dataIndex: "eventName",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,
        render: (eventName: string, item: any) => (
          <Popover content={hoverContent(eventName)} placement="topLeft">
            <PopoverTextStyled $color={"var(--navi-color)"}>
              {eventName}
            </PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.EVENT_MANAGEMENT.EVENT_REASON")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "EventReason",
        dataIndex: "eventReason",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 50,
        render: (eventReason: string, item: any) => (
          <Popover content={hoverContent(eventReason)} placement="topLeft">
            <PopoverTextStyled $color={"var(--navi-color)"}>
              {eventReason}
            </PopoverTextStyled>
          </Popover>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("TS.GUEST_PASS_EVENT.GUEST_PASS")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "GuestPass",
        dataIndex: "quantity",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (credit: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(credit.toString())}
          </AlignTextStyled>
        ),
        width: 20,
      },

      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REDEEM_DATE")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "CreatedDate",
        dataIndex: "createdDate",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 20,
        render: (createdDate: string) => (
          <AlignTextStyled $textAlign={"center"}>
            {formatDate(createdDate)}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.REMAINING")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "Remaining",
        dataIndex: "remaining",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (remaining: number) => (
          <AlignTextStyled $textAlign={"right"}>
            {onFormatSeparateThousand(remaining.toString())}
          </AlignTextStyled>
        ),
        width: 20,
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.EXPIRED_DATE")}
          </TitleColumnButtonStyled>
        ),
        sorter: true,
        key: "ExpiredDate",
        dataIndex: "expiredDate",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (expiredDate: string, item: any) => (
          <AlignTextStyled $textAlign={"center"}>
            {moment(expiredDate).utcOffset(0).format(DATE_FORMAT)}
            <BlankIconButtonStyled
              disabled={item.remaining ? false : true}
              onClick={() => onOpenChangeExpiryDate(item)}
            >
              <EditOutlined />
            </BlankIconButtonStyled>
          </AlignTextStyled>
        ),
        width: 100,
      },
    ],
    [t]
  );

  const onSort = (_: any, __: any, sort: any) => {
    const order = sort?.order;
    if (order == "ascend") {
      setSortType("Ascending");
    } else {
      setSortType("Descending");
    }
    setSortColumn(sort.columnKey);
  };

  const columnsSubTable = useMemo(
    () => [
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("TS.GUEST_PASS.GUEST_PASS_CODE")}
          </TitleColumnButtonStyled>
        ),
        key: "guestPassCode",
        dataIndex: "guestPassCode",
        render: (guestPassCode: number) => (
          <AlignTextStyled $textAlign={"center"}>
            {guestPassCode}
          </AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("TS.GUEST_PASS.GUEST_NAME")}
          </TitleColumnButtonStyled>
        ),
        key: "guestName",
        dataIndex: "guestName",
        render: (guestName: string) => (
          <AlignTextStyled $textAlign={"center"}>{guestName}</AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.STATUS")}
          </TitleColumnButtonStyled>
        ),
        key: "status",
        dataIndex: "status",
        render: (status: number) => (
          <AlignTextStyled $textAlign={"center"}>{status}</AlignTextStyled>
        ),
      },
      {
        title: (
          <TitleColumnButtonStyled $color={"var(--navi-color)"}>
            {t("SS.MEMBER_CREDIT_SPENDING.DATE")}
          </TitleColumnButtonStyled>
        ),

        key: "usedDate",
        dataIndex: "usedDate",
        render: (usedDate: string) => (
          <AlignTextStyled $textAlign={"center"}>
            {moment(usedDate).format(DATE_FORMAT) === "01/01/0001"
              ? null
              : moment(usedDate).format(DATE_FORMAT)}
          </AlignTextStyled>
        ),
      },
    ],
    [t]
  );

  const getGuestPassesDetail = useCallback(async () => {
    if (typeof guestPassId !== "number") {
      return;
    }
    try {
      setDetailLoading((oldLoading: any) => ({
        ...oldLoading,
        [guestPassId]: true,
      }));
      const result = await getGuestPassDetail(guestPassId.toString());
      setGuestPassDetail((oldGuestPassDetail: any) => ({
        ...oldGuestPassDetail,
        [guestPassId]: result,
      }));
      setDetailLoading((oldDetailLoading: any) => ({
        ...oldDetailLoading,
        [guestPassId]: false,
      }));
    } catch (error) {
      setDetailLoading((oldDetailLoading: any) => ({
        ...oldDetailLoading,
        [guestPassId]: false,
      }));
    }
  }, [guestPassId]);

  useEffect(() => {
    getGuestPassesDetail();
  }, [getGuestPassesDetail]);

  useEffect(() => {
    getData();
  }, [getData]);

  const expandedRow = (row: any) => {
    const guestPassId = row.memberGuestPassId;

    const details = guestPassDetail[guestPassId] || [];
    const isLoading = detailLoading[guestPassId] || false;

    return (
      <ConfigProvider renderEmpty={() => customizeRenderEmpty(true)}>
        <TableStyled
          rowKey="guestPassCode"
          columns={columnsSubTable}
          loading={isLoading}
          dataSource={details}
          $customStyle={{
            isSubTable: true,
            noUpperCase: true,
          }}
          pagination={false}
        />
      </ConfigProvider>
    );
  };

  const onToggleModal = (toggle: boolean) => {
    setIsOpenModal(toggle);
    getData();
    setGuestPassId(undefined);
  };

  const customizeRenderEmpty = (isExpandRow: boolean) => (
    <EmptyListStyled>
      <AlignTextStyled>
        {isExpandRow
          ? t("TS.NO_TRANSACTION_FOUND")
          : t("TS.NO_GUEST_PASS_FOUND")}
      </AlignTextStyled>
    </EmptyListStyled>
  );

  const onTableRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setGuestPassId(record.memberGuestPassId);
      setExpandedRowKeys([...expandedRowKeys, record.key]);
    } else {
      setGuestPassId(undefined);
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
    }
  };

  return (
    <Boundary id="memberBenefitTable">
      <ConfigProvider renderEmpty={() => customizeRenderEmpty(false)}>
        <Container>
          <HeaderTitleStyled $fontSize={11}>
            {t("TS.GUEST_PASS_SPENDING.TOTAL_QUANITTY_AVAILABLE", {
              key: onFormatSeparateThousand(amountAvailable),
            })}
          </HeaderTitleStyled>
          <TransactionListStyled>
            <TableParent
              columns={columns}
              dataSource={data}
              key="key"
              loading={loading}
              onExpand={onTableRowExpand}
              expandedRowKeys={expandedRowKeys}
              expandedRowRender={expandedRow}
              customStyle={{
                height: 84,
                noUpperCase: true,
                boldTitle: true,
                centerTitle: true,
              }}
              paging={{
                ...pagingData,
                pageSize,
                color: "var(--navi-color)",
                setPageIndex: setPageIndex,
                setPageSize: setPageSize,
              }}
              onTableChange={onSort}
            />
          </TransactionListStyled>
        </Container>
      </ConfigProvider>
      <ChangeGuestPassExpiryDate
        isOpenModal={isOpenModal}
        setOpenModal={(isOpen) => onToggleModal(isOpen)}
        guestPassId={guestPassId}
        operatorId={operatorId}
        expiryDate={expiryDate}
        memberId={memberId}
      />
    </Boundary>
  );
};

export default ViewGuestPassSpending;
