import styled from 'styled-components/macro';

const ForbiddenStyled = styled.div`
  max-width: 100px;
  margin: 50px auto 0;
`;

export const ForbiddenContentStyled = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

export default ForbiddenStyled;
